import { useQuery } from 'react-query'
import { GameCategoryInterface } from '../../shared/interfaces/gameInterfaces';
import { BaseQueryProps } from '../../shared/interfaces/liveCasinoInterfaces';
import { getCardCategories, getCardCategoriesByRoute } from './lobby';



interface CardCategoriesProps extends BaseQueryProps {
  pageTitle?: string;
  pageUrl?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface ResponseTypes {
  data: {
    categories: GameCategoryInterface[];
  };
  isLoading: boolean;
}

const useCardCategories = (customerId: string, userToken: string, props?: CardCategoriesProps): ResponseTypes => {
  const params = {
    language: 'EN',
    ...props
  }
  if (customerId) params.customerId = customerId
  if (userToken) params.userToken = userToken
  const { data, isLoading } = useQuery(
    ['card-categories-games', customerId],
    () => getCardCategories(params)
  )
  return { data, isLoading }
}

const useCardCategoriesByRoute = (categoryName: string, customerId: string, token: string): ResponseTypes => {
  const params: BaseQueryProps = {
    language: 'EN',
    categoryName,
  }

  if (customerId) params.customerId = customerId
  if (token) params.userToken = token
  const { data, isLoading } = useQuery(
    ['card-categories-by-route', categoryName],
    () => getCardCategoriesByRoute(params)
  )
  return { data, isLoading }
}

export {
  useCardCategories,
  useCardCategoriesByRoute
}