import { useMemo, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useAuth , getToken} from '../../authentication'
import { COOKIE_CONFIGS, COOKIE_EXPIRY_DAYS, CLASSES } from './constants'
import { sendOptin } from '../../asyncData'
import { EVENTS } from '../../business'



const useXtremePush = () => {
  const { initialized, logged, customerId, customerSub } = useAuth()
  const { pathname } = useLocation()
  const validCookieConfigs = useMemo(
    () =>
      COOKIE_CONFIGS.filter(
        config => config.CkActive && config.CkAuthenticate === logged,
      ),
    [logged],
  )

  const setXtremePushCookie = useCallback(() => {
    const cookieConfig = validCookieConfigs.find(
      config =>
        (config.CkEqualPath && config.CkPath === pathname) ||
        // REVIEW not startsWith?
        (!config.CkEqualPath && pathname.includes(config.CkPath)),
    )

    if (cookieConfig) {
      // REVIEW do we really need to add and remove classes to/from the body?
      const body = document.querySelector('body')
      if (Cookies.get(cookieConfig.CkName) === false) {
        body.classList.remove(logged ? CLASSES.LOGGED_IN : CLASSES.LOGGED_OUT)
      } else {
        body.classList.add(logged ? CLASSES.LOGGED_IN : CLASSES.LOGGED_OUT)
        Cookies.set(cookieConfig.CkName, cookieConfig.CkAuthenticate, {
          expires: COOKIE_EXPIRY_DAYS,
          path: cookieConfig.CkPath,
          domain: window.location.hostname,
        })
      }
    }
  }, [logged, pathname, validCookieConfigs])

  useEffect(() => {
    if (initialized) {
      setXtremePushCookie()
    }
  }, [initialized, setXtremePushCookie])


  useEffect(() => {
    const optinHandler = ({ detail }) => {
      if (detail.eventName === 'OPT-IN' && logged) {
        sendOptin(
          detail.payload.campaignId, // It comes from XP
          detail.payload.brandId, // It comes from XP
          customerId,
          customerSub,
          getToken(),
        );
      }
    };
    // triggers opt-in event when user is logged-in
    if (window.xtremepush && logged) {
      window.xtremepush('event', EVENTS.MARKETING.OPTIN.OPEN)
    }
    document.addEventListener(EVENTS.MARKETING.OPTIN.CLICK, optinHandler);

    return () => document.removeEventListener(EVENTS.MARKETING.OPTIN.CLICK, optinHandler);
  },[logged, customerId, customerSub])
}

export default useXtremePush
