import React, { ReactNode } from 'react'
import cx from 'classnames'
import useStyles from './grid.styles'

interface GridProps {
  children: ReactNode;
  type?: string;
  className?: string;
  gridRef?: (() => void) | { current: any};
}
const Grid: React.FC<GridProps> = ({ children, type, className, gridRef }) => {
  const classes = useStyles()
  const gridClasses = cx(classes.grid, classes[type], className)

  return <div className={gridClasses} ref={gridRef}>{children}</div>
}


export default Grid
