import merge from 'deepmerge'
import baseTheme from '../baseTheme'
import brandPalette from './palette'
import typography from './typography'
import noResultsSvg from '../../../assets/icons/no-results-alt.svg?url'
import { getExternalSiteUrl } from '../../../config'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () =>{
  return <img src={noResultsSvg} alt='no-results'></img>
}

const sportsBettingPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    typography,
    brand: {
      namespace: 'sbnasite',
      name: 'Sports Betting',
      website: 'sportsbetting.ag',
      email: 'cssupport@sportsbetting.ag',
      help: 'https://help.wildcasino.ag/',
      themeColor: palette.quaternary800,
      bonusInfoUrl: 'https://help.wildcasino.ag/bonus-tutorial-and-faq/',
      balanceWidget: {
        name: 'sportsbetting',
        colors: {
          balanceState: palette.primary,
          balanceCloseState: palette.white,
        },
      },
      freebet: false,
    },
    zIndex: {
      appBar: 1030,
    },
    fontFamily: {
      primaryFont: 'Arimo',
      secondaryFont: 'Lato',
    },
    fontSize: {
      xl: typography.fontSize?.xl,
      xxl: typography.fontSize?.xxl,
    },
    fontWeight: {
      normal: typography.fontWeight.regular,
      bold: typography.fontWeight.bold,
    },
    buttons: {
      primaryFrontColor: palette.quaternary800,
      primarySolidColor: baseTheme.palette.neutral1,
      primarySolidBg: palette.tertiary900,
      secondarySolidColor: palette.sbDarkGraphite,
      secondarySolidBg: palette.primary800,
      iconColorDisabled: baseTheme.palette.neutral1_16,
      ghostSolidBg: 'transparent',
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: baseTheme.palette.neutral1,
      hover: {
        primarySolidBg: palette.tertiary900,
        ghostSolidBg: baseTheme.palette.neutral1,
        ghostSolidColor: palette.primaryBlue,
      },
    },
    mainPageContentSpec: {
      paddingTop: '0rem',
      paddingBottom: '4.5rem',
    },
    gradients: {
      flatGradientYellow: `linear-gradient(to bottom, ${palette.btnPrimary}, ${palette.btnPrimary})`,
      flatGradientYellowHover: `linear-gradient(to bottom, ${palette.btnPrimaryHover}, ${palette.btnPrimaryHover})`,
      secondaryGradientDark: `linear-gradient(to bottom, ${palette.secondary900}, ${palette.secondary800})`,
    },
    tags: {
      tagBgNew: '#028247',
      tagBgExclusive: palette.placeholder,
    },
    errorPage: {
      color: palette.primaryBlue,
      weight: 700,
      goToHomeButtonVariant: 'primary',
    },
    card: {
      background: palette.primaryBlue,
    },
    loader: {
      color: palette.primaryBlue,
      fontWeightSemiBold: typography.fontWeight.bold,
    },
    bonusTable: {
      headerSize: 'xl',
      headColor: baseTheme.palette.neutral1,
      headRadius: '.25rem .25rem 0 0',
      headBgColor: palette.primaryBlue,
      tableVariant: 'light',
      rowBg: palette.tableBg,
      action: 'secondary',
      fontWeight: typography.fontWeight.regular,
      detailsWrapperBg: palette.quaternary700,
      routePath: '/casino-bonus',
    },
    markdown: {
      contentColor: palette.primaryBlue,
    },
    paymethod: {
      background: palette.primaryBlue,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: baseTheme.palette.neutral3,
      variant: 'minimal',
      textColor: palette.darkBlueCustom,
    },
    pageContentBg: {
      light: palette.quaternary700,
    },
    pageHeading: {
      textTransform: 'title',
      color: palette.primaryBlue,
      fontWeight: typography.fontWeight.bold,
      bgColor: 'transparent',
    },
    selectInput: {
      border: `1px solid ${palette.gray700}`,
      // @ts-ignore TODO: gray700 does not exist on palette, validate the correct attribute.
      borderColorHover: baseTheme.palette.gray700,
      iconColor: baseTheme.palette.neutral3,
      color: baseTheme.palette.neutral3,
      bgColor: baseTheme.palette.neutral1,
    },
    shape: {
      borderRadius: '.15rem',
    },
    appBar: {
      background: palette.primaryBlue,
      logoWidth: '45px',
      logoWidthMd: '115px',
    },
    blackjack: {
      iconColor: palette.primaryBlue,
      iconColorHover: palette.secondary900,
    },
    infoModal: {
      background: palette.primaryBlue,
    },
    mobileMenuToggle: {
      bgColor: palette.secondary800,
    },
    carouselsTitle: {
      iconColor: palette.primaryBlue,
      linkColor: palette.primaryBlue,
    },
    promotions: {
      bgColor: 'transparent',
      fontColor: baseTheme.palette.neutral1,
      titleColor: palette.cardSubtitle,
      subtitleColor: palette.secondary900,
      arrowsColor: palette.quaternary800,
      button: {
        bgColor: palette.btnSecondary,
        hover: palette.hoverBtn,
      },
    },
    searchBar: {
      background: palette.inputBg,
      closeIconColor: palette.primaryBlue,
      svgColor: palette.primaryBlue,
    },
    input: {
      color: palette.primaryBlue,
      borderColor: palette.primaryBlue,
      borderColorHover: palette.btnSecondary,
    },
    linkCard: {
      bgColor: palette.primary,
      bgColorVisited: palette.primary,
      bgColorHover: baseTheme.palette.neutral1,
      activeColor: palette.primary,
      hoverColor: palette.primary,
    },
    breadcrumb: {
      color: palette.primary,
      currentColor: palette.primary800,
    },
    navigation: {
      linkSelected: palette.secondary500,
      linkDefault: palette.secondary150,
      linkHover: palette.secondary500,
    },
    edgeTier: {
      breakpoint: 'sm',
      bottom: '6rem',
    },
    summary: {
      backgroundColor: palette.primaryBlue,
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'external',
      url: `${getExternalSiteUrl()}/cashier/deposit`,
    },
    intercom: {
      brand: 'sportsbetting',
    },
    hasCasinoBonusSection: true,
    searchModal: {
      headerBg: '#123333',
      active: false,
    },
    switch: {
      thumbColor: baseTheme.palette.neutral1,
      thumbColorChecked: palette.primaryBlue,
      trackColor: baseTheme.palette.neutral3_60,
      trackColorChecked: palette.primaryBlue,
    },
  }
}

export default sportsBettingPartialTheme
