import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme =>  ({
  pageContents: {
    paddingTop: theme.custom?.mainPageContentSpec?.paddingTop || '3.5rem',
    paddingBottom: theme.custom?.mainPageContentSpec?.paddingBottom || '2.75rem',
    [mq('sm')]: {
      paddingTop: theme.custom?.mainPageContentSpec?.paddingTop || '4.5rem',
      paddingBottom: theme.custom?.mainPageContentSpec?.paddingBottom || '4.5rem',
    },
  },
  clearMargin: {
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      // TODO this margin set as important is temporal, since when we go with the new nav, 
      // we will need to remove the marginTop added on src/ui/config/createMuiTheme.ts:L135
      // which is the one making us to override it with this marginTop important.
      // the specific code that will be removed is 
      // `marginTop: theme.universalNavigation?.config?.appMarginTop || 0,`
      marginTop: '0 !important'
    },
  },
  // dark: {},
  light: {
    backgroundColor: theme?.custom?.pageContentBg?.light || theme.custom?.palette.neutral1_5,
  },
}))

export default useStyles
