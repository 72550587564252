import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  markdownContent: {
    color: theme.custom.markdown?.contentColor || theme.custom.palette.neutral1_87,
    backgroundColor: theme.custom.markdown?.background || 'transparent',
    fontSize: theme.custom.fontSize.md,
    '& p': {
      lineHeight: theme.custom.lineHeight.md,
      margin: '0.5rem 0',
      [mq('sm')]: {
        marginBottom: '0.75rem',
      },
    },
    '& li': {
      marginLeft: '1.5625rem',
      lineHeight: theme.custom.lineHeight.md,
      margin: '0.25rem 0',

      '& input': {
        marginRight: '0.3125rem',
      },
    },
    '& ul': {
      margin: '0.5rem 0',
      padding: 0,
      '& li': {
        listStyle: 'disc',
      },
    },
    '& ol': {
      margin: '0.5rem 0',
      padding: 0,
      '& li': {
        listStyle: 'decimal',
      },
    },
    '& table': {
      borderCollapse: 'collapse',
      margin: '0.75rem 0 1rem 0',
      '& tr': {
        '&:nth-child(even)': {
          backgroundColor: theme.custom.markdown?.evenTableBg || theme.custom.palette.neutral1_5,
        },
      },
      '& td': {
        ...texts.stepDown1,
        padding: '0.875rem 1rem 0.8125rem',
        borderBottom: `1px solid ${theme.custom.palette.neutral1_16}`,
      },
      '& th': {
        ...texts.stepDown1,
        fontWeight: theme.custom.fontWeight.bold,
        textTransform: 'uppercase',
        padding: '0.75rem 1rem',
        borderBottom: `2px solid ${theme.custom.palette.neutral1_87}`,
      },
      [mq('sm')]: {
        margin: '1rem 0 1.5rem 0',
      },
    },
  },
}))

export default useStyles
