import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '../Icon'
import useStyles from './errorNote.styles'

const ErrorNote = props => {
  const classes = useStyles()
  const errorClasses = cx(
    classes.errorBox,
    props.size && classes[props.size],
    props.type && classes[props.type],
  )

  return (
    <div className={errorClasses}>
      <Icon name="info" />
      <span className={classes.errorText}>{props.children}</span>
    </div>
  )
}

ErrorNote.defaultProps = {
  size: 'small',
  type: 'light',
}

ErrorNote.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['dark', 'light']),
  children: PropTypes.node,
}

export default ErrorNote
