import Env from '@env';
import { getDataIdState } from './constants'

export class EdgetTierProvider {

  #currentID

  #EDGE_TIER_SCRIPT_ID_DOM = 'EdgeTierScript'
  #EDGE_TIER_BUTTON_ID = 'edge-tier-chat'

  #WIDGET_VARIABLE = {
    TIER: 10,
    NAME: 9,
    CUSTOMER_ID: 8
  }

  #DATA_ID_STATE = getDataIdState(Env.get('VITE_GSETTING'))
  #COMPANY_NAME_WIDGET = Env.get('VITE_EDGETIER_COMPANY_NAME') || 'csops';

  // Setup Id Retry Policy
  #SETUP_ID_RETRIES_COUNT = 0
  #SET_UP_ID_RETRY_POLICY = {
    MAX_NUMBERS_OF_RETRIES: 5,
    TIMEOUT_BETEWEEN_RETRIES: 500
  }
  #timeoutInstance

  async start(props) {
    const { isInitialize, show } = props
    return new Promise((res, rej) => {
      try {
        if (!isInitialize) {
          const scriptTag = document.createElement('script')
          scriptTag.setAttribute("src", `https://cdn.edgetier.com/${this.#COMPANY_NAME_WIDGET}.js`);
          scriptTag.setAttribute('defer', 'defer')
          scriptTag.setAttribute("id", this.#EDGE_TIER_SCRIPT_ID_DOM)
          scriptTag.setAttribute("data-hidden", true)
          document.getElementsByTagName('head')[0].appendChild(scriptTag)
          scriptTag.addEventListener('load', ()=> {
            this.#setIdAndStoreVariables({ show })
            return res()
          })
        }
        this.#setIdAndStoreVariables({ show })
        return res()
      } catch (error) {
        return rej()
      }  
    })
  }

  #setIdAndStoreVariables({logged, isVIP, customerId, tier, show, username}) {
    let newID = this.#getID({logged, isVIP, tier})
    if (newID !== this.#currentID && window.EdgeTierChat && logged !== undefined) {
      if(logged && (customerId === undefined || tier === undefined)) return
      this.#currentID = newID
      this.#setupId(newID, () => {
        customerId && this.#storeVariable(this.#WIDGET_VARIABLE.CUSTOMER_ID, customerId)
        tier && this.#storeVariable(this.#WIDGET_VARIABLE.TIER, tier)
        username && this.#storeVariable(this.#WIDGET_VARIABLE.NAME, username)
        show && this.show()
      })
    }
  }

  #getID(params) {
    const { logged } = params
    return (logged ) ? this.#DATA_ID_STATE.LOGGED_IN : this.#DATA_ID_STATE.LOGGED_OUT
  }

  #setupId(id, cb) {
    window.EdgeTierChat?.setSetupId(id, (error)=> {
      if (error && this.#SETUP_ID_RETRIES_COUNT < this.#SET_UP_ID_RETRY_POLICY.MAX_NUMBERS_OF_RETRIES) {
        this.#SETUP_ID_RETRIES_COUNT += 1
        return this.#timeoutInstance = setTimeout(() => {
          this.#setupId(id,cb)
        }, this.#SET_UP_ID_RETRY_POLICY.TIMEOUT_BETEWEEN_RETRIES);
      }
      this.#timeoutInstance && clearTimeout(this.#timeoutInstance)
      this.#SETUP_ID_RETRIES_COUNT = 0
      cb()
    })
  }

  #storeVariable(key, value) {
    window.EdgeTierChat?.storeVariable(key, value)
  }

  updateVariables({data, show, isLoading}) {
    const { isVIP, LiveChatInformation, logged } =  data
    const tier = LiveChatInformation?.SegmentMax
    const customerId = LiveChatInformation?.CustomerID
    const username = LiveChatInformation?.Username
    this.#setIdAndStoreVariables({logged, isVIP, tier, customerId, show, isLoading, username})
  }

  show() {
    if (!this.#doesButtonExists()) {
      window.EdgeTierChat?.open();
    }
  }

  #doesButtonExists() {
    return !!document.getElementById(this.#EDGE_TIER_BUTTON_ID)
  }

  hide() {
    // Yet to be implemented by the provider
  }
}
