import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './bottomTicker.styles'

const BottomTicker = ({ jackpot, size }) => {
  const classes = useStyles()
  
  return (
    <div className={`${classes.bottomTicker} ${size}`}>
      <p>{jackpot}</p>
    </div>
  )
}

BottomTicker.propTypes = {
  jackpot: PropTypes.string,
  size: PropTypes.string,
}

export default BottomTicker
