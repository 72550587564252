import { useEffect, useState } from 'react';
import { getToken, useAuth } from '../authentication';
import { getGame } from '../asyncData';
import { isScrollEnabled as hasScroll, UrlDataInterface } from 'src/context/Game/InfoModal/utils';


const useHandleLiveGame = (cardId: number) => {
    const { logged, username, customerSub, redirectToLogin } = useAuth()

const [gameData, setGameData] = useState<UrlDataInterface>()
const [isGameOpen, setGameOpen] = useState(false)

const handleOpen = () => {  
  if (logged) {
    openGame(cardId)
  } else {
    redirectToLogin()
  }
}
const openGame = async (gameCardId: number) => {
  const userToken = getToken()
  // to-do: Replace with new /launch
  const data = await getGame(
    gameCardId,
    username,
    userToken,
    customerSub,
  )
  const isScrollEnabled = hasScroll(data)
  setGameData({...data, isScrollEnabled})
}

const handleClose = () => {
  setGameData(null)
  setGameOpen(false)
}

useEffect(() => {
  if (gameData && gameData?.launchURL) {
    setGameOpen(true)
  }
},[gameData,isGameOpen])


return {
    handleOpen,
    handleClose,
    isGameOpen,
    gameData
}
}


export default useHandleLiveGame