import { makeStyles } from '../materialUiWrapper'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  navigation: {
    display: 'flex',
    padding: 0,
    overflowY: 'hidden',
    overflowX: 'auto',
    margin: 0,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& $navigationItem:not(:first-child)': {
      marginLeft: 2,
    },
  },
  searchItem: {
    color: theme.custom.palette.neutral1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.custom.navigation?.linkDefault || theme.custom.palette.neutral1_5,

    [hoverDevice()]: {
      '&:hover': {
        background:  theme.custom.navigation?.linkHover || theme.custom.palette.neutral1_12,
      }
    },
  },
  searchModal:{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: theme.custom.palette.quaternary700,
    overflowY: 'auto',
  },
  searchLink: {
    padding: '1rem',
  },
  searchIcon: {
    width: '44px',
    height: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [mq('sm')]: {
      width: '55.95px',
      height: '55.95px',
    },
    '& > svg': {
      width: '1.55rem',
      height: '1.55rem',
      color: theme.custom.palette.neutral1,
      fill: theme.custom.palette.neutral1
    }
  },
  navigationItem: {
    flex: 1,
    height: '2.875rem',
    position: 'relative',
    [mq('sm')]: {
      height: '3.5rem',
    },
  },
  navigationLink: {
    display: 'block',
    position: 'relative',
    whiteSpace: 'nowrap',
    padding: '1rem 0.75rem',
    background: theme.custom.navigation?.linkDefault || theme.custom.palette.neutral1_5,
    color: theme.custom.palette.neutral1,
    textAlign: 'center',
    fontSize: theme.custom.fontSize.xxs,
    fontWeight: theme.custom.fontWeight.semibold,
    letterSpacing: '0.0313rem',
    textTransform: 'uppercase',
    transition: 'background 300ms ease',

    [mq('sm')]: {
      padding: '1.25rem 1.25rem',
      fontSize: theme.custom.fontSize.xs,
      lineHeight: theme.custom.lineHeight.sm,
    },
    [hoverDevice()]: {
      '&:hover': {
        background:  theme.custom.navigation?.linkHover || theme.custom.palette.neutral1_12,
      }
    },
    '&.active': {
      background: theme.custom.navigation?.linkSelected || theme.custom.palette.neutral1_12,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 2,
        background:  theme.custom.palette.neutral1,
      },
    }
  },
  navListText: {
    display: 'block',
    minWidth: '5rem',
    [mq('sm')]: {
      minWidth: '6.5rem',
    },
  },
  navigationLinkSelected: {
    background: theme.custom.navigation?.linkSelected || theme.custom.palette.neutral1_12,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 2,
      background: theme.custom.navigation?.afterBg || theme.custom.palette.neutral1,
    },
  },
  navigationLinkDisabled: {
    color: theme.custom.palette.neutral1_38,
    pointerEvents: 'none',
  },
}))

export default useStyles
