import React from 'react'
import useReferralLink from '../../../hooks/useReferralLink'
import { LayoutProps } from './types/LayoutProps'


const Layout: React.FC<LayoutProps> = ({children}) => {
  useReferralLink()

  return <>{children}</>
}

export default Layout