import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.custom.promotions?.bgColor,
    marginTop:'40px',
    display: 'flex',
    width: '100%',
    paddingBottom: '3.5rem',
    position: 'relative',
    minHeight: '18.25rem',
    color: theme.custom.promotions?.fontColor,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  h2: {
    fontFamily: theme.custom.fontFamily.primaryFont,
    fontSize: '30px',
    color: theme.custom.promotions?.titleColor,
    textAlign: 'center',
    marginBottom: '4px',
    lineHeight:'40px',
  },
  h3: {
    color: theme.custom.promotions?.subtitleColor,
    fontSize:'18px',
    lineHeight:'26px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default useStyles