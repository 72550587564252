import { ComponentPropsWithRef, forwardRef } from 'react'
import cx from 'classnames'

import useStyles from './input.styles'

type Ref = HTMLInputElement;

interface InputProps extends ComponentPropsWithRef<'input'> {
  hasError?: boolean;
  className?: string;
  hasRightIcon?: boolean;
  hasLeftIcon?: boolean;
  variant?: string;
}

const Input = forwardRef<Ref, InputProps>((props, ref) => {
  const { hasError, hasRightIcon, className, hasLeftIcon, variant, ...rest } = props
  const classes = useStyles({ variant })

  const inputClasses = cx(
    classes.input,
    hasError && classes.hasError,
    hasRightIcon && classes.hasRightIcon,
    hasLeftIcon && classes.hasLeftIcon,
    className
  )

  return <input className={inputClasses} ref={ref} {...rest} />
})

export default Input
