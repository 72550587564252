import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Collapse } from '@material-ui/core'

import { useTranslations, useCurrency } from '../../i18n'

import Box from '../components/Box'
import Autocomplete from '../components/Autocomplete'
import Icon from '../components/Icon'
import Button from '../components/Button'

import { buildBonusExtraData } from './utils'
import useStyles from './bonusWalletMobile.styles'

const ItemRow = props => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()
  const itemClasses = cx(classes.item, open && classes.isVisible)

  return (
    <div className={itemClasses}>
      <div className={classes.info}>
        <button className={classes.button} onClick={() => setOpen(!open)}>
          <Icon name="chevron-right" />
        </button>
        <div className={classes.details}>
          <strong className={classes.title}>{props.title}</strong>
          <span className={classes.id}>{props.subtitle}</span>
          <div className={classes.feature}>
            <span className={classes.featureLabel}>{props.label}</span>
            {props.value}
          </div>
        </div>
        <div className={classes.action}>{props.children}</div>
      </div>
      <Collapse in={open}>
        <div className={classes.features}>
          {Object.entries(props.features).map(([key, value], index) => (
            <div key={index} className={classes.feature}>
              <span className={classes.featureLabel}>{key}</span>
              {/* TODO: define correctly type for value and avoid casting  */}
              {value as React.ReactNode}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  )
}

ItemRow.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  features: PropTypes.object.isRequired,
}

const BonusWalletMobile = props => {
  const formatMessage = useTranslations()
  const formatCurrency = useCurrency()
  return (
    <Box padded={false}>
      {props.bonuses.map((data, index) => (
        <ItemRow
          key={index}
          title={data.Description}
          subtitle={data.BonusAccountId}
          label={
            data.BonusType === 'Free Spins'
              ? formatMessage('noun:remaining-spins')
              : formatMessage('noun:current-balance')
          }
          value={
            data.BonusType === 'Free Spins'
              ? data.RemainigSpins
              : formatCurrency(data.Balance)
          }
          features={buildBonusExtraData(data, true)}
        >
          {data.BonusType === 'Deposit Bonus' ? (
            <Button height="small" widthBehaviour="full">
              {formatMessage('action:play-now')}
            </Button>
          ) : (
            <Autocomplete
              options={data.Games}
              placeholder={formatMessage('action:select-game')}
              onChange={() => console.log('onchange')}
            />
          )}
        </ItemRow>
      ))}
    </Box>
  )
}

BonusWalletMobile.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default BonusWalletMobile
