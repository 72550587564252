import Env from '@env';
import { OriginSingleton } from '../../config/originSingleton';
import parseEnvBoolean from '../../config/parseEnvBoolean';
import { isMobile, isTablet } from '../../libs/uaparser';

type DeviceType = 'mobile' | 'tablet' | 'desktop';
enum DeviceTypeIds {
  desktop = 1,
  mobile = 2,
  tablet = 3,
}
const mirrorsEnabled = parseEnvBoolean('VITE_MIRROR_FF_ENABLED')
const instance = OriginSingleton.getInstance()
const mirrorId = mirrorsEnabled && instance.isMirror() ? instance.mirrorId : undefined
/**
 * Get the user device.
 * @returns DeviceType
 */
const getUserDevice = (): DeviceType => {
  if (isMobile()) {
    return 'mobile';
  }
  if (isTablet()) {
    return 'tablet';
  }
  return 'desktop';
}

const getDeviceTypeId = () => {
  const deviceType = getUserDevice();

  return DeviceTypeIds[deviceType];
}

/**
 * Check if the user's device is desktop
 * @returns object 
 */
const deviceBrandId = (): { deviceTypeId: DeviceTypeIds, brandId: number, mirrorId?: string } | never => {
  const brandIdStr = Env.get('VITE_BRAND_ID');
  if (!brandIdStr || isNaN(Number(brandIdStr))) {
    throw new Error('VITE_BRAND_ID is not defined or not a valid number');
  }

  const brandId = Number(brandIdStr);
  const deviceType: DeviceType = getUserDevice();

  return { deviceTypeId: DeviceTypeIds[deviceType], brandId, mirrorId };
}

export { getUserDevice, deviceBrandId, getDeviceTypeId };
