import { makeStyles } from '../../../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  resultsCtn: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: '6%',
  }
}))

export default useStyles
