import { makeStyles } from '../../../materialUiWrapper'
import { slideTheme } from '../../../../config/slideTheme'
import { mq } from '../../../../config/utils'


const useStyles = makeStyles(theme => ({
  titleCtn: {
    display: 'grid',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    display: 'contents',
    height: '1.813rem',
    fontSize: slideTheme.title.fontSize.xs,
    fontWeight: 600,
    color: 'white',
    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.6)',
    textTransform: 'uppercase',
    '& label': {
      verticalAlign: 'sub',
    },

    [mq('sm')]: {
      fontSize: slideTheme.title.fontSize.sm,
    },
    [mq('md')]: {
      fontSize: slideTheme.title.fontSize.md,
    }
  },
  subtitle: {
    height: '0.813rem',
    fontSize: slideTheme.subtitle.fontSize.xs,
    fontWeight: 600,
    color: 'white',
    textTransform: 'uppercase',
    '& label': {
      verticalAlign: 'sub',
    },

    [mq('sm')]: {
      fontSize: slideTheme.subtitle.fontSize.sm,
    },
    [mq('md')]: {
      fontSize: slideTheme.subtitle.fontSize.md,
    }
  },
}))

export default useStyles
