import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createTheme } from '@material-ui/core/styles'
import texts from './texts'

const spacing = factor => `${factor}rem`
const createMuiTheme = ({ breakpoints: bp, ...theme }) => {
  const breakpoints = createBreakpoints(bp)
  const arimoFont = {
    fontFamily: 'Arimo',
    fontStyle: 'bold',
    fontWeight: 700,
    src: `url('/assets/fonts/arimo/arimo-bold.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const arimoFontFallback = {
    fontFamily: 'Arimo-bold',
    fontStyle: 'bold',
    fontWeight: 700,
    src: `url('/assets/fonts/arimo/arimo-bold.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const arimoRegular = {
    fontFamily: 'Arimo',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url('/assets/fonts/arimo/arimo-regular.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const latoRegular = {
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    src: `url('/assets/fonts/lato/lato-regular.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const lato = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    src: `url('/assets/fonts/lato/lato-regular.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const latoItalic = {
    fontFamily: 'Lato-Italic',
    fontStyle: 'italic',
    src: `url('/assets/fonts/lato/lato-italic.ttf')`,
    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  }

  const options = {
    breakpoints,
    typography: {
      fontFamily: [
        'inherit',
        arimoFont.fontFamily,
        arimoRegular.fontFamily,
        arimoFontFallback.fontFamily,
        latoRegular.fontFamily,
        lato.fontFamily,
        latoItalic.fontFamily].join(','),
    },
    spacing,
    shape: {
      borderRadius: spacing(0.25),
      borderRadiusLarge: spacing(0.5),
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      sideMenu: 1450,
      tooltip: 1500,
    },
    transitions: {
      duration: {
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
        short: 250,
        shorter: 200,
        shortest: 150,
        standard: 300,
        enter: 600,
        exit: 400,
      },
    },
    overrides: {
      MuiCssBaseline: {

        '@global': {
          ':root': {
            '--duotone-1': theme.palette.tertiary500,
            '--success': theme.palette.success,
          },
          '@font-face': [arimoFont, arimoRegular, arimoFontFallback, latoItalic, latoRegular, lato],
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            height: '100%',
            width: '100%',
            'overscroll-behavior': 'none',
          },
          body: {
            fontFamily: [
              '"Open Sans"',
              '-apple-system',
              'BlinkMacSystemFont',
              'Segoe UI',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
            ],

            backgroundColor: theme.elementColors.appBackground,
            width: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            margin: 0,
            padding: 0,
            minHeight: '100%',
            'overscroll-behavior': 'none',
          },
          '#app': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            position: 'relative',
            zIndex: 1,
            '& main': {
              flexGrow: 1,
              marginTop: 0,
              [breakpoints.up('sm')]: {
                marginTop: theme.universalNavigation?.config?.appMarginTop || 0,
              }
            },
          },
          'h1, h2, h3, h4, h5, h6, p': {
            margin: 0,
          },
          '.icon': {
            fill: theme.palette.neutral1,
            width: '1em',
            height: '1em',
            verticalAlign: 'middle',
            overflow: 'hidden',
          },
          img: {
            maxWidth: '100%',
            display: 'block',
          },
          'ul, ol': {
            listStyle: 'none',
          },
          a: {
            textDecoration: 'none',
            color: 'inherit',
          },
          code: {
            whiteSpace: 'initial',
          },
          'input[type="text"], input[type="email"], input[type="password"], input[type="search"]': {
            fontSize: 'inherit',
          },
          '::placeholder': {
            fontFamily: 'inherit',
          },
          [breakpoints.down(theme.edgeTier?.breakpoint)]: {
            "#edge-tier-chat:not(.edge-tier-chat-open)": {
              bottom: theme.edgeTier?.bottom,
            },
            ".ck-logged-out #edge-tier-chat:not(.edge-tier-chat-open)": {
              bottom: theme.edgeTier?.bottomloggedOut
            },
          },
        },
      },
      MuiSkeleton: {
        text: {
          transform: 'none',
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: theme.palette.neutral3_70,
        },
      },
      MuiDrawer: {
        paper: {
          padding: 0,
        },
      },
      MuiSwitch: {
        switchBase: {
          color: theme?.switch?.thumbColor,
        },
        colorSecondary: {
          '&$checked': {
            color: theme?.switch?.thumbColorChecked,
          }
        },
        track: {
            backgroundColor: theme?.switch?.trackColor,

            '$checked$checked + &': {
              backgroundColor: theme?.switch?.trackColorChecked,
            }
        }
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: theme?.checkbox?.color,
            
            '&:hover': {
              backgroundColor: theme?.checkbox?.colorHover,
            }
          },
          '&:hover': {
            backgroundColor: theme?.checkbox?.colorHover,
          }
        },
      },
      MuiMenu: {
        paper: {
          padding: 0,
        },
      },
      MuiListItem: {
        root: {
          whiteSpace: 'break-spaces',

          '&$selected': {
            backgroundColor: theme.palette.neutral3_16,
          },

          '&$button': {
            whiteSpace: 'break-spaces',

            '&:hover': {
              backgroundColor: theme.palette.neutral3_5,
            },
          },
        },
      },
      MuiPaper: {
        root: {
          padding: '0.25rem 1rem',
          color: theme.palette.gray,
        },
      },
      MuiInput: {
        root: {
          '&$disabled': {
            borderColor: theme.palette.neutral1_16,
            color: theme.palette.neutral1_16,

            '&:hover': {
              borderColor: theme.palette.neutral1_16,
            },
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: theme.palette.neutral1_87,

          '&$outlined': {
            transform: 'translate(19px, 20px) scale(1)',

            '&$shrink': {
              transform: 'translate(19px, -6px) scale(0.75)',
            },
          },
        },
      },
      MuiFormLabel: {
        root: {
          ...texts.stepDown1,

          '&$focused': {
            color: theme.palette.neutral1_87,
          },
        },
      },
      MuiSelect: {
        root: {
          padding: '1.1875rem 2.5rem 1.1875rem 1.1875rem',

          '&$disabled': {
            color: theme.palette.neutral1_16,
          },
        },
        select: {
          '&$select': {
            paddingRight: '2.5rem',
          },
        },
        selectMenu: {
          color: theme.palette.neutral1_87,
        },
        icon: {
          color: theme.palette.neutral1_87,
          right: '1rem',

          '&$disabled': {
            color: theme.palette.neutral1_16,
          },
        },
      },
      MuiPagination: {
        ul: {
          justifyContent: 'center',
        },
      },
      MuiPaginationItem: {
        root: {
          minWidth: '1.875rem',
          margin: 0,
          color: theme.palette.neutral1_87,

          [breakpoints.up('sm')]: {
            minWidth: '2rem',
            margin: '0 3px',
          },

          '&$disabled': {
            color: theme.palette.neutral1_38,
          },
        },
        page: {
          color: theme.palette.neutral1_87,

          '&:hover': {
            color: theme.palette.neutral1_87,
            backgroundColor: theme.palette.neutral1_5,
          },

          '&$selected': {
            color: theme.palette.neutral1_87,
            backgroundColor: theme.palette.neutral1_16,

            '&:hover': {
              backgroundColor: theme.palette.neutral1_16,
            },
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          color: 'currentColor',
          '& $notchedOutline': {
            border: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          color: 'currentColor',
        },
      },
      MuiAutocomplete: {
        root: {
          width: '100%',
          border: `2px solid ${theme.palette.neutral1_16}`,
          borderRadius: '0.25rem',
          fontSize: theme.fontSize.sm,
          lineHeight: theme.lineHeight.xs,
          color: theme.palette.neutral1_87,
          backgroundColor: 'transparent',
          outline: 'none',

          '&:hover': {
            borderColor: theme.palette.neutral1_60,
            '&$hasPopupIcon&$hasClearIcon': {
              '& $inputRoot': {
                '&[class*="MuiOutlinedInput-root"]': {
                  paddingRight: 65,
                },
              },
            },
          },
          '&:focus': {
            borderColor: theme.palette.neutral1_87,
            borderWidth: '2px',
            '&$hasPopupIcon&$hasClearIcon': {
              '& $inputRoot': {
                '&[class*="MuiOutlinedInput-root"]': {
                  paddingRight: 65,
                },
              },
            },
          },
          '&$hasPopupIcon&$hasClearIcon': {
            '& $inputRoot': {
              '&[class*="MuiOutlinedInput-root"]': {
                paddingRight: '2.5rem',
              },
            },
          },
        },
        inputRoot: {
          fontSize: theme.fontSize.sm,
          lineHeight: theme.lineHeight.xs,

          '& $input$inputFocused:first-child': {
            padding: 0,
          },
          '&[class*="MuiOutlinedInput-root"]': {
            paddingTop: '0.4375rem',
            paddingBottom: '0.4375rem',
          },
        },
        input: {
          height: 'auto',
          color: theme.palette.neutral1_87,

          '&::placeholder': {
            color: theme.palette.neutral1_87,
            opacity: 1,
          },
        },
        clearIndicator: {
          borderRadius: 0,
        },
        popupIndicator: {
          '& path': {
            d:
              "path('M19.293 7.29301C19.683 6.90301 20.317 6.90301 20.707 7.29301C21.097 7.68301 21.097 8.31701 20.707 8.70701L12.707 16.707C12.317 17.097 11.683 17.097 11.293 16.707L3.29299 8.70701C2.90299 8.31701 2.90299 7.68301 3.29299 7.29301C3.68299 6.90301 4.31699 6.90301 4.70699 7.29301L12 14.586L19.293 7.29301Z')",
          },
        },
        popper: {
          width: 'calc(100% - 1.5rem) !important',
          left: '0.5rem !important',
          marginTop: '0.625rem',

          [breakpoints.up('sm')]: {
            width: '376px !important',
            left: '0 !important',
          },
        },
        paper: {
          maxHeight: '14rem',
          padding: 0,

          [breakpoints.up('sm')]: {
            maxHeight: '22rem',
          },
        },
      },
      MuiSvgIcon: {
        root: {
          '&$fontSizeSmall': {
            fontSize: '1.375rem',
          },
        },
      },
    },
    props: {
      MuiUseMediaQuery: { noSsr: true },
      MuiAutocomplete: {
        autoComplete: true,
        getOptionLabel: option => option.GameName,
      },
      MuiSelect: {
        displayEmpty: true,
        disableUnderline: true,
      },
      MuiPopover: {
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiPagination: {
        count: 4,
        siblingCount: 1,
      },
      MuiTextField: {
        variant: 'outlined',
      },
      MuiCollapse: {
        timeout: {
          appear: 600,
          enter: 600,
          exit: 400,
        },
      },
    },
    custom: theme,
  }
  // @ts-expect-error TODO variable options attributes types does not match
  //with parameter type defined by createTheme, this need to be fixed eventually.
  return createTheme(options)
}

export default createMuiTheme
