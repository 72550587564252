import { makeStyles } from '../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  backButton: {
    display: 'flex',
    padding: 0,
    marginBottom: '0.75rem',
    border: 0,
    fontSize: theme.custom.fontSize.sm,
    lineHeight: theme.custom.lineHeight.xs,
    cursor: 'pointer',
    backgroundColor: 'transparent',

    '& > span': {
      display: 'flex',
      marginRight: '0.125rem',
      fontSize: theme.custom.fontSize.md,
    },
  },
  light: {
    color: theme.custom.palette.neutral1,
  },
  dark: {
    color: theme.custom.palette.neutral2,
  },
}))

export default useStyles
