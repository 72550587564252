import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Container from '../Container'
import useStyles from './emptyMessage.styles'

const EmptyMessage = props => {
  const classes = useStyles()
  const emptyMessageClasses = cx(
    classes.emptyMessage,
    classes[props.size],
    classes[props.variant],
  )

  return (
    <Container>
      <div className={emptyMessageClasses}>
        {props.image}
        <p className={classes.title}>{props.title}</p>
        {props.description ? (
          <p className={classes.description}>{props.description}</p>
        ) : null}
        {props.children ? (
          <div className={classes.action}>{props.children}</div>
        ) : null}
      </div>
    </Container>
  )
}

EmptyMessage.defaultProps = {
  variant: 'transparent',
  size: 'medium',
}

EmptyMessage.propTypes = {
  variant: PropTypes.oneOf(['transparent', 'filled']).isRequired,
  size: PropTypes.oneOf(['small', 'medium']).isRequired,
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
}

export default EmptyMessage
