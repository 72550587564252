import { makeStyles } from '../materialUiWrapper'
import { aspectRatio, centered, mq, supports } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.custom.palette.neutral1_16,
    backgroundColor: theme.custom.palette.placeholder20,
  },
  default: {
    '& > svg': {
      width: '3rem',
      height: '3rem',
      [mq('sm')]: {
        width: '4rem',
        height: '4rem',
      },
    },
  },
  big: {
    '& > svg': {
      width: '5rem',
      height: '5rem',
    },
  },
  aspectRatio: {
    ...aspectRatio(),
  },
  svgCentered: {
    [supports('aspect-ratio: 1/1', false)]: {
      '& > svg': {
        ...centered('absolute'),
      },
    },
  }
}))

export default useStyles
