import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MuiSkeleton from '@material-ui/lab/Skeleton'
import useStyles from './skeleton.styles'

const Skeleton = props => {
  const { variant, isAdaptive, isRounded, isNeutral, ...rest } = props
  const classes = useStyles(rest)

  const skeletonClasses = cx(
    classes.root,
    isAdaptive && classes.adaptive,
    isNeutral && classes.neutral,
    isRounded && classes.rounded,
    rest.aspectRatio && classes.aspectRatio,
  )

  return <MuiSkeleton variant={variant} className={skeletonClasses} />
}

Skeleton.defaultProps = {
  variant: 'text',
}

Skeleton.propTypes = {
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  isAdaptive: PropTypes.bool,
  isNeutral: PropTypes.bool,
  isRounded: PropTypes.bool,
  aspectRatio: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Skeleton
