import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Markdown from '../Markdown'

import useStyles from './card.styles'

const Card = props => {
  const classes = useStyles()

  return (
    <div className={classes.card}>
      <Icon className={classes.cardIcon} name={props.icon} />
      <p className={classes.cardTitle}>{props.title}</p>
      <div className={classes.cardDescription}>
        <Markdown content={props.description} />
      </div>
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Card
