import React, { ComponentPropsWithRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useStyles from './section.styles'

type Ref = HTMLDivElement

interface SectionProps extends ComponentPropsWithRef<'div'> {
  mb: 'extrasmall' | 'small' | 'medium' | 'large' | 'extralarge';
}
const Section = forwardRef<Ref, SectionProps>((props, ref) => {
  const { children, mb, ...rest } = props
  const classes = useStyles(props)
  const containerClasses = cx(classes.section, classes[props.mb])

  return (
    <div ref={ref} className={containerClasses} {...rest}>
      {props.children}
    </div>
  )
})

Section.propTypes = {
  children: PropTypes.node.isRequired,
  mb: PropTypes.oneOf(['extrasmall', 'small', 'medium', 'large', 'extralarge']),
}

export default Section
