import React, { useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { parse } from 'qs'

import {
  isQuickDepositEnabled,
  isFavoriteFromOpenGameEnabled,
} from '../../../config'
import { useFavorites, useBalance } from '../../../asyncData'

import { usePlayContext } from '../PlayContext'

import GamePlayModal from './GamePlayModal'

const GamePlayModalContext = props => {
  const { gameData, setGameData, openGameInIframe } = usePlayContext()

  const { refetch: refetchUserBalance } = useBalance()
  const location = useLocation()
  const history = useNavigate()
  const query = parse(location.search, { ignoreQueryPrefix: true })
  const {
    isLoadingFavorites,
    errorFavorites,
    favorites,
    addFavorite,
    deleteFavorite,
  } = useFavorites()

  useEffect(() => {
    const onBackButtonEvent = e => {
      e.preventDefault()
      if (gameData) {
        return setGameData(null)
      }
    }

    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [gameData, setGameData])

  const favorited =
    favorites &&
    favorites.FavoritesGames.some(game => game.GameID === gameData?.GameID)

  const providerId = parseInt(query?.ProviderID, 10)

  const handleFavorite = useCallback(
    () => (favorited ? deleteFavorite({...query, ...gameData}) : addFavorite({...query, ...gameData})),
    [gameData, favorited, deleteFavorite, addFavorite],
  )

  const isModalOpen =
    Boolean(query.gameId) &&
    Boolean(query.extGameId) &&
    gameData !== null &&
    openGameInIframe

  const handleClose = () => {
    refetchUserBalance()
    setGameData(null)
    history(location.pathname, {replace: true})
  }

  if (!isModalOpen) return null

  return (
    <GamePlayModal
      quickDepositEnabled={isQuickDepositEnabled()}
      gameUrl={gameData?.url}
      title={gameData?.name}
      favorited={favorited}
      disabled={isLoadingFavorites || Boolean(errorFavorites)}
      onFavorite={handleFavorite}
      providerId={providerId}
      open={isModalOpen}
      onClose={handleClose}
      isScrollEnabled={gameData?.isScrollEnabled}
    ></GamePlayModal>
  )
}

export default GamePlayModalContext
