import Env from '@env';
import createCustomTheme from './createCustomTheme'
import createMuiTheme from './createMuiTheme'
import bolnasiteTheme from './bolnasite'
import gcsiteTheme from './gcsite'
import hrnasiteTheme from './hrnasite'
import sbnasiteTheme from './sbnasite'
import ssnasiteTheme from './ssnasite'
import wcnasiteTheme from './wcnasite'
import qbcnasiteTheme from './qbcnasite'
import lvnasiteTheme from './lvnasite'

const brandsPartialTheme = {
    'bolnasite': bolnasiteTheme,
    'gcsite': gcsiteTheme,
    'hrnasite': hrnasiteTheme,
    'sbnasite': sbnasiteTheme,
    'ssnasite': ssnasiteTheme,
    'wcnasite': wcnasiteTheme,
    'qbcnasite': qbcnasiteTheme,
    'lvnasite': lvnasiteTheme,
}

const brandPartialTheme = brandsPartialTheme[Env.get('VITE_GSETTING')] || {}
const customTheme = createCustomTheme(brandPartialTheme())

const theme = createMuiTheme(customTheme)

export default theme
