import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import useStyles from './mobileActions.styles'

const MobileActions = props => {
  const classes = useStyles()
  return (
    <MediaQuery maxWidth={599}>
      <div className={classes.mobileActions}>{props.children}</div>
    </MediaQuery>
  )
}

MobileActions.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MobileActions
