import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Box as Spacing } from '@material-ui/core'
import Icon from '../Icon'
import Skeleton from '../Skeleton'
import ErrorNote from '../ErrorNote'
import Popover from '@material-ui/core/Popover'

import { useCurrency } from '../../../i18n'
import useStyles from './indicatorBar.styles'

const IndicatorDropdown = props => {
  const formatCurrency = useCurrency()
  const ref = useRef()
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const indicatorItemClasses = cx(
    classes.indicatorItem,
    props.isLoading && classes.isLoading,
    props.error && classes.hasError,
    open && classes.isOpen,
  )

  return (
    <>
      <button
        className={indicatorItemClasses}
        ref={ref}
        onClick={() => setOpen(true)}
      >
        <strong className={classes.indicatorTitle}>
          {props.name}
          {props.error ? null : <Icon name="chevron-down" />}
        </strong>
        {props.isLoading && !props.error ? (
          <Skeleton width={56} height={12} />
        ) : !props.error ? (
          <span id='totalBalanceHeader' className={classes.indicatorAmount}>
            {typeof props.amount === 'number'
              ? formatCurrency(props.amount)
              : '—'}
          </span>
        ) : null}
      </button>
      {props.error ? (
        <Spacing mt={-0.625} mb={0.625} px={0.75}>
          <ErrorNote size="medium">{props.error}</ErrorNote>
        </Spacing>
      ) : props.children ? (
        <Popover
          open={open}
          anchorEl={ref?.current}
          onExited={() => {
            if (props.onExited) props.onExited()
            setOpen(false)
          }}
          onClose={() => {
            if (props.onClose) props.onClose()
            setOpen(false)
          }}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {props.children}
        </Popover>
      ) : null}
    </>
  )
}

IndicatorDropdown.propTypes = {
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.node || PropTypes.any,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

export default IndicatorDropdown
