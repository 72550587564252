import React from 'react'

import { useTranslations } from '../../i18n'

import Carousel from '../../ui/Carousel'
import Container from '../../ui/components/Container'
import Heading from '../../ui/components/Heading'
import GameCard from '../../ui/components/GameCard'
import LinkCard from '../../ui/components/LinkCard'

import { MAX_GAMES_CAROUSEL, VIEW_MORE_LINK_THRESHOLD } from '../../business'
import { GamesInterface } from '../../shared/interfaces/gameInterfaces'

type GameCarouselProps = {
  name: string;
  icon: string;
  slug: string;
  games: GamesInterface[];
  big?: boolean;
  isEnabledViewAll?: boolean;
  hasBreakingContainer?: boolean;
  gamesLength?: number;
}
const GameCarousel: React.FC<GameCarouselProps> = ({ name, icon, slug, games, big, isEnabledViewAll = true, gamesLength, hasBreakingContainer}) => {
  const formatMessage = useTranslations()
  const length = gamesLength || games.length
  if (!length) return null

  const to = `/games/${slug}`
  const size = big ? 'regular' : 'small'
  return (
    <>
      <Container>
        <Heading
          title={name}
          number={length}
          to={(length >= VIEW_MORE_LINK_THRESHOLD) && isEnabledViewAll ? to : undefined}
          iconURL={icon}
        />
      </Container>
      <Carousel big={big} hasBreakingContainer={hasBreakingContainer}>
          {games.map((game, index) => {
            return (
              <GameCard
                game={game}
                size={size}
                origin={name}
                key={index}
              />)
          })}
          {(length > MAX_GAMES_CAROUSEL) && isEnabledViewAll ? (
            <LinkCard
              to={to}
              text={formatMessage('action:view-all')}
            />
          ) : null}
        </Carousel>
    </>
  )
}

export default GameCarousel
