import useStyles from './betLimits.styles'

interface BetLimitsProps {
  limit: {
    minLimit: number,
    maxLimit: number,
  }
}

const BetLimits = ({ limit: {minLimit, maxLimit} }: BetLimitsProps) => {
  const classes = useStyles()
  const label = `Limit: ${minLimit}-${maxLimit}`

  return (
    <div className={classes.betLimits}>
      <label>{label}</label>
    </div>
  )
}

export default BetLimits
