import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './textNote.styles'

const TextNote = props => {
  const classes = useStyles(props)

  const textNoteClasses = cx(classes.textNote, classes[props.variant], props.className)

  return <p className={textNoteClasses}>{props.children}</p>
}

TextNote.defaultProps = {
  variant: 'default',
}

TextNote.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'caps', 'title', 'intro', 'optin']),
  children: PropTypes.node.isRequired,
}

export default TextNote
