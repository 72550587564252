import { useParams } from 'react-router-dom';
import { formatMessage } from '../../../../i18n';
import useStyles from './content.styles';
import Head from '../../../../context/Head';
import { useIsBrand } from '../../../../hooks/useIsBrand';

const Content = () => {
    const classes = useStyles();
    const { slug } = useParams();
    const { isSB } = useIsBrand();

    return (
        <>
            {isSB && (
                <Head
                    title={formatMessage(`meta:title:category:${slug}`)}
                    description={formatMessage(`meta:description:category:${slug}`)}
                />
            )}
            <div className={classes.container}>
                {formatMessage(`meta:sb101:casino:${slug}:content`, {
                    p: chunks => <p>{chunks}</p>,
                    h2: chunks => <h2>{chunks}</h2>,
                    h1: chunks => <h1>{chunks}</h1>
                })}
            </div>
        </>
    )
}

export default Content;
