import Env from '@env';
import React, { useRef } from 'react'

import TitlePlaceholder from './TitlePlaceholder'
import Skeleton from '../Skeleton'
import Img from '../Img'
import Tags from '../GameCardLiveCasino/TagsTypes'
import BottomTicker from './BottomTicker'
import { ASPECT_RATIOS } from '../../constants'

import { useCurrency } from '../../../i18n'
import useStyles from './gameCard.styles'
import useOpenGame from '../../../hooks/useOpenGame'
import { GamesInterface } from '../../../shared/interfaces/gameInterfaces'
import useHandleLiveGame from '../../../hooks/useHandleLiveGame'
import useSize from '../../../hooks/useSize'
import { OffsetWidthCardContext } from '../../context'
import EnabledCard from '../GameCardLiveCasino/EnabledCard'
import GameCardOverlay from '../GameCardOverlay'
import GamePlayModal from '../../../context/Game/PlayModal/GamePlayModal'

const GameCardSkeleton: React.FC = () => (
  <Skeleton variant="rect" aspectRatio={ASPECT_RATIOS.GAME_CARD} />
)

type GameCardPlaceholderTypes = {
  title: string
  size: string
}

const GameCardPlaceholder = (props: GameCardPlaceholderTypes) => (
  <TitlePlaceholder
    title={props.title}
    aspectRatio={ASPECT_RATIOS.GAME_CARD}
    size={props.size}
  />
)

export type GameCardTypes = {
  game: GamesInterface
  size?: string
  origin: string
}

const GameCard = ({ game, size, origin }: GameCardTypes) => {
  const formatCurrency = useCurrency()
  const liveCardsRef = useRef(null)
  useSize(liveCardsRef)
  const classes = useStyles()
  const handleOpenGame = useOpenGame()
  const { handleOpen, handleClose, gameData, isGameOpen } = useHandleLiveGame(
    game.cardId,
  )
  const CDN = Env.get('VITE_IMAGE_BASE_URL')
  const hasTags =
    !!game.lastResults ||
    !!game.title ||
    !!game.subtitle ||
    !!game.seats ||
    !!game.limit
  return (
    <div ref={liveCardsRef} className={classes.cardContent}>
      <OffsetWidthCardContext.Provider
        value={{ offsetWidth: liveCardsRef?.current?.offsetWidth }}
      >
        {game && game?.hasLiveData && (
          <EnabledCard cardId={game.cardId} initialEnabled={true} />
        )}
        <div
          className={classes.gameCard}
          onClick={() => {
            game.hasLaunchPopup
              ? handleOpenGame(
                  game.cardId,
                  game.extGameId,
                  origin,
                  game.providerId,
                )
              : handleOpen()
          }}
        >
          <GameCardOverlay
            size={size === 'regular' ? 'xl' : 'lg'}
            gameTitle={game.name}
          />
          <Img
            src={
              game?.backGroundImageFullUrl ||
              (game?.backGroundImageUrl
                ? `${CDN}${game.backGroundImageUrl}`
                : '')
            }
            alt={game.name || game.title || 'game card'}
            fallback={<GameCardPlaceholder title={game.name} size={size} />}
            placeholder={<GameCardSkeleton />}
          />
          {liveCardsRef?.current?.offsetWidth && hasTags ? (
            <>
              <Tags game={game} hasTags={hasTags} />
            </>
          ) : null}
          {game.jackpotValue > 0 && (
            <BottomTicker
              jackpot={formatCurrency(game.jackpotValue)}
              size={size}
            />
          )}
        </div>
      </OffsetWidthCardContext.Provider>
      {isGameOpen && gameData.launchURL && (
        <GamePlayModal
          providerId={game?.providerId}
          quickDepositEnabled={false}
          title={`${game?.name} - ${game.categoryid}`}
          gameUrl={gameData?.launchURL}
          open={isGameOpen}
          onClose={handleClose}
          isScrollEnabled={gameData?.isScrollEnabled}
        ></GamePlayModal>
      )}
    </div>
  )
}

GameCard.Skeleton = GameCardSkeleton
GameCard.Placeholder = GameCardPlaceholder

export default GameCard
