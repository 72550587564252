import { useEffect, useMemo, useState } from 'react'

// TODO: Improve this hook on next ticket
// Ex: The LANDSCAPE_TYPES and PORTRAIT_TYPES constants don't need to be inside the hook.
const useOrientationChange = () => {
  const LANDSCAPE_TYPES = useMemo(() => ['landscape-primary', 'landscape-secondary'], [])
  const PORTRAIT_TYPES = useMemo(() => ['portrait-primary', 'portrait-secondary'], [])
  const [landscapeMode, setLandscapeMode] = useState(false)
  const [portraitMode, setPortraitMode] = useState(false)
  let orientation

  if (window?.screen?.orientation) {
    orientation = window.screen.orientation.type
  } else {
    orientation = window.innerHeight > window.innerWidth ? 'portrait-primary' : 'landscape-primary'
  }

  useEffect(() => {
    if(LANDSCAPE_TYPES.includes(orientation)){
      return setLandscapeMode(true);
    }
    if(PORTRAIT_TYPES.includes(orientation)){
      return setPortraitMode(true);
    }
  }, [LANDSCAPE_TYPES, PORTRAIT_TYPES, orientation])

  return {
    isLandscape: landscapeMode,
    isPortrait: portraitMode,
  }
}

export default useOrientationChange
