// NOTE default values for unused options are provided as comments
const OPTIONS_BASE = {
  type: 'slider',
  // startAt: 0,
  // focusAt: 0,
  // autoplay: false,
  // hoverpause: true,
  keyboard: false,
  bound: true,
  // swipeThreshold: 80,
  // dragThreshold: 120,
  // perTouch: false,
  touchRatio: 0.8,
  // animationDuration: 400,
  rewind: false,
  // rewindDuration: 800,
  // animationTimingFunc: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  // direction: 'ltr',
  // classes: {...},
  // throttle: 25,
}

export const OPTIONS_DEFAULT = {
  ...OPTIONS_BASE,
  perView: 13,
  peek: 60,
  gap: 8,
  breakpoints: {
    2559: {
      perView: 11,
      peek: 60,
    },
    1799: {
      perView: 9,
      peek: 48,
    },
    1199: {
      perView: 6,
      peek: 36,
    },
    899: {
      perView: 4,
      peek: 24,
    },
    599: {
      perView: 3,
      peek: 12,
      gap: 4,
    },
  },
}

export const OPTIONS_BIG = {
  ...OPTIONS_BASE,
  perView: 11,
  peek: 60,
  gap: 8,
  breakpoints: {
    2559: {
      perView: 9,
      peek: 60,
    },
    1799: {
      perView: 6,
      peek: 48,
    },
    1199: {
      perView: 4,
      peek: 36,
    },
    899: {
      perView: 3,
      peek: 24,
    },
    599: {
      perView: 2,
      peek: 12,
      gap: 4,
    },
  },
}
