import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import { HeroSkeleton, StaticHero } from '../../ui/components/Hero'
import { useTranslations } from '../../i18n'
import { useCategories, getCmsImageUrl } from '../../asyncData'
import { isMobileOrTablet } from '../../libs/uaparser'
import useResizeHandler from '../../hooks/useResizeHandler'
import baseTheme from '../../ui/config/baseTheme'
import { isLiveCasinoMobile } from '../../libs/utils'
import history from '../../libs/history'

const CategoryHero = props => {
  const formatMessage = useTranslations()
  const [isHidden, setIsHidden] = useState(false)
  const params = useParams()
  const { isLoading, error, data } = useCategories()
  
  useEffect(() => {
    return history.listen(location => {
      hiddenOrShowCategoryHero(location.pathname)
    })
  }, [history])

  useEffect(() => {
    hiddenOrShowCategoryHero(window.location.pathname)
  }, [])

  useResizeHandler(() => {
    const value = baseTheme.breakpoints.values['md'];
    //TODO: update this to useDevice hook if applies
    const isMobile = window.matchMedia(`(max-width: ${value}px)`).matches || window.matchMedia(`(pointer: coarse)`).matches;
    hiddenOrShowCategoryHero(window.location.pathname, isMobile);
  });

  const hiddenOrShowCategoryHero = (path, isMobile = isMobileOrTablet()) => {
    setIsHidden(isLiveCasinoMobile(path, isMobile))
  }
  const slug = props.slug || params.slug
  if (error) return null

  if (isLoading) return <HeroSkeleton />

  const category = data?.find(category => category.slug === slug)

  if (!category?.banner?.mobile || !category?.banner?.desktop) return null

  return (
    <>
      {!isHidden && (
        <StaticHero
          imageMobile={getCmsImageUrl(category.banner.mobile)}
          imageDesktop={getCmsImageUrl(category.banner.desktop)}
          alt={formatMessage('noun:category-banner', category)}
        />
      )}
    </>
  )
}

CategoryHero.propTypes = {
  slug: PropTypes.string,
}

export default CategoryHero
