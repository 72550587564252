import Env from '@env';
import React from 'react'
import PropTypes from 'prop-types'
import history from '../libs/history'
import keycloak, { config } from './keycloak'
import KeycloakProvider from './KeycloakProvider';
import { KEYCLOAK_EVENTS } from './keycloak';
import { applyRedirect } from './redirect';
import { getExternalSiteUrl } from '../config';
import Cookies from 'js-cookie'
import { getTopLevelDomain } from '../libs/utils'

/**
 * Login types
 * @typedef {(0|1|2|3|4|5|6|7|8)} LoginType
 * @enum {LoginType}
 */
const LOGIN_TYPES = {
  UNKNOWN: 0,
  COMPLETE: 1,
  PROSPECT: 2,
  VERIFY: 3,
  MESSAGE: 4,
  DEPOSIT: 5,
  FAILED: 6,
  BYPASS: 7,
  FORCE: 8,
}
/**
 * getLoginAction - Returns appropiate login action for those SAC that have EXTERNAL_BASE_URI configured to redirect outside
 * @param {string} redirectTo - relative url as response from keycloak
 */
//Here in EXTERNAL_REDIRECTS we can set any return from keyloack that needs to go to the base url
const EXTERNAL_REDIRECTS = [
  '/cashier',
  '/cashier/deposit',
  // To-DO: Review if BU needs to enable this redirection in SAC
  // '/sportsbook'
]
const EXTERNAL_URI = getExternalSiteUrl()

const AUTH_3_FLAG = Env.get('VITE_AUTH_3_FLAG')

export const getLoginAction = redirectTo => {
  return EXTERNAL_REDIRECTS.includes(redirectTo)
    ? window.location.assign(`${EXTERNAL_URI}${redirectTo}`)
    : history.push('/',{replace: true})
}

const isLV = Env.get('VITE_GSETTING') === 'lvnasite';
const hasDeletedSilentLoginCookie = window.localStorage.getItem('hasDeletedSilentLoginCookie');

/**
 * checkLogin - Check login type and perform appropiate actions
 * @param {Object} response - API response
 * @param {LoginType} response.LoginType - Login type
 * @param {String} response.RedirectTo - URL to redirect the user to if login
 * type is COMPLETE (1) and he has logged in from the home page
 */
const checkLogin = async response => {
  if (!response) {
    return
  }
  const { LoginType, RedirectTo } = response
  const redirectTo = !!EXTERNAL_URI ? getLoginAction : history.push
  // NOTE cast login type to number, just in case it is a string
  switch (Number(LoginType)) {
    case LOGIN_TYPES.COMPLETE:
      // NOTE redirect only if user in home page with no open game
      if (history.location.pathname === '/' && history.location.search === '') {
        redirectTo(RedirectTo);
      }
      break
    case LOGIN_TYPES.DEPOSIT:
      history.push('/cashier')
      break
    case LOGIN_TYPES.VERIFY:
      // TODO redirect to /verify-account
      break
    case LOGIN_TYPES.FORCE:
      // TODO redirect to /forced-password-reset
      break
    case LOGIN_TYPES.UNKNOWN:
    case LOGIN_TYPES.FAILED:
    case LOGIN_TYPES.BYPASS:
      // NOTE these types should not be received in front end, the user should
      // be kept in keycloak's portal instead
      break
    case LOGIN_TYPES.PROSPECT:
    case LOGIN_TYPES.MESSAGE:
      // NOTE these types are deprecated and no loger used
      break
    default:
      break
  }
}

const isNoOpenGame = () => window.location.search === '' || window.location.search.includes('session_state');

/**
 * Matches customer identity with Graphyte Session.
 */

// keycloak
const trackIdentify = userId => {
  const graphyte = window.graphyte
  if (typeof graphyte?.page === 'function') {
    graphyte.identify(userId)
  }
}

const onReady = () => {
  if (!keycloak.authenticated && hasDeletedSilentLoginCookie) {
    window.localStorage.removeItem('hasDeletedSilentLoginCookie');
  }
}

const userJustManuallyLoggedIn = () => {
  const authAction = window.localStorage.getItem('AUTH_ACTION');
  window.localStorage.removeItem('AUTH_ACTION');
  return authAction && ['LOGIN', 'JOIN'].includes(authAction);
}

const onAuthSuccess = async () => {
  if (userJustManuallyLoggedIn()) {

    if (AUTH_3_FLAG) {
      if (isNoOpenGame()) {
        await applyRedirect();
      }
    } else {
      checkLogin(keycloak.idTokenParsed?.['na-api-response'])
      trackIdentify(keycloak.idTokenParsed?.preferred_username)
    }

    if (window.xtremepush) {
      window.xtremepush(
        'set',
        'user_id',
        keycloak.tokenParsed?.preferred_username,
      )
    }
  }

  if (isLV) {
    removeSilentLoginCookie();
  }

}

const removeSilentLoginCookie = () => {
  if (!hasDeletedSilentLoginCookie) {
    Cookies.remove('SilentLogin', { domain: getTopLevelDomain() })
    window.localStorage.setItem('hasDeletedSilentLoginCookie', 'true')
  }
}

const AUTH_EVENT_HANDLERS = {
  [KEYCLOAK_EVENTS.ON_AUTH_SUCCESS]: onAuthSuccess,
  [KEYCLOAK_EVENTS.ON_READY]: onReady,
  [KEYCLOAK_EVENTS.ON_INIT_ERROR]: (e) => console.log('Error al iniciar keycloak: ', e)
}

const handleKeycloakEvent = (event) => {
  AUTH_EVENT_HANDLERS[event] && AUTH_EVENT_HANDLERS[event](event);
}

const ReactKeycloakProviderWrapper = ({ children }) => {
  return (
    <KeycloakProvider
      authClient={keycloak}
      initOptions={config}
      onEvent={handleKeycloakEvent}>
      {children}
    </KeycloakProvider>
  )
}

ReactKeycloakProviderWrapper.propTypes = {
  children: PropTypes.node,
}

export default ReactKeycloakProviderWrapper
