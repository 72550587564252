import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './buttonGroup.styles'

const ButtonGroup = props => {
  const { isReversible, children } = props
  const classes = useStyles()

  const buttonGroupClasses = cx(
    classes.buttonGroup,
    isReversible && classes.reverse,
  )

  return <div className={buttonGroupClasses}>{children}</div>
}

ButtonGroup.propTypes = {
  isReversible: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default ButtonGroup
