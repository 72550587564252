//TODO: remove this code when backend change the keys to PascalCase
const camelToPascalCase = str => {
  return str
    .replace(/(?:^|\s|_|-)(.)/g, (match, char) => char.toUpperCase())
    .replace(/Id$/g, 'ID')
}

export const changeKeysToPascalCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(item => changeKeysToPascalCase(item))
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((newObj, key) => {
      const pascalKey = camelToPascalCase(key)
      newObj[pascalKey] = changeKeysToPascalCase(obj[key])
      return newObj
    }, {})
  }

  return obj
}

