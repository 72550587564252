import React from 'react'
import { AuthContext } from "./useAuth";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { KEYCLOAK_EVENTS } from './keycloak';

const KeycloakProvider = (props) => {
  const { authClient, initOptions, onEvent } = props;

  const [auth, setAuth] = useState({
    initialized: false,
    keycloak: authClient,
  });

  useEffect(() => {

    initListeners();
    
    authClient.init(initOptions)
      .catch(onError(KEYCLOAK_EVENTS.ON_INIT_ERROR))
  }, []);

  const initListeners = () => {

    authClient.onAuthError = onError(KEYCLOAK_EVENTS.ON_AUTH_ERROR);
    authClient.onAuthRefreshError = onError(KEYCLOAK_EVENTS.ON_AUTH_REFRESH_ERROR);

    authClient.onReady = () => {
      if (!auth.initialized) {
        setAuth({ initialized: true, keycloak: authClient });
      }

      onEvent && onEvent(KEYCLOAK_EVENTS.ON_READY);
    }

    authClient.onTokenExpired = () => {
      authClient.updateToken(5);
    }

    authClient.onAuthSuccess = () => {
      onEvent && onEvent(KEYCLOAK_EVENTS.ON_AUTH_SUCCESS);
      setAuth({ initialized: true, keycloak: authClient });
    };

  }

  const onError = (event) => (error) => {
    console.log('error: ', error)
    onEvent && onEvent(event)
  }

  return (
    <AuthContext.Provider value={auth}>
      {props.children}
    </AuthContext.Provider>
  )
}

KeycloakProvider.prototype = {
  children: PropTypes.node,
  config: PropTypes.node,
  authClient: PropTypes.node,
}

export default KeycloakProvider;
