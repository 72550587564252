import React,{ useEffect } from 'react'
import { useTheme } from '@material-ui/core'
import { useBalance } from '../../../../asyncData'
import { useEnvData } from '../../../../hooks/useEnvData'
import { useEvent } from 'react-use'
import { getExternalSiteUrl } from '../../../../config'

const useModalHandler = ({
  game,
  isModalOpen,
  logged,
  favorited,
  isLoadingPlayableBalance,
  enableBalanceAlert,
  activeBonusStatus,
  playableBalance,
  playForFree,
  onFavorite,
  handlePlay,
  handleClose,
  isBtnDisabled,
  executedFromBonusLocation,
}) => {
  const theme = useTheme()
  const userBalance = useBalance()
  const { knowledgeBaseUrl } = useEnvData()

  const bonusRulesURL = `${knowledgeBaseUrl}/bonus-rules/`

  const prelaunchStyles = {...(theme.custom.palette?.universalPrelaunchModal || {}), placeholder: theme.custom.palette?.placeholder ?? 'rgba(255,255,255,0.5)'}

  const handleRedirectToDeposit = () => {
    window.location.replace(theme.custom.cashierUrl.url)
  }

  useEvent('playGame', handlePlay, window)
  useEvent('addFavorite', onFavorite, window)
  useEvent('deleteFavorite', onFavorite, window)
  useEvent('closeModal', handleClose, window)
  useEvent('redirectToDeposit', handleRedirectToDeposit, window)
  useEvent('redirectToLogin', handlePlay, window)

  useEffect(() => {
    let referrer = document.referrer
    if (referrer && referrer === getExternalSiteUrl()) {
      handlePlay()
    }

    return () => {
      referrer = null
    }
  }, [])

  useEffect(() => {
    if (
      isModalOpen &&
      game &&
      document.querySelector('universal-prelaunch-modal')
    ) {
      const options = {
        theme: prelaunchStyles,
        config: {
          isLoggedIn: logged,
          shouldRedirect: false,
          isFavoritesEnabled: false,
          isFavorited: favorited,
          playableBalance:
            enableBalanceAlert && !isLoadingPlayableBalance
              ? playableBalance
              : ' - ',
          playForFree: playForFree,
          activeBonusStatus: activeBonusStatus,
          isPlayableBalanceActive: enableBalanceAlert,
          bonusTermsLink: bonusRulesURL,
          userBalance: enableBalanceAlert
            ? undefined
            : userBalance?.data?.Profile?.AvailableBalance,
          btnDisabled: isBtnDisabled,
          executedFromBonusLocation,
        },
        data: {
          gameTitle: game?.GameName,
          gameDescription: game?.Description,
          desktopImageUrl: game?.ImageDescriptionURLFull,
          mobileImageUrl: game?.ImageUrlGameFull,
          volatility: game?.Volatility,
          categoryId: game?.CategoryID,
          extGameId: game?.ExtGameID,
          gameId: game?.GameID,
          jackpotValue: game?.JackpotValue,
          providerId: game?.ProviderID,
        },
      }

      const modal = document.querySelector('universal-prelaunch-modal')
      if (modal) {
        modal.options = options
      }

    }
  }, [
    isModalOpen,
    game,
    logged,
    favorited,
    isLoadingPlayableBalance,
    playableBalance,
    playForFree,
    activeBonusStatus,
    bonusRulesURL,
    enableBalanceAlert,
    prelaunchStyles,
    userBalance?.data?.Profile?.AvailableBalance,
    isBtnDisabled,
    executedFromBonusLocation,
  ])
}

export default useModalHandler
