import React from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from './Boundary'
import NotFound from './NotFound'
import InternalError from './InternalError'

const GlobalErrorBoundary = props => (
  <ErrorBoundary
    fallbackRender={({ error, resetErrorBoundary: onReset }) =>
      error === 404 ? <NotFound /> : <InternalError onTryAgain={onReset} />
    }
  >
    {props.children}
  </ErrorBoundary>
)

GlobalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalErrorBoundary
