import useStyles from './results.style'

const Results = ({ value, color } : { value: string, color: string, }) => {
  const classes = useStyles({
    backgroundColor: color,
  })

  return (
    <div className={classes.result}>
      <label>{value}</label>
    </div>
  )
}

export default Results
