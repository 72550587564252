import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { useTranslations } from '../../../i18n'
import useStyles from './loginSkeleton.styles'
import Link from '../Link'
import Loader from '../Loader'
import { CustomTheme } from '../../CustomTheme/types'
import DynamicSvgIcon from '../Icon'

const LoginSkeleton = props => {
  const { logo } = props
  const classes = useStyles()
  const theme = useTheme<CustomTheme>()
  const formatMessage = useTranslations()

  return (
    <div className={classes.loginSkeleton}>
      <div className={classes.main}>
        <header className={classes.header}>
          <Link to="/">
            <picture className={classes.logo}>
              <source srcSet={logo} />
              <img
                src={logo}
                alt={formatMessage('noun:logo-brand', {
                  brand: theme.custom.brand.name,
                })}
              />
            </picture>
          </Link>
        </header>
        <div className={classes.loadingContent}>
          <Loader className="loading" size="large" hasText />
        </div>
        <p className={classes.helpTxt}>
          <DynamicSvgIcon className='' name={'question'}></DynamicSvgIcon>
          <span>
            {formatMessage('noun:having-problems')}{' '}
            {formatMessage('noun:contact-support')}
          </span>
        </p>
      </div>
      <footer className={classes.footer}>
        <div className={classes.footerContainer}>
          <ul className={classes.footerTop}>
            <li className={classes.footerItem}>
              <DynamicSvgIcon className={classes.footerIcon} name="security"></DynamicSvgIcon>
              {formatMessage('noun:safe-secure-txt')}
            </li>
            <li className={classes.footerItem}>
              <DynamicSvgIcon className={classes.footerIcon} name="safe"></DynamicSvgIcon>
              {formatMessage('noun:trusted-experience')}
            </li>
          </ul>
          <div className={classes.footerBottom}>
            {formatMessage('noun:by-joining-you-agree', {
              linkBrand: <Link to="/">{theme.custom.brand.name}</Link>,
              termsLink: formatMessage => <span>{formatMessage}</span>,
              privacyLink: formatMessage => <span>{formatMessage}</span>,
            })}
          </div>
        </div>
      </footer>
    </div>
  )
}

LoginSkeleton.propTypes = {
  logo: PropTypes.string.isRequired,
}

export default LoginSkeleton
