import { makeStyles } from '../../../../../ui/components/materialUiWrapper'
import { mq } from '../../../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  bottomTicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '2rem',
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    fontSize: theme.custom.fontSize.xs,
    lineHeight: theme.custom.lineHeight.md,
    fontWeight: theme.custom.fontWeight.bold,
    color: theme.custom.palette.neutral1,
    backgroundImage: `linear-gradient(
      to bottom,
      ${theme.custom.palette.neutral2_60},
      ${theme.custom.palette.neutral2}
      )`,
    position: 'absolute',
    bottom: 0,
    overflow: 'hidden',

    [mq('sm')]: {
      width: '100%',
      height: '2.75rem',
      padding: '0 1rem',
      borderBottomLeftRadius: 0,
      fontSize: theme.custom.fontSize.lg,
      right: 0,
    },
  },
}))

export default useStyles
