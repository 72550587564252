import { getDeviceTypeId } from "src/ui/config/deviceUtils"

export const isScrollEnabled =( data: UrlDataInterface ) => {
    const deviceTypeId = getDeviceTypeId()
    const device = data?.scrollConfig?.deviceScrolls?.find(
      device => device.deviceTypeId === deviceTypeId,
    )

    return device ? device.allowScroll : false
  }
  type deviceScrollType = {
    deviceTypeId: number;
    allowScroll: boolean;
  }
  export interface UrlDataInterface {
    launchURL: string;
    scrollConfig: {
        deviceScrolls: deviceScrollType[]
    },
    isScrollEnabled?: boolean;
  };