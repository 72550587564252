import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    color: theme.custom.section?.color || theme.custom.palette.neutral1_87,

    '&:last-of-type': {
      marginBottom: 0,
    },

    '& h2': {
      ...texts.stepUp4,
      fontWeight: theme.custom.fontWeight.extrabold,
      marginTop: 24,
      marginBottom: 4,

      [mq('lg')]: {
        marginTop: 32,
      },
    },
    '& h3': {
      ...texts.stepUp2,
      fontWeight: theme.custom.fontWeight.extrabold,
      marginTop: 0,
      marginBottom: 4,
    },
    '& p:not([class])': {
      ...texts.baseline,
      lineHeight: theme.custom.lineHeight.md,

      '& + p': {
        marginTop: 12,
      },
    },
  },
  extrasmall: {
    marginBottom: theme.spacing(1.25),
  },
  small: {
    marginBottom: theme.spacing(1.5),
  },
  medium: {
    marginBottom: theme.spacing(1.75),
  },
  large: {
    marginBottom: theme.spacing(2),
  },
  extralarge: {
    marginBottom: theme.spacing(1.75),

    [mq('md')]: {
      marginBottom: theme.spacing(3.25),
    },
  },
}))

export default useStyles
