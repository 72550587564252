import useStyles from './menuDesktop.styles'
import PropTypes from 'prop-types'
import '../../../../assets/fonts/arimo/arimo-regular.ttf'
import { MenuItem } from '../MenuItem'

const MenuDesktop = ({ navigationItems }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
       <ul className={classes.navigation}>
          {navigationItems.map(item => (
            <li className={classes.navigationItem} key={item.name}>
                <MenuItem item={item} active={item.name === 'CASINO' ? 'active' : ''}/>
            </li>
          ))}
        </ul>
    </div>
  )
}

MenuDesktop.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
}

export default MenuDesktop;