import Navigation from '../ui/components/Navigation'
import NavigationSkeleton from '../ui/components/Navigation/NavigationSkeleton'
import { buildCategories, buildCategoryLinks } from '../business'
import { useGetCategories } from '../asyncData'
import { useTranslations } from '../i18n'
import { useGetCategoriesRules } from '../hooks/useGetCategoriesRules'
import { isSoftswissIntegrationEnabled, getExternalSiteUrl } from '../config'

const NavigationContext = () => {
  const formatMessage = useTranslations()

  const { data: categoryData, isLoading } = useGetCategories()

  const externalUrl = getExternalSiteUrl()

  const categoriesConditions = useGetCategoriesRules()
  if (isLoading) return <NavigationSkeleton />

  const items = isSoftswissIntegrationEnabled()
    ? buildCategories(categoryData?.Categories)
    : buildCategoryLinks(categoryData?.CategoryGames)

  categoriesConditions.forEach(item => {
    if (item.condition) items.push(item.value)
  })

  if (externalUrl) {
    items.unshift({
      name: formatMessage('lobby'),
      to: '/',
    })
  }

  return <Navigation items={items} />
}

export default NavigationContext
