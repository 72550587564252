import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '../../../i18n'
import useStyles from './legalAgeModal.styles'
import { useTheme } from '@material-ui/core'
import logoGamingCity from '../../../assets/logos/logo-gc.svg?url'
import Button from '../../components/Button'
import RadioButton from './RadioButton'
import { CustomTheme } from '../../CustomTheme/types'

const LegalAgeModal = props => {
  const formatMessage = useTranslations()
  const theme = useTheme<CustomTheme>()
  const classes = useStyles()

  const [age, setAge] = useState(null)

  return (
    <>
      <div className={classes.modalBackdrop}></div>
      <div className={classes.modalContainer}>
        <div className={classes.modalContent}>
          <img
            className={classes.modalLogo}
            src={logoGamingCity}
            alt={formatMessage('noun:logo-brand', {
              brand: theme.custom.brand.name,
            })}
          />
          <h1 className={classes.modalTitle}>
            You must be 21 or older to visit this website.
          </h1>
          <p className={classes.modalText}>Please verify your age below.</p>
          <form
            className={classes.modalForm}
            onSubmit={e => {
              e.preventDefault()
              props.onSubmit(age === 'older21' ? true : false)
            }}
          >
            <RadioButton
              id="older21"
              text="Yes, I am 21 or older."
              checked={age === 'older21'}
              onChange={e => setAge(e.currentTarget.value)}
            />
            <RadioButton
              id="under21"
              text="No, I am under 21."
              checked={age === 'under21'}
              onChange={e => setAge(e.currentTarget.value)}
            />
            <Button
              type="submit"
              className={classes.modalButton}
              widthBehaviour="full"
              disabled={!age}
            >
              SUBMIT
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

LegalAgeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LegalAgeModal
