import React from 'react'
import MediaQuery from 'react-responsive'

import Skeleton from '../../../../ui/components/Skeleton'
import { ASPECT_RATIOS } from '../../../../ui/constants'

import useStyles from './gameInfoSkeleton.styles'

const GameInfoSkeleton = props => {
  const classes = useStyles()

  return (
    <div className={classes.gameInfoSkeleton}>
      <div className={classes.imageWrapper}>
        <Skeleton variant="rect" aspectRatio={ASPECT_RATIOS.GAME_INFO} />
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.textGroup}>
          <div className={classes.heading}>
            <Skeleton width="100%" height={22} />
          </div>
          <div className={classes.paragraph}>
            <Skeleton height={12} />
          </div>
          <div className={classes.paragraph}>
            <Skeleton height={12} />
          </div>
          <MediaQuery maxWidth={599}>
            <div className={classes.paragraph}>
              <Skeleton height={12} />
            </div>
          </MediaQuery>
        </div>
        <div className={classes.ratingGroup}>
          <div className={classes.ratingTitle}>
            <Skeleton height={12} />
          </div>
          <div className={classes.ratingIcons}>
            <Skeleton variant="circle" />
            <Skeleton variant="circle" />
            <Skeleton variant="circle" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GameInfoSkeleton
