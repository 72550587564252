import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './box.styles'

const Box = props => {
  const classes = useStyles()
  const boxClasses = cx(classes.box, !props.padded && classes['nopadded'])

  return (
    <section className={boxClasses}>
      {props.caption && (
        <header className={classes.boxHeader}>
          <p id={props.captionId}>{props.caption}</p>
        </header>
      )}
      <div className={classes.boxBody}>{props.children}</div>
    </section>
  )
}

Box.defaultProps = {
  padded: true,
}

Box.propTypes = {
  caption: PropTypes.string,
  captionId: PropTypes.string,
  children: PropTypes.node.isRequired,
  padded: PropTypes.bool,
}

export default Box
