import { getExternalSiteUrl } from "../../../config"
import { getExternalUri } from "../../../context/Layout/SportBettingLayout/utils"

export const gsettingToBrand = gsetting => {
  const config = {
    bolnasite: 'betonline',
    gcsite: 'gamingcity',
    hrnasite: 'highroller',
    sbnasite: 'sportsbetting',
    ssnasite: 'superslots',
    wcnasite: 'wildcasino',
    qbcnasite: 'queenbeecasino',
    lvnasite: 'lowvig'
  }

  return config[gsetting]
}

export const formatUrl = (url) => {
  if (!url) return ''
  if (url.includes("__external__") || url.includes('https')) return url.replace('__external__', '')
  return `${getExternalUri()}${url}`
}

export const mapLinks = (links = []) => {
  return links.map(link => {
    return {
      url: formatUrl(link?.url),
      type: link?.actionType === 'open' ? 'outer' : 'inner',
      text: link?.Label,
      windowElement: link?.actionType === 'open' ? null : undefined,
    }
  })
}

