import React from 'react'

import PageContents from './components/PageContents'
import Loader from './components/Loader'

const Redirecting = () => {
  return (
    <PageContents
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader size="large" hasWrapper hasText />
    </PageContents>
  )
}

export default Redirecting
