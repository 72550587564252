import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './bottomTicker.styles'

const BottomTicker = props => {
  const classes = useStyles()

  return (
    <div className={classes.bottomTicker}>
      <p>{props.jackpot}</p>
    </div>
  )
}

BottomTicker.propTypes = {
  jackpot: PropTypes.string,
}

export default BottomTicker
