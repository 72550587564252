import { useEffect, useCallback } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useLocation } from 'react-router-dom'

import { useEnvData } from './useEnvData'

import { disconnectBffServer, connectBffServer } from '../asyncData/bff/server'

const tabDetection = () => {
  if (document.hidden) {
    disconnectBffServer()
  } else {
    connectBffServer()
  }
}

const useHandlerSocketConnection = () => {
  const { idleSocketTime } = useEnvData()
  const location = useLocation()

  const handleRouteModification = useCallback(() => {
    if (!(location.pathname === '/' || location.pathname.includes('/live-casino'))) {
      disconnectBffServer()
    }
    if (!location.pathname.search) {
      connectBffServer()
    } else disconnectBffServer()
  }, [])

  /*  Hook to check IDLE   */
  useIdleTimer({
    timeout: idleSocketTime,
    onIdle: disconnectBffServer,
    onActive: connectBffServer,
    debounce: 500,
  })

  useEffect(() => {
    /* Set game launch behavior and change of route */
    handleRouteModification()
  }, [location])

  useEffect(() => {
    /* Set tab change envent  */
    document.addEventListener('visibilitychange', tabDetection)
    /* Set app closed event */
    window.addEventListener('beforeunload', disconnectBffServer)

    return () => {
      document.removeEventListener('visibilitychange', tabDetection)
      window.removeEventListener('beforeunload', disconnectBffServer)
    }
  }, [])
}

export default useHandlerSocketConnection
