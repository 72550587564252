import React, { useState, useEffect } from 'react'
import Result from './results'
import useStyles from './baccaratTag.styles'
import { gameCardUpdater } from '../../../../../asyncData/bff'
import { reArrangementResults } from '../../../../config/utils'

const onTagMounted = (cardId, setGameResults) => {
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.subscribeToId(
      setGameResults,
      cardId,
      'lastResults',
      reArrangementResults,
    )
  }
}

const onTagUnmounted = (cardId, setGameResults) => {
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.unsubscribeFromId(setGameResults, cardId)
  }
}

const LastResultsTag = ({ results, cardId, isLive }) => {
  const [gameResults, setGameResults] = useState(results?.items || [])
  useEffect(() => {
    if (isLive) { 
    onTagMounted(cardId, setGameResults)
    return () => {
      onTagUnmounted(cardId, setGameResults)
    }}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()
  return (
    <div className={classes.resultsCtn}>
      {gameResults?.map((result, index) => (
        <Result key={(result.key ?? index)} value={result.value} color={result.hexColor} />
      ))}
  </div>
  )
}

export default LastResultsTag
