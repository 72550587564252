import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  step: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    borderRadius: theme.shape.borderRadius,
    color: theme.custom?.card?.cardSlim?.color || theme.custom.palette.neutral1_87,
    boxShadow: theme.custom.shadows.output,
    background: theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_5,
  },
  stepText: {
    ...texts.stepDown1,
    marginLeft: 20,
  },
}))

export default useStyles
