import { makeStyles } from '../../../ui/components/materialUiWrapper'
import { hoverDevice } from '../../../ui/config/utils'

const useModalStyles = makeStyles(
  theme => ({
    root: {
      color: theme.custom.palette.neutral1,
      backgroundColor: !theme.custom.universalPrelaunchModal ? theme.custom.palette.neutral3_70 : '',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 auto',
      color: theme.custom.palette.neutral1,
      backgroundColor: theme.custom.infoModal?.background || theme.custom.palette.quaternary700,
      outline: 0,
    },
    header: {
      alignItems: 'center',
      backgroundColor: theme.custom.infoModal?.bgHeader || theme.custom.infoModal?.background,
      color: theme.custom.palette.neutral1,
      display: 'flex',
      fontSize: theme.custom.fontSize.xs,
      justifyContent: 'space-between',
      lineHeight: theme.custom.lineHeight.sm,
      paddingLeft: '0.75rem',
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: theme.zIndex.appBar,
    },
    headerDesktop: {
      minHeight: '3.125rem',
    },
    headerMobile: {
      minHeight: '2.25rem',
    },
    title: {
      fontSize: theme.custom.fontSize.sm,
      fontWeight: theme.custom.fontWeight.bold,
      lineHeight: theme.custom.lineHeight.xs,
      margin: 0,
      padding: 0,
      position: 'relative',
      zIndex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      minWidth: 0,
      '& button': {
        position: 'relative',
      },
    },
    closeButton: {
      appearance: 'none',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      fontSize: theme.custom.fontSize.xs,
      height: '100%',
      lineHeight: theme.custom.lineHeight.sm,
      outline: 'none',
      overflow: 'hidden',
      padding: '0 0.6875rem 0 0.9375rem',
      position: 'relative',
      zIndex: 1,
      '& .icon': {
        width: '1.5rem',
        height: '1.5rem',
        display: 'inline-block',
        opacity: 0.87,
      },
      [hoverDevice()]: {
        '&:hover': {
          '& .icon': {
            opacity: 0.6,
          },
        },
      },
    },
  }),
  { name: 'MuiModalDialog' },
)

export default useModalStyles
