export enum UserBalanceActions {
  INITIALIZE,
  UPDATE_BALANCE,
  UPDATE_USER_DATA,
  UPDATE_VIP_DATA
}

export interface UserBalanceAction {
  type: UserBalanceActions
  payload: Record<string, unknown>
}