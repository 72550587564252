type EnvVars = Record<string, string>;

const Env = (() => {
  let _env: EnvVars | string = '%env_vars%';

  return {
    get(key: string): string | undefined | unknown {
      if (typeof _env === 'string') {
        return undefined;
      }
      if (key === '') {
        return _env;
      }
      return _env[key];
    },
    set(key: string, value: string | EnvVars): void {
      if (key === '' && typeof value === 'object') {
        _env = value;
        return;
      }
      if (typeof _env !== 'string') {
        _env[key] = value as string;
      }
    }
  };
})();

export default Env;