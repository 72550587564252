import { makeStyles } from '@material-ui/core'
import { CustomTheme } from '../../CustomTheme/types'

const useStyles = makeStyles<CustomTheme>(theme => {
  return {
    glideArrows:{
      position:'absolute',
      top:'50%',
      width: '100%',
      display:'flex',
      justifyContent:'space-between',
      left: 0
    },
    arrow: {
      background: 'none',
      border: 'none',
      padding: 0,
      width:'fit-content',
      color: theme.custom.promotions?.arrowsColor || theme.custom.palette.primary,
      fontSize:'2em',
      '&:hover': {
        cursor: 'pointer',
      },
      
    },
    glideArrowLeft:{
      position:'absolute',
      left:0,
      '@media (min-width:1750px)':{
        left: '220px'
      },
      '@media (min-width:500px) and (max-width:1295px)':{
        left: '10%',
      }
    },
    glideArrowRight:{
      position:'absolute',
      right:0,
      '@media (min-width:1750px)':{
        right: '220px'
      },
      '@media (min-width:500px) and (max-width:1295px)':{
        right: '10%',
      }
    }
  }
})
export default useStyles
