import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0.75rem',
    paddingLeft: 0,
    marginTop: '1rem',
    marginBottom: 0,

    [mq('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [mq('md')]: {
      gap: '1.5rem',
      marginTop: '1.5rem',
    },
    [mq('lg')]: {
      gridTemplateColumns: props => `repeat(${props.cols}, 1fr)`,
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default useStyles
