/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ComponentPropsWithoutRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './pageContents.styles'
import useLastVisitedUrl from '../../../hooks/useCashierOauthRedirect'

interface PageContentsProp extends ComponentPropsWithoutRef<'main'> {
  type?: 'dark' | 'light'
  isNewNavigationActive?: boolean
}
const PageContents = (props: PageContentsProp) => {
  const classes = useStyles()
  const { type, children, isNewNavigationActive, ...otherProps } = props
  const mainClasses = cx(classes.pageContents, type && classes[type], isNewNavigationActive && classes.clearMargin)
  useLastVisitedUrl()

  return (
    <main className={mainClasses} {...otherProps}>
      {children}
    </main>
  )
}

PageContents.defaultProps = {
  type: 'dark',
}

PageContents.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']),
  children: PropTypes.any,
}

export default PageContents
