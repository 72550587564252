import Env from '@env';
import React, { useContext, useEffect } from 'react'
import { getUniversalBalanceElement } from './UserBalance/UserBalance.utils'
import UserBalance from './UserBalance'
import AvatarBalance from './AvatarBalance'
import { useAuth } from '../../../../authentication'
import { UserBalanceContext } from '../../../../context/UserBalance'
import { useVipLevels } from '../../../../asyncData/customer/useVipLevels'
import { useBalance } from '../../../../asyncData'
import { UserBalanceActions } from '../../../../context/UserBalance/interfaces'
import { isVipLevelsEnabled, isXPEnabled } from '../../../../business'
import { useIsBrand } from '../../../../hooks/useIsBrand'
import {  replaceMirrorUrlHelper } from '../../../../config/originSingleton'
import useXtremePushStyles from '../../../../ui/external-components/Header/balanceAccount/xtremepush.styles'

const BETA_MODE = 'Beta Mode'

export const getExternalBaseUri = () => {



  return replaceMirrorUrlHelper(Env.get('VITE_EXTERNAL_BASE_URI')) || document.location.origin
}

const UniversalBalanceProps = () => {
  const { logged, logout, firstName, customerId } = useAuth()
  const { isSB } = useIsBrand()
  const { isLoading, data: vipData, refetch, isError } = useVipLevels() || {}
  const { state, dispatch } = useContext(UserBalanceContext)

   

  const {
    isSuccess: isSuccessBalance,
    data: balanceData,
    isFetching,
    isRefetchError,
  } = useBalance({
    useErrorBoundary: true,
    refetchInterval: 1000 * 6, // refetching each six seconds - This implementation should be change with BFF integration
  })

  const getProfilePayload = profile => {
    return {
      TotalBalance: profile?.TotalBalance,
      AvailableBalance: profile?.AvailableBalance,
      FreePlayBalance: profile?.FreePlayBalance,
      IsBonusWalletAllowed: profile?.IsBonusWalletAllowed,
      BonusMoneyBalance: profile?.BonusMoneyBalance,
      PendingBets: profile?.PendingBets,
      LockedCash: isSB ? BETA_MODE : profile?.LockedCash,
      BonusFunds: isSB ? BETA_MODE : profile?.BonusFunds,
      RealBalance: isSB ? BETA_MODE : profile?.RealBalance,
    }
  }


   window.addEventListener('webcomponent/universal-balance/ready', () => {
     const universalBalance = getUniversalBalanceElement()

     universalBalance.authenticated = logged
     universalBalance.siteUrl = getExternalBaseUri()
   })

  useEffect(() => {
    if (isVipLevelsEnabled()) {
      dispatch({
        type: UserBalanceActions.UPDATE_VIP_DATA,
        payload: { vipData, isLoading, isError },
      })
    }
  }, [isLoading, vipData])

  useEffect(() => {
    if (isFetching && !isRefetchError && balanceData) {
      const { Profile } = balanceData
      dispatch({
        type: UserBalanceActions.UPDATE_BALANCE,
        payload: getProfilePayload(Profile),
      })
    }
  }, [isFetching])

  useEffect(() => {
    if (balanceData && isSuccessBalance) {
      const { Profile } = balanceData
      dispatch({
        type: UserBalanceActions.UPDATE_USER_DATA,
        payload: {
          CustomerID: Profile.CustomerID,
          FirstName: Profile.FirstName,
        },
      })

      dispatch({
        type: UserBalanceActions.UPDATE_BALANCE,
        payload: getProfilePayload(Profile),
      })
    }
  }, [balanceData])

  return ({ logout, logged, balanceData, firstName, customerId, vipData })
}

const UniversalBalanceComponent = () => {
  useXtremePushStyles()
  const { logout, logged, balanceData, firstName, customerId, vipData } = UniversalBalanceProps();
  return (
    <UserBalance
      logout={logout}
      logged={logged}
      balances={balanceData?.Profile}
      userData={{ name: firstName, customerId: customerId }}
      vipData={vipData}
      usextremepush={isXPEnabled()}
    />
  )
}

const AvatarBalanceComponent = () => {
  const { logged, balanceData, vipData } = UniversalBalanceProps();
  return (
      <AvatarBalance
        logged={logged}
        balances={balanceData?.Profile}
        vipData={vipData}
        usextremepush={isXPEnabled()}
      />
  )
}

export { AvatarBalanceComponent, UniversalBalanceComponent };
