import { createContext, useReducer, useEffect } from 'react'
import { userBalanceReducer } from './reducer'
import { BehaviorSubject } from 'rxjs'
import { UserBalanceActions } from './interfaces'

export const UserBalanceContext = createContext(null)

export const UserBalanceProvider = ({ children }) => {
  const initialState = {
    userData: new BehaviorSubject({}),
    balances: new BehaviorSubject({}),
    vipData: new BehaviorSubject({}),
  }
  const [state, dispatch] = useReducer(userBalanceReducer, initialState)

  useEffect(() => {
    dispatch({
      type: UserBalanceActions.INITIALIZE,
      payload: undefined
    })
  }, [])

  return (
    <UserBalanceContext.Provider value={{ state, dispatch }}>
      {children}
    </UserBalanceContext.Provider>
  )
}
