import { useQuery } from 'react-query'

import { fetchCashierInformation } from './services'

export const useCashierInformation = options => {
  const query = useQuery('cashier', fetchCashierInformation, {
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return query
}
