import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  button: {
    ...texts.button,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px hidden transparent',
    borderRadius: theme.custom?.shape?.borderRadius || theme.shape.borderRadius,
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
      opacity: 0.4,
    },

    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: '0.75rem',
    },
  },
  primary: {
    color: theme.custom.buttons?.primaryFrontColor,
    backgroundImage: theme.custom.gradients.primaryGradient,
    [hoverDevice()]: {
      '&:hover': {
        backgroundImage: theme.custom.gradients.primaryGradientDark,
      },
      '&:active': {
        color: theme.custom.buttons?.primaryFrontColorActive,
        backgroundImage: theme.custom.gradients.primaryGradientLight,
      },
    },
  },
  secondary: {
    color: theme.custom.buttons?.secondaryFrontColor,
    backgroundImage: theme.custom.gradients.secondaryGradient,
    [hoverDevice()]: {
      '&:hover': {
        backgroundImage: theme.custom.gradients.secondaryGradientDark,
      },
      '&:active': {
        color: theme.custom.buttons?.secondaryFrontColorActive,
        backgroundImage: theme.custom.gradients.secondaryGradientLight,
      },
    },
  },
  primarySolid: {
    color: theme.custom.buttons?.primarySolidColor,
    background: theme.custom.buttons?.primarySolidBg,
    [hoverDevice()]: {
      '&:hover': {
        background: theme.custom.buttons?.hover.primarySolidBg,
        boxShadow: '0px 8px 6px rgba(0, 0, 0, 0.15)',
        color: theme.custom.palette.white
      },
    }
  },
  secondarySolid: {
    color: theme.custom.buttons?.secondarySolidColor,
    background: theme.custom.buttons?.secondarySolidBg,
    [hoverDevice()]: {
      '&:hover': {
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
      },
    },
    '& svg': {
      marginLeft: '0rem',
    },
  },

  ghostSolid: {
    backgroundColor: theme.custom.buttons?.ghostSolidBg || 'transparent',
    color: theme.custom.buttons?.ghostSolidColor,
    borderColor: theme.custom.buttons?.ghostSolidBorder,
    borderStyle: 'solid',
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.buttons?.hover?.ghostSolidBg,
        color: theme.custom.buttons?.hover.ghostSolidColor
      },
      '&:active': {
        backgroundColor: theme.custom.buttons?.active?.ghostSolidBg || theme.custom.palette.neutral1_16,
        color: theme.custom.buttons?.active?.ghostSolidColor || theme.custom.palette.neutral1,
      },
    },
  },

  ghost: {
    backgroundColor: 'transparent',
    color: theme.custom.buttons?.ghostColor || theme.custom.palette.neutral1,
    borderColor: theme.custom.buttons?.ghostBorder || theme.custom.palette.neutral1,
    borderStyle: 'solid',
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.buttons?.hover?.ghostBg || theme.custom.palette.neutral1,
        color: theme.custom.buttons?.hover?.ghostColor || theme.custom.palette.neutral2,
      },
      '&:active': {
        backgroundColor: theme.custom.buttons?.active?.ghostBg || theme.custom.palette.neutral1_16,
        color: theme.custom.buttons?.active?.ghostColor || theme.custom.palette.neutral1,
      },
    },
  },

  ghostDark: {
    backgroundColor: 'transparent',
    color: theme.custom.palette.neutral2,
    borderColor: theme.custom.palette.neutral2,
    borderStyle: 'solid',
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.palette.neutral2_16,
        color: theme.custom.palette.neutral2,
      },
      '&:active': {
        backgroundColor: theme.custom.palette.neutral1_16,
        color: theme.custom.palette.grayLight,
      },
    },
  },

  white: {
    backgroundColor: theme.custom.palette.neutral1,
    color: theme.custom.palette.neutral2,
    borderColor: theme.custom.palette.neutral2,
    borderStyle: 'solid',
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.palette.neutral1_87,
        color: theme.custom.palette.neutral2
      },
      '&:active': {
        backgroundColor: theme.custom.palette.neutral1_87,
        color: theme.custom.palette.grayLight,
      },
    },
  },

  flat: {
    backgroundColor: 'transparent',
    color: theme.custom.palette.neutral1,

    '& svg': {
      marginLeft: 0,
    },

    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.palette.neutral1_9,
        color: theme.custom.palette.neutral1,
      },
      '&:active': {
        backgroundColor: theme.custom.palette.neutral1_16,
        color: theme.custom.palette.grayLight,
      },
    },
  },

  pill: {
    color: theme.custom.buttons?.pill?.color,
    backgroundColor: theme.custom.buttons?.pill?.bgColor,
    borderRadius: '5rem',
    fontWeight: 'normal',
    
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.buttons?.pill?.bgColorHover,
      },
      '&:active': {
        color: theme.custom.buttons?.pill?.color,
        backgroundColor: theme.custom.buttons?.pill?.bgColorHover,
      },
    },
    
  },
  adaptative: {
    width: 'auto',
  },
  full: {
    width: '100%',
  },
  responsive: {
    width: '100%',

    [mq('sm')]: {
      width: 'auto',
    },
  },
  fixed: {
    [mq('sm')]: {
      width: '16.5rem',
    },
  },
  smallest: {
    ...texts.stepUp7,
    height: '30px',
    padding: '0.5rem'
  },
  smaller: {
    ...texts.stepUp6,
    height: '32px',
    padding: '0.5rem'
  },
  small: ({ variant }) => ({
    ...texts.buttonSmall,
    height: '36px',
    padding: variant !== 'pill' ?  '9px 19px' : '9px',
  }),
  medium: {
    height: '44px',
    padding: '12px 21px',
  },
  large: {
    height: '56px',
    padding: '18px 27px',
  },
  extralarge: {
    ...texts.buttonExtralarge,
    height: '60px',
    padding: '18px 28px',
  },
  buttonText: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonIcon: {
    '& > svg': {
      fill: 'currentColor',
    },
  },
  buttonLoader: {
    color: 'currentColor',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  bold: {
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 'normal',
  },
}))

export default useStyles
