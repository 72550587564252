import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from '../Icon'

import useStyles from './backButton.styles'

const BackButton = props => {
  const classes = useStyles()
  const backButtonClasses = cx(
    classes.backButton,
    props.type && classes[props.type],
  )
  return (
    <button className={backButtonClasses} onClick={props.onClick}>
      <Icon name="chevron-left" />
      {props.children}
    </button>
  )
}

BackButton.defaultProps = {
  type: 'dark',
}

BackButton.propTypes = {
  type: PropTypes.oneOf(['light', 'dark']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default BackButton
