import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { supports } from '../../config/utils'

const useStyles = makeStyles((theme) => ({
    input: ({ variant }) => ({
      ...texts.input,
      width: '100%',
      display: 'inline-flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      border: `1px solid ${variant === 'alt' ? theme.custom?.input?.borderColorAlt : theme.custom?.input?.borderColor}`,
      boxShadow: `0 0 0 1px transparent`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: variant === 'alt' ? theme.custom?.input?.bgAltColor : theme.custom?.input?.bgColor,
      color: variant === 'alt' ? theme.custom?.input?.colorAlt : theme.custom?.input?.color,
      outline: 'none',
      padding: variant === 'alt' ? '' : '1.1875rem',
      margin: '1px',
      '&:hover': {
        borderColor: theme.custom?.input?.borderColorHover || theme.custom.palette.neutral1_60,
      },
      '&:focus': {
        borderColor: theme.custom?.input?.borderColorHover ||  theme.custom.palette.neutral1_87,
        boxShadow: `0 0 0 1px ${theme.custom.palette.neutral1_87}`,
  
        // NOTE iOS does a zoom in when an input gets focused and its font size is not 16px
        // https://stackoverflow.com/questions/30102792/css-media-query-to-target-only-ios-devices
        // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout
        [supports('-webkit-touch-callout: none')]: {
          fontSize: theme.custom.fontSize.md,
        },
      },
      '&[disabled]': {
        borderColor: theme.custom.palette.neutral1,
        opacity: 0.16,
      },
      '&::placeholder': {
        color:  variant === 'alt' ? theme.custom?.input?.placeholderAlt : theme.custom?.input?.placeholder,
      },
    }),
    hasError: {
      borderColor: theme.custom.palette.errorLight,
      boxShadow: `0 0 0 1px ${theme.custom.palette.errorLight}`,
      margin: 0,
      '&:hover': {
        borderColor: theme.custom.palette.errorLight,
        margin: 0,
      },
      '&:focus': {
        borderColor: theme.custom.palette.errorLight,
        boxShadow: `0 0 0 1px ${theme.custom.palette.errorLight}`,
        margin: 0,
      },
    },
    hasRightIcon: {
      paddingRight: '3.375rem',
    },
    hasLeftIcon: {
      paddingLeft: '3.375rem',
    },
}))

export default useStyles
