import { Navigate } from 'react-router-dom'
import Redirecting from '../ui/Redirecting'
import { useAuth } from '../authentication'

interface ProtectedRouteProps{
    notLoggedRedirect?: string,
    children: string | JSX.Element | JSX.Element[] | (() => JSX.Element)
}

const ProtectedRoute = ({ notLoggedRedirect, children }: ProtectedRouteProps) => {
  const { initialized, logged } = useAuth()
  if (!initialized) {
    return <Redirecting />
  }
  if (!logged) {
    return <Navigate to={notLoggedRedirect} />
  }
  return <>{children}</>
}

export default ProtectedRoute
