import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './favButton.styles'

const FavButton = props => {
  const { label, favorited, disabled, onClick } = props
  const classes = useStyles()

  return (
    <button
      className={classes.favButton}
      aria-label={label}
      onClick={onClick}
      disabled={disabled}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={classes.favIcon}
      >
        {favorited ? (
          <path
            d="M23 9.703L15.399 8.639 12 2 8.601 8.639 1 9.703 6.5 14.871 5.202 22.167 12 18.722 18.798 22.167 17.5 14.871 23 9.703z"
            transform="translate(-256 -4010) translate(256 4010)"
          />
        ) : (
          <path
            d="M12 2l3.399 6.639L23 9.703l-5.5 5.168 1.298 7.296L12 18.722l-6.798 3.445L6.5 14.87 1 9.703l7.601-1.064L12 2zm2.092 8.475L12 6.39l-2.092 4.086-4.496.629 3.247 3.05-.786 4.417L12 16.48l4.126 2.091-.785-4.416 3.246-3.051-4.495-.629z"
            transform="translate(-857 -2877) translate(518 2830) translate(0 36) translate(339 11)"
          />
        )}
      </svg>
    </button>
  )
}

FavButton.propTypes = {
  label: PropTypes.string,
  favorited: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default FavButton
