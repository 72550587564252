import { makeStyles } from '../components/materialUiWrapper'

const useStyles = makeStyles(theme => ({
  paper: {
    width: 'min(calc(100% - 2rem), 22rem)',
    padding: '0.75rem 1rem',
  },
}))

export default useStyles
