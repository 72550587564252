import baseTheme from './baseTheme'
import mq from './mq'

const stepUp7 = {
  fontFamily: 'Arimo',
  fontSize: '0.813rem',
  lineWeight: '700',
  lineHeight: '.938rem',
  letterSpacing: 0,
  [mq('xxs')]: {
    fontSize: baseTheme.fontSize.xs
  },
  [mq('sm')]: {
    fontSize: baseTheme.fontSize.sm
  }
}
const stepUp6 = {
  fontFamily: 'Arimo',
  fontSize: baseTheme.fontSize.sm,
  lineHeight: '.8rem',
  letterSpacing: 0,
  [mq('xxs')]: {
    fontSize: baseTheme.fontSize.xs
  },
  [mq('sm')]: {
    fontSize: baseTheme.fontSize.sm
  }
}

const stepUp5 = {
  fontSize: baseTheme.fontSize.xxxxl,
  lineHeight: 1.21,
  letterSpacing: 0,
}

const stepUp4 = {
  fontSize: baseTheme.fontSize.xxl,
  lineHeight: 1.29,
  letterSpacing: 0,
  [mq('md')]: {
    fontSize: baseTheme.fontSize.xxxl,
    lineHeight: 1.33,
  },
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xxxxl,
    lineHeight: 1.21,
  },
}

const stepUp3 = {
  fontSize: baseTheme.fontSize.xl,
  lineHeight: 1.33,
  letterSpacing: 0,
  [mq('md')]: {
    fontSize: baseTheme.fontSize.xxl,
    lineHeight: 1.29,
  },
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xxxl,
  },
}

const stepUpCaps3 = {
  ...stepUp3,
  textTransform: 'uppercase',
}

const stepUp2 = {
  fontSize: baseTheme.fontSize.lg,
  lineHeight: 1.3,
  letterSpacing: 0,
  [mq('md')]: {
    fontSize: baseTheme.fontSize.xl,
  },
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xxl,
    lineHeight: 1.29,
  },
}

const stepUpCaps2 = {
  ...stepUp2,
  textTransform: 'uppercase',
}

const stepUp2Higher = {
  ...stepUp2,
  lineHeight: 1.55,
}

const stepUp1 = {
  fontSize: baseTheme.fontSize.md,
  lineHeight: 1.33,
  letterSpacing: 0,
  [mq('md')]: {
    fontSize: baseTheme.fontSize.lg,
  },
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xl,
  },
}

const stepUpCaps1 = {
  ...stepUp1,
}

const stepUp1Higher = {
  ...stepUp1,
  lineHeight: 1.56,
}

const baseline = {
  fontSize: baseTheme.fontSize.sm,
  lineHeight: baseTheme.lineHeight.xs,
  letterSpacing: 0,
  [mq('md')]: {
    fontSize: baseTheme.fontSize.md,
    lineHeight: baseTheme.lineHeight.md,
  },
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.lg,
  },
}

const baselineCondensed = {
  ...baseline,
  letterSpacing: '0.4px',
}

const stepDown1 = {
  fontSize: baseTheme.fontSize.sm,
  lineHeight: baseTheme.lineHeight.xs,
  letterSpacing: 0,
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.md,
    lineHeight: baseTheme.lineHeight.md,
  },
}

const stepDownCaps1 = {
  ...stepDown1,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
}

const stepDown2 = {
  fontSize: baseTheme.fontSize.xs,
  lineHeight: baseTheme.lineHeight.sm,
  letterSpacing: 0,
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.sm,
  },
}

const stepDownCaps2 = {
  ...stepDown2,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
}

const stepDown3 = {
  fontSize: baseTheme.fontSize.xxs,
  lineHeight: baseTheme.lineHeight.xxs,
  letterSpacing: 0,
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xs,
    lineHeight: baseTheme.lineHeight.sm,
  },
}

const stepDownCaps3 = {
  ...stepDown3,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
}

const stepDown4 = {
  fontSize: baseTheme.fontSize.xxxs,
  lineHeight: baseTheme.lineHeight.xs,
  letterSpacing: '0.3px',
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.xxs,
    lineHeight: baseTheme.lineHeight.xxs,
  },
}

const button = {
  fontSize: baseTheme.fontSize.sm,
  fontWeight: baseTheme.fontWeight.bold,
  lineHeight: baseTheme.lineHeight.xs,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.md,
  },
}

const buttonSmall = {
  fontSize: baseTheme.fontSize.xs,
  lineHeight: baseTheme.lineHeight.sm,
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.sm,
  },
}

const buttonExtralarge = {
  fontSize: baseTheme.fontSize.md,
  lineHeight: baseTheme.lineHeight.sm,
  [mq('xl')]: {
    fontSize: baseTheme.fontSize.lg,
  },
}

const input = {
  fontSize: baseTheme.fontSize.sm,
  lineHeight: baseTheme.lineHeight.xs,
  letterSpacing: 0,
}

const helperText = {
  fontSize: baseTheme.fontSize.xs,
  lineHeight: baseTheme.lineHeight.sm,
  letterSpacing: 0,
}

const texts = {
  stepUp7,
  stepUp6,
  stepUp5,
  stepUp4,
  stepUp3,
  stepUpCaps3,
  stepUp2,
  stepUpCaps2,
  stepUp2Higher,
  stepUp1,
  stepUpCaps1,
  stepUp1Higher,
  baseline,
  baselineCondensed,
  stepDown1,
  stepDownCaps1,
  stepDown2,
  stepDownCaps2,
  stepDown3,
  stepDownCaps3,
  stepDown4,
  button,
  buttonSmall,
  buttonExtralarge,
  input,
  helperText,
}

export default texts
