import Env from '@env';
import { getExternalSiteUrl } from "../config"

const useEnvData = () => {
  const {
    VITE_CMS_BASE_ENDPOINT: baseSubDomain,
    VITE_KAMELEOON_IFRAME_URI: kameleoonDomain,
    VITE_SITE_CODE: siteCode,
    VITE_GSETTING: brand,
    VITE_GRAPHYTE_ENABLED: isGraphyteEnabled,
    VITE_KNOWLEDGE_BASE_URL: knowledgeBaseUrl,
    VITE_BONUS_TABLE: bonusTableTemplate,
    VITE_IDLE_SOCKET_TIME: idleSocketTime,
    VITE_BRAND_ID: brandId,
    VITE_VIP_SNACKBAR: vipSnackbar,
    VITE_ENV: envName
  } = Env.get('')

  let baseDomain = getExternalSiteUrl()

  return {
    baseDomain,
    baseSubDomain,
    siteCode,
    kameleoonDomain,
    brand,
    isGraphyteEnabled,
    knowledgeBaseUrl,
    bonusTableTemplate,
    idleSocketTime,
    brandId,
    vipSnackbar,
    envName
  }
}

export { useEnvData }
