import { post } from '../api'
import { apiManagerFactory, API_MANAGER_TYPE } from '../apiManager'

const TARGET_API = apiManagerFactory
  .create(API_MANAGER_TYPE.BASE)
  .setTarget('casino')
const TARGET_LIVE_CASINO_API = 'live-casino'

export const fetchGames = async () => {
  return post({ api: TARGET_API, endpoint: '/get-games' })
}

export const fetchGamesByCategory = async categoryName => {
  return post({
    api: TARGET_API,
    endpoint: '/get-games-by-category-type',
    payload: { categoryName },
  })
}

export const fetchFavoriteGames = async () => {
  return post({ api: TARGET_API, endpoint: '/get-customer-favorite-game' })
}

export const fetchGameDetail = async (gameId, extGameId) => {
  return post({
    api: TARGET_API,
    endpoint: '/get-game-detail',
    payload: { gameId, extGameId },
  })
}

export const addFavoriteGame = async (gameId, extGameId) => {
  return post({
    api: TARGET_API,
    endpoint: '/add-customer-favorite-game',
    payload: { gameId, extGameId },
  })
}

export const deleteFavoriteGame = async (gameId, extGameId) => {
  return post({
    api: TARGET_API,
    endpoint: '/delete-customer-favorite-game',
    payload: {
      gameId,
      extGameId,
    },
  })
}

export const fetchLiveCasinoLimit = async () => {
  return post({ api: TARGET_API, endpoint: '/get-live-casino-limit' })
}

export { TARGET_API, TARGET_LIVE_CASINO_API }
