import React from 'react'

import GameCard from '../components/GameCard'
import HeadingSkeleton from '../components/Heading/Skeleton'
import Container from '../components/Container'

import Carousel from './Carousel'

const GAME_CARD_SKELETON_COUNT = 8

const CarouselSkeleton = ({ singleSkelenton }) => {
  const items = new Array(GAME_CARD_SKELETON_COUNT)
    .fill(null)
    .map((_, i) => <GameCard.Skeleton key={i} />)

  return singleSkelenton ? (
    <>
      <Container>
        <HeadingSkeleton />
      </Container>
      <Carousel>{items}</Carousel>
    </>
  ) : (
    <>
      <Container>
        <HeadingSkeleton />
      </Container>
      <Carousel big>{items}</Carousel>
      <Container>
        <HeadingSkeleton />
      </Container>
      <Carousel>{items}</Carousel>
      <Container>
        <HeadingSkeleton />
      </Container>
      <Carousel>{items}</Carousel>
    </>
  )
}

export default CarouselSkeleton
