import {useEffect } from 'react'

import Head from '../context/Head'
import Layout from '../context/Layout/SportBettingLayout'
import ScrollToTop from '../context/ScrollToTop'

import { useTranslations } from '../i18n'
import { useIsBrand } from '../hooks/useIsBrand'
import { isIntercomFFEnabled } from '../config'
import useGTMTracking from '../hooks/useGTMTracking'
import SharedStyles from '../ui/components/NewNavigation/components/SharedStyles'
import { initializeIntercom } from '../context/Intercom'
import { useProfile } from '../asyncData'
import { useAuth } from '../authentication'
import { useTheme } from '@material-ui/core'
import { isIntercomCookieEnabled } from '../business'
import AmberRoutes from './content/AmberRoutes'


const App = () => {
  const formatMessage = useTranslations()
  const { isBOL } = useIsBrand()
  const { logged, username, firstName, customerId, email, initialized } = useAuth()
  const { data } = useProfile(username, { enabled: logged })
  const theme = useTheme()

  useEffect(() => {
    if (initialized && typeof window.Intercom === "function" && (isIntercomCookieEnabled() || isIntercomFFEnabled())) {
      const vipTier = data?.LiveChatInformation?.SegmentMax
      const brand = theme['custom']?.intercom?.brand

      if ((logged && data !== undefined) || !logged) {
        initializeIntercom(customerId, firstName, email, brand, vipTier)
      }
    }
  }, [initialized, logged, data])


  useGTMTracking(isBOL())

  return (
    <Layout>
      <ScrollToTop />
      <Head
        title={formatMessage('meta:title')}
        description={formatMessage('meta:description')}
      />
      <AmberRoutes />
      <SharedStyles />
    </Layout>
  )
}

export default App
