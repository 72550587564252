import Env from '@env';
const parseEnvBoolean = envKey => {
  const envValue = Env.get('')[envKey]
  if (envValue === 'true') {
    return true
  }

  if (!envValue || envValue === 'false') {
    return false
  }

  console.warn(
    `No proper value "${envValue}" provided for ${envKey}. Defaulting to "false"`,
  )

  return false
}

export default parseEnvBoolean
