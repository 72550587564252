import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import keycloak from './keycloak'
import useAuth from './useAuth'

import useReferralLink from '../hooks/useReferralLink'

const RedirectToJoin = ({ children }) => {
  const { initialized, logged } = useAuth()
  const history = useNavigate()

  useReferralLink()

  useEffect(() => {
    if (initialized) {
      if (logged) history('/')
      else keycloak.register({ redirectUri: window.location.origin })
    }
  }, [initialized, logged, history])

  return children
}

export default RedirectToJoin
