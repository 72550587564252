import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: '#0a0a0a',
    opacity: 0.7,
    zIndex: theme.zIndex.appBar + 1,
  },
  modalContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '42.5rem',
    padding: '1rem',
    zIndex: theme.zIndex.appBar + 2,
  },
  modalContent: {
    background: theme.custom.palette.quaternary700,
    color: theme.custom.palette.neutral1,
    textAlign: 'center',
    padding: '2.25rem 1.25rem 2.5rem',
  },
  modalLogo: {
    display: 'block',
    margin: '0 auto',
    marginBottom: '.25rem',
    width: '100%',
    maxWidth: '13.625rem',
  },
  modalTitle: {
    fontSize: theme.custom.fontSize.lg,
    fontWeight: theme.custom.fontWeight.extrabold,
    lineHeight: 1.33,
    margin: '0 0 .5rem',
  },
  modalText: {
    fontSize: theme.custom.fontSize.sm,
    opacity: 0.6,
  },
  modalForm: {
    margin: '1.75rem auto 0',
    textAlign: 'left',
    display: 'inline-block',
    minWidth: '11.25rem',
  },
  modalButton: {
    width: '11.25rem',
    margin: '.5rem auto 0',
  },

  [mq('md')]: {
    modalTitle: {
      fontSize: theme.custom.fontSize.xl,
      margin: '0 0 0.75rem',
    },
    modalContent: {
      padding: '3.75rem 1.25rem 4.25rem',
    },
    modalLogo: {
      maxWidth: '19.0625rem',
      marginBottom: '0.625rem',
    },
    modalForm: {
      margin: '2rem auto 0',
    },
    modalButton: {
      margin: '1.25rem auto 0',
    },
  },
}))

export default useStyles
