import { useEffect, useState, useCallback } from 'react'
import { QueryObserverResult, RefetchOptions, UseQueryResult, useQuery,  } from 'react-query'
import useBaseUrl from '../../hooks/useBaseUrl'
import { extendCategory } from '../../business'
import { getToken, useAuth } from '../../authentication'
import useGraphyte from '../../hooks/useGraphyte/useGraphyte'
import { isUnifiedLobbyEnabled } from '../../config'
import { getGame } from './game-launch'
import { getCardCategoriesGameDetail, getCategories } from './categories'
import { SortAndFilterOptionsProps, SortAndFilterErrorResponse, SortAndFilterResponse, SortAndFilterProps, GamesResponseTypes } from './sort-and-filter-options/types'
import { getSortAndFilterOptions } from './sort-and-filter-options'
import { getFilteredGames } from './getFilteredGames'

const useLiveCasino = (queryKey, fetchData) => {
  const token = getToken()
  const { initialized, username } = useAuth()
  const [isLoading, setIsLoading] = useState(!initialized)
  const queryKeyWithUser = [...queryKey, username || '<nouser>']

  const { data, isError, error } = useQuery(
    queryKeyWithUser,
    () => fetchData(username, token),
    {
      enabled: initialized,
    },
  )

  useEffect(() => {
    setIsLoading(data === undefined)
  }, [data])

  return { data, isLoading, isError, error }
}




//we are not using this hook, launch is directly called from getGames in card component.
const useLiveLaunchGame = cardId => {
  const { data, isLoading, refetch } = useQuery(
    ['live-casino-launch-game', cardId],
    () => getGame(cardId, null, null, null),
  )

  return { data, isLoading, refetch }
}

const useLiveLobbyGames = (categoryName, customerId, pageTitle) => {
  const [anonymousId, setAnonymousId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const baseUrl = useBaseUrl()
  const { graphyte, getAnonymousId } = useGraphyte()

  useEffect(() => {
    const fetchAnonymousId = async () => {
      try {
        if (graphyte && !anonymousId) {
          const id = await getAnonymousId()
          setAnonymousId(id)
        }
      } catch (error) {
        console.error('Error fetching anonymous id: ', error)
      }
    }

    fetchAnonymousId()
  }, [])

}


const useGetCategories = (options = {}) => {
  const { data, isLoading } = useQuery(
    ['live-casino-categories'],
    getCategories,
    options,
  )
  return { data, isLoading }
}
 
const useGetGameDetail = (gameId, providerId, extGameId, options = {}) => {
  const {
    data,
    isLoading,
    isError,
    isFetching,
    isPreviousData,
    refetch,
    remove,
  } = useQuery(
    ['live-casino-game-details', gameId, providerId, extGameId],
    () => {
      if(gameId, providerId, extGameId){
        return getCardCategoriesGameDetail(gameId, providerId, extGameId)
      }

      return null
    })

  return {
    data,
    isLoading,
    isError,
    isFetching,
    isPreviousData,
    refetch,
    remove,
  }
}



const useGetSortAndFilterOptions = ()  => {
  const params: SortAndFilterOptionsProps = {
    language: 'en',
  }

  const { data, isLoading } : UseQueryResult<SortAndFilterResponse, unknown> = useQuery(
    ['sort-and-filter-options', params.language],
    () => getSortAndFilterOptions(params)
  )

  return { data, isLoading }
}

const useGetFilteredGames = (initialParams: SortAndFilterProps): Partial<UseQueryResult<GamesResponseTypes, unknown>> => {  
  const { pageNumber, pageSize, isByDefault, requestFilters} = initialParams
  const payload: SortAndFilterProps = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    isByDefault,
    language: 'en',
    requestFilters: requestFilters,
  }
  const [params, setParams] = useState(payload)

  const { data, isLoading, refetch } = useQuery(
    ['card-categories/card-filter', params],
    () => getFilteredGames(params)
  )

  const updateAndRefetch = useCallback(async (newParams: RefetchOptions) => {
    setParams(newParams as SortAndFilterProps)
    return await refetch()
  }, [refetch, setParams])

  return { data, isLoading, refetch: updateAndRefetch }
}

export {
  useGetCategories,
  useGetGameDetail,
  useGetSortAndFilterOptions,
  useGetFilteredGames,
}