import { makeStyles } from '../../../ui/components/materialUiWrapper'

const useStyles = makeStyles(theme => ({
  contentShift: {
    marginLeft: props => (props.menuState ? '265px' : '86px'),
    transition: 'margin-left 0.05s ease',
  },
  appFluidContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  }
}))

export default useStyles
