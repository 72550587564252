import Env from '@env';
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/core'

import Button from "../../../ui/components/Button"
import Container from "../Container"
import CustomPropTypes from "../../../customPropTypes"
import NavLink from "../NavLink"
import useStyles from "./footer.styles"
import { openLiveChat } from "../../../liveChat"
import Bitcoin from "../../../assets/images/logos/bitcoin.svg"
import GambleAware from "../../../assets/images/logos/gambleaware.svg"
import Mastercard from "../../../assets/images/logos/mastercard.svg"
import Rng from "../../../assets/images/logos/rng-certified.svg"
import Visa from "../../../assets/images/logos/visa.svg"
import { useAuth } from '../../../authentication'
import { useFooter } from '../../../asyncData'
import { useTranslations } from '../../../i18n'
import { CustomTheme } from '../../CustomTheme/types'

const Footer = ({ items, logo }) => {
  const classes = useStyles()
  const formatMessage = useTranslations()
  const theme = useTheme<CustomTheme>()
  const { data } = useFooter()
  const { logged } = useAuth()

  const bitcoin = data?.bitcoin
  const currentYear = new Date().getFullYear()
  const footerItems =
    items ??
    data?.links?.map(link => ({
      name: link.label,
      to: link.url,
    })) ??
    []
  const footerLogo = logo ?? `/logo-footer.svg`
  const gamble_aware = data?.gamble_aware
  const mastercard = data?.mastercard
  const rng = data?.rng
  const visa = data?.visa

  return (
    <footer
      id="footer-container"
      className={cx(classes.footer, !logged && classes.notLogged)}
    >
      <Container>
        {/* Enable chat button only for nSB */}
        {Env.get('VITE_GSETTING') === 'sbnasite' ? (
          <Button
            widthBehaviour="adaptative"
            height="small"
            onClick={openLiveChat}
            variant="ghost"
          >
            {formatMessage('action:start-live-chat')}
          </Button>
        ) : null}
        <div className={classes.footerContent}>
          <div className={classes.footerSection}>
            {footerItems.length > 0 ? (
              <p id="quick-links-title" className={classes.footerNavTitle}>
                {formatMessage('noun:quick-links')}
              </p>
            ) : null}
            <ul id="quick-links-container" className={classes.footerNav}>
              {footerItems.map(footerNav => (
                <li className={classes.footerNavItem} key={footerNav.name}>
                  <NavLink
                    to={footerNav.to}
                    className={classes.footerNavLink}
                  >
                    {footerNav.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.footerSection}>
            <ul id="payments-container" className={classes.footerLogos}>
              <li className={classes.footerLogosItem}>
                {visa ? (
                  <a href={visa} target="_blank" rel="noreferrer noopener">
                    <Visa />
                  </a>
                ) : (
                  <Visa />
                )}
              </li>
              <li className={classes.footerLogosItem}>
                {bitcoin ? (
                  <a href={bitcoin} target="_blank" rel="noreferrer noopener">
                    <Bitcoin />
                  </a>
                ) : (
                  <Bitcoin />
                )}
              </li>
              <li className={classes.footerLogosItem}>
                {rng ? (
                  <a href={rng} target="_blank" rel="noreferrer noopener">
                    <Rng />
                  </a>
                ) : (
                  <Rng />
                )}
              </li>
              <li className={classes.footerLogosItem}>
                {mastercard ? (
                  <a
                    href={mastercard}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Mastercard />
                  </a>
                ) : (
                  <Mastercard />
                )}
              </li>
              <li className={classes.footerLogosItem}>
                {gamble_aware ? (
                  <a
                    href={gamble_aware}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <GambleAware />
                  </a>
                ) : (
                  <GambleAware />
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.footerContent}>
          <div className={cx(classes.footerSection, classes.footerLogoWrapper)}>
            <Link to="/" className={classes.footerLogo}>
              <img
                id="logo-footer"
                src={footerLogo}
                alt={formatMessage('noun:logo-brand', {
                  brand: theme.custom.brand.name,
                })}
              />
            </Link>
          </div>
          <div
            className={cx(
              classes.footerSection,
              classes.footerCopyrightWrapper,
            )}
          >
            <p id="copyright" className={classes.footerCopyright}>
              {formatMessage('noun:copyright', {
                brand: theme.custom.brand.website,
                year: currentYear,
              })}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: CustomPropTypes.link.isRequired,
    }),
  ),
  logo: PropTypes.string,
}

export default Footer
