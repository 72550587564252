import { makeStyles } from '../../../../../ui/components/materialUiWrapper'
import { mq } from '../../../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  rating: {
    paddingTop: '0.5rem',

    [mq('sm')]: {
      paddingLeft: '4.625rem',
      paddingTop: 0,
    },
  },
  ratingTitle: {
    paddingBottom: '0.5rem',
    margin: 0,
    fontSize: theme.custom.fontSize.xs,
    lineHeight: theme.custom.lineHeight.sm,
    fontWeight: theme.custom.fontWeight.semibold,
    opacity: 0.87,

    [mq('sm')]: {
      fontSize: theme.custom.fontSize.sm,
      lineHeight: theme.custom.lineHeight.xs,
      fontWeight: theme.custom.fontWeight.regular,
    },
  },
  ratingIndicatorGroup: {
    display: 'flex',
    fontSize: theme.custom.fontSize.xl,
  },
  ratingIndicator: {
    display: 'flex',

    '& svg': {
      fill: theme.custom.infoModal?.ratingColor || theme.custom.palette.neutral1,
      opacity: 0.16,

      [mq('sm')]: {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
  },
  active: {
    '& svg': {
      opacity: 1,
    },
  },
}))

export default useStyles
