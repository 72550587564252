import { makeStyles } from '@material-ui/core'
import { mq } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        position: 'sticky',
        top: '3.75rem',
        zIndex: theme.custom.zIndex?.appBar,
        justifyContent: 'center',
        alignItems: 'center',
        height: '3.75rem',
        width: '100%',
        boxShadow: '0 0.0625rem 0.4375rem rgb(0 0 0 / 15%)',
        background: theme.custom?.navigation?.bgColor || theme.custom.palette.gray100,
        [mq('md', 'max')]: {
            display: 'none'
          },
    },
    navigation: {
        maxWidth: '104.5rem',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 2.5rem'
    },
    navigationItem: {
        display: 'listItem',
    },
}));

export default useStyles;
