import { EdgetTierProvider } from "../../Edgetier"

export class CustomerChatEngine {
    #Provider

    constructor(provider) {
        this.#Provider = provider
    }

    async start(props) {
        await this.#Provider.start(props)
    }

    show() {
        this.#Provider.show()
    }

    hide() {
        this.#Provider.hide()
    }

    updateVariables(props) {
        this.#Provider.updateVariables(props)
    }

    static getInstance(provider) {
        if (!this.instance) return new CustomerChatEngine(provider)
        return this.instance
    }
}

export const ChatWidgetProvider = {
    EDGETIER: new EdgetTierProvider()
}