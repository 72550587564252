import { useEffect, useContext } from 'react'
import '@platform/avatar-balance'

interface IAvatarBalanceWebComponent extends Element {
  authenticated: string
  balance: string
  level: string
  usextremepush: boolean
}
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'avatar-balance': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

const getAvatarBalanceElement = () =>
  document.querySelector('avatar-balance') as IAvatarBalanceWebComponent

const AvatarBalance = ({ logged, balances, vipData, usextremepush }) => {
  useEffect(() => {
    const avatarBalanceElem = getAvatarBalanceElement()
    if (avatarBalanceElem) {
      avatarBalanceElem.authenticated = logged?.toString()
      avatarBalanceElem.balance = balances?.AvailableBalance?.toString()
      avatarBalanceElem.level = vipData?.CurrentLevelName?.toLowerCase()
      avatarBalanceElem.usextremepush = usextremepush
    }
  }, [logged, balances, vipData, usextremepush])

  return <avatar-balance></avatar-balance>
}

export default AvatarBalance
