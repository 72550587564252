import { isVipLevelsEnabled } from "../../../business"
import { getExternalUri } from "../../../context/Layout/SportBettingLayout/utils"
import { getCasinoSubPath101 } from '../../../ui/config/utils';

const EXTERNAL_BASE_URI = getExternalUri()
const bolnasite = {
    theme: {
      background: '#2D2E2C',
      primary: '#EE3536',
      accent: '#EE3536',
      text: '#90918D',
      copyright: '#90918D',
      titles: '#FFFFFF',
      subTitles: '#E7E7E7',
      link: '#90918D',
      activeLink: '#FFFFFF',
      mobileLink: '#90918D',
      grid: '#4b4b4b',
      textButton: '#FFFFFF',
      activeTextButton: '#EE3536',
      activeButton: '#FFFFFF',
      svg: '#FFFFFF',
      chevron: '#FFFFFF',
    },
    nhButton: {
      text: 'Need help?',
    },
    sbcButton: {
      visible: true,
      text: 'Switch to classic site',
    },
    promotions: [
      {
        text: 'Promos',
        url: `${EXTERNAL_BASE_URI}/promotions`,
      },
      {
        text: 'News Room',
        url: `${EXTERNAL_BASE_URI}/news-room`,
      },
      {
        text: 'BetOnline vs Competition',
        url: `${EXTERNAL_BASE_URI}/betonline-vs-competitors`
      },
      {
        text: 'Bet TV',
        url: `${EXTERNAL_BASE_URI}/bettv`
      }
    ],
    quickLinksTitle: 'Quick links',
    quickLinks: [
      {
        text: 'About Us',
        type: 'outer',
        url: 'https://help.betonline.ag/knowledge-base/about-us/',
        windowElement: null,
      },
      {
        text: 'Refer a Friend',
        type: 'inner',
        url: `${EXTERNAL_BASE_URI}/referafriend`,
        windowElement: undefined
      },
      {
        text: 'Rules',
        type: 'outer',
        url: 'https://help.betonline.ag/knowledge-base/betonline-rules/',
        windowElement: null,
      },
      {
        text: 'Privacy Policy',
        type: 'outer',
        url: 'https://help.betonline.ag/knowledge-base/privacy-policy/',
        windowElement: null,
      },
      {
        text: 'Affiliates',
        type: 'inner',
        url: 'https://www.commissionkings.ag/',
        windowElement: undefined
      },
      {
        text: 'Terms & Conditions',
        type: 'outer',
        url: 'https://help.betonline.ag/knowledge-base/terms-and-conditions/',
        windowElement: null,
      },
      {
        text: 'Responsible Gaming',
        type: 'outer',
        url: 'https://help.betonline.ag/knowledge-base/responsible-g/',
        windowElement: null,
      },
    ],
    siteMapTitle: 'Site Map',
    siteMapLinks: [
      {
        title: 'casino',
        links: [
          {
            text: 'Play Casino',
            type: 'inner',
            url: '/',
          },
          {
            text: 'Blackjack',
            type: 'inner',
            url: '/games/table-games-blackjack',
          },
          {
            text: 'Baccarat',
            type: 'inner',
            url: `${getCasinoSubPath101()}baccarat`,
          },
          {
            text: `Craps`,
            type: `inner`,
            url: `${getCasinoSubPath101()}craps`,
          },
          {
            text: `Roulette`,
            type: `inner`,
            url: `${getCasinoSubPath101()}roulette`,
          },
          {
            text: `Keno`,
            type: `inner`,
            url: `${getCasinoSubPath101()}keno`,
          },
          {
            text: 'Slots',
            type: 'inner',
            url: '/games/slots',
          },
          {
            text: 'Video Poker',
            type: 'inner',
            url: '/games/video-poker',
          },
          {
            text: 'Bitcoin Gambling',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/crypto/bitcoin`
          }
        ],
      },
      {
        title: 'sports',
        links: [
          {
            text: 'Sportsbook',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook`,
          },
          {
            text: 'NFL Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/football/nfl`,
          },
          {
            text: 'NBA Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/basketball/nba`,
          },
          {
            text: 'MLB Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/baseball/mlb`,
          },
          {
            text: 'NHL Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/hockey/nhl`,
          },
          {
            text: 'NCAAF Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/football/ncaa`,
          },
          {
            text: 'NCAAB Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/basketball/ncaa`,
          },
          {
            text: 'Super Bowl Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/futures-and-props/super-bowl`,
          },
          {
            text: 'UFC / MMA Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/martial-arts/mma`,
          },
          {
            text: 'Boxing Betting Odds',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/sportsbook/boxing/bouts`,
          },
        ],
      },
      {
        title: 'poker',
        links: [
          {
            text: 'Play Poker',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/poker`,
          },
          {
            text: 'Download',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/poker/download`,
          },
          {
            text: 'Texas Holdem',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/poker/texas-holdem`,
          },
          {
            text: 'Omaha Poker',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/poker/omaha`,
          },
        ],
      },
      {
        title: 'racebook',
        links: [
          {
            text: 'Horse Betting',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/horse-betting?ws=0`,
          },
          {
            text: 'Kentucky Derby',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/horse-betting/triple-crown/kentucky-derby`,
          },
          {
            text: 'Preakness Stakes',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/horse-betting/triple-crown/preakness-stakes`,
          },
          {
            text: 'Belmont Stakes',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/horse-betting/triple-crown/belmont-stakes`,
          },
          {
            text: 'Breeders Cup',
            type: 'inner',
            url: `${EXTERNAL_BASE_URI}/horse-betting/breeders-cup`,
          },
        ],
      },
    ],
    trustedExp: {
      copyright: `Copyright @${new Date().getFullYear()} BetOnline.ag. All rights reserved.`,
      branding: 'BetOnline.ag.',
      title: 'A TRUSTED & SAFE EXPERIENCE',
      message:
        "At BetOnline, our company's guiding principle is to establish long-lasting, positive relationships with our customers and within the online gaming community for over 25 years.",
      bitcoin: true,
      litecoin: true,
      ethereum: true,
      visa: true,
      mastercard: true,
      americanExpress: true,
      discover: true,
      moneygram: true,
      responsible: true,
      secure: true,
      eighteen: true,
      dateTime: true,
      socialNetworks: {
        visible: true,
        icons: [
          {
            icon: 'https://cdn-cms.betonline.ag/img/Facebook_47eba461dd.svg',
            url: 'https://www.facebook.com/betonlineag/',
            altText: 'Facebook',
          },
          {
            icon: 'https://cdn-cms.betonline.ag/img/Instagram_1a00557bf1.svg',
            url: 'https://www.instagram.com/betonline_ag/',
            altText: 'Instagram',
          },
          {
            icon: 'https://cdn-cms.betonline.ag/img/Twitter_bd8dc0a929.svg',
            url: 'https://www.twitter.com/betonline_eu',
            altText: 'X'
          },
          {
            icon: 'https://cdn-cms.betonline.ag/img/Youtube_19e4b31cae.svg',
            url: 'https://www.youtube.com/channel/UCIm3xNUuD89wacPuNQc9T1A/featured',
            altText: 'YouTube'
          },
          {
            icon: 'https://cdn-cms.betonline.ag/img/Tik_Tok_c83cb33d98.svg',
            url: 'https://www.tiktok.com/@betonline.ag',
            altText: 'TikTok'
          }
        ]
      },
      officialPartners: {
        visible: true,
        title: 'Official Partners',
        partners: [
          {
            logoDesktop: 'https://cdn-cms.betonline.ag/img/mobile_laliga_fc7c3cf0c2.svg',
            logoMobile: 'https://cdn-cms.betonline.ag/img/desktop_laliga_d9c1b40200.svg',
            altText: 'LaLiga'
          },
          {
            logoDesktop: 'https://cdn-cms.betonline.ag/img/mobile_LFA_f9e0799f11.svg',
            logoMobile: 'https://cdn-cms.betonline.ag/img/desktop_LFA_b91bf7ab08.svg',
            altText: 'LFA'
          },
          {
            logoDesktop: 'https://cdn-cms.betonline.ag/img/mobile_Matchroom_7773fadaf3.svg',
            logoMobile: 'https://cdn-cms.betonline.ag/img/desktop_matchroom_0431ab15d0.svg',
            altText: 'Matchroom'
          },
          {
            logoDesktop: 'https://cdn-cms.betonline.ag/img/mobile_goldenboy_98cbfcf88c.svg',
            logoMobile: 'https://cdn-cms.betonline.ag/img/mobile_goldenboy_98cbfcf88c.svg',
            altText: 'GoldenBoy'
          }
        ],
      }
    },
  }


export const getBolOptions = () => {
  if (isVipLevelsEnabled() && !bolnasite.promotions.some(elem => elem.text === 'VIP Rewards')) bolnasite.promotions = [...bolnasite.promotions, {
    text: "VIP Rewards",
    url: `${EXTERNAL_BASE_URI}/vip-rewards`
  }]

  return bolnasite
}