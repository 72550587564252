import React, { forwardRef, ReactNode } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useCurrency } from '../../i18n'

import Icon from '../components/Icon'

import useStyles from './summary.styles'

type Ref = HTMLDivElement;

// TODO use the correct types for defined props.
interface SummaryItemProps {
  onClick?: any;
  hideInfo?: boolean;
  hasError?: ReactNode;
  type?: 'detail' | 'regular' | 'total';
  label: ReactNode;
  amount: ReactNode;

}

const SummaryItem = forwardRef<Ref, SummaryItemProps>((props, ref) => {
  const formatCurrency = useCurrency()
  const classes = useStyles()
  const itemClasses = cx(classes.item, props.type && classes[props.type])
  const itemLabelClasses = cx(
    classes.itemLabel,
    props.onClick && classes.button,
  )

  const Component = props.onClick ? 'button' : 'div'

  return (
    <div className={itemClasses}>
      <div className={classes.innerWrapper} ref={ref}>
        <Component className={itemLabelClasses} onClick={props.onClick}>
          {props.label}
          {props.onClick && !props.hideInfo ? (
            <Icon name="info-outlined" />
          ) : null}
        </Component>
        <div className={classes.itemAmount}>
          {typeof props.amount === 'undefined' || props.amount === null
            ? '—'
            : typeof props.amount === 'number'
            ? formatCurrency(props.amount)
            : props.amount}
        </div>
      </div>
      {props.hasError ? props.hasError : null}
    </div>
  )
})

SummaryItem.defaultProps = {
  type: 'regular',
}

SummaryItem.propTypes = {
  onClick: PropTypes.func,
  hideInfo: PropTypes.bool,
  hasError: PropTypes.node,
  type: PropTypes.oneOf(['detail', 'regular', 'total']),
  label: PropTypes.node.isRequired,
  amount: PropTypes.node.isRequired,
}

export default SummaryItem
