import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './textHeading.styles'

const TextHeading = props => {
  const Component = `h${props.level}`
  const classes = useStyles()
  const headingClasses = cx(classes.root, classes[Component])

  // @ts-ignore TODO: generate correct types for component
  return <Component className={headingClasses}>{props.children}</Component>
}

TextHeading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  children: PropTypes.node,
}

export default TextHeading
