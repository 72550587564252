import merge from 'deepmerge'

import base, { BaseTheme } from './baseTheme'

/**
 * createCustomTheme - Creates a complete theme from a partial theme object
 * NOTE this works by using a base theme, adding extra pieces (which depend on
 * parts of the base theme, e.g. colors), and overriding it with the partial
 * theme
 * @param {Theme} overrides - partial theme object
 * @return {Theme} complete theme object
 */
const createCustomTheme = (overrides: BaseTheme) => {
  // NOTE create a temporary final palette to use it in extra parts of the theme
  const palette = merge(base.palette, overrides.palette)  

  const extra = {
    buttons: {
      primaryFrontColor: palette.neutral1,
      primaryFrontColorActive: palette.primary800,
      secondaryFrontColor: palette.neutral1,
      secondaryFrontColorActive: palette.secondary800,
      iconColorDisabled: palette.neutral1_38,
    },
    elementColors: {
      appBackground: palette.quaternary700,
    },
    gradients: {
      primaryGradient: `linear-gradient(to bottom, ${palette.tertiary500}, ${palette.primary800})`,
      primaryGradientDark: `linear-gradient(to bottom, ${palette.primary800}, ${palette.tertiary800})`,
      primaryGradientLight: `linear-gradient(to bottom, ${palette.primary50}, ${palette.tertiary100})`,
      secondaryGradient: `linear-gradient(to bottom, ${palette.secondary500}, ${palette.secondary800})`,
      secondaryGradientDark: `linear-gradient(to bottom, ${palette.secondary900}, ${palette.secondary700})`,
      secondaryGradientLight: `linear-gradient(to bottom, ${palette.secondary50}, ${palette.secondary100})`,
    },
    tags: {
      tagColorLight: palette.neutral1,
      tagColorDark: palette.quaternary800,
      tagBgFeatured: palette.neutral1,
      tagBgNew: palette.primary800,
      tagBgExclusive: palette.secondary800,
    },
  }

  // TODO: Define the correct types for the custom theme
  return merge.all<{breakpoints: any, [key: string]: any}>([base, extra, overrides])
}

export default createCustomTheme
