import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useStyles from './container.styles'

const Container = props => {
  const { children, maxWidth, align, className } = props
  const classes = useStyles()
  const containerClasses = cx(
    classes.container,
    classes[maxWidth],
    classes[align],
    className,
  )

  return <div className={containerClasses}>{children}</div>
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOf(['sm', 'xl']),
  align: PropTypes.oneOf(['left']),
  className: PropTypes.string,
}

export default Container
