import Env from '@env';
import { universal101Enum } from '../../../context/Layout/SportBettingLayout/universal101Enum'
import '../../../context/Layout/SportBettingLayout/universal101Styles.css'
import React, { useEffect } from 'react'
import 'universal-101'
import { useLocation } from 'react-router-dom'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'universal-101': any
    }
  }
}

const Universal101 = () => {
  const brand = Env.get('VITE_GSETTING')
  const location = useLocation()

  useEffect(() => {
    const newUrl = location.pathname
    if (newUrl) {
      const event = new CustomEvent('urlChange', { detail: newUrl })
      window.dispatchEvent(event)
    }
  }, [location.pathname])

  return (
    <div className={`universal-101-${universal101Enum[brand] ?? brand}`}>
      <universal-101
        brand={universal101Enum[brand] ?? brand}
        environment={Env.get('VITE_ENV')}
      ></universal-101>
    </div>
  )
}

export default Universal101
