import React from 'react'
import Promotion from './Promotion'
import PropTypes from 'prop-types'
import GeneralSlider from '../../../components/GeneralSlider'
import useStyles from './promotionCarousel.styles'
import {
  promotionCarouselOption,
  customStyle,
} from './promotionCarousel.options'

const PromotionCarousel = props => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props?.data && (
        <GeneralSlider
          useControls={true}
          options={promotionCarouselOption}
          styles={customStyle}
        >
          {props?.data.map((promotion, i) => {
            return (
              <li key={i}>
                <Promotion data={promotion} />
              </li>
            )
          })}
        </GeneralSlider>
      )}
    </div>
  )
}

PromotionCarousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default PromotionCarousel