import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  textNote: {
    marginBottom: '1rem',
    fontSize: theme.custom.fontSize.sm,
    lineHeight: theme.custom.lineHeight.xs,
    color: theme.custom.textNote?.color || theme.custom.palette.neutral1_60,

    [mq('lg')]: {
      marginBottom: '1.25rem',
    },
  },
  title: {
    marginBottom: '0.25rem',
    fontSize: theme.custom.fontSize.sm,
    fontWeight: theme.custom.fontWeight.extrabold,
    lineHeight: theme.custom.lineHeight.xs,
    color: props =>
      props.color
        ? theme.palette[props.color]
        : theme.custom.textNote?.titleColor || theme.custom.palette.neutral1_60,
  },
  intro: {
    marginBottom: 0,
    fontSize: theme.custom.fontSize.md,
    textAlign: 'center',
    lineHeight: theme.custom.lineHeight.md,
    color: theme.custom.textNote?.color || theme.custom.palette.neutral1_87,
  },
  caps: {
    marginBottom: '0.75rem',
    fontSize: theme.custom.fontSize.xs,
    fontWeight: theme.custom.fontWeight.bold,
    lineHeight: theme.custom.lineHeight.sm,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: theme.custom.textNote?.color || theme.custom.palette.neutral1_87,
  },
}))

export default useStyles
