import Env from '@env';
import KeycloakJs from 'keycloak-js'
import { default as OldKeycloak } from 'keycloak-js-12'
import { replaceMirrorUrlHelper } from '../config/originSingleton'

import { VenusKeycloak } from 'src/venus/keycloak-venus';
import { isLibraryMode } from '../config/index';

// https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
const AUTH_3_FLAG = Env.get('VITE_AUTH_3_FLAG')
const TIMEOUT_LOGIN_IFRAME_MS = 30000
let keycloak

const IS_LIBRARY_MODE = isLibraryMode();

const getKeyCloakUrl = () => {
  const keycloakUrl = Env.get('VITE_KEYCLOAK_URL')
  //node env is set to production in ppd and prod, envs where we have mirror.
  return replaceMirrorUrlHelper(keycloakUrl)
}

if (AUTH_3_FLAG) {
  keycloak = new KeycloakJs({
    url: getKeyCloakUrl(),
    realm: Env.get('VITE_KEYCLOAK_REALM'),
    clientId: Env.get('VITE_KEYCLOAK_CLIENT_ID'),
  })
} else if (IS_LIBRARY_MODE) {

  keycloak = new VenusKeycloak();

} else {
  keycloak = new OldKeycloak({
    url: getKeyCloakUrl(),
    realm: Env.get('VITE_KEYCLOAK_REALM'),
    clientId: Env.get('VITE_KEYCLOAK_CLIENT_ID'),
  })
}



export const KEYCLOAK_EVENTS = {
  ON_READY: 'onReady',
  ON_AUTH_SUCCESS: 'onAuthSuccess',
  ON_AUTH_ERROR: 'onAuthError',
  ON_AUTH_REFRESH_ERROR: 'onAuthRefreshError',
  ON_INIT_ERROR: 'onInitError',
}

export const config = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  pkceMethod: 'S256',
  enableLogging: import.meta.env.NODE_ENV === 'development',
  checkLoginIframe: false,
  checkLoginIframeTimeout: TIMEOUT_LOGIN_IFRAME_MS,
  responseMode: 'query',
}

const isLogged = () => keycloak.authenticated
const logout = redirectUri => keycloak.logout({ redirectUri })
const getToken = () => keycloak.token
const updateToken = seconds => keycloak.updateToken(seconds)

const changePassword = async ({ redirectUri }) => {
  await updateToken(30)
  // "openid tfa" -> 'tfa' here forces the TFA step to be completed.
  // "RESET_PASSWORD" -> This is the AIA. It triggers the set new password form after logging in.
  const paramsLoginDefault = { scope: 'openid tfa', action: 'RESET_PASSWORD' }
  let paramsLogin = {}

  if (!!redirectUri) {
    paramsLogin = { ...paramsLoginDefault, redirectUri }
  } else {
    paramsLogin = { ...paramsLoginDefault }
  }

  keycloak.login({ ...paramsLogin })
}

export { isLogged, logout, getToken, updateToken, changePassword }

export default keycloak
