import { useAuth } from '../authentication'
import { useTranslations } from '../i18n'
import { useTheme } from '@material-ui/core'

export const useGetCategoriesRules = () => {
  const { logged } = useAuth()
  const formatMessage = useTranslations()
  const theme = useTheme()

  return [
    {
      condition: logged && theme['custom']?.hasCasinoBonusSection,
      value: {
        name: formatMessage('noun:casino-bonus'),
        to: '/casino-bonus',
      },
    },
  ]
}
