const palette = {
  // brand
  primary800: '#c07531',
  primary50: '#f8f2e4',
  secondary900: '#007a4a',
  secondary800: '#009761',
  secondary700: '#00b575',
  secondary600: '#00c985',
  secondary500: '#00d992',
  secondary100: '#b7f5df',
  secondary50: '#e2fbf2',
  tertiary800: '#9e743c',
  tertiary700: '#b88646',
  tertiary600: '#c9944c',
  tertiary500: '#d3a562',
  tertiary100: '#ffebcb',
  quaternary800: '#041613',
  quaternary700: '#082b2a',
  quaternary700_87: 'rgba(8, 43, 42, 0.87)',
  quaternary700_0: 'rgba(8, 43, 42, 0)',
  placeholder: '#016b64',
  placeholder20: '#015c56',
  placeholder80: '#d6d6d6',
  gray: '#363535',
  grayLight: '#979797',
  // states
  errorLight: '#cf536a',
  success: '#00b575',
  breadCrumb: '#ffffff',
  // footer
  activeFooterButton: 'linear-gradient(to bottom, #C07531 0%, #9E743C 100%)',
  footerPrimary: 'linear-gradient(180deg, #d3a562 0%, #c07531 100%)',
  footerAccent: '#FD9823',
  footerSubTitles: '#E7E7E7',
  footerBg: '#264544',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.7)',
    modal: {
      modalBackgroundColor: '#041613',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #00d992, #009761)',
      backgroundColorHover: 'linear-gradient(to bottom, #007a4a, #00b575)',
      backgroundColorActive: 'linear-gradient(to bottom, #e2fbf2, #b7f5df)',
      textColorActive: '#009761',
      textColorHover: '#FFF',
      textColor: '#FFF  ',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #d3a562, #c07531)',
      backgroundColorHover: 'linear-gradient(to bottom, #c07531, #9e743c)',
      backgroundColorActive: 'linear-gradient(to bottom, #f8f2e4, #ffebcb)',
      textColorActive: '#c07531',
      textColorHover: '#fff',
      textColor: '#fff',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255)',
      gameDescription: 'rgba(255,255,255)',
      gameVolatilityTitleColor: 'rgba(255,255,255)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#979797',
        backgroundColor: '#363535',
      },
      balance: {
        textColor: '#00d992',
      },
    },
  },
}

export default palette
