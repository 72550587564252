export const slideTheme = {
  tagList: {
    paddingBottom: {
      xs: '0.589rem',
      sm: '0.438rem',
      md: '0.5rem',
      lg: '1.463rem',
    }
  },
  gameResults: {
    minHeight: {
      xs: '1.916rem',
      sm: '1.853rem',
      md: '1.974rem',
      lg: '1.564rem',
    },
  },
  title: {
    fontSize: {
      xs: '1rem',
      sm: '1.188rem',
      md: '1.313rem',
      lg: '2rem',
    },
  },
  subtitle: {
    fontSize: {
      xs: '0.5rem',
      sm: '0.625rem',
      md: '0.625rem',
      lg: '1rem',
    },
  },
  result: {
    height: {
      xs: '0.916rem',
      sm: '1.086rem',
      md: '1.311rem',
      lg: '2.128rem',
    },
    fontSize: {
      xs: '0.5rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '0.813rem',
    },
  },
  betLimits: {
    height: {
      xs: '1.241rem',
      sm: '1.241rem',
      md: '1.498rem',
      lg: '2.267rem',
    },
    width: {
      xs: '5.625rem',
      sm: '6.685rem',
      md: '7.961rem',
      lg: '12.094rem',
    },
    fontSize: {
      xs: '0.5rem',
      sm: '0.6rem',
      md: '0.625rem',
      lg: '0.938rem',
    },
  },
  liveTag:{
    height: {
      xs: '1.241rem',
      sm: '1.241rem',
      md: '1.498rem',
      lg: '2.267rem',
    },
    fontSize: {
      xs: '0.5rem',
      sm: '0.5rem',
      md: '0.625rem',
      lg: '0.938rem',
    },
    dotSize:{
      xs: '0.3rem',
      sm: '0.3rem',
      md: '0.438rem',
      lg: '0.625rem',
    }
  },
  seatsAmount: {
    fontSize: {
      xs: '0.5rem',
      sm: '0.5rem',
      md: '0.5rem',
      lg: '0.625rem',
    },
  },
  seatsLabel: {
    fontSize: {
      xs: '0.438rem',
      sm: '0.5rem',
      md: '0.625rem',
      lg: '0.938rem',
    },
  },
}
