import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    '& > *': {
      [mq('sm', 'max')]: {
        width: '100%',
      },

      '& + *': {
        marginTop: '0.75rem',

        [mq('sm')]: {
          marginTop: 0,
          marginLeft: '1.25rem',
        },
      },
    },
  },
  reverse: {
    [mq('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',

      '& > *': {
        '& + *': {
          marginLeft: 0,
          marginRight: '1.25rem',
        },
      },
    },
  },
}))

export default useStyles
