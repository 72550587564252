import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import useStyles from './payMethods.styles'

const PayMethodsList = props => {
  const classes = useStyles()
  const paymethodsClasses = cx(classes.wrapPaymethodsList, classes[props.size])

  return (
    <div className={paymethodsClasses}>
      <h3 className={classes.paymethodsTitle}>{props.title}</h3>
      <ul className={classes.paymethodsList}>
        {props.children.map((child, idx) => (
          <li key={idx} className={classes.paymethodListItem}>
            {child}
          </li>
        ))}
      </ul>
      {props.action ? (
        <div className={classes.paymethodsListActions}>{props.action}</div>
      ) : null}
    </div>
  )
}

PayMethodsList.defaultProps = {
  size: 'small',
}

PayMethodsList.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  children: PropTypes.node.isRequired,
  action: PropTypes.node,
}

export default PayMethodsList
