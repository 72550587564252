import { makeStyles } from '@material-ui/core'
import { mq } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
    navContainer: {
        position: 'fixed',
        zIndex: 1101,
        bottom: 'max(env(safe-area-inset-bottom), 0em)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        boxShadow: '0px -2px 7px rgb(0 0 0 / 20%)',
        background: theme.custom.palette.white,
        [mq('md', 'min')]: {
            display: 'none'
        },
        [mq('sm', 'min')]: {
            bottom: '0',
        }
    },
    mainNav:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
        position: 'relative',

        '& > a':{
            padding: '0.31rem 0',
            width: '20%',
            color: theme.custom.palette.mainMenuBgColor,
            '& span > svg > path': {
                fill: theme.custom.palette.mainMenuBgColor
            }
        },
        '& svg':{
            height: '1.25rem',
            width: 'auto',
        },
    },
    menuItems: {
        background: theme.custom.palette.primary,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        color: theme.custom.palette.white,
        width: '100%',
        height: '0',
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'height .25s ease-in-out',

        '& > *':{
            opacity: 0,
            visibility: 'hidden',
            transition: 'all .15s ease',
        },

        '& svg':{
            height: '2.18rem',
            width: 'auto',
        },
        
        '& a': {
            padding: '0.625rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.custom.palette.white,
            fontSize: 'inherit',

            '& g > path':{
                fill: theme.custom.palette.white,
            },
            '& * > path':{
                fill: theme.custom.palette.white,
            }
        },

        '&.open': {
            height: '25rem',

            '& > *':{
                opacity: 1,
                visibility: 'visible',
            },
        }
    },
    toggleButton: {
        background: theme.custom.mobileMenuToggle.bgColor,
        width: '3rem',
        height: '3rem',
        marginTop: '-1.25rem',
        border: 'none',
        borderRadius: '3rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 4px rgb(0 0 0 / 15%)',
        cursor: 'pointer',
        transform: 'scale(1)',
        transition: 'scale .35s linear',

        '&:active': {
            transform: 'scale(0.85)',
        },

        '& span':{
            display: 'block',
            width: '22px',
            height: '3px',
            marginBottom: '5px',
            background: theme.custom.palette.white,
            borderRadius: '5px',
            transition: 'all .3s ease-in-out',
        },

        '& span:last-child': {
            marginBottom: 0,
        },

        '&.open': {
            '& span:first-child': {
                transform: 'rotateZ(-45deg) translate(-6px, 5px)',
            },
            '& span:nth-child(2)': {
                opacity: '0',
                visibility: 'hidden',
            },
            '& span:last-child': {
                transform: 'rotateZ(45deg) translate(-6px, -5px)',
            }
        },
    },
}));

export default useStyles;