import { makeStyles } from '../../../ui/components/materialUiWrapper'
import { hoverDevice, mq } from '../../../ui/config/utils'

const useModalStyles = makeStyles(
  theme => ({
    playContainer: {
      alignItems: 'center',
      width: 'auto',
      height: '100%',
      maxWidth: '100%',
      position: 'relative',
      top: 0,
      transform: 'none',
    },
    quickDepositButton: {
      padding: '6px 8.5px 6px 8px',
      height: '1.5rem',
      fontSize: theme.custom.fontSize.xxs,
      [mq('md')]: {
        fontSize: theme.custom.fontSize.xs,
      },
      [hoverDevice()]: {
        '&:hover': {
          backgroundColor: 'rgb(7, 177, 77, 0.42)',
          cursor: 'pointer',
        },
      },
      [mq('md', 'max')]: {
        '& span span': {
          display: 'none',
        },
      },
      [mq('md')]: {
        height: '2rem',
      },
    },

    iframeContainer: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    iframe: {
      width: '100%',
      display: 'inline-block',
      flex: 1,
    },
    cashierContainer: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      zIndex: theme.zIndex.appBar,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#0a0a0a',
      opacity: 1,
    },
    cashierIframe: {
      width: '500px',
      height: '500px',
      [mq('sm', 'max')]: {
        width: '100%',
        height: '100%',
      },
    },
  }),
  { name: 'MuiPlayModal' },
)

export default useModalStyles
