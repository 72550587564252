import { cashRacesUrlEnabled, isUnifiedLobbyEnabled } from '../../config'
import { createRequester } from './requester'
import { changeKeysToPascalCase } from './services'
import { deviceBrandId } from '../../ui/config/deviceUtils'



const createCategoriesMenuService = () => {
  const requester = createRequester('categories/menu')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const createGameDetailService = () => {
  const requester = createRequester('card-categories/game-detail')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const createTournamentService = () => {
  const requester = createRequester('get-tournament-url')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const categoriesMenuService = createCategoriesMenuService()
const getGamesDetailsService = createGameDetailService()
const getTournamentService = createTournamentService()

const getCategories = async () => {
  const isUnifiedLobby = isUnifiedLobbyEnabled()
  const requester =  categoriesMenuService 
  
  const requestData = isUnifiedLobby
    ? { isTournamentsEnabled: cashRacesUrlEnabled() }
    : undefined

  return changeKeysToPascalCase(await requester.request(requestData))
}

const getCardCategoriesGameDetail = async (gameId, providerId, extGameId) => {
  const requester = getGamesDetailsService
  const requestData = {
    gameId,
    providerId,
    extGameId,
  }

  return changeKeysToPascalCase(await requester.request(requestData))
}

const getTournament = async customerId => {
  const requester = getTournamentService
  const response = await requester.request({ customerId })
  return response
}

export { getCategories, getCardCategoriesGameDetail, getTournament }
