import React, { useState, useEffect, useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { useTheme } from '@material-ui/core'

import NavLink from '../NavLink'
import Icon from '../Icon'
import { useTranslations } from '../../../i18n'
import CustomPropTypes from '../../../customPropTypes'

import useStyles from './globalHeader.styles'
import { CustomTheme } from '../../CustomTheme/types'

const GlobalHeader = props => {
  const { links, children, onMenuClick, logged, logo, logoSlim } = props
  // XXX themification (i18n)
  const theme = useTheme<CustomTheme>()
  const [shadow, setShadow] = useState(false)

  const getScrollPosition = useCallback(
    () => (window.scrollY <= 0 ? setShadow(false) : setShadow(true)),
    [],
  )

  useEffect(() => {
    getScrollPosition()
    const options = { passive: true }
    window.addEventListener('scroll', getScrollPosition, options)
    return () =>
      window.removeEventListener('scroll', getScrollPosition)
  }, [getScrollPosition])

  const formatMessage = useTranslations()
  const classes = useStyles()
  const headerClasses = cx(classes.globalHeader, shadow && classes.shadow)

  return (
    <header id="header" className={headerClasses}>
      <Link to="/" className={classes.logo}>
        <picture>
          <source media="(max-width: 1199px)" srcSet={logoSlim} />
          <source media="(min-width: 1200px)" srcSet={logo} />
          <img
            src={logo}
            id="logo-header"
            alt={formatMessage('noun:logo-brand', {
              brand: theme.custom.brand.name,
            })}
          />
        </picture>
      </Link>

      <MediaQuery minWidth={900}>
        <div className={classes.navigation}>
          {links.map(link => (
            <NavLink
              to={link.to}
              key={link.name}
              className={classes.navigationLink}
              id={`${link.name.toLowerCase()}-button`}
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      </MediaQuery>

      {logged ? (
        <div className={classes.wrapActions}>{children}</div>
      ) : (
        <MediaQuery minWidth={600}>
          <div className={classes.wrapActions}>{children}</div>
        </MediaQuery>
      )}

      <div className={classes.wrapTriggers}>
        <button
          id="hamburger-menu-button"
          onClick={onMenuClick}
          className={classes.triggerButton}
        >
          <Icon name="menu" />
        </button>
      </div>
    </header>
  )
}

GlobalHeader.defaultProps = {
  links: [],
}

GlobalHeader.propTypes = {
  logo: PropTypes.string.isRequired,
  logoSlim: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: CustomPropTypes.link.isRequired,
    }),
  ),
  onMenuClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  logged: PropTypes.bool,
}

export default GlobalHeader
