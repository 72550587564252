import { createStyles } from '@material-ui/core/styles'
import { makeStyles } from '../materialUiWrapper'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme =>
  createStyles({
    indicatorBar: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      position: 'relative',
    },
    indicatorBarItem: {
      fontSize: theme.custom.fontSize.xs,
      lineHeight: theme.custom.lineHeight.xs,

      [mq('xs')]: {
        fontSize: theme.custom.fontSize.sm,
      },
      [mq('xl')]: {
        fontSize: theme.custom.fontSize.md,
        lineHeight: theme.custom.lineHeight.md,
      },
    },
    indicatorItem: {
      display: 'block',
      padding: '0.9375rem 0.75rem',
      border: 0,
      textAlign: 'left',
      color: theme.custom.indicatorBar?.itemColor || theme.custom.palette.neutral1,
      fontSize: 'inherit',
      lineHeight: 'inherit',
      cursor: 'pointer',
      backgroundColor: 'transparent',

      [mq('xs')]: {
        padding: '0.75rem',
      },

      '& svg': {
        transition: 'transform 300ms',
      },
      // TODO: Review
      '& > .MuiSkeleton-root': {
        marginTop: '0.125rem',
        marginBottom: '0.125rem',

        [mq('xl')]: {
          marginBottom: '0.25rem',
        },
      },
    },
    indicatorItemLink: {
      [hoverDevice()]: {
        '&:hover $indicatorAmount': {
          color:  theme.custom.palette.btnPrimary || theme.custom.palette.tertiary800,
        },
      },
    },
    indicatorItemInline: {
      [mq('md')]: {
        display: 'flex',
        alignItems: 'center',

        '& svg': {
          display: 'none',
        },
      },
    },
    isOpen: {
      '& svg': {
        transform: 'rotate(-180deg)',
      },
    },
    isLoading: {
      pointerEvents: 'none',
    },
    hasError: {
      pointerEvents: 'none',
    },
    indicatorTitle: {
      display: 'flex',
      fontWeight: theme.custom.fontWeight.regular,
      color: theme.custom.indicatorBar?.titleColor || theme.custom.palette.neutral1_60,

      '& > span': {
        marginLeft: '0.25rem',
      },
    },
    indicatorAmount: {
      color: theme.custom.indicatorBar?.amountColor || theme.custom.palette.neutral1,
      fontWeight: theme.custom.fontWeight.bold,

      [mq('md', 'max')]: {
        display: 'block',
      },
    },
    paper: {
      minWidth: 'min(calc(100% - 2rem), 22rem)',
      maxWidth: 'min(calc(100% - 2rem), 22rem)',
      padding: '1rem 1rem 1.25rem',
      overflow: 'visible',
      marginTop: '-0.125rem',

      [mq('sm')]: {
        marginTop: '0.375rem',
        marginLeft: '-2.5rem',
      },
      [mq('xl')]: {
        marginTop: '0.125rem',
      },

      '&::before': {
        content: '""',
        width: '0.5rem',
        height: '0.5rem',
        background: 'inherit',
        top: 0,
        left: '65%',
        position: 'absolute',
        transform: 'translate(-50%, -50%) rotate(45deg)',

        [mq('sm')]: {
          width: '0.75rem',
          height: '0.75rem',
        },
      },
    },
  }),
)

export default useStyles
