import React from 'react'

import PageContents from '../ui/components/PageContents'
import Loader from '../ui/components/Loader'

const Loading = () => (
  <PageContents>
    <Loader size="large" hasWrapper hasText />
  </PageContents>
)

export default Loading
