import React from 'react'
import PropTypes from 'prop-types'
import useXtremePush from '../../../hooks/useXtremePush'
import useReferralLink from '../../../hooks/useReferralLink'
import LayoutDecorator from './decorator'

export { LayoutDecorator }

const Layout = props => {
  useXtremePush()
  useReferralLink()

  return <>{props.children}</>
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
