import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    width: '1295px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: '2px',
    marginTop: '56px',
    '@media (max-width:780px)': {
      width: '300px',
      marginTop: '24px',
    },
    '@media (max-width:1295px) and (min-width:780px)': {
      minWidth: '600px',
      maxWidth: '600px',
    },
  },
}))

export default useStyles