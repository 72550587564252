import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'

import useResizeHandler from '../../../hooks/useResizeHandler'

import useStyles from './img.styles'
import { STATUS } from './constants'

// NOTE see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#image_types
const getMiMEType = (source = '') => {
  const extension = source.split('.').pop()
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'jfif':
    case 'pjpeg':
    case 'pjp':
      return 'image/jpeg'
    case 'svg':
      return 'image/svg+xml'
    default:
      return `image/${extension}`
  }
}

const MobileOrDesktopImg = props => {
  const { breakpoints } = useTheme()
  const {
    imageMobile,
    imageDesktop,
    placeholder,
    fallback,
    breakpoint,
    ...rest
  } = props

  const mediaMobile = `(max-width: ${breakpoints.values[breakpoint] - 1}px)`
  const mediaDesktop = `(min-width: ${breakpoints.values[breakpoint]}px)`

  const classes = useStyles()
  // TODO: Add correct type for the useRef
  const ref = useRef<any>()
  const [status, setStatus] = useState(STATUS.LOADING)

  const handleStatusUpdate = useCallback(
    () => setStatus(ref.current.complete ? STATUS.READY : STATUS.LOADING),
    [],
  )

  useEffect(handleStatusUpdate, [handleStatusUpdate, imageMobile, imageDesktop])

  useResizeHandler(handleStatusUpdate)

  const className = status === STATUS.READY ? classes.image : classes.hidden

  // NOTE empty alt to prevent linter warning, should be overwriten by props
  return (
    <div className={classes.imageWrapper}>
      {status === STATUS.LOADING ? placeholder : null}
      {status === STATUS.ERROR ? fallback : null}
      <picture className={className}>
        <source
          media={mediaMobile}
          srcSet={imageMobile}
          type={getMiMEType(imageMobile)}
        />
        <source
          media={mediaDesktop}
          srcSet={imageDesktop}
          type={getMiMEType(imageDesktop)}
        />
        <img
          ref={ref}
          src={imageMobile}
          alt=""
          loading="lazy"
          {...rest}
          className={classes.image}
          onLoad={() => setStatus(STATUS.READY)}
          onError={() => setStatus(STATUS.ERROR)}
        />
      </picture>
    </div>
  )
}

MobileOrDesktopImg.defaultProps = {
  breakpoint: 'sm',
}

MobileOrDesktopImg.propTypes = {
  breakpoint: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
  placeholder: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
  imageMobile: PropTypes.string.isRequired,
  imageDesktop: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default MobileOrDesktopImg
