import { makeStyles } from '@material-ui/core';
import mq from '../../../config/mq';

const useStyles = makeStyles(theme => ({
    nav: {
        boxShadow: '0 0 0.65rem rgb(0 0 0 / 15%)',
        backgroundColor: theme.custom.palette.neutral1,
        paddingBottom: '2.375rem',
        [mq('xxs')]: {
            width: '100%',
            margin: '0 0 0 calc((100% - 92%)/2)'
        },
        [mq('md')]: {
            width: '15.5rem',
            margin: '0 0 0 calc(100% - 15.5rem)',
        },
        '& ol' : {
            '& li': {
                listStyle: 'none',
                marginBottom: '1.313rem',
                fontFamily: theme.custom.fontFamily.primaryFont,
                fontWeight: theme.custom.fontWeight.normal,
                color: theme.custom.palette.neutral3
            },
            '& li:last-child': {
                marginBottom: '0rem'
            },
            margin: '1.125rem 0rem 0rem 0rem',
            paddingLeft: '1.438rem',
        }
    },
    headerTitle: {
        height: '3.599rem',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        [mq('xxs')]: {
            justifyContent: 'left',
            paddingLeft: '1.313rem'
        },
        [mq('md')]: {
            justifyContent: 'center',
        },
        backgroundColor: theme.custom.palette.secondary150,

        '& h2' : {
            fontFamily: theme.custom.fontFamily.primaryFont,
            fontStyle: 'normal',
            fontWeight: theme.custom.fontWeight.normal,
            fontSize: '1.313rem',
            color: theme.custom.palette.neutral1,
            lineHeight: '1.5rem'
        }
    }

}));

export default useStyles;