import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '../../i18n'

import BackButton from '../components/BackButton'
import TextHeading from '../components/TextHeading'

import Info from './Info'

const PromotionalBonusInfo = props => {
  const { onGoBack } = props
  const formatMessage = useTranslations()

  return (
    <>
      {onGoBack ? (
        <BackButton onClick={onGoBack}>
          {formatMessage('action:back')}
        </BackButton>
      ) : null}
      <Info>
        <TextHeading level={1}>
          {formatMessage('noun:promotional-bonus')}
        </TextHeading>
        <p>{formatMessage('bonus-wallet:promotional-bonus:info')}</p>
      </Info>
    </>
  )
}

PromotionalBonusInfo.propTypes = {
  onGoBack: PropTypes.func,
}

export default PromotionalBonusInfo
