import { makeStyles } from '../components/materialUiWrapper'
import { hoverDevice } from '../config/utils'

const useStyles = makeStyles(theme => ({
  info: {
    '& h1': {
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
      fontSize: theme.custom.fontSize.md,
      fontWeight: theme.custom.fontWeight.extrabold,
      color: theme.custom.palette.neutral2,
    },
    '& h2': {
      margin: '.5rem 0 .25rem',
      fontSize: theme.custom.fontSize.sm,
      fontWeight: theme.custom.fontWeight.semibold,
      color: theme.custom.palette.neutral2,
      '&:last-of-type': {
        '& + p': {
          marginBottom: '1.3125rem',
        },
      },
    },
    '& p': {
      marginTop: 0,
      marginBottom: '0.75rem',
      fontSize: theme.custom.fontSize.sm,
      color: theme.custom.palette.neutral2_60,
    },
    '& a': {
      display: 'inline-flex',
      marginTop: '0.75rem',
      color: theme.custom.info?.link || theme.custom.palette.tertiary800,
      position: 'relative',

      '&[disabled]': {
        color: theme.custom.palette.neutral2_38,
        cursor: 'default',
        pointerEvents: 'none',
      },
      [hoverDevice()]: {
        '&:hover': {
          color: theme.custom.palette.neutral2_60,
        },
      },

      '& span': {
        display: 'flex',
        width: '1rem',
        height: '1rem',
        fontSize: theme.custom.fontSize.md,
        marginLeft: '0.25rem',
      },
    },
  },
}))

export default useStyles
