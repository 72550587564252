import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  error: {
    padding: '1rem 1.25rem 1.125rem 3rem',
    border: `2px solid ${theme.custom.palette.errorLight}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.custom.fontSize.sm,
    color: theme.custom.palette.errorLight,
    position: 'relative',

    '& > span': {
      content: '""',
      display: 'block',
      width: '1.25rem',
      height: '1.25rem',
      fontSize: theme.custom.fontSize.xl,
      position: 'absolute',
      top: '1rem',
      left: '1rem',

      '& .icon': {
        display: 'block',
        fill: 'currentColor',
      },
    },
  },
  fixed: {
    marginBottom: '1.5rem',

    [mq('sm')]: {
      maxWidth: '34.5rem',
      marginBottom: '2rem',
    },
    [mq('md')]: {
      maxWidth: '42.5rem',
    },
  },
  fluid: {},
  errorFav: {
    margin: '1.25rem 0.75rem 0.5rem',
  },
  errorUrl: {
    margin: '0.5rem 0.75rem 0',
  },
  errorTitle: {
    marginBottom: '0.375rem',
    fontWeight: theme.custom.fontWeight.bold,
  },
}))

export default useStyles
