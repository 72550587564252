import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './bonusMessage.styles'
import  infoIconSvg from '../../../../../assets/icons/info-alt.svg?url'
import { formatMessage, useCurrency } from '../../../../../i18n'
import { useEnvData } from '../../../../../hooks/useEnvData'

const InfoIcon = () =>{
  return <img src={infoIconSvg} alt='no-results'></img>
}

const BonusMessage = ({ bonus, message, isLoadingPlayableBalance, isError }) => {
  const classes = useStyles()
  const title = message === 'ActiveBonusRestricted' && formatMessage('bonus-wallet:info:no-playable-balance')
  const { knowledgeBaseUrl } = useEnvData()
  const formatCurrency = useCurrency()
  const bonusRulesURL = `${knowledgeBaseUrl}/bonus-rules/`
  
  if (isError){
    return null
  }

  return (
    !isLoadingPlayableBalance && (
      <div className={classes.wrapper}>
        {title && (
          <>
            <InfoIcon />
            <p className={classes.title}>{title}</p>
            <a href={bonusRulesURL} className={classes.link}>{formatMessage('noun:terms-conditions')}</a>
          </>
        )}
        <p className={classes.bonus}>
          {formatMessage('bonus-wallet:label:playable-balance')}: 
          <span className={classes.number}>
            {formatCurrency(bonus)}
          </span>
        </p>
      </div>
    )
  )
}

BonusMessage.propTypes = {
  bonus: PropTypes.number,
  message: PropTypes.string || null,
  isLoadingPlayableBalance: PropTypes.bool,
  isError: PropTypes.bool,
}

export default BonusMessage