import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

import Link from '../Link'
import TextHeading from '../TextHeading'
import useStyles from './markdown.styles'

/**
 * Wrapper around react-markdown's component
 * @see https://github.com/remarkjs/react-markdown
 */
const Markdown = props => {
  const { content, type, ...rest } = props
  const classes = useStyles()

  const renderLink = useCallback(
    data => (
      <Link to={data.href} type={type}>
        {data.children}
      </Link>
    ),
    [type],
  )

  return (
    <div className={cx(classes.markdownContent, 'markdownContent')}>
      <ReactMarkdown
        remarkPlugins={[gfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: renderLink,
          h1: TextHeading,
          h2: TextHeading,
          h3: TextHeading,
          h4: TextHeading,
          h5: TextHeading,
          h6: TextHeading,
        }}
        {...rest}
      >
        {props.content}
      </ReactMarkdown>
    </div>
  )
}

Markdown.defaultProps = {
  type: 'dark',
}

Markdown.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']),
  content: PropTypes.string.isRequired,
}

export default Markdown
