import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './info.styles'

const Info = props => {
  const classes = useStyles()

  return <div className={classes.info}>{props.children}</div>
}

Info.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Info
