import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  errorBox: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: theme.custom.fontSize.md,

    '& .icon': {
      display: 'inline-block',
      fill: 'currentColor',
    },
    '& span:not([class])': {
      display: 'flex',
    },
  },
  light: {
    color: theme.custom.palette.errorLight,
  },
  dark: {
    color: theme.custom.palette.errorDark,
  },
  small: {
    fontSize: theme.custom.fontSize.sm,
  },
  medium: {
    fontSize: theme.custom.fontSize.md,
  },
  errorText: {
    ...texts.helperText,
    display: 'inline-block',
    marginLeft: '0.375rem',

    '& > button, & > a': {
      padding: 0,
      border: 0,
      color: 'currentColor',
      textDecoration: 'underline',
      backgroundColor:  'transparent',
      cursor: 'pointer',
    },
  },
}))

export default useStyles
