import Env from '@env';
// todo: move this code to src/config/  I did not do it requires a bit of analysis 
const parseEnvString = envKey => {
  const envValue = Env.get('')[envKey]

  if (envValue == null) {
    console.warn(
      `No proper value "${envValue}" provided for ${envKey}. Provide string value for this ${envKey}`,
    )
    return null
  }

  return envValue
}

const parseEnvNumber = envKey => {
  const envValue = Env.get('')[envKey]

  if (envValue == null) {
    console.warn(
      `No proper value "${envValue}" provided for ${envKey}. Provide string value for this ${envKey}`,
    )
    return null
  }

  const envNumber = parseInt(envValue)
  return Number.isNaN(envNumber) ? null : envNumber
}

const parseEnvBoolean = envKey => {
  return Env.get('')[envKey] === 'true'? true : false;
}

const isLiveCasinoEnabled = () => {
  return parseEnvBoolean('VITE_USE_LIVE_CASINO');
}

export { parseEnvBoolean, parseEnvNumber, parseEnvString, isLiveCasinoEnabled }
