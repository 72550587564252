type tokenParsed = {
  preferred_username: string,
  name: string,
  email: string,
  given_name: string,
  family_name: string,
  sub: string,
}

export class VenusKeycloak {
  token: string = ''
  authenticated: boolean
  tokenParsed?: tokenParsed
  idTokenParsed?: {
    sub: string
  }
  sessionId: string
  epoch: number

  createRegisterUrl() {
    return '/join'
  }

  createLoginUrl() {
    return '/login'
  }

  logout() {
    window.location.assign('/logout')
  }

  setToken(token: string) {
    this.token = token
  }

  async init(authenticated: boolean, sessionId: string, tokenParsed?: tokenParsed) {
    if(authenticated) await this.getToken()
    this.authenticated = authenticated
    this.sessionId = sessionId
    this.tokenParsed = tokenParsed
    this.idTokenParsed = {
      sub: tokenParsed.sub
    }
  }

  async getToken() {
    const tokenResponse = await fetch('/get-token')
    if(!tokenResponse.ok) {
        this.authenticated = false
        return
    }
    
    const { token, epoch } = await tokenResponse.json()
    this.token = token
    this.epoch = epoch
    this.authenticated = true
  }

  async updateToken(validity: number) {
    if(Math.floor(Date.now()) + validity*1000 > this.epoch) {
      await this.getToken()
    }
  }
}