import { makeStyles } from '../../../components/materialUiWrapper'
import { mq } from '../../../config/utils'

const useXtremePushStyles = makeStyles(theme => ({
  "@global": {
    ".webpush-notification-center-color-custom #webpush-notification-center-header": {
      height: "50px",
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.custom.palette.sideMenu,
      borderBottom: "none",
      fontWeight: "bold",
      padding: "0 5px"
    },
    "#webpush-notification-center": {
      top: "58px !important",
      boxShadow: "none !important",
      [mq('sm', 'max')]: {
          top: "0px !important",
          width: "100% !important",
          maxWidth: "unset !important",
      }
    },
    "#webpush-notification-center.webpush-notification-center-layout-panel": {
      width: "380px",
    },
    "#webpush-notification-center-title": { marginLeft: "40px" },
    "#webpush-notification-center-list": {
      backgroundColor: theme.custom.palette.white,
      top: "50px",
      overflow: "scroll",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": { display: "none" }
    },
    "#webpush-notification-center-close": { top: "17px", right: "12px" },
    ".webpush-notification-center-item": {
      borderBottom: "1px solid rgba(0,0,0,0.2) !important",
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "auto auto",
      gap: "5px 10px",
      "& .webpush-notification-center-item-text, & .webpush-notification-center-item-title": {
        color: `${theme.custom.palette.neutral3} !important`,
        fontFamily: theme.custom.fontFamily.primaryFont,
        padding: "0"
      },
      "& .webpush-notification-center-item-image": { 
        marginRight: "8px",
        gridRow: "1 / span 3"
       },
      "& .webpush-notification-center-item-title": { 
        fontSize: "11px",
        gridColumn: "2",
        gridRow: "1",
        "& b, strong": { fontWeight: 700 }
      },
      "& .webpush-notification-center-item-text": {
        fontSize: "10px",
        marginTop: "2px",
        gridColumn: "2",
        gridRow: "2"
      }
    },
    ".webpush-notification-center-item-date": { paddingLeft: "0 !important" },
    "#webpush-notification-center-subscription": {
      display: "flex",
      "& .webpush-onoffswitch-container": { order: 2 },
      "& .webpush-notification-center-subscription-status": { order: 1 },
      "& .webpush-onoffswitch-label": { margin: "0" }
    },
    ".webpush-notification-center-color-custom .webpush-onoffswitch-checkbox:checked + .webpush-onoffswitch-label": {
      backgroundColor: theme.custom.palette?.xpGreen,
      "&::before": { backgroundColor: theme.custom.palette?.xpGreen }
    },
    "#webpush-notification-center-load-more": { color: `${theme.custom.palette.neutral3} !important` },
    ".webpush-notification-center-icon-bell": { top: "14px !important" },
    "#webpush-notification-center-open": { display: "none !important" }
  }
}))

export default useXtremePushStyles
