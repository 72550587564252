import React, { ComponentPropsWithoutRef } from 'react'
import PropTypes from 'prop-types'

import useStyles from './radioButton.styles'

interface RadioButtonProps extends ComponentPropsWithoutRef<'input'> {
  id: string;
  text: string;
}
const RadioButton = (props: RadioButtonProps) => {
  const classes = useStyles()

  return (
    <div className={classes.radioButton}>
      <input
        className={classes.radioButtonInput}
        type="radio"
        id={props.id}
        name={props.id}
        value={props.id}
        {...props}
      />
      <label className={classes.radioButtonLabel} htmlFor={props.id}>
        {props.text}
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default RadioButton
