import { makeStyles } from '../../../materialUiWrapper'
import { slideTheme } from '../../../../config/slideTheme'
import { mq } from '../../../../config/utils'

const useStyles = makeStyles(theme => ({
  seatsCtn: {
    display: 'flex',
    height: '1.231rem',
    textAlign: 'center',
    padding: '0px',
    color: '#FFFFFF',
    fontWeight: 700,
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center'
  },
	seatsAmount: {
		backgroundColor: '#C07531',
    height: '0.783rem',
    width: '0.821rem',
    borderRadius: '50%',
    marginRight: '0.338rem',
		fontSize: slideTheme.seatsAmount.fontSize.xs,
    [mq('sm')]: {
		  fontSize: slideTheme.seatsAmount.fontSize.sm,
    },
    [mq('md')]: {
		  fontSize: slideTheme.seatsAmount.fontSize.md,
    }
	},
	seatsLabel: {
    fontSize: slideTheme.seatsLabel.fontSize.xs,
    [mq('sm')]: {
      fontSize: slideTheme.seatsLabel.fontSize.sm,
    },
    [mq('md')]: {
      fontSize: slideTheme.seatsLabel.fontSize.md,
    }
	}
}))

export default useStyles
