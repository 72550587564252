import Env from '@env';
import '@platform/side-menu'
import { useAuth } from '../../../../authentication'
import { useEffect, useLayoutEffect } from 'react'
import { getBasePath } from '../../../config/utils'
import { useEnvData } from '../../../../hooks/useEnvData'
import { useUrlChange } from 'src/hooks/useUrlChange'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../../../../libs/uaparser'
import { useLockScroll } from 'src/hooks/useLockScroll'

export const SideMenu = ({ children, sideNavTrigger }) => {
  const { brand } = useEnvData()
  const { initialized, logged } = useAuth()
  const navigate = useNavigate()
  const { blockModalScroll, unblockModalScroll } = useLockScroll()
  const items = [
    {
      name: 'Casino Lobby',
      icon: 'casino',
      route: `${getBasePath()}/`,
    },
    {
      name: 'New Games',
      icon: 'newGames',
      route: `${getBasePath()}/games/new`,
    },
    {
      name: 'Popular Games',
      icon: 'popularGames',
      route: `${getBasePath()}/games/popular`,
    },
    {
      name: 'Live Casino',
      icon: 'liveCasino',
      route: `${getBasePath()}/games/live-casino`,
    },
    {
      name: 'Table Games',
      icon: 'tableGames',
      route: `${getBasePath()}/games/table-games`,
    },
    {
      name: 'Slots',
      icon: 'slots',
      route: `${getBasePath()}/games/slots`,
    },
    {
      name: 'Tournaments',
      icon: 'cashRaces',
      route: `${getBasePath()}/tournaments`,
    },
    {
      name: 'Video Poker',
      icon: 'videoPoker',
      route: `${getBasePath()}/games/video-poker`,
    },
    {
      name: 'Specialty Games',
      icon: 'specialtyGames',
      route: `${getBasePath()}/games/specialty-games`,
    },
  ]

  useUrlChange('urlChange')

  useLayoutEffect(() => {
    if (initialized && logged) {
      items.push({
        name: 'Casino Bonus',
        icon: 'promos',
        route: `${getBasePath()}/casino-bonus`,
      })

      const sideMenu = document.querySelector('side-menu')
      if (sideMenu) {
        sideMenu.menuItems = items
      }
    }
  }, [logged, initialized])

  useLayoutEffect(() => {

    const handleSideNavEvent = (event) => {
      sideNavTrigger(event?.detail?.visible)
    }
    window.addEventListener('webcomponent/side-menu/menu-state-open', handleSideNavEvent)

    return () => {
      window.removeEventListener('webcomponent/side-menu/menu-state-open', handleSideNavEvent)
    }
  }, [])
  const handleOpenEvent = (event) => {
      if (event.detail?.visible) {
        blockModalScroll()
      } else {
        unblockModalScroll()
      }
  }
  useEffect(() => {
    window.addEventListener('header-navigation-menu-toggled', handleOpenEvent)
    return () => window.removeEventListener('header-navigation-menu-toggled', handleOpenEvent)
  }, [])

  window.addEventListener('webcomponent/side-menu/ready', () => {
    const sideMenu = document.querySelector('side-menu')
    sideMenu.menuItems = items
    sideMenu.brand = brand
    sideMenu.hasProductSlider = true
    sideMenu.env = Env.get('VITE_ENV')
    sideMenu.navigate = !isMobile() ? navigate : undefined
  })

  return <side-menu>{children}</side-menu>
}
