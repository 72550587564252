import {
  MultiServiceTransportClient,
  WebSocketMultiServiceTransportClientImpl,
} from '@websites.common/multi-service-transport-client'
import { socketSettings, transportSettings } from './config'
import { eventType } from './requesterFactory'

export default class TransportImpl {
  static transportImp = null

  static subscribe(eventName, listener) {
    window.addEventListener(eventName, listener)
  }

  static unsubscribe(eventName, listener) {
    window.removeEventListener(eventName, listener)
  }

  static onTransportStatusChanged() {
    const event = new CustomEvent(eventType.onTransportStatusChanged, {
      detail: {},
    })
    window.dispatchEvent(event)
  }

  static getInstance() {
    if (TransportImpl.transportImp === null) {
      const socketImp = new WebSocketMultiServiceTransportClientImpl(
        socketSettings,
      )
      TransportImpl.transportImp = new MultiServiceTransportClient(
        socketImp,
        transportSettings,
        TransportImpl.onTransportStatusChanged,
      )
    }
    return this.transportImp
  }
}
