import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './list.styles'
import { mapChildren } from '../../../libs/utils'

const List = props => {
  const { children, ...rest } = props
  const classes = useStyles(props)

  return (
    <ul className={classes.list} {...rest}>
      {mapChildren(children, (child, idx) => (
        <li key={idx} className={classes.listItem}>
          {child}
        </li>
      ))}
    </ul>
  )
}

List.propTypes = {
  cols: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default List
