import { makeStyles } from '../../materialUiWrapper'
import { aspectRatio, centered, mq } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
  titlePlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.custom.titlePlaceholder?.color || theme.custom.palette.neutral1,
    backgroundColor: theme.custom.titlePlaceholder?.bgColor || theme.custom.palette.placeholder,
    padding: '.5rem',
    lineHeight: '1rem',
    textAlign: 'center',
    fontSize: theme.custom.fontSize.sm,

    '&.small': {
      fontSize: theme.custom.fontSize.xs,
    },

    [mq('md')]: {
      fontSize: theme.custom.fontSize.md,
      '&.small': {
        fontSize: theme.custom.fontSize.sm,
      },
    },
    [mq('lg')]: {
      padding: '.8125rem',
      lineHeight: '1.35rem',
    },
  },
  aspectRatio: {
    ...aspectRatio()
  },
  titlePlaceholderTxt: {
    ...centered('absolute'),
    width: '100%',
    padding: '1rem',
  }
}))

export default useStyles
