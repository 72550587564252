import Env from '@env';
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../authentication'
import { useEnvData } from '../../../../hooks/useEnvData'
import {
  AvatarBalanceComponent,
  UniversalBalanceComponent,
} from '../UniversalBalanceComponent'
import { MainNavigation, HeaderNavigation } from '../components/index'
import { getGTMDataObject, headerLinks } from '../../../../context/Layout/SportBettingLayout/utils'
import { isDesktop, isTablet } from '../../../../libs/uaparser'

const NewHeader = () => {
  const { initialized, logged: isLogged, redirectToJoin, redirectToLogin } = useAuth()
  const { baseDomain } = useEnvData()
  const [headerButtons, setHeaderButtons] = useState(undefined)

  const isDesktopDevice = isDesktop() || isTablet()

  useEffect(() => {

    if(!isDesktopDevice) {
      return
    }
    if(!isLogged) {
      setHeaderButtons({
        hasLogin: true,
        hasJoin: true,
        loginCallback() {
          handlePushDataLayer('login', 3, isLogged, `${baseDomain}/login`)
          redirectToLogin()
        },
        joinCallback() {
          handlePushDataLayer('join', 4, isLogged, `${baseDomain}/join`)
          redirectToJoin()
        }
      })
    } else {
      setHeaderButtons({
        hasDeposit: true,
        depositCallback() {
          handlePushDataLayer('deposit', 3, isLogged, `${baseDomain}/cashier/deposit`)
          window.location.href = headerLinks(siteKey, 'banking')
        }
      })
    }
  }, [initialized, isDesktopDevice, isLogged])

  const handlePushDataLayer = (name, index, isLogged, redirectUrl, noText?) => {
    getGTMDataObject(
      name,
      index,
      isLogged ? 3 : 4,
      noText ? null : name,
      redirectUrl,
    )
  }

  const siteKey = Env.get('VITE_GSETTING')
  

  return (
    <div style={{ position: 'sticky', top: 0, zIndex: 999 }}>
      {!isDesktopDevice && <MainNavigation />}
      <UniversalBalanceComponent />
      <HeaderNavigation slotButtons={headerButtons}>
      {!isDesktopDevice && <AvatarBalanceComponent />}
      {isDesktopDevice && isLogged && <AvatarBalanceComponent />}
      </HeaderNavigation>
    </div>
  )
}

export default NewHeader
