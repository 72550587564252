import { useEnvData } from './useEnvData'

export const useIsBrand = () => {
  const { brand } = useEnvData()

  const isWC = () => brand === 'wcnasite'
  const isSS = () => brand === 'ssnasite'
  const isGC = () => brand === 'gcsite'
  const isSB = () => brand === 'sbnasite'
  const isHR = () => brand === 'hrnasite'
  const isBOL = () => brand === 'bolnasite'
  const isQBC = () => brand === 'qbcnasite'
  const isLV = () => brand === 'lvnasite'

  return {
    brand,
    isWC,
    isSS,
    isGC,
    isSB,
    isHR,
    isBOL,
    isQBC,
    isLV,
  }
}
