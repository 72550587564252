import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  loader: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.custom.palette.neutral1_60,
  },
  hasWrapper: {
    width: '100%',
    padding: '4.5rem 1rem',
  },
  small: {
    fontSize: theme.custom.fontSize.xl,
  },
  medium: {
    fontSize: theme.custom.fontSize.xxxxl,
  },
  large: {
    fontSize: theme.custom.fontSize.xxxxxl,
  },
  loaderIcon: {
    width: '1em',
    height: '1em',
    color: theme.custom.loader?.color || theme.custom.palette.neutral1_60
  },
  loaderText: {
    marginTop: '0.5rem',
    ...texts.stepDown1,
    fontWeight: theme.custom.loader?.fontWeightSemiBold || theme.custom.fontWeight.extrabold,
    color: theme.custom.loader?.color || theme.custom.palette.neutral1_60

  },
}))

export default useStyles
