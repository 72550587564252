import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'

import { Box as Spacing } from '@material-ui/core'
import Icon from '../Icon'
import Skeleton from '../Skeleton'
import ErrorNote from '../ErrorNote'

import { useCurrency } from '../../../i18n'
import useStyles from './indicatorBar.styles'
import CustomPropTypes from '../../../customPropTypes'

const Indicator = props => {
  const formatCurrency = useCurrency()

  const classes = useStyles()

  const indicatorItemClasses = cx(
    classes.indicatorItem,
    classes.indicatorItemLink,
    classes.indicatorItemInline,
    props.isLoading && classes.isLoading,
    props.error && classes.hasError,
  )

  return (
    <>
      <NavLink className={indicatorItemClasses} to={props.to}>
        <strong className={classes.indicatorTitle}>
          {props.name}
          {props.error ? null : <Icon name="chevron-right" />}
        </strong>
        {props.isLoading && !props.error ? (
          <Skeleton width={56} height={12} />
        ) : !props.error ? (
          <span id={props.id} className={classes.indicatorAmount}>
            {typeof props.amount === 'number'
              ? formatCurrency(props.amount)
              : '—'}
          </span>
        ) : null}
      </NavLink>
      {props.error ? (
        <Spacing mt={-0.625} mb={0.625} px={0.75}>
          <ErrorNote size="medium">{props.error}</ErrorNote>
        </Spacing>
      ) : null}
    </>
  )
}

Indicator.propTypes = {
  isLoading: PropTypes.bool,
  amount: CustomPropTypes.requiredUnless('isLoading', PropTypes.number),
  error: PropTypes.node,
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string || null,
}

export default Indicator
