import { createRequester } from './requester';

const createRedirectService = () =>{
  return createRequester('auth/redirect-to')
}

const redirectService = createRedirectService();

const sendRedirectTo = async (useCase, accountNumber, origin, customerSub, userToken, gsetting) => {
  const requester = redirectService
  return await requester.request({
    useCase,
    accountNumber,
    origin,
    customerSub,
    userToken,
    gsetting
  })
}

export {
  sendRedirectTo,
}