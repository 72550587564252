const GAME_CARD_ASPECT_RATIO = '8 / 11'

export const ASPECT_RATIOS = {
  BANNER: { xs: '9 / 4', sm: '192 / 35' },
  GAME_CARD: GAME_CARD_ASPECT_RATIO,
  GAME_INFO: { xs: GAME_CARD_ASPECT_RATIO, sm: '891 / 293' },
  LIVE_CASINO_CARD: { xs: '136 / 89', sm: '203 / 90' },
  PROMOTION_CARD: '360 / 227',
  PAYMENT_METHOD: '115 / 44',
}
