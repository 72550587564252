import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import cx from 'classnames'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'

import { countChildren, mapChildren } from '../../libs/utils'

import Icon from '../components/Icon'

import useStyles from './carousel.styles'
import { OPTIONS_DEFAULT, OPTIONS_BIG } from './constants'

type CarouselProps = {
  children: React.ReactNode;
  big?: boolean;
  hasBreakingContainer?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({ children, big, hasBreakingContainer = false}) => {
  const [controls, setControls] = useState({ prev: false, next: false })
  const sliderNode = useRef(null)
  const glide = useRef(null)
  const glideSlideRef = useRef(null)
  const childrenCount = countChildren(children)

  const updateControls = useCallback(() => {
    if (!glide.current) return

    setControls({
      prev: glide.current.index > 0,
      next:
        glide.current.index + glide.current.settings.perView < childrenCount,
    })
  }, [childrenCount])

  const handleResize = useCallback(() => {
    if (!glide.current) return

    if (childrenCount <= glide.current.settings.perView) {
      // NOTE if we are not at first slide, we need to set the index to 0
      // sadly, this makes the call to glide.disable() to not work until the
      // transition is over
      if (glide.current.index > 0) {
        glide.current.go('<<')
        setTimeout(
          () => glide.current.disable(),
          glide.current.settings.animationDuration,
        )
      } else glide.current.disable()
    } else {
      glide.current.enable()
    }
  }, [childrenCount])

  useEffect(() => {
    if (!sliderNode.current) return
    const options = big ? OPTIONS_BIG : OPTIONS_DEFAULT
    glide.current = new Glide(sliderNode.current, options)
    glide.current.on(['mount.after', 'resize'], handleResize)
    glide.current.on(['mount.after', 'move', 'resize'], updateControls)
    glide.current.mount()

    return () => glide.current.destroy()
  }, [childrenCount, big, handleResize, updateControls])

  const classes = useStyles(big)
  const sliderClasses = cx(classes.slider, 'glide')
  const trackClasses = cx(classes.track, 'glide__track')
  const slidesClasses = cx(classes.slides, 'glide__slides')
  const slideClasses = cx(classes.slide, 'glide__slide')
  const prevClasses = cx(
    classes.button,
    classes.buttonLeft,
    !controls.prev && 'hidden',
  )
  const nextClasses = cx(
    classes.button,
    classes.buttonRight,
    !controls.next && 'hidden',
  )

  const index = glide.current?.index ?? 0
  const perView = glide.current?.settings.perView ?? 0

  const prevPage = Math.max(0, index - perView)
  const nextPage = Math.min(
    // NOTE this guards against negative values for carousels with less than
    // a full page of items, even though it would not cause any harm because
    // arrows will be hidden and the carousel disabled when this happens
    Math.max(childrenCount - perView, 0),
    index + perView,
  )

  return (
    <div className={hasBreakingContainer ? classes.root : ''}>
      <div className={sliderClasses} ref={sliderNode}>
        <div className={trackClasses} data-glide-el="track">
          <ul className={slidesClasses}>
            {mapChildren(children, (child: React.ReactNode, index: number) => (
              <li key={index} className={slideClasses} ref={glideSlideRef}>
                {child}
              </li>
            ))}
          </ul>
        </div>
        <div className="glide__arrows" data-glide-el="controls">
          <button className={prevClasses} data-glide-dir={`=${prevPage}`}>
            <Icon name="chevron-left" />
          </button>
          <button className={nextClasses} data-glide-dir={`=${nextPage}`}>
            <Icon name="chevron-right" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Carousel
