import React, { useState, useCallback } from 'react'
import Cookies from 'js-cookie'

import { getTopLevelDomain } from '../libs/utils'

interface CookieConfig { 
  expires: Date;
  domain: string;
  sameSite: 'Lax' | 'Strict' | 'None';
  secure: boolean;
}
const COOKIE: { name: string, config: CookieConfig} = {
  name: 'age-verified',
  config: {
    expires: new Date(2147483647 * 1000),
    domain: getTopLevelDomain(),
    sameSite: 'None',
    secure: true,
  },
}
type UseVerifiedAgeCookieReturn = [() => boolean, () => boolean, () => void, () => void]

const useVerifiedAgeCookie = (): UseVerifiedAgeCookieReturn => {
  const [cookieValue, setCookieValue] = useState<string | undefined>(Cookies.get(COOKIE.name))

  const isVerified: () => boolean = useCallback(() => {
    return cookieValue !== undefined
  }, [cookieValue])

  const isRejected : () => boolean = useCallback(() => {
    return cookieValue === 'not-verified'
  }, [cookieValue])

  const accept: () => void = useCallback(() => {
    Cookies.set(COOKIE.name, 'verified', COOKIE.config)
    setCookieValue('verified')
  }, [])

  const reject: () => void = useCallback(() => {
    setCookieValue('not-verified')
  }, [])

  return [isVerified, isRejected, accept, reject]
}

export default useVerifiedAgeCookie
