import React from 'react'
import PropTypes from 'prop-types'

import Form from '../Form'
import Input from '../Input'
import Icon from '../Icon'
import useStyles from './searchBar.styles'
import { useIsBrand } from '../../../hooks/useIsBrand'

const SearchBar = ({ id, value, placeholder, hasError, onChange, onClear, onBlur }) => {
  const { isSB } = useIsBrand()
  const sBbrand = { brand: isSB() }
  const classes = useStyles(sBbrand)

  return (
    <div className={classes.searchBar}>
      <Form.Field>
        <Input
          type="text"
          id={id}
          value={value}
          placeholder={placeholder}
          hasError={hasError}
          hasRightIcon
          onChange={onChange}
          className={classes.searchInput}
          onBlur={onBlur}
        />
        {value ? (
          <span
            className={classes.close}
            onClick={onClear}
            style={{ cursor: 'pointer' }}
          >
            <Icon name="close" />
          </span>
        ) : (
          <span className={classes.iconWrapper}>
            <Icon name="search" />
          </span>
        )}
      </Form.Field>
    </div>
  )
}

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
}

export default SearchBar
