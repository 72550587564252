import Env from '@env';
import React from 'react'
import PropTypes from 'prop-types';
import 'universal-navigation';
import { useAuth } from '../../../authentication';
import { useEffect } from 'react';
import theme from '../../config';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { useEnvData } from '../../../hooks/useEnvData';
import { useLockScroll } from '../../../hooks/useLockScroll';
import { useEvent } from 'react-use';
import { useRedirectToExternal } from '../../../hooks/useRedirectToExternal';
import { isMobile } from '../../../libs/uaparser';
import { isNewNavigationFlagSynched } from '../../../context/kameleeon/experiments/newNavigation';
import { useTheme } from '@material-ui/core';

const MainMenu = ({ navigationItems }) => {
  const { brand } = useEnvData()
  const { logged, redirectToLogin } = useAuth()
  const { baseDomain } = useEnvData()
  const { lockScroll, unlockScroll } = useLockScroll()
  const { redirectToExternalUrl } = useRedirectToExternal()
  const theme = useTheme()
  // Flag to enable and disable feat
  const VIP_ENABLED = Env.get('VITE_USE_VIP_LEVELS')
  const navDesktop = navigationItems[0]
  let navMobileItems = navigationItems[1]
  let navMobileTop = navigationItems[navigationItems.length - 1]
  const navigation = document.querySelector('universal-navigation')
  const redirectToMyAccount = () => redirectToExternalUrl(`${baseDomain}/my-account`)
  let navDesktopActive = navDesktop.map((item) => {
    if(item.name === 'CASINO') {
      return { ...item, active: true }
    }
    return item
  })

  navMobileTop = navMobileTop.map((item) => {
    if(item.name === 'CASHIER' && !logged){
      return { ...item, to: `${baseDomain}/banking` }
    }
    return item
  })

  if (VIP_ENABLED !== "true") {
    navDesktopActive = navDesktopActive.filter(item => item.name !== 'VIP REWARDS');
    navMobileItems = navMobileItems.filter(item => item.name !== 'VIP REWARDS');
  }

  useEvent('menuToggle', (event) => {
    const isOpen = event.detail.isOpen;

    if(isOpen){
      lockScroll()
    } else {
      unlockScroll()
    }
  })

  useEvent('redirectToLogin', redirectToLogin)
  useEvent('redirectToMyAccount', redirectToMyAccount)
  const isNewNavEnabled = theme.custom?.hasNewNavigation
  useEffect(() => {
    const options = {
      brand: brand,
      config: {
        isLoggedIn: logged,
        marginTop: theme.custom.universalNavigation?.config?.marginTop,
        position: theme.custom.universalNavigation?.config?.position,
      },
      navigationMain: navMobileTop,
      navigationItems: navMobileItems,
      navigationItemsDesktop: navDesktopActive
    }

    if (navigation) {
      navigation.options = options
    }
  }, [logged, navMobileTop, navMobileItems, navDesktopActive, brand, navigation])

  if (theme.custom.universalNavigation?.active && !isNewNavEnabled || (isNewNavEnabled && !isMobile())) {
    return (
      <universal-navigation></universal-navigation>
    )
  } else {
    return (
      <>
        <MenuDesktop navigationItems={navDesktop}/>
        <MenuMobile navigationItems={navMobileItems} navigationTopItems={navMobileTop}/>
      </>
    )
  }
}

MainMenu.propTypes = {
  navigationItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array
  ]),
}

export default MainMenu;