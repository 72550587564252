import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './rating.styles'

const VOLATILITY_ICONS_COUNT = 3

const VolatilityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className="icon"
  >
    <path
      d="M14.02 8.335c-.174-.29-.483-.483-.831-.483h-2.204l1.604-4.91c.097-.29.04-.618-.135-.87-.193-.25-.464-.405-.773-.405H8.646c-.425 0-.792.27-.908.657L5.05 10.442c-.097.29-.058.619.135.87.194.251.464.406.793.406H8.8l-.464 7.21c0 .097.058.174.135.193h.058c.078 0 .136-.038.174-.096l5.374-9.762c.135-.29.116-.638-.058-.928z"
      transform="translate(-570 -3045) translate(518 2830) translate(0 36) translate(12 155) translate(40 24)"
    />
  </svg>
)

const Rating = props => {
  const classes = useStyles()
  return (
    <div className={classes.rating}>
      <h2 className={classes.ratingTitle}>{props.label}</h2>
      <div className={classes.ratingIndicatorGroup}>
        {new Array(VOLATILITY_ICONS_COUNT).fill(null).map((_, index) => (
          <span
            key={index}
            className={cx(
              classes.ratingIndicator,
              props.volatility > index ? classes.active : null,
            )}
          >
            <VolatilityIcon />
          </span>
        ))}
      </div>
    </div>
  )
}

Rating.propTypes = {
  label: PropTypes.string.isRequired,
  volatility: PropTypes.number.isRequired,
}

export default Rating
