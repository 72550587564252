const handler = {
    INITIALIZE: (state, action) => {
        const { show, chatEngine, isInitialize } = action.payload
        return {
            ...state,
            chatEngine,
            isInitialize,
            show
        }
    },
    SHOW: (state, action) => {
        const { show } = action.payload
        return {
            ...state,
            show
        }
    }
}

export const chatEngineReducer = (state, action) => handler[action?.type] ? handler[action.type](state,action) : state