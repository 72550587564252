import Env from '@env';
import { sendRedirectTo } from '../asyncData';
import { getToken } from './keycloak';
import keycloak from './keycloak'
import { getExternalSiteUrl } from '../config';

const EXTERNAL_URI = getExternalSiteUrl();
const CASHIER_REDIRECT_RESPONSE = 'cashier';
const EXTERNAL_CASHIER_URL = '/cashier/deposit';
const SAC_CASHIER_URL = '/cashier';

const redirectToCashier = () => {
  if (EXTERNAL_URI) {
    window.location.assign(`${EXTERNAL_URI}${EXTERNAL_CASHIER_URL}`);
  } else {
    window.location.assign(`${window.location.origin}${SAC_CASHIER_URL}`);
  }
}

const checkRedirection = (redirectTo) => {
  // TODO: analizar cuando retorna sportsbook
  if (redirectTo === CASHIER_REDIRECT_RESPONSE) {
    redirectToCashier();
  }
}

export const applyRedirect = async () => {
  const accountNumber = keycloak.tokenParsed?.preferred_username;

  try {
    const origin = window.location.origin;
    const response = await sendRedirectTo(
      'login',
      accountNumber,
      origin,
      keycloak?.idTokenParsed?.sub,
      getToken(),
      Env.get('VITE_GSETTING')
    );

    if (response && response.redirectTo) {
      checkRedirection(response.redirectTo);
    }
  } catch (redirectError) {
    // handle properly the error
    console.error({ redirectError })
  }
}