export const COOKIE_CONFIGS = [
  {
    CkActive: true,
    CkName: 'CK_homepageOut',
    CkPath: '/',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_homepageIn',
    CkPath: '/',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_slotsOut',
    CkPath: '/games/slots',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_slotsIn',
    CkPath: '/games/slots',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_tableGamesOut',
    CkPath: '/games/table-games',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_tableGamesIn',
    CkPath: '/games/table-games',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_blackjackOut',
    CkPath: '/games/blackjack',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_blackjackIn',
    CkPath: '/games/blackjack',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_liveCasinoIn',
    CkPath: '/games/live-casino',
    CkEqualPath: false,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_liveCasinoOut',
    CkPath: '/games/live-casino',
    CkEqualPath: false,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_videoPokerIn',
    CkPath: '/games/video-poker',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_videoPokerOut',
    CkPath: '/games/video-poker',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_specialtyGamesIn',
    CkPath: '/games/specialty-games',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_specialtyGamesOut',
    CkPath: '/games/specialty-games',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_promotionsIn',
    CkPath: '/promotions',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
  {
    CkActive: true,
    CkName: 'CK_promotionsOut',
    CkPath: '/promotions',
    CkEqualPath: true,
    CkAuthenticate: false,
  },
  {
    CkActive: true,
    CkName: 'CK_myAccountIn',
    CkPath: '/my-account',
    CkEqualPath: true,
    CkAuthenticate: true,
  },
]

export const COOKIE_EXPIRY_DAYS = 1

export const CLASSES = {
  LOGGED_IN: 'ck-logged-in',
  LOGGED_OUT: 'ck-logged-out',
}
