import React from 'react'
import { Link } from 'react-router-dom'

import PageContents from '../../ui/components/PageContents'
import Container from '../../ui/components/Container'
import { StaticHero, HeroSkeleton } from '../../ui/components/Hero'
import ContentSkeleton from '../../ui/components/ContentSkeleton'
import Section from '../../ui/components/Section'
import Markdown from '../../ui/components/Markdown'
import CardList from '../../ui/components/List/CardList'
import Button from '../../ui/components/Button'
import PayMethodsList from '../../ui/components/PayMethods/PayMethodsList'
import PayMethod from '../../ui/components/PayMethods/PayMethod'
import EmptyMessage from '../../ui/components/EmptyMessage'
import NoResultsImage from '../../assets/icons/no-results.svg'

import { useTranslations } from '../../i18n'

import { useBanking, getCmsImageUrl } from '../../asyncData'

const Banking = () => {
  const formatMessage = useTranslations()
  const { isLoading, error, data } = useBanking()

  if (error === 404 || error === 403) {
    return (
      <PageContents>
        <EmptyMessage
          image={<NoResultsImage />}
          title={formatMessage('generic:empty-message:title')}
          description={formatMessage('banking:empty-message')}
        >
          <Button as={Link} to="/">
            {formatMessage('action:go-homepage')}
          </Button>
        </EmptyMessage>
      </PageContents>
    )
  } else if (error) throw error

  return (
    <PageContents>
      {isLoading ? (
        <HeroSkeleton />
      ) : (
        <StaticHero
          imageMobile={getCmsImageUrl(data.banner.mobile)}
          imageDesktop={getCmsImageUrl(data.banner.desktop)}
          alt={formatMessage('noun:banking-methods')}
        />
      )}
      <Container maxWidth="xl">
        {isLoading ? (
          <ContentSkeleton />
        ) : (
          <>
            <Section mb="extrasmall">
              <Container align="left">
                <Markdown content={data.content} />
              </Container>
              {data.payment.length > 0 ? (
                <Container spacing="md">
                  <PayMethodsList
                    title={formatMessage('noun:deposit-options')}
                    size="medium"
                    action={
                      <Button
                        as={Link}
                        to="/cashier"
                        widthBehaviour="responsive"
                      >
                        {formatMessage('action:make-deposit')}
                      </Button>
                    }
                  >
                    {data.payment.map(method => (
                      <PayMethod
                        key={method.id}
                        name={method.name}
                        image={getCmsImageUrl(method.logo)}
                        min={method.min}
                        max={method.max}
                        content={method.description}
                      />
                    ))}
                  </PayMethodsList>
                </Container>
              ) : null}
            </Section>
            {data.payout.length > 0 ? (
              <Section mb="medium">
                <Container>
                  <h2>{formatMessage('noun:withdrawals')}</h2>
                  <PayMethodsList title={formatMessage('noun:payout-options')}>
                    {data.payout.map(method => (
                      <PayMethod
                        key={method.id}
                        name={method.name}
                        image={getCmsImageUrl(method.logo)}
                        min={method.min}
                        max={method.max}
                        content={method.description}
                      />
                    ))}
                  </PayMethodsList>
                </Container>
              </Section>
            ) : null}
            {data.safe_and_secure.cards.length > 0 ? (
              <Section>
                <Container>
                  <h3>{formatMessage('noun:safe-secure')}</h3>
                  <CardList list={data.safe_and_secure} />
                </Container>
              </Section>
            ) : null}
          </>
        )}
      </Container>
    </PageContents>
  )
}

export default Banking
