const palette = {
  primary800: '#ff8800',
  primary50: '#fff8e1',
  secondary900: '#3e49a9',
  secondary800: '#466aca',
  secondary700: '#4b7adc',
  secondary600: '#508cf0',
  secondary500: '#539cff',
  secondary100: '#c2e0ff',
  secondary50: '#e5f3ff',
  tertiary800: '#ff9500',
  tertiary700: '#ffac00',
  tertiary600: '#ffc500',
  tertiary500: '#ffdf00',
  tertiary100: '#fefbbf',
  quaternary800: '#3a0b4a',
  quaternary700: '#52005c',
  quaternary700_87: 'rgba(82, 0, 92, 0.87)',
  quaternary700_0: 'rgba(82, 0, 92, 0)',
  placeholder: '#870e95',
  placeholder20: '#780c84',
  placeholder80: '#780c84',
  // states
  breadCrumb: '#ffffff',
  // footer
  accent: '#FD9823',
  chevron: '#828282',
  footerBg: '#671f70',
  footerGradientPrimary: 'linear-gradient(180deg, #FFDF00 0%, #FF8800 100%)',
  footerGradientSecondary:
    'linear-gradient(to bottom, #FF8800 0%, #FF9500 100%)',
  footerSubTitles: '#E7E7E7',
  gray: '#363535',
  grayLight: '#979797',
  links: '#70BF45',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.7)',
    modal: {
      modalBackgroundColor: '#3a0b4a',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #539cff, #466aca)',
      backgroundColorHover: 'linear-gradient(to bottom, #3e49a9, #466aca)',
      backgroundColorActive: 'linear-gradient(to bottom, #e5f3ff, #c2e0ff)',
      textColorActive: '#466aca',
      textColorHover: '#FFF',
      textColor: '#FFF  ',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #ffdf00, #ff8800)',
      backgroundColorHover: 'linear-gradient(to bottom, #ff8800, #ff9500)',
      backgroundColorActive: 'linear-gradient(to bottom, #fff8e1, #fefbbf)',
      textColorActive: '#ff8800',
      textColorHover: '#3a0b4a',
      textColor: '#3a0b4a',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255,.87)',
      gameDescription: 'rgba(255,255,255, 1)',
      gameVolatilityTitleColor: 'rgba(255,255,255,.87)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#979797',
        backgroundColor: '#363535',
      },
      balance: {
        textColor: '#539cff',
      },
    },
  },
}

export default palette
