import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  detailSkeleton: {
    width: '100%',
  },
  heading1: {
    maxWidth: '68%',
    marginBottom: '0.75rem',
    [mq('sm')]: {
      maxWidth: 'min(70%, 480px)',
    },
  },
  heading2: {
    maxWidth: '40%',
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    [mq('sm')]: {
      maxWidth: 'min(65%, 240px)',
    },
  },
  paragraph: {
    maxWidth: '93%',
    marginBottom: '0.75rem',
    [mq('sm')]: {
      maxWidth: 'min(94%, 680px)',
    },
    '&:nth-of-type(odd)': {
      maxWidth: '80%',
      [mq('sm')]: {
        maxWidth: 'min(82%, 520px)',
      },
    },
  },
}))

export default useStyles
