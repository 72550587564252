// Bocato Services - OptIn endpoint
import { createRequester } from './requester';

const createOptinService = () => {
  return createRequester('marketing/opt-in');
}

const optionService = createOptinService();

const sendOptin = async (campaignId, brandId, customerId, customerSub, userToken) => {
  const requester = optionService
  return await requester.request({
      campaignId,
      brandId,
      customerId,
      customerSub,
      userToken,
    })
}

export {
  sendOptin,
}