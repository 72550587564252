import { useState, useEffect } from 'react'
import useStyles from './blackjackTag.styles'
import { gameCardUpdater  } from '../../../../../asyncData/bff'

const onTagMounted = (cardId, setCurSeats) => {
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.subscribeToId(
      setCurSeats,
      cardId,
      'seats',
    );
    }
}

const onTagUnmounted = (cardId, setCurSeats) => {
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.unsubscribeFromId(
      setCurSeats,
      cardId,
    );
  }
}

const SeatsTag = ({ seats, cardId, isLive }) => {
  const [curSeats, setCurSeats] = useState(seats);
  useEffect(() => {
    if (isLive) {
    onTagMounted(cardId, setCurSeats);
    return () => {
      onTagUnmounted(cardId, setCurSeats);
    }}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { availableSeats } = curSeats
  const classes = useStyles()

  return (
    <div className={classes.seatsCtn}>
      <div className={classes.seatsAmount}>{availableSeats}</div>
      <span className={classes.seatsLabel}>seats available</span>
    </div>
  )
}

export default SeatsTag
