import React, { useRef } from 'react'
import cx from 'classnames'
import { useClickOutside } from 'use-events'

import useStyles from './sideMenu.styles'
import NavLink from '../NavLink'
import Icon from '../Icon'
import { useAuth } from '../../../authentication'
import { isLiveCasinoEnabled } from '../../../asyncData/bff/config/envVars'

interface MenuItem {
  name: string;
  to: string;
  onClick?: () => void;
  icon: string;
  selected?: boolean;
  iconUrl?: string;
}

interface SideMenuProps {
  items: MenuItem[];
  isOpen?: boolean;
  onClose: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ items, isOpen = false, onClose }) => {
  const { logout } = useAuth()
  const ref = useRef(null)

  useClickOutside([ref], () => {
    if (isOpen) {
      onClose()
    }
  })

  const classes = useStyles()
  const sideMenuClasses = cx(classes.sideMenu, isOpen && classes.isOpen)

  return (
    <aside id="side-menu-container" className={sideMenuClasses} ref={ref}>
      <div className={classes.closeArea}>
        <button id="close-menu-button" className={classes.closeButton} onClick={onClose}>
          <Icon name="close" />
        </button>
      </div>
      <div className={classes.scrollableArea}>
        <ul id="nav-list-container" className={classes.navList}>
          {items.map(menuItem => (
            <li className={classes.navListItem} key={menuItem.name}>
              {menuItem.name === 'Log Out' && (
                <NavLink
                  onClick={logout}
                  to={menuItem.to}
                  className={classes.navListLink}
                >
                  <span className={classes.navListTopDivider}></span>
                  <Icon name={menuItem.icon} />
                  <span className={classes.navListText}>{menuItem.name}</span>
                  <span className={classes.navListBottomDivider}></span>
                </NavLink>
              )}
              {menuItem.name !== 'Log Out' && (
                <NavLink
                  onClick={onClose}
                  to={menuItem.to}
                  className={(arg) => cx(
                    classes.navListLink,
                  (menuItem.selected || arg?.isActive) && classes.navListLinkSelected,
                  )}
                >
                  <span className={classes.navListTopDivider}></span>
                  <Icon name={menuItem.icon} url={menuItem?.iconUrl} />
                  <span className={classes.navListText}>{menuItem.name}</span>
                  <span className={classes.navListBottomDivider}></span>
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

export default SideMenu
