import { makeStyles } from '../../../components/materialUiWrapper'
import { mq } from '../../../config/utils'

const useStyles = (headerPosition, backdropOpen) => makeStyles(theme => ({
  balanceCtn: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    fontSize: '0.75rem',
    fontWeight: '600',
    color: theme.custom.palette.white,
    cursor: 'pointer',
    padding: '0',
    [mq('sm')]: {
      padding: '12px 16px',
    },
    '& span': {
      marginRight: '6px'
    },

  },
  root: {
    '& .MuiPaper-root': {
      padding: '0',
      margin: '0',
      top: '0 !important',
      borderRadius: '0 0 10px 10px',
      minWidth: 'calc(100% + 6px)',
      marginLeft: '17px',
      [mq('sm')]: {
        minWidth: '320px',
        marginLeft: '0px',
      },
      [mq('md')]: {
        top: `-${headerPosition}px !important`,
      },
    },
    '&.MuiPopover-root': {
      inset: `${headerPosition}px 0 0 0 !important`,
      [mq('md')]: {
        inset: `${headerPosition + 60}px 0 0 0 !important`,
      },
      '& :first-child': {
        inset: `${headerPosition}px 0 0 0 !important`,
        backgroundColor: `${theme.custom.palette.backdrop} !important`,
        [mq('md')]: {
          inset: `${headerPosition + 60}px 0 0 0 !important`,
        },
      },
    }
  },
  popoverCtn: {
    height: '350px',
    width: '100%'
  },
  iconUser: {
    position: 'relative',
    '& svg': {
      height: '25px',
      width: '25px',
      
      '& circle': {
        fill: theme.custom.brand.balanceWidget?.colors.balanceCloseState,
      },
      '& g': {
        fill: theme.custom.brand.balanceWidget?.colors.balanceState,
      },
    },
    '&.has-notifications': {
      '&::before':{
        backgroundColor: theme.custom.palette.quaternary800,
        borderRadius: '50%',
        bottom: '1px',
        content: '""',
        display: 'block',
        height: '8px',
        position: 'absolute',
        right: '-1px',
        width: '8px'
      }
    }
  },
  closeIcon: {
    '& svg': {
      height: '25px',
      width: '25px',
      '& circle': {
        fill: theme.custom.brand.balanceWidget?.colors.balanceState,
      },
      '& g': {
        fill: theme.custom.brand.balanceWidget?.colors.balanceCloseState,
      },
    },
  },
  ubcBackdrop: {
    padding: '0',
    margin: '0',
    minWidth: '100vw',
    height: '100vh',
    position: 'absolute',
    top: '3.75rem',
    left: 0,
    display: backdropOpen ? 'block' : 'none',
    backgroundColor: `${theme.custom.palette.backdrop}`,
  },
}))

export default useStyles