import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import MobileOrDesktopImg from '../../../ui/components/Img/MobileOrDesktopImg'
import { HeroPlaceholder, HeroSkeleton } from '../../../ui/components/Hero'
import CustomPropTypes from '../../../customPropTypes'

import useStyles from './dynamicHero.styles'

const Slide = props => {
  const { onClick, ...rest } = props
  const isClickFunction = typeof onClick === 'function'

  const classes = useStyles()

  if (isClickFunction) {
    return (
      <div onClick={onClick} className={classes.clickableContainer}>
        <MobileOrDesktopImg
          placeholder={<HeroSkeleton />}
          fallback={<HeroPlaceholder />}
          {...rest}
        />
      </div>
    )
  }

  return (
    <Link to={onClick}>
      <MobileOrDesktopImg
        placeholder={<HeroSkeleton />}
        fallback={<HeroPlaceholder />}
        {...rest}
      />
    </Link>
  )
}

Slide.propTypes = {
  imageMobile: PropTypes.node.isRequired,
  imageDesktop: PropTypes.node.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: CustomPropTypes.link,
}

export default Slide
