import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  account: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.625rem 0',
    ...texts.stepDown1,
    color: theme.custom.account?.color || theme.custom.palette.neutral1,

    // TODO: Review. Handle border-left depending on the context placed
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: '0.0625rem',
      height: '0.75rem',
      marginRight: '1.5rem',
      backgroundColor: theme.custom.account?.color || theme.custom.palette.neutral1,
    },

    [mq('sm')]: {
      marginLeft: '0.75rem',
    },

    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.tertiary800,
      },
    },

    '& span': {
      verticalAlign: 'middle',
    },
    '& .icon': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '0.5rem',
      fill: 'currentColor',
    },
    [hoverDevice()]: {
      '&.active': {
        borderColor: theme.custom.account?.color || theme.custom.palette.neutral1,
        color: theme.custom.account?.color || theme.custom.palette.neutral1,
        cursor: 'default',
        position: 'relative',

        '&::after': {
          content: '""',
          display: 'block',
          width: 'calc(100% - 1.5rem)',
          height: '0.125rem',
          marginRight: '1.5rem',
          position: 'absolute',
          bottom: 0,
          left: '1.5rem',
          backgroundColor:  theme.custom.account?.color || theme.custom.palette.neutral1,
        },

        '&:hover': {
          color: theme.custom.account?.color || theme.custom.palette.neutral1,
        },
      },
    },
  },
}))

export default useStyles
