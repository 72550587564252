import { createRequester } from './requester';
import { deviceBrandId } from '../../ui/config/deviceUtils';

const createLiveCasinoLaunchGameService = () => {
  const requester = createRequester('game-launch/live-casino')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const createLiveLaunchGameService = () => {
  const requester = createRequester('game-launch/get-url')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const createLaunchService = () => {
  const requester = createRequester('game-launch/launch')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const liveCasinoLaunchGameService = createLiveCasinoLaunchGameService();
const liveLaunchGameService = createLiveLaunchGameService();
const launchService = createLaunchService();

const getGame = async (cardId, userId, userToken, customerSub) => {
  const requester = liveCasinoLaunchGameService
  return await requester.request({
    id: cardId,
    language: 'EN',
    userId,
    userToken,
    customerSub,
  })
}

const getLiveGameUrl = async (parameters, bonusAccountId, userId, userToken, customerSub) => {
  const requester = liveLaunchGameService
  return await requester.request({
    id: parameters.gameID,
    extGameId: parameters.extGameID,
    providerId: parameters.providerID,
    returnUrl: parameters.returnUrl,
    parentCategoryName: parameters.parentCategoryName,
    categoryName: parameters.categoryName,
    bonusAccountId,
    userId,
    userToken,
    customerSub,
    language: 'EN'
  })
}

const getGameUrl = async (parameters) => {
  const requester = launchService
  return await requester.request({
    ...parameters,
    language: 'EN'
  })
}

export {
  getGame,
  getLiveGameUrl,
  getGameUrl
}