import { useCallback } from 'react'
import { useIntl } from 'react-intl'

const defaultOptions = { style: 'currency', currency: 'USD' }

const useCurrency = () => {
  const intl = useIntl()

  const formatCurrency = useCallback(
    (number, opts) => intl.formatNumber(number, { ...defaultOptions, ...opts }),
    [intl],
  )

  return formatCurrency
}

export default useCurrency
