import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  containerRoot: {
    maxWidth: '100%',
  },
  containerRootTopRight: {
    top: '3.875rem',
    right: 0,

    [mq('sm')]: {
      top: '4.875rem',
    },
  },
}))

export default useStyles
