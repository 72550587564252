import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    separator: {
        margin:  '0 1.25rem',
        border: '0.0625rem solid rgba(151,151,151,.275164)',
        height: '1.6875rem'
    }
}));

export default useStyles;