const palette = {
  primary800: '#D0A03A',
  primary50: '#FFF6DA',
  secondary900: '#395FB1',
  secondary800: '#3A6AD0',
  secondary700: '#426FD1',
  secondary600: '#577FD6',
  secondary500: '#6F92DC',
  secondary100: '#D3DEF4',
  secondary50: '#EEF2FB',
  tertiary900: '#B69E3E',
  tertiary800: '#B9A43C',
  tertiary700: '#BFAE3B',
  tertiary600: '#C6BA39',
  tertiary500: '#D0C63A',
  tertiary100: '#EFEBBA',
  quaternary800: '#050913',
  quaternary700: '#080F21',
  placeholder: '#25396A',
  placeholder20: '#2A4279',
  // states
  breadCrumb: '#ffffff',
  // footer
  accent: '#FD9823',
  chevron: '#828282',
  footerBg: '#262c3c',
  footerGradientPrimary: 'linear-gradient(180deg, #D0C63A 0%, #D0A03A 100%)',
  footerGradientSecondary:
    'linear-gradient(to bottom, #D0A03A 0%, #B9A43C 100%)',
  footerSubTitles: '#E7E7E7',
  gray: '#363535',
  grayLight: '#979797',
  links: '#70BF45',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.7)',
    modal: {
      modalBackgroundColor: '#080f21',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #6f92dc, #3a6ad0)',
      backgroundColorHover: 'linear-gradient(to bottom, #395fb1, #426fd1)',
      backgroundColorActive: 'linear-gradient(to bottom, #eef2fb, #d3def4)',
      textColorActive: '#3a6ad0',
      textColorHover: '#fff',
      textColor: '#FFF  ',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #D0C63A, #D0A03A)',
      backgroundColorHover: 'linear-gradient(to bottom, #D0A03A, #B9A43C)',
      backgroundColorActive: 'linear-gradient(to bottom, #FFF6DA, #EFEBBA)',
      textColorActive: '#fff',
      textColorHover: '#fff',
      textColor: '#fff',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255,.87)',
      gameDescription: 'rgba(255,255,255, 1)',
      gameVolatilityTitleColor: 'rgba(255,255,255,.87)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#979797',
        backgroundColor: '#363535',
      },
      balance: {
        textColor: '#6F92DC',
      },
    },
  },
}

export default palette
