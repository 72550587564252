import { useEffect } from 'react'

const useResizeHandler = handleResize => {
  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])
}

export default useResizeHandler
