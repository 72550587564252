import { makeStyles } from '../../ui/components/materialUiWrapper'

const useStyles = makeStyles(theme => ({
  linearProgress: {
    background: theme.custom?.palette?.placeholder ?? 'rgba(255,255,255,05)',
    '& .MuiLinearProgress-bar':{
      backgroundColor: theme.custom?.palette?.quaternary700
    }
  }
}))

export default useStyles
