import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const useOpenGame = () => {
  const history = useNavigate()

  
  const location = useLocation()

  const handleOpenGame = useCallback(
    (gameId, extGameId, origin,ProviderID, bonus) =>
    { ProviderID ? history(`${location.pathname}?gameId=${gameId}&extGameId=${extGameId}&origin=${origin}&ProviderID=${ProviderID}`, { state: { bonus }, replace: true }) :  history(`${location.pathname}?gameId=${gameId}&extGameId=${extGameId}&origin=${origin}`, { state: { bonus }, replace: true })},
    [history, location.pathname],
  )

  return handleOpenGame
}

export default useOpenGame
