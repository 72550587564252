import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { isMobileOrTablet } from '../../libs/uaparser'
import { shouldOpenMobileGameInIframe } from '../../config'

const PlayContext = React.createContext()

export const PlayContextProvider = ({ children }) => {
  const openMobileGameInIframe = shouldOpenMobileGameInIframe()
  const openGameInIframe = !isMobileOrTablet() || openMobileGameInIframe

  const [gameData, setGameData] = useState(null)

  return (
    <PlayContext.Provider
      value={{
        gameData,
        setGameData,
        openGameInIframe,
      }}
    >
      {children}
    </PlayContext.Provider>
  )
}

PlayContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const usePlayContext = () => {
  const context = useContext(PlayContext)

  if (context === undefined) {
    throw new Error('usePlayContext must be used within PlayContextProvider')
  }

  return context
}
