import React from 'react'
import { createContext, useEffect, useReducer, useState } from 'react'
import { CustomerChatEngine } from '../../vendor/widgets/ChatEngine'
import { chatEngineReducer } from './reducer'
import PropTypes from 'prop-types'
import { useAuth } from '../../authentication'
import { useProfile } from '../../asyncData'
import { isTierVIP } from '../../business'

const initialState = {
  chatEngine: null,
  isInitialize: false,
  show: false,
}

export const ChatEngineContext = createContext()

export const ChatEngineProvider = ({ provider, children, show = false }) => {
  const [state, dispatch] = useReducer(chatEngineReducer, initialState)
  const { logged, username, initialized: isAuthInitialized } = useAuth()
  const { data, isLoading: isLoadingUserProfile } = useProfile(username, {
    enabled: logged,
  })
  useEffect(() => {
    const initialize = async () => {
      try {
        const chatEngineInstance = CustomerChatEngine.getInstance(provider)
        await chatEngineInstance.start({
          show,
          isInitialize: state?.isInitialize,
        })
        dispatch({
          type: 'INITIALIZE',
          payload: {
            show,
            chatEngine: chatEngineInstance,
            isInitialize: true,
          },
        })
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            show: false,
            chatEngine: null,
            isInitialize: false,
          },
        })
      }
    }
    initialize()
  }, [provider, show])

  useEffect(() => {
      isAuthInitialized &&
      !isLoadingUserProfile &&
      state.chatEngine?.updateVariables({
        data: {
          ...data,
          isVIP: isTierVIP(data?.LiveChatInformation?.SegmentMax),
          logged,
        },
        show,
      })
  }, [
    logged,
    state?.chatEngine,
    data?.LiveChatInformation?.SegmentMax,
    data,
    isAuthInitialized,
    isLoadingUserProfile,
  ])

  useEffect(() => {
    if (state?.show) return state.chatEngine?.show()
    state.chatEngine?.hide()
  }, [state?.show, state?.chatEngine])

  const showWidget = show =>
    dispatch({
      type: 'SHOW',
      payload: {
        show,
      },
    })

  return (
    <ChatEngineContext.Provider value={{ ...state, showWidget }}>
      {children}
    </ChatEngineContext.Provider>
  )
}

ChatEngineProvider.propTypes = {
  children: PropTypes.node,
  provider: PropTypes.any,
  show: PropTypes.bool,
}
