import Env from '@env';
import base from './base.json'
import messagesSuperSlots from './ssnasite.json'
import messagesWildCasino from './wcnasite.json'
import messagesGamingCity from './gcsite.json'
import messagesSportBetting from './sbnasite.json'
import messagesHighRoller from './hrnasite.json'
import messagesBetOnline from './bolnasite.json'
import messagesQueenBeeCasino from './qbcnasite.json'
import messagesLowvig from './lvnasite.json'

const messagesForBrand = brand => {
  let messages
  switch (brand) {
    case 'sbnasite':
      messages = messagesSportBetting
      break
    case 'lvnasite':
      messages = messagesLowvig
      break
    case 'bolnasite':
      messages = messagesBetOnline
      break
    case 'ssnasite':
      messages = messagesSuperSlots
      break
    case 'gcsite':
      messages = messagesGamingCity
      break
    case 'hrnasite':
      messages = messagesHighRoller
      break
    case 'qbcnasite':
      messages = messagesQueenBeeCasino
      break
    case 'wcnasite':
    default:
      messages = messagesWildCasino
      break
  }
  return Object.assign(base, messages)
}

export default messagesForBrand(Env.get('VITE_GSETTING'))
