import Env from '@env';
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

const Head = props => {
  const googleSiteVerificationKey =
    Env.get('VITE_GOOGLE_SITE_VERIFICATION')
  const { title, description } = props
  const theme = useTheme()
  
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      <link
        rel="canonical"
        href={`${Env.get('VITE_SITE_URL')}${window.location.pathname}`}
      />
      <meta name="theme-color" content={theme.custom.brand.themeColor} />
      <meta
        name="msapplication-TileColor"
        content={theme.custom.brand.themeColor}
      />
      {googleSiteVerificationKey ? (
        <meta
          name="google-site-verification"
          content={googleSiteVerificationKey}
        />
      ) : null}
    </Helmet>
  )
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Head
