import PropTypes from 'prop-types'
import cx from 'classnames'
import Modal from '@material-ui/core/Modal'

import { isMobileOrTablet } from '../../../libs/uaparser'
import Icon from '../../../ui/components/Icon'

import useModalStyles from './modalDialog.styles'
import { useTheme } from '@material-ui/core'
import { useEffect } from 'react'
import { useLockScroll } from '../../../hooks/useLockScroll'
import useOrientationChange from '../../../hooks/useOrientationChange'
import { useIsIOS } from '../../../hooks/useIsIOS'

const ModalDialog = props => {
  const { executedFrom } = props;
  const theme = useTheme()
  const modalClasses = useModalStyles()
  const paperClasses = cx(modalClasses.paper, props.className)
  const headerClasses = cx(
    modalClasses.header,
    isMobileOrTablet() ? modalClasses.headerMobile : modalClasses.headerDesktop,
  )
  const {lockScroll, unlockScroll} = useLockScroll()
  const { isLandscape } = useOrientationChange()
  const {isIOS} = useIsIOS()

  useEffect(() => {
    if(props.open) {
      lockScroll()
    }
    if(isIOS && isLandscape) {
      unlockScroll()
    }
    return () => {
      unlockScroll()
    }
  }, [props.open, lockScroll, unlockScroll, isLandscape, isIOS])

  return (
    <Modal
      open={props.open}
      className={modalClasses.root}
      onClose={props.onClose}
      disableScrollLock
    >
      <div className={paperClasses}>
       {!theme.custom.universalPrelaunchModal || executedFrom !== 'infoModal' ?  
       <div className={headerClasses}>
          <div className={modalClasses.titleContainer}>
            <h1 className={modalClasses.title}>{props.title}</h1>
            {props.actionLeft}
          </div>
          <div>
            {props.actionRight}
            <button
              className={modalClasses.closeButton}
              onClick={props.onClose}
            >
              <Icon name="close" />
            </button>
          </div>
        </div>: ''}
        {props.children}
      </div>
    </Modal>
  )
}

ModalDialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  actionLeft: PropTypes.node,
  executedFrom: PropTypes.string,
  children: PropTypes.node.isRequired,
  actionRight: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default ModalDialog
