import Env from '@env';
export interface IUniversalBalanceWebComponent extends Element {
  authenticated: boolean
  options: string
  level: string
  siteUrl: string
  usextremepush: boolean
}

export const getUniversalBalanceElement = () =>
  document.querySelector('universal-balance') as IUniversalBalanceWebComponent

export const createCashierFormWithDraw = () => {
  const { host, protocol } = window.location
  
  const urllastvisited = `${protocol}//${host}`
  const action = Env.get('VITE_UBC_WITHDRAW_URL')
  const formHTML = `
    <form id="cashier-form" name="myFormPost" action="${action}" method="get" target="">
      <div id="container">
        <input type="hidden" id="ID" name="ID" value="null">
        <input type="hidden" name="bp" value="">
        <input type="hidden" name="tid" value="0">
        <input type="hidden" name="pt" value="">
        <input type="hidden" id="ActionUrl" name="ActionUrl" value="">
        <input type="hidden" name="RedirectURL" value="">
        <input type="hidden" name="NewArchFlag" value="true">
        <input type="hidden" name="iframe" value="false">
        <input type="hidden" name="urllastvisited" value="${urllastvisited}">
        <input type="hidden" name="Action" value="PAYOUT">
      </div>
    </form>
  `

  return formHTML
}

export const sendCashierForm = () => {
  const form = document.getElementById('cashier-form') as HTMLFormElement | null
  if (form) form.submit()
}

export const isCashierFormWithDrawCreated = document.getElementById(
  'cashier-form',
)
