import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  formField: {
    position: 'relative',
  },
  label: {
    display: 'block',
  },
  helperText: {
    ...texts.helperText,
    display: 'block',
    color: theme.custom.palette.neutral1,
    margin: '0.25rem 1.125rem',
    opacity: 0.6,
  },
  formFieldset: {
    padding: 0,
    border: 0,
    margin: '0 0 24px 0',

    [mq('md')]: {
      marginBottom: 32,
    },
  },
  formLegend: {
    marginBottom: 12,
    fontSize: theme.custom.fontSize.sm,
    fontWeight: theme.custom.fontWeight.extrabold,
    color: theme.custom.form?.formLegendColor || theme.custom.palette.neutral1_87,
  },
  formInputWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(17rem, 1fr))',
    gap: 12,

    [mq('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: 24,
    },
  },
  formSelectWrapper: {
    [mq('sm')]: {
      maxWidth: '16.5rem',
    },
  },
  formError: {
    padding: '1rem 1.25rem 1.125rem 3rem',
    border: `2px solid ${theme.custom.palette.errorLight}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.custom.fontSize.sm,
    color: theme.custom.palette.errorLight,
    position: 'relative',

    '& > span': {
      content: '""',
      display: 'block',
      width: '1.25rem',
      height: '1.25rem',
      fontSize: theme.custom.fontSize.xl,
      position: 'absolute',
      top: '1rem',
      left: '1rem',

      '& .icon': {
        display: 'block',
        fill: 'currentColor',
      },
    },
  },
  fixed: {
    marginBottom: '1.5rem',

    [mq('sm')]: {
      maxWidth: '34.5rem',
      marginBottom: '2rem',
    },
    [mq('md')]: {
      maxWidth: '42.5rem',
    },
  },
  fluid: {},
  errorFav: {
    margin: '1.25rem 0.75rem 0.5rem',
  },
  errorUrl: {
    margin: '0.5rem 0.75rem 0',
  },
  formErrorTitle: {
    marginBottom: '0.375rem',
    fontWeight: theme.custom.fontWeight.bold,
  },
}))

export default useStyles
