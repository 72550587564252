import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '1.25rem',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.custom.shadows.output,
    background: theme.custom.card?.background || theme.custom.palette.neutral1_5,
  },
  cardIcon: {
    display: 'block',
    marginBottom: '1.25rem',
    color: theme.custom.card?.cardIcon?.color || theme.custom.palette.neutral1_87,
  },
  cardTitle: {
    marginBottom: '0.25rem',
    ...texts.baseline,
    fontWeight: theme.custom.fontWeight.extrabold,
    color: theme.custom.card?.cardTitle?.color || theme.custom.palette.neutral1_87,
  },
  cardDescription: {
    '& .markdownContent': {
      '& p': {
        marginTop: 0,
        marginBottom: 0,
        ...texts.stepDown1,
        color: theme.custom.card?.cardDescription?.color || theme.custom.palette.neutral1_60,
      },
    },
  },
}))

export default useStyles
