const palette = {
  primary800: '#028247',
  primary50: '#e6f6ec',
  secondary900: '#e15619',
  secondary800: '#eb6e1c',
  secondary700: '#f27e1e',
  secondary600: '#f88d20',
  secondary500: '#fd9923',
  secondary100: '#fee0b5',
  secondary50: '#fff3e1',
  tertiary800: '#3a872a',
  tertiary700: '#4e9b33',
  tertiary750: '#419F46',
  tertiary600: '#61af3d',
  tertiary500: '#70bf45',
  tertiary100: '#d4ecc7',
  quaternary800: '#121212',
  quaternary700: '#121212',
  quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  quaternary700_0: 'rgba(18, 18, 18, 0)',
  placeholder: '#333333',
  placeholder20: '#3d3d3d',
  placeholder80: '#d6d6d6',
  gray: '#363535',
  grayLight: '#979797',
  grayLightSecondary: '#90918D',
  links: '#70BF45',
  white: '#fff',
  accent: '#FD9823',
  chevron: '#828282',
  footerBg: '#2D2E2C',
  footerSubTitles: '#E7E7E7',
  footerGradientPrimary: 'linear-gradient(180deg, #FD9823 0%, #EC701D 100%)',
  footerGradientSecondary: 'linear-gradient(to bottom, #e15619, #f27e1e)',
  // states
  breadCrumb: '#ffffff',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.7)',
    modal: {
      modalBackgroundColor: '#121212',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #fd9923, #eb6e1c)',
      backgroundColorHover: 'linear-gradient(to bottom, #e15619, #f27e1e)',
      backgroundColorActive: 'linear-gradient(to bottom, #fff3e1, #fee0b5)',
      textColorActive: '#eb6e1c',
      textColorHover: '#FFF',
      textColor: '#FFF  ',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #70bf45, #028247)',
      backgroundColorHover: 'linear-gradient(to bottom, #028247, #3a872a)',
      backgroundColorActive: 'linear-gradient(to bottom, #e6f6ec, #d4ecc7)',
      textColorActive: '#028247',
      textColorHover: '#fff',
      textColor: '#fff',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255,.87)',
      gameDescription: 'rgba(255,255,255, 1)',
      gameVolatilityTitleColor: 'rgba(255,255,255,.87)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#979797',
        backgroundColor: '#363535',
      },
      balance: {
        textColor: '#fd9923',
      },
    },
  },
}

export default palette
