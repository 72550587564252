import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import CustomPropTypes from '../../../customPropTypes'
import { openLiveChat } from '../../../liveChat'

const LIVE_CHAT_LINK = '/live-chat'

const isExternalUrl = url =>
  typeof url === 'string' &&
  new URL(url, window.location.href).host !== window.location.host

/**
 * Wrapper around react-router's NavLink component to work with external URLs
 * and custom paths (configured on CMS) which translate to concrete actions
 * NOTE children is passed down explicitly to the anchor element to prevent
 * eslint rule jsx-a11y/anchor-has-content from warning
 */
const MaybeExternalNavLink = props => {
  const { children, to, activeclassname, id, className, ...rest } = props
  const isExternal = isExternalUrl(to)

  if (isExternal) {
    const classes = typeof className === 'string' ? className : className()
    return (
      <a href={to} id={id} target="_blank" rel="noreferrer" className={classes} {...rest}>
        {children}
      </a>
    )
  }

  // TODO: Define correct types for override.
  const override: {
    onClick?: (param: any) => void;
    to?: string;
  } = {}
  if (to === LIVE_CHAT_LINK) {
    override.onClick = ev => {
      ev.preventDefault()
      openLiveChat()
    }
    override.to = '#'
  }

  return <NavLink {...props} {...override} />
}

MaybeExternalNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: CustomPropTypes.link.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]) 
}

export default MaybeExternalNavLink
