import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    active: {
        color: theme.custom.palette.secondary800
    },
    link: {
        fontSize: '1.063rem',
        lineHeight: '1.25rem'
    }
}));

export default useStyles;