import Env from '@env';
import availableLocales from './locales'
import translations from './translations'

const DEFAULT_LOCALE =
  Env.get('VITE_DEFAULT_LOCALE') || availableLocales[0].locale

const findMatchingLocale = (language = undefined) => {
  const matchingLocale = availableLocales.find(({ match }) =>
    match.test(
      language
        ? language
        : typeof navigator !== 'undefined' && navigator
        ? navigator.language
        : DEFAULT_LOCALE,
    ),
  )

  return matchingLocale ? matchingLocale.locale : DEFAULT_LOCALE
}

const setupI18n = (language = undefined) => {
  const locale = findMatchingLocale(language)

  const messages = translations[locale] || translations[DEFAULT_LOCALE]
  
  if (!translations[locale]) {
    console.warn(`Missing translations for locale ${locale}`)
  }

  return {
    messages,
    locale,
  }
}

export default setupI18n
