import merge from 'deepmerge'
import baseTheme from '../baseTheme'
import brandPalette from './palette'
import typography from './typography'
import noResultsSvg from '../../../assets/icons/no-results-alt.svg?url'
import { getExternalSiteUrl } from '../../../config'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const betonlinePartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    typography,
    brand: {
      namespace: 'bolnasite',
      name: 'Betonline',
      website: 'betonline.ag',
      email: 'cssupport@betonline.ag',
      help: 'https://help.betonline.ag/',
      themeColor: palette.primaryBlack,
      bonusInfoUrl: 'https://help.betonline.ag/bonus-tutorial-and-faq/',
      balanceWidget: {
        name: '', //take the default name for the balance widget the default must be ''
        colors: {
          balanceState: palette.primaryBlack,
          balanceCloseState: palette.white,
        },
        freebet: true,
      },
    },
    zIndex: {
      appBar: 1301,
      needHelpBackdrop: 1302,
    },
    fontFamily: {
      primaryFont: 'Arimo',
      secondaryFont: 'Lato',
    },
    fontSize: {
      xl: typography.fontSize?.xl,
      xxl: typography.fontSize?.xxl,
    },
    fontWeight: {
      normal: typography.fontWeight.regular,
      bold: typography.fontWeight.bold,
    },
    buttons: {
      primaryFrontColor: baseTheme.palette.neutral1,
      secondaryFrontColor: baseTheme.palette.neutral1,
      secondaryFrontColorActive: baseTheme.palette.neutral1,
      iconColorDisabled: baseTheme.palette.neutral1_16,
      primarySolidColor: baseTheme.palette.neutral1,
      primarySolidBg: palette.gray800,
      secondarySolidColor: baseTheme.palette.neutral1,
      secondarySolidBg: palette.green,
      ghostSolidBg: palette.tertiary500,
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: palette.tertiary500,
      hover: {
        primarySolidBg: palette.gray700,
        ghostSolidBg: palette.tertiary400,
        // @ts-ignore TODO: neutral1 does not exist on baseTheme, check for the correct attribute
        ghostSolidColor: baseTheme.neutral1,
      },
    },
    mainPageContentSpec: {
      paddingTop: '0rem',
      paddingBottom: '4.5rem',
    },
    gradients: {
      // @ts-ignore TODO: btnPrimary does not exist on baseTheme, check for the correct attribute
      flatGradientYellow: `linear-gradient(to bottom, ${palette.btnPrimary}, ${palette.btnPrimary})`,
      // @ts-ignore TODO: btnPrimaryHover does not exist on baseTheme, check for the correct attribute
      flatGradientYellowHover: `linear-gradient(to bottom, ${palette.btnPrimaryHover}, ${palette.btnPrimaryHover})`,
      primaryGradient: `linear-gradient(to bottom, ${palette.primary}, ${palette.primary})`,
      primaryGradientDark: `linear-gradient(to bottom, ${palette.primary700}, ${palette.primary700})`,
      secondaryGradient: `linear-gradient(to bottom, ${palette.green}, ${palette.green})`,
      secondaryGradientDark: `linear-gradient(to bottom, ${palette.green300}, ${palette.green300})`,
      secondaryGradientLight: `linear-gradient(to bottom, ${palette.green700}, ${palette.green700})`,
    },
    tags: {
      tagBgNew: '#028247',
      tagBgExclusive: palette.placeholder,
    },
    errorPage: {
      color: palette.primaryBlack,
      weight: 700,
      goToHomeButtonVariant: 'primary',
    },
    loader: {
      color: palette.primaryBlack,
      fontWeightSemiBold: typography.fontWeight.bold,
    },
    bonusTable: {
      headerSize: 'xl',
      headColor: baseTheme.palette.neutral1_87,
      headRadius: '.25rem .25rem 0 0',
      headBgColor: palette.gray,
      tableVariant: 'light',
      rowBg: palette.gray100,
      altRowBg: palette.quaternary700,
      action: 'secondary',
      fontWeight: typography.fontWeight.regular,
      detailsWrapperBg: palette.quaternary700,
      routePath: '/casino-bonus',
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: palette.primary,
      variant: 'minimal',
      textColor: palette.primaryBlack,
    },
    pageContentBg: {
      light: baseTheme.palette.neutral1,
      // @ts-ignore TODO: primaryDark does not exist on baseTheme, check for the correct attribute
      textColor: palette.primaryDark,
    },
    pageHeading: {
      textTransform: 'title',
      color: palette.primaryBlack,
      fontWeight: typography.fontWeight.bold,
      bgColor: 'transparent',
    },
    selectInput: {
      border: `1px solid ${palette.gray700}`,
      // @ts-ignore TODO: gray700 does not exist on baseTheme, check for the correct attribute
      borderColorHover: baseTheme.palette.gray700,
      iconColor: baseTheme.palette.neutral3,
      color: baseTheme.palette.neutral3,
      bgColor: baseTheme.palette.neutral1,
    },
    shape: {
      borderRadius: '.15rem',
    },
    appBar: {
      background: palette.primaryBlack,
      logoWidth: '120px',
      logoWidthMd: '178px',
    },
    blackjack: {
      iconColor: baseTheme.palette.neutral1,
      iconColorHover: baseTheme.palette.neutral1,
      bgColor: palette.primary,
    },
    infoModal: {
      background: palette.gray700,
    },
    mobileMenuToggle: {
      bgColor: palette.primary,
    },
    carouselsTitle: {
      iconColor: palette.secondary500,
      linkColor: palette.gray900,
    },
    promotions: {
      bgColor: 'transparent',
      fontColor: baseTheme.palette.neutral2,
      titleColor: baseTheme.palette.neutral2,
      subtitleColor: palette.primary,
      arrowsColor: palette.primaryBlack,
      button: {
        bgColor: palette.primary,
        hover: palette.primary700,
      },
    },
    searchBar: {
      background: 'transparent',
      closeIconColor: palette.primaryBlack,
      svgColor: palette.primaryBlack,
    },
    input: {
      color: palette.primaryBlack,
      borderColor: palette.primaryBlack,
      borderColorHover: palette.primaryBlack,
    },
    linkCard: {
      bgColor: palette.secondary500,
      bgColorVisited: palette.secondary500,
      bgColorHover: palette.gray100,
      activeColor: palette.secondary500,
      hoverColor: palette.secondary500,
    },
    breadcrumb: {
      color: palette.primaryBlack,
      currentColor: palette.primary,
    },
    navigation: {
      linkSelected: palette.primary,
      linkDefault: palette.secondary150,
      linkHover: palette.gray700,
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'external',
      url: `${getExternalSiteUrl()}/cashier/deposit`,
    },
    universalNavigation: {
      active: true,
      config: {
        appMarginTop: '3.75rem',
        marginTop: '3.75rem',
        position: 'fixed',
      },
    },
    newNavigation: {
      mobile: true,
    },
    hasNewNavigation: true,
    intercom: {
      brand: 'betonline',
    },
    hasCasinoBonusSection: true,
    searchModal: {
      active: false,
    },
    switch: {
      thumbColor: baseTheme.palette.neutral1,
      thumbColorChecked: palette.primary,
      trackColor: baseTheme.palette.neutral3_60,
      trackColorChecked: palette.primary,
    },
  }
}

export default betonlinePartialTheme
