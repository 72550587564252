import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: theme.custom.fontWeight.extrabold,
    color: theme.custom.textHeading?.color || theme.custom.palette.neutral1_87,
  },
  h1: {
    ...texts.stepUp4,
    marginTop: '1.5rem',
    marginBottom: '0.25rem',

    [mq('lg')]: {
      marginTop: '2rem',
    },
  },
  h2: {
    ...texts.stepUp2,
    // margin: '1rem 0 0.25rem 0',
    marginBottom: '0.25rem',

    [mq('sm')]: {
      // margin: '1.5rem 0 0.75rem 0',
      marginBottom: '0.75rem',
    },
  },
  h3: {
    ...texts.stepUp1,
    // margin: '1rem 0 0.25rem 0',
    marginBottom: '0.25rem',

    [mq('sm')]: {
      // margin: '1.5rem 0 0.5rem 0',
      marginBottom: '0.5rem',
    },
  },
  h4: {
    ...texts.baseline,
    // margin: '1rem 0 0.25rem 0',
    marginBottom: '0.25rem',

    [mq('sm')]: {
      // margin: '1.5rem 0 0.5rem 0',
      marginBottom: '0.5rem',
    },
  },
  h5: {
    ...texts.stepDown1,
    // margin: '0.875rem 0 0.25rem 0',
    marginBottom: '0.25rem',

    [mq('sm')]: {
      // margin: '1.125rem 0 0.5rem 0',
      marginBottom: '0.5rem',
    },
  },
  h6: {
    ...texts.stepDown2,
    // margin: '0.875rem 0 0.25rem 0',
    marginBottom: '0.25rem',

    [mq('sm')]: {
      // margin: '1rem 0 0.5rem 0',
      marginBottom: '0.5rem',
    },
  },
}))

export default useStyles
