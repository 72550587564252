import { useCallback } from 'react'
import { useIntl } from 'react-intl'
// NOTE force Storybook to refresh when editing translation files
import './translations'
import locales from './translations'

const useTranslations = () => {
  const intl = useIntl()

  const formatMessage = useCallback(
    (key, values = {}) =>
      intl.formatMessage({ id: key, defaultMessage: key }, values),
    [intl],
  )

  return formatMessage
}

export const existKeyInJson = (key) => {
  return locales?.en.hasOwnProperty(key)
}
export default useTranslations
