import { useCallback } from "react"

export const useLockScroll = () => {
    const lockScroll = useCallback(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.width = '100%'
        document.body.style.height = '100%'
        document.body.style.touchAction = 'none'
        window.addEventListener('touchmove', (e) =>{
            e.preventDefault()
        })
    }, [])

    const unlockScroll = useCallback(() => {
        document.body.removeAttribute('style')
        window.removeEventListener('touchmove', (e) =>{
            e.preventDefault()
        })
    }, [])

    let originalStyles
    const blockModalScroll = () => {
        originalStyles = {
          position: document.body?.style?.position,
          scrollPosition: window?.scrollY || document?.documentElement?.scrollTop,
        }
        document.body.style.overflowY = 'hidden'
        document.body.style.position = 'fixed'
        document.body.style.top = `-${originalStyles?.scrollPosition}px`
    }
    
    const unblockModalScroll = () => {
        document.body.style.overflowY = 'unset'
        document.body.style.position = originalStyles?.position
        window.scrollTo(0, originalStyles?.scrollPosition)
      }

    return {
        lockScroll,
        unlockScroll,
        blockModalScroll,
        unblockModalScroll
    }
}
