import { useEffect, useState } from 'react';
import { gameCardUpdater } from '../../../../asyncData';
import Icon from '../../Icon'
import useStyles from './enabledCard.styles'
const onEnabledMounted = (cardId, setEnabled) => {  
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.subscribeToId(
      setEnabled,
      cardId,
      'enabled',
    );
    }
}

const onEnabledUnmounted = (cardId, setEnabled) => {  
  // we only subscribe tag lists that have a cardId
  if (cardId) {
    gameCardUpdater.unsubscribeFromId(
      setEnabled,
      cardId,
    );
  }
}
const EnabledCard = ({cardId, initialEnabled}) => {
  const [enabled, setEnabled] = useState(initialEnabled);
  useEffect(() => {
    onEnabledMounted(cardId, setEnabled);
    return () => {
      onEnabledUnmounted(cardId, setEnabled);
    }
  }, []);
  const classes = useStyles()
  return (
    <div className={enabled === true? classes.enabled : classes.disabled}>
      <div className={classes.shadow}></div>
      <div className={classes.plug}>
        <Icon name="plug" />
      </div>
    </div>
  )
}
export default EnabledCard
