import React from 'react'
import useStyles from './menuItem.styles'
import PropTypes from 'prop-types'
import Icon from '../../../components/Icon'
import { useAuth } from '../../../../authentication'
import { useEnvData } from '../../../../hooks/useEnvData'

export const MenuItem = ({ item, onClick, active }) => {
    const { baseDomain } = useEnvData()
    const { redirectToLogin, logged } = useAuth()
    const myAccountUrl = `${baseDomain}/my-account`
    const classes = useStyles()
    let link

    if(item?.name === 'LOGIN' && logged){
        link = myAccountUrl
    } else if (item?.name === 'LOGIN') {
        link = '#!'
    } else {
        link = item?.to
    }
    
    return (
        <a 
            className={`${classes.anchorItem} ${active}`} 
       		href={link} 
        	onClick={(item?.name === 'LOGIN' && !logged) ? (() => redirectToLogin()) : onClick && onClick}
            id={`main-menu-${item?.name}`}
            >
          <Icon name={item?.icon} />
          {(item?.name ==='LOGIN' && logged) ? 'ACCOUNT' : item?.name}
        </a>
    ) 
}
MenuItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired
    }),
    onClick: PropTypes.func,
    active: PropTypes.string
}