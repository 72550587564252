import { makeStyles } from '../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  mobileActions: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar,
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.custom.palette.quaternary800,
    display: 'flex',
    padding: '0.75rem 0.75rem max(env(safe-area-inset-bottom), 12px)',

    '& button': {
      marginLeft: '0.75rem',
      width: '50%',
    },
    '& button:first-child': {
      marginLeft: 0,
    },
  },
}))

export default useStyles
