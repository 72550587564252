import useStyles from './titlesTag.styles'

const Titles = ({ title, subtitle }: { title: string, subtitle: string }) => {
  const blackjackTitle = 'BlackJack'
  const classes = useStyles()

  return (
    <div className={classes.titleCtn}>
      {title === blackjackTitle ? null : (
        <div className={classes.subtitle}>
          <label>{subtitle}</label>
        </div>
      )}
      <div className={classes.title}>
        <label>{title}</label>
      </div>
    </div>
  )
}

export default Titles
