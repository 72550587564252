import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './form.styles'

const FormFieldset = props => {
  const classes = useStyles()

  return (
    <fieldset className={classes.formFieldset}>
      <legend className={classes.formLegend}>{props.legend}</legend>
      <div className={classes.formInputWrapper}>{props.children}</div>
    </fieldset>
  )
}

FormFieldset.propTypes = {
  legend: PropTypes.string,
  children: PropTypes.node,
}

export default FormFieldset
