import { makeStyles } from '../../materialUiWrapper'
import { mq } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
  textPlaceholder: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    color: theme.custom?.placeholder?.color || theme.custom.palette.neutral1,
    fontSize: theme.custom.fontSize.xs,
    lineHeight: '1rem',
    [mq('lg')]: {
      fontSize: theme.custom.fontSize.sm,
      lineHeight: '1.35rem',
    },
  }
}))

export default useStyles
