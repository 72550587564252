import { isFeatureFlagActive } from '../KameleoonClient'

const AB_TEST_NEW_NAVIGATION_KEY = 'websites-ff-universal_navigation'

// set the default state for the experiment in this case Regular layout is off
let layoutFlag = true

export const isNewNavigationEnabled = async () => {
  try {
    const value = await isFeatureFlagActive(AB_TEST_NEW_NAVIGATION_KEY)
    layoutFlag = value === 'on'
    return layoutFlag
  } catch (error) {
    return false
  }
}

export const isNewNavigationFlagSynched = () => layoutFlag