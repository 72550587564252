import React from 'react'
import { Link } from 'react-router-dom'

import Icon from '../Icon'
import useStyles from './heading.styles'
import { useTranslations } from '../../../i18n'

interface HeadingProps {
  icon?: string;
  title: string;
  number?: number;
  to?: string;
  iconURL?: string;
}

const Heading: React.FC<HeadingProps> = ({ icon, title, number, to, iconURL }) => {
  const formatMessage = useTranslations()
  const classes = useStyles()
  return (
    <div className={classes.heading}>
      <div className={classes.headingLeft}>
        {(icon || iconURL) && (
          <div className={classes.icon}>
            <Icon name={icon} url={iconURL} />
          </div>
        )}
        <h2 className={classes.text}>
          {title}
          {number ? <span>({number})</span> : null}
        </h2>
      </div>
      {to ? (
        <Link to={to} className={classes.link}>
          <span>{formatMessage('action:view-all')}</span>
          <Icon name="chevron-right" />
        </Link>
      ) : null}
    </div>
  )
}

export default Heading
