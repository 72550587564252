import '@platform/shared-styles';
import { useEnvData } from '../../../../hooks/useEnvData';
const SharedStyles = () => {
    const {brand} = useEnvData();

    return (
        <shared-styles brand={brand}></shared-styles>
    )
}

export default SharedStyles;