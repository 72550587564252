import { makeStyles } from '../../../ui/components/materialUiWrapper'
import { hoverDevice } from '../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  favButton: {
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: 0,
    margin: '0 1.3rem 0 1rem',
    color: theme.custom.palette.neutral1,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    [hoverDevice()]: {
      '&:hover': {
        opacity: 0.6,
      },
    },

    '&:disabled': {
      color: theme.custom.palette.neutral1_24,
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  favIcon: {
    fill: 'currentColor',
    verticalAlign: 'middle',
  },
}))

export default useStyles
