import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import Icon from '../../../components/Icon';
import useStyles from './blackjack.styles';

const BlackJack = props => {
    const { to: game, id  } = props
    const classes = useStyles();
    return <Link to={game} className={classes.wrapper} id={id}>
        <Icon className={classes.icon} name="blackjack-spade" />
    </Link>
}

BlackJack.propTypes = {
    to: PropTypes.string,
    id: PropTypes.string
}
BlackJack.defaultProps = {
    id: 'blackjack-spade'
}

export default BlackJack;