import React, { useLayoutEffect, useEffect, useContext } from 'react'
import '@pty-balance/widget'
import { BehaviorSubject } from 'rxjs'

import { useAuth } from '../../../../../authentication'
import { UserBalanceContext } from '../../../../../context/UserBalance'
import { useEnvData } from '../../../../../hooks/useEnvData'
import { useTheme } from '@material-ui/core'
import { isVipLevelsEnabled, isVipAvatarEnabled, isXPEnabled } from '../../../../../business'
import Cookies from 'js-cookie'
import useGTMTracking from '../../../../../hooks/useGTMTracking'
import { getExternalBaseUri } from '../../../../components/NewNavigation/UniversalBalanceComponent'
import { useIsBrand } from '../../../../../hooks/useIsBrand'

const UniversalBalance = () => {
  const { baseDomain } = useEnvData()
  const { logout, logged } = useAuth()
  const { state } = useContext(UserBalanceContext)
  const { userData, balances, vipData } = state
  const theme = useTheme()
  const { isBOL } = useIsBrand()
  const { registerUniversalBalanceGTMEvent, getUniversalBalanceGTMEvent } = useGTMTracking()

  useLayoutEffect(() => {
    const initBalanceWidget = (event) => {
      
      const balance = event.detail.component

      balance.customSettings = theme.custom.brand.balanceWidget.customSettings

      balance.init(
        {
          sitesUrl: getExternalBaseUri(),
        },
        {
          authService: {
            logout: () => {
              if (logged) {
                logout()
                Cookies.remove('showvipsnackbar')
              }
            },
          },
          balanceService: {
            userData,
            balances,
            notificationsCount: new BehaviorSubject(null),
            vipData,
          },
          pokerService: {},
        },
      )
    } 
    window.addEventListener('balance-widget-ready', initBalanceWidget)

    return () => window.removeEventListener('balance-widget-ready', initBalanceWidget);
  }, [balances, baseDomain, logged, logout, userData])

  useEffect(() => {
    const handleActiveToggle = event => {
      const { active } = event.detail
      registerUniversalBalanceGTMEvent(getUniversalBalanceGTMEvent(active))
    }

    window.addEventListener('avatartoggled', handleActiveToggle)

    return () => window.removeEventListener('avatartoggled', handleActiveToggle)
  }, [])

  useEffect(() => {
    if (isXPEnabled()) {
      let balanceWidgetShadowRoot;
      let balanceWidget;
      let balanceWidgetAvatar;
      const balanceWidgetShadowRootInterval = setInterval(() => {
          balanceWidget = document.querySelector('balance-widget');
          balanceWidgetShadowRoot = balanceWidget.shadowRoot;
          if (balanceWidgetShadowRoot) {
              balanceWidgetAvatar = balanceWidgetShadowRoot.querySelector('figure.icon-user-account');
              clearInterval(balanceWidgetShadowRootInterval);
          }
      });
      setInterval(() => {
        if (window.xtremepush) {
          window.xtremepush('inbox', 'badge', {},
          (messages) => {
              if (balanceWidgetAvatar) {
                  if (messages?.badge) {
                          balanceWidget.setAttribute('xtremepushnotificationscount', messages.badge.toString());
                          balanceWidgetAvatar.classList.add('has-notifications');
                          } else {
                          balanceWidget.setAttribute('xtremepushnotificationscount', '0');
                          balanceWidgetAvatar.classList.remove('has-notifications')
                      }
                  }
              }
          );
        }
      }, 3000);
    }
  },[])

  const hasBonus = isBOL() ? 'false' : 'true'
  return (
    <>
      <balance-widget
        hasavatar={isVipAvatarEnabled()}
        hasbonus={hasBonus}
        haspokerwallet="false"
        hasVip={isVipLevelsEnabled()}
        brand={theme.custom.brand.balanceWidget.name}
        usextremepush={isXPEnabled()}
        freebet={theme.custom.brand.balanceWidget?.freebet}
      />
    </>
  )
}

export default UniversalBalance
