import { makeStyles } from '../materialUiWrapper'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  pageHeadingWrapper: {
    backgroundColor: theme?.custom?.pageHeading?.bgColor || 'transparent',
    position: 'relative',
    zIndex: 1,
  },
  pageHeading: {
    padding: '1.75rem 0',
    textAlign: 'center',
    color: theme?.custom?.pageHeading?.color || theme.custom.palette.neutral1,
    position: 'relative',

    [mq('lg')]: {
      padding: '3rem 0',
    },
  },
  headingBackLink: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',

    [hoverDevice()]: {
      '&:hover': {
        '& svg': {
          fill: theme.custom.palette.btnSecondary || theme.custom.palette.tertiary800,
        },
      },
    },

    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      fill: theme.custom.palette.neutral1
    },
  },
  headingTitle: {
    margin: 0,
    fontFamily: theme?.custom?.fontFamily?.primaryFont,
    fontSize: theme.custom.fontSize.xl,
    lineHeight: theme.custom.lineHeight.xs,
    fontWeight: theme?.custom?.pageHeading?.fontWeight || theme.custom.fontWeight.extrabold,
    textAlign: 'center',
    textTransform: theme?.custom?.pageHeading?.textTransform || 'uppercase',

    [mq('lg')]: {
      fontSize: theme.custom.fontSize.xxl,
      lineHeight: theme.custom.lineHeight.sm,
    },
  },
  dropdownWrapper: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}))

export default useStyles
