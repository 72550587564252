import { makeStyles } from '../../materialUiWrapper'
import { hoverDevice } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
  radioButton: {
    display: 'block',
    position: 'relative',
    marginBottom: '1.25rem',
    [hoverDevice()]: {
      '&:hover': {
        '& $radioButtonInput:not(:disabled)': {
          '&::before': {
            opacity: 1,
          },
          '& + $radioButtonLabel': {
            cursor: 'pointer',
          }
        }
      },
    },
  },
  radioButtonInput: {
    appearance: 'none',
    background: 'transparent',
    outline: 'none',
    display: 'block',
    position: 'absolute',
    top: '-0.125rem',
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',

    '&::before, &::after': {
      content: '""',
      display: 'block',
      borderRadius: '100%',
      position: 'absolute',
    },
    '&::before': {
      width: '1.5rem',
      height: '1.5rem',
      border: `0.0625rem solid ${theme.custom.palette.neutral1}`,
      opacity: .6,
      transition: 'opacity 200ms ease',
    },
    '&::after': {
      width: '0.75rem',
      height: '0.75rem',
      background: theme.custom.palette.success,
      top: '50%',
      left: '50%',
      margin: '-0.375rem 0 0 -0.375rem',
      opacity: 0,
      transition: 'opacity 200ms ease',
    },

    '&:disabled': {
      cursor: 'initial',
      '&::before, & + $radioButtonLabel, &:checked::after': {
        opacity: .16,
      }
    },

    '&:checked': {
      '&::after, &::before': {
        opacity: 1,
      }
    },

  },
  radioButtonLabel: {
    paddingLeft: '2rem',
    lineHeight: '1.29',
    opacity: '0.87',
    fontSize: theme.custom.fontSize.sm,
  }
}))

export default useStyles
