import React, { useRef, useEffect } from 'react'
import PageContents from '../../ui/components/PageContents'
import { useAuth } from '../../authentication'
import { useCashierInformation } from '../../asyncData'
import useCashierOauthRedirect from '../../hooks/useCashierOauthRedirect'

const CashierOAuth = () => {
  const { logged } = useAuth()
  const { data, isSuccess } = useCashierInformation({
    enabled: logged,
    useErrorBoundary: true,
  })
  const myFormGet = useRef(null)
  const { lastUrlForCashier: lastUrlVisited } = useCashierOauthRedirect()
  const { host, protocol } = window.location
  const prevUrl =  `${protocol}//${host}${lastUrlVisited.includes('cashier') ? '/':lastUrlVisited}`

  useEffect(() => {
    if (!isSuccess) return
    const myFormCashier = myFormGet.current
    if (myFormCashier) {
      myFormCashier.submit()
    }
  }, [isSuccess])

  return (
    <PageContents>
      <form ref={myFormGet} name="myFormGet" action={data?.Url} method="get" id="cashier-from" target="" style={{ height: '100vh' }}>
          <div id="container">    
              <input type="hidden" id="ID" name="ID" value={data?.ID ?? ''} />       
              <input type="hidden" name="bp" value="false" />
              <input type="hidden" name="tid" value="0" /> 	
              <input type="hidden" name="pt" value="" />
              <input type="hidden" id="ActionUrl" name="ActionUrl" value="" />	
              <input type="hidden" name="RedirectURL" value=""/>
              <input type="hidden" name="NewArchFlag" value="true"/>
              <input type="hidden" name="iframe" value="false"/>
              <input type="hidden" name="urllastvisited" value={prevUrl} />
          </div>
      </form>
    </PageContents>
  )
}

export default CashierOAuth