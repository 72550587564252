import React from 'react'
import PropTypes from 'prop-types'

import List from './'
import Card from '../Card'
import CardSlim from '../CardSlim'

/**
 * CardList - A card list component to work with CMS's data structure
 */
const CardList = props => {
  const { cards, big } = props.list
  const Component = big ? Card : CardSlim
  return (
    <List cols={big ? 4 : 3}>
      {cards.map(card => (
        <Component
          key={card.id}
          icon={card.icon}
          title={card.title}
          // @ts-ignore fix typescript error for description
          description={card.description}
        />
      ))}
    </List>
  )
}

CardList.propTypes = {
  list: PropTypes.shape({
    big: PropTypes.bool,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        icon: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    ).isRequired,
  }),
}

export default CardList
