export type BaseTheme = {
  breakpoints: {
      values: {
          xxs: number;
          xs: number;
          sm: number;
          md: number;
          lg: number;
          xl: number;
          sbMobile: number;
      };
  };
  breakpointsCard: {
      values: {
          xs: number;
          sm: number;
          md: number;
          lg: number;
      };
  };
  fontSize: {
      xxxs: string;
      xxs: string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      xxl: string;
      xxxl: string;
      xxxxl: string;
      xxxxxl: string;
  };
  fontWeight: {
      regular: number;
      semibold: number;
      bold: number;
      extrabold: number;
  };
  lineHeight: {
      xxs: number;
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
  };
  palette: {
      darkBlue?: string;
      darkCharcoal?: string;
      errorDark?: string;
      errorLight?: string;
      ligthGray?: string;
      lightGray_100?: string;
      lightGray_150?: string;
      liveTag?: string;
      liveTagBackground?: string;
      neutral1_12?: string;
      neutral1_16?: string;
      neutral1_24?: string;
      neutral1_38?: string;
      neutral1_5?: string;
      neutral1_60?: string;
      neutral1_87?: string;
      neutral1_9?: string;
      neutral1?: string;
      neutral2_0?: string;
      neutral2_24: string,
      neutral2_16: string,
      neutral2_38?: string;
      neutral2_60?: string;
      neutral2_87?: string;
      neutral2_90?: string;
      neutral2?: string;
      neutral3_12?: string;
      neutral3_16?: string;
      neutral3_38?: string;
      neutral3_5?: string;
      neutral3_60?: string;
      neutral3_70?: string;
      neutral3_87?: string;
      neutral3_9?: string;
      neutral3?: string;
      primary50?: string;
      primary800?: string;
      quaternary700?: string;
      quaternary800?: string;
      secondary100?: string;
      secondary50?: string;
      secondary500?: string;
      secondary700?: string;
      secondary800?: string;
      secondary900?: string;
      success?: string;
      tertiary100?: string;
      tertiary500?: string;
      tertiary800?: string;
      warning?: string;
  };
  shadows: {
      overlay: string;
      output: string;
      header: string;
  };
  borders: {
      rounded: string;
  };
};

/**
 * Custom theme object
 * @typedef {Object} Theme
 * @property {Object} brand - Brand information
 * @property {Object} breakpoints - MUI-compilant breakpoints object
 * @property {Object} buttons - Button color palette
 * @property {Object} elementColors - Color palette for specific elements
 * @property {Object} fontSize - Font size map
 * @property {Object} fontWeight - Font weight map
 * @property {Object} gradients - Gradients map
 * @property {Object} lineHeight - Line height map
 * @property {Object} palette - Color palette
 * @property {Object} shadows - Shadow map
 * @property {Object} tags - Tag color palette
 *
 * @constant {Theme} - Base theme, exposed so that its properties can be
 * accessed from child themes (e.g. colors) and utility functions (e.g.
 * breakpoints)
 */
const baseTheme: BaseTheme = {
  breakpoints: {
    values: {
      xxs: 0,
      xs: 375,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
      sbMobile: 745
    },
  },
  breakpointsCard: {
    values: {
      xs: 111,
      sm: 131,
      md: 156,
      lg: 237
    }
  },
  fontSize: {
    xxxs: '0.5rem',
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    xxl: '1.75rem',
    xxxl: '2.25rem',
    xxxxl: '3rem',
    xxxxxl: '4rem',
  },
  fontWeight: {
    regular: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  lineHeight: {
    xxs: 1.2,
    xs: 1.29,
    sm: 1.33,
    md: 1.38,
    lg: 1.5,
    xl: 1.55,
  },
  palette: {
    // neutral
    neutral1: '#ffffff',
    neutral1_87: 'rgba(255, 255, 255, 0.87)',
    neutral1_60: 'rgba(255, 255, 255, 0.60)',
    neutral1_38: 'rgba(255, 255, 255, 0.38)',
    neutral1_24: 'rgba(255, 255, 255, 0.24)',
    neutral1_16: 'rgba(255, 255, 255, 0.16)',
    neutral1_12: 'rgba(255, 255, 255, 0.12)',
    neutral1_9: 'rgba(255, 255, 255, 0.09)',
    neutral1_5: 'rgba(255, 255, 255, 0.05)',
    neutral2: '#121212',
    neutral2_90: 'rgba(18, 18, 18, 0.90)',
    neutral2_87: 'rgba(18, 18, 18, 0.87)',
    neutral2_60: 'rgba(18, 18, 18, 0.60)',
    neutral2_38: 'rgba(18, 18, 18, 0.38)',
    neutral2_24: 'rgba(18, 18, 18, 0.24)',
    neutral2_16: 'rgba(18, 18, 18, 0.16)',
    neutral2_0: 'rgba(18, 18, 18, 0)',
    neutral3: '#000000',
    neutral3_87: 'rgba(0, 0, 0, 0.87)',
    neutral3_70: 'rgba(0, 0, 0, 0.70)',
    neutral3_60: 'rgba(0, 0, 0, 0.60)',
    neutral3_38: 'rgba(0, 0, 0, 0.38)',
    neutral3_16: 'rgba(0, 0, 0, 0.16)',
    neutral3_12: 'rgba(0, 0, 0, 0.12)',
    neutral3_9: 'rgba(0, 0, 0, 0.09)',
    neutral3_5: 'rgba(0, 0, 0, 0.05)',
    //darks
    darkCharcoal: '#333333',
    // states
    errorDark: '#b00020',
    errorLight: '#cf6679',
    warning: '#ffc107',
    success: '#70bf45',
    // gray
    ligthGray: '#D5D3D3',
    lightGray_100: '#FAFAFA',
    lightGray_150: '#F5F5F5',
    // blue
    darkBlue: '#192B49',
    // live casino 
    liveTagBackground: '#2C2828',
    liveTag: '#FE6565',
  },
  shadows: {
    overlay: '0 8px 16px 0 rgba(0, 0, 0, 0.2)',
    output: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    header: '0 6px 12px 0 rgba(0, 0, 0, 0.2)',
  },
  borders: {
    rounded: '0.15rem',
  }
}

export default baseTheme
