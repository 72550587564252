import React from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from '../../../../i18n'
import CustomPropTypes from '../../../../customPropTypes'
import Skeleton from '../../../../ui/components/Skeleton'
import Placeholder from '../../../../ui/components/Placeholder'
import { ASPECT_RATIOS } from '../../../../ui/constants'
import 'universal-prelaunch-modal'
import { useTheme } from '@material-ui/core'
import useModalHandler from './useModalHandler'
import OldInfoModal from './OldInfoModal'

const GameInfoSkeleton = props => (
  <Skeleton
    variant="rect"
    isRounded={props.isRounded}
    aspectRatio={ASPECT_RATIOS.GAME_INFO}
  />
)

GameInfoSkeleton.propTypes = {
  isRounded: PropTypes.bool,
}

const GameInfoPlaceholder = () => (
  <Placeholder aspectRatio={ASPECT_RATIOS.GAME_INFO} type="default" />
)

const GameInfo = props => {
  const {
    game,
    logged,
    favorited,
    disabled,
    onFavorite,
    actions,
    errorBefore,
    errorAfter,
    enableBalanceAlert,
    isLoadingPlayableBalance,
    activeBonusStatus,
    playableBalanceStatus,
    playableBalance,
    playableBalanceError,
    handleClose,
    handlePlay,
    isModalOpen,
    playForFree,
    isBtnDisabled,
    executedFromBonusLocation,
  } = props

  const formatMessage = useTranslations()
  const myBonusLocation = '/my-account/my-bonus'
  const theme = useTheme()

  const category = game.Reels
    ? `${game.Reels} ${formatMessage('noun:reels')} ${
        game.Lines ? game.Lines : ''
      }`
    : undefined

  useModalHandler({
    game,
    isModalOpen,
    logged,
    favorited,
    isLoadingPlayableBalance,
    enableBalanceAlert,
    activeBonusStatus,
    playableBalance,
    playForFree,
    onFavorite,
    handlePlay,
    handleClose,
    isBtnDisabled,
    executedFromBonusLocation,
  })

  if (isModalOpen) {
    return (
      <div>
        {isModalOpen && game && theme.custom.universalPrelaunchModal ? (
          <div>
            <universal-prelaunch-modal></universal-prelaunch-modal>
          </div>
        ) : isModalOpen && game ? (
          <div>
            <OldInfoModal
              errorBefore={errorBefore}
              game={game}
              logged={logged}
              favorited={favorited}
              disabled={disabled}
              onFavorite={onFavorite}
              category={category}
              errorAfter={errorAfter}
              enableBalanceAlert={enableBalanceAlert}
              myBonusLocation={myBonusLocation}
              isLoadingPlayableBalance={isLoadingPlayableBalance}
              activeBonusStatus={activeBonusStatus}
              playableBalanceError={playableBalanceError}
              playableBalanceStatus={playableBalanceStatus}
              playableBalance={playableBalance}
              actions={actions}
            />
          </div>
        ) : null}
      </div>
    )
  } else {
    return null
  }
}

// NOTE give default (falsy) values for error messages to avoid proptypes errors
GameInfo.defaultProps = {
  errorBefore: '',
  errorAfter: '',
}

GameInfo.Placeholder = GameInfoPlaceholder

GameInfo.propTypes = {
  game: CustomPropTypes.game.isRequired,
  logged: PropTypes.bool,
  favorited: PropTypes.bool,
  disabled: PropTypes.bool,
  onFavorite: PropTypes.func,
  actions: PropTypes.node,
  errorBefore: PropTypes.string,
  errorAfter: PropTypes.string,
  isLoadingPlayableBalance: PropTypes.bool,
  activeBonusStatus: PropTypes.string,
  playableBalanceStatus: PropTypes.string,
  playableBalance: PropTypes.number,
  playForFree: PropTypes.bool,
  enableBalanceAlert: PropTypes.bool,
  playableBalanceError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default GameInfo
