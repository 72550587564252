import React, { useState, useRef, useEffect, useContext } from 'react'
import Popover from '@material-ui/core/Popover'

import Icon from '../../../components/Icon'
import { useCurrency } from '../../../../i18n'
import UniversalBalance from './UniversalBalance'
import { useBalance } from '../../../../asyncData'
import { UserBalanceContext } from '../../../../context/UserBalance'
import { useHeaderContext } from '../index'
import useStyles from './index.styles'
import useXtremePushStyles from './xtremepush.styles'
import { useLockScroll } from '../../../../hooks/useLockScroll'
import { useVipLevels } from '../../../../asyncData/customer/useVipLevels';
import { useIsBrand } from '../../../../hooks/useIsBrand';
import { isIntercomCookieEnabled, isVipAvatarEnabled, isVipLevelsEnabled, isXPEnabled } from '../../../../business'
import { isMobileOrTablet } from '../../../../libs/uaparser'
import useGTMTracking from '../../../../hooks/useGTMTracking'
import { UserBalanceActions } from '../../../../context/UserBalance/interfaces'
import { isIntercomFFEnabled } from '../../../../config'
const BETA_MODE = 'Beta Mode';

const UserBalance = () => {
  const { height: headerPosition } = useHeaderContext() || {}
  const [backdropOpen, setBackdropOpen] = useState(false)
  const [hasNotifications, setHasNotifications] = useState(false)
  const classes = useStyles(headerPosition, backdropOpen)()
  useXtremePushStyles()
  const formatCurrency = useCurrency()
  const balanceRef = useRef(null)
  const [popoverPosition, setPopoverPosition] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const vipAvatarEnabled = isVipAvatarEnabled()
  const { state, dispatch } = useContext(UserBalanceContext)
  const closeMenuEvent = new CustomEvent('closeMobileMenu')
  const { isSB } = useIsBrand()
  const {
    isSuccess: isSuccessBalance,
    data: balanceData,
    isFetching,
    isRefetchError
  } = useBalance({
    useErrorBoundary: true,
    refetchInterval: 1000 * 6, // refetching each six seconds - This implementation should be change with BFF integration
  })
  const {
    isLoading,
    data: vipData,
    refetch,
    isError
  } = useVipLevels() || {}
  const { registerUniversalBalanceGTMEvent, getUniversalBalanceGTMEvent } = useGTMTracking()
  const { lockScroll, unlockScroll } = useLockScroll()
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    lockScroll()
    registerUniversalBalanceGTMEvent(getUniversalBalanceGTMEvent(true));
    if(isMobileOrTablet()){
      document.dispatchEvent(closeMenuEvent)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    document.dispatchEvent(closeMenuEvent);
    unlockScroll();
    registerUniversalBalanceGTMEvent(getUniversalBalanceGTMEvent(false));
  }
  useEffect(() => {
    if (!anchorEl) {
      unlockScroll()
    }
  }, [anchorEl])

  const getProfilePayload = (profile) => {
    return (
      {
        TotalBalance: profile?.TotalBalance,
        AvailableBalance: profile?.AvailableBalance,
        FreePlayBalance: profile?.FreePlayBalance,
        IsBonusWalletAllowed: profile?.IsBonusWalletAllowed,
        BonusMoneyBalance: profile?.BonusMoneyBalance,
        PendingBets: profile?.PendingBets,
        LockedCash: isSB ? BETA_MODE : profile?.LockedCash,
        BonusFunds: isSB ? BETA_MODE : profile?.BonusFunds,
        RealBalance: isSB ? BETA_MODE : profile?.RealBalance
      }
    )
  }

  const toggleBackdrop = (event) => {
    setBackdropOpen((event as CustomEvent).detail.active)
  }

  const onBackdropClose = () => {
    window.dispatchEvent(new CustomEvent('toggleavatar', { detail: {active: false}, bubbles: true}))
    setBackdropOpen(false)
  }

  useEffect(() => {
    if (isVipLevelsEnabled()) {
      dispatch({
        type: UserBalanceActions.UPDATE_VIP_DATA,
        payload: {vipData, isLoading, isError}
      })
    }
  }, [isLoading, vipData])

  // TODO: Defines what to do when isSuccessBalances is false
  useEffect(() => {
    if (balanceData && isSuccessBalance) {
      const { Profile } = balanceData
      dispatch({
        type: UserBalanceActions.UPDATE_USER_DATA,
        payload: {
          CustomerID: Profile?.CustomerID,
          FirstName: Profile?.FirstName,
        },
      })

      dispatch({
        type: UserBalanceActions.UPDATE_BALANCE,
        payload: getProfilePayload(Profile),
      })
    }
  }, [balanceData])

  useEffect(() => {
    if (!isVipAvatarEnabled()) {
      setPopoverPosition(balanceRef?.current?.offsetLeft)
    }
  }, [balanceRef, balanceData])

  const checkNotifications = () => {
    if (window.xtremepush) {
      window.xtremepush('inbox', 'badge', {}, (messages) => {
        setHasNotifications(messages?.badge ?? false);
      });
    }
  };

  useEffect(() => {
    if (!isVipAvatarEnabled() && isXPEnabled()) {
      const intervalId = setInterval(checkNotifications, 3000);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    const onNeedHelpOpen = () => {
      if (!(isIntercomCookieEnabled() || isIntercomFFEnabled())) {
        setAnchorEl(null)
        lockScroll()
        document.dispatchEvent(closeMenuEvent)
      }
    }

    const showVipModal = () => {
      document.getElementById('app').style.zIndex = 'unset'
      refetch()
      lockScroll()
    }

    const closeVipModal = () => {
      document.getElementById('app').style.zIndex = '1'
      unlockScroll()
    }


    window.addEventListener('showhelpmodal', onNeedHelpOpen)
    window.addEventListener('showvipmodal', showVipModal)
    window.addEventListener('closeVipModal', closeVipModal)

    return () => {
      window.removeEventListener('showhelpmodal', onNeedHelpOpen)
      window.removeEventListener('showvipmodal', showVipModal)
      window.removeEventListener('closeVipModal', closeVipModal)
    }
  }, [anchorEl])

  window.addEventListener('closeneedhelpmodal', () => {
    unlockScroll()
  })

  useEffect(() => {
    if (isFetching && !isRefetchError && balanceData) {
      const { Profile } = balanceData
      dispatch({
        type: UserBalanceActions.UPDATE_BALANCE,
        payload: getProfilePayload(Profile)
      })
    }
  }, [isFetching])

  useEffect(() => {
    if (vipAvatarEnabled) {
      window.addEventListener('avatartoggled', toggleBackdrop)
    }

    return () => {
      window.removeEventListener('avatartoggled', toggleBackdrop)
    }
  }, [vipAvatarEnabled])


  return (
    <>
      {!isVipAvatarEnabled() ?
        (
          <div
            ref={balanceRef}
            className={classes.balanceCtn}
            onClick={open ? handleClose : handleClick}
            id="universal-balance"
          >
            <span>{formatCurrency(state?.totalBalance)}</span>
            {open ? (
              <Icon name="close-icon" className={classes.closeIcon} />
            ) : (
              <Icon name="icon-user-account" className={`${classes.iconUser} ${hasNotifications ? 'has-notifications' : ''}`} />
            )}
          </div>
        ) :
        (
          <div>
            <UniversalBalance />
            <div
              className={classes.ubcBackdrop}
              onClick={onBackdropClose}
            />
          </div>
        )
      }
      <Popover
        className={classes.root}
        anchorReference="anchorPosition"
        anchorPosition={{
          left: popoverPosition,
          top: balanceRef?.current?.offsetHeight
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={{
          enter: 300,
          exit: 0,
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
      >
        <UniversalBalance />
      </Popover>
    </>
  )
}

export default UserBalance
