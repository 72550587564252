import { useState, useCallback, useEffect } from "react";

const useIsEnabledBackToClassic = (enabledMobile, enabledDesktop) => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.screen.width < 900) setIsMobile(true)
    }, [window.screen])
    
    const isEnabled = useCallback(() => {
        return (isMobile && enabledMobile) || (!isMobile && enabledDesktop)
    }, [enabledMobile, enabledDesktop, isMobile])

    return { isEnabled }
}
export default useIsEnabledBackToClassic