import { makeStyles } from '../materialUiWrapper'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  sideMenu: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.custom.palette.sideMenu || theme.custom.palette.quaternary800,
    color: theme.custom.sideMenu?.textColor || theme.custom.palette.neutral1,
    boxShadow: theme.custom.shadows.overlay,
    transform: 'translate3d(100%, 0, 0)',
    transition: 'all 0.6s ease-in-out',
    top: '0',
    right: 0,
    boxSizing: 'border-box',
    zIndex: theme.zIndex.sideMenu,
    [mq('sm')]: {
      width: 'auto',
      minWidth: '18.75rem',
      maxWidth: '23.4375rem',
      right: '0',
    },
  },
  isOpen: {
    transform: 'translate3d(0, 0, 0)',
  },
  closeArea: {
    height: '3.5rem',
    width: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeButton: {
    cursor: 'pointer',
    outline: 'none',
    margin: '0 1rem',
    backgroundColor: 'transparent',
    border: '0',
    padding: 0,
    color: theme.custom.palette.neutral1,
    '&[disabled]': {
      cursor: 'default',
    },
    '& .icon': {
      width: '1.5rem',
      height: '1.5rem',
      fill: theme.custom.globalHeader?.triggerButtonIconColor || theme.custom.palette.neutral1
    },
    [hoverDevice()]: {
      '&:hover': {
        '& .icon': {
          fill:  theme.custom.palette.btnPrimary || theme.custom.palette.tertiary800,
        },
      },
    },
  },
  scrollableArea: {
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingTop: '1px',
    flex: 1,
  },
  navList: {
    margin: '0',
    padding: '0',
  },
  navListItem: {
    margin: '-1px 0 0',
    overflow: 'hidden',
    padding: 0,
  },
  navListLink: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    position: 'relative',
    width: '100%',
    padding: '0.8125rem 1.5rem',
    fontWeight: theme.custom.fontWeight.extrabold,
    fontSize: theme.custom.fontSize.md,
    lineHeight: theme.custom.lineHeight.md,
    border: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'inherit',
    textAlign: 'left',
    cursor: 'pointer',
    '& .icon': {
      width: '1.5rem',
      height: '1.5rem',
      opacity: 0.87,
      verticalAlign: 'middle',
      fill: theme.custom.sideMenu?.textColor || theme.custom.palette.neutral1,
    },
    '& img': {
      width: '1.5rem',
      height: '1.5rem',
      opacity: 0.87,
      filter: theme.custom?.sideMenu?.imgFilter,
    },
    '&:visited': {
      color: theme.custom.sideMenu?.textColor || theme.custom.palette.neutral1,
    },
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.sideMenu?.hoverBg || theme.custom.palette.neutral1_9,
        color: theme.custom.sideMenu?.hoverColor || 'currentColor',
        '.navListTopDivider': {
          backgroundColor: theme.custom.palette.primary500,
        },
        '.navListBottomDivider': {
          backgroundColor: theme.custom.palette.primary500,
        },
      },
      '&:active': {
        background: theme.custom.sideMenu?.activeBg || theme.custom.palette.neutral1,
        color: theme.custom.sideMenu?.activeColor || theme.custom.palette.neutral2,
        '& .icon': {
          fill: theme.custom.sideMenu?.activeIcon || theme.custom.palette.neutral2,
        },
        '& img': {
          filter: theme.custom?.sideMenu?.activeImg,
        },
      },
    },
  },
  navListLinkSelected: {
    color: theme.custom.sideMenu?.selectedColor || theme.custom.palette.secondary500,
    '& .icon': {
      fill: theme.custom.sideMenu?.selectedIconColor || theme.custom.palette.secondary500,
    },
    '& img': {
      filter: theme.custom?.sideMenu?.selectedImg,
    },
    '&:visited': {
      color: theme.custom.sideMenu?.selectedColor || theme.custom.palette.secondary500,
    },
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.sideMenu?.selectedHover || theme.custom.palette.secondary500,
      },
      '&:active': {
        color: theme.custom.sideMenu?.selectedActive || theme.custom.palette.neutral2,
        '& .icon': {
          fill: theme.custom.sideMenu?.activeSelectedIcon || 'currentColor',
        },
      },
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 1,
      left: 0,
      width: 4,
      background: theme.custom.sideMenu?.beforeColor || theme.custom.palette.secondary500,
      zIndex: 1,
    },
  },
  navListText: {
    opacity: 0.87,
    verticalAlign: 'middle',
  },
  navListTopDivider: {
    display: 'block',
    width: '100%',
    height: '1px',
    position: 'absolute',
    left: '0',
    top: '0',
    zIndex: '1',
    backgroundColor: theme.custom.palette.neutral1_16,
  },
  navListBottomDivider: {
    display: 'block',
    width: '100%',
    height: '1px',
    position: 'absolute',
    left: '0',
    bottom: '0',
    zIndex: '1',
    backgroundColor: theme.custom.divider?.color || theme.custom.palette.neutral1_16,
  },
}))

export default useStyles
