const DATA_ID_SATE_SS = {
  LOGGED_IN: 5,
  LOGGED_OUT: 6
}

const DATA_ID_SATE_WC = {
  LOGGED_IN: 7,
  LOGGED_OUT: 8
}

const DATA_ID_SATE_SB = {
  LOGGED_IN: 11,
  LOGGED_OUT: 12
}

const DATA_ID_SATE_HR = {
  LOGGED_IN: 17,
  LOGGED_OUT: 18
}

const DATA_ID_SATE_GC = {
  LOGGED_IN: 19,
  LOGGED_OUT: 20
}

const DATA_ID_SATE_BOL = {
  LOGGED_IN: 21,
  LOGGED_OUT: 22
}

const DATA_ID_SATE_QBC = {
  LOGGED_IN: 23,
  LOGGED_OUT: 24
}

const DATA_ID_SATE_LV = {
  LOGGED_IN: 15,
  LOGGED_OUT: 16
}

export const getDataIdState = brand => {
  switch (brand) {
    case 'ssnasite':
      return DATA_ID_SATE_SS
    case 'wcnasite':
      return DATA_ID_SATE_WC
    case 'gcsite':
      return DATA_ID_SATE_GC
    case 'hrnasite':
      return DATA_ID_SATE_HR
    case 'qbcnasite':
      return DATA_ID_SATE_QBC
    case 'lvnasite':
      return DATA_ID_SATE_LV
    case 'bolnasite':
      return DATA_ID_SATE_BOL
    case 'sbnasite':
      return DATA_ID_SATE_SB
    default:
      return null
  }
}
