import Env from '@env';
import React, { useState, useEffect } from 'react'
import Header from '../../../ui/external-components/Header'
import { useAuth } from '../../../authentication'
import { useBackToClassicBtn } from '../../../asyncData/cms/useBackToClassicBtn'
import { useEnvData } from '../../../hooks/useEnvData'
import MediaQuery from 'react-responsive'
import BlackJack from '../../../ui/external-components/Header/BlackJack'
import Separator from '../../../ui/external-components/Header/separator'
import VipRewards from '../../../ui/external-components/VipRewardsProgressPopup'
import UserBalance from '../../../ui/external-components/Header/balanceAccount'
import CustomButtons from '../../../ui/external-components/Header/Buttons/CustomButtons'
import { formatMessage } from '../../../i18n'
import { getGTMDataObject, headerLinks } from './utils'
import { useIsBrand } from '../../../hooks/useIsBrand'
import { useRedirectToExternal } from '../../../hooks/useRedirectToExternal'
import { replaceMirrorUrlHelper } from '../../../config/originSingleton'

const AmberHeader = () => {
  const [isLogged, setIsLogged] = useState(undefined)
  const { initialized, logged, redirectToJoin, redirectToLogin } = useAuth()
  const { data = {} } = useBackToClassicBtn()
  const { baseDomain } = useEnvData()
  const { brand, isBOL } = useIsBrand()
  const { redirectToExternalUrl } = useRedirectToExternal()

  const bankingIcon = {
    sbnasite: 'banking',
    bolnasite: 'banking-w',
  }
  const handlePushDataLayer = (name, index, isLogged, redirectUrl, noText?) => {
    if (isBOL()) {
      getGTMDataObject(name, index, isLogged ? 3 : 4, noText ? null : name, redirectUrl)
    }
  }
  const siteKey = Env.get('VITE_GSETTING')

  useEffect(() => {
    if (initialized) setIsLogged(logged)
  }, [initialized, logged])

  return <Header
    logo={`/logo.svg`}
    logoSlim={`/logo-slim.svg`}
    logged={logged}
    logoLink={replaceMirrorUrlHelper(baseDomain)}
    backToClassicBtn={data}
  >
    <MediaQuery minWidth={745}>
      <div onClick={() => handlePushDataLayer('blackjack', 1, isLogged, `${window.location.href}${headerLinks(siteKey, 'blackjack')}`, true)}>
        <BlackJack
          to={headerLinks(siteKey, 'blackjack')}
          id="desktop-spades-button"
        />
      </div>
      {isLogged && <Separator />}
    </MediaQuery>
    {isLogged && (
      <>
        {isBOL() && <VipRewards />}
        <UserBalance />
        <MediaQuery minWidth={745}>
          <Separator />
          <CustomButtons
            icon={bankingIcon[siteKey]}
            disabled={!initialized}
            variant="secondarySolid"
            height="smallest"
            onClick={() => {
              handlePushDataLayer('deposit', 3, isLogged, `${baseDomain}/cashier/deposit`)
              window.location.href = headerLinks(siteKey, 'banking')
            }}
            id="desktop-deposit-button"
          >
            {formatMessage('action:deposit')}
          </CustomButtons>
        </MediaQuery>
      </>
    )}
    {!isLogged && (
      <>
        <MediaQuery minWidth={745}>
          <CustomButtons
            type="banking"
            disabled={!initialized}
            variant="ghostSolid"
            height="smaller"
            onClick={() => {
              handlePushDataLayer('banking', 2, isLogged,`${baseDomain}/banking`)
              redirectToExternalUrl(`${baseDomain}/banking`)
            }}
            id="desktop-banking-button"
          >
            {formatMessage('noun:banking')}
          </CustomButtons>
        </MediaQuery>
        <CustomButtons
          type="login"
          disabled={!initialized}
          variant="primarySolid"
          height="smaller"
          onClick={() => {
            handlePushDataLayer('login', 3, isLogged, `${baseDomain}/login`)
            redirectToLogin()
          }}
          id="desktop-login-button"
        >
          {formatMessage('action:login')}
        </CustomButtons>
        <CustomButtons
          type="join"
          disabled={!initialized}
          variant="secondarySolid"
          height="smaller"
          onClick={() => {
            handlePushDataLayer('join', 4, isLogged, `${baseDomain}/join`)
            redirectToJoin()
          }}
          id="join-button"
        >
          {formatMessage('action:join-now')}
        </CustomButtons>
      </>
    )}
  </Header>
}

export default AmberHeader
