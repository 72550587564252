import React from 'react'
import PropTypes from 'prop-types'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import { useTranslations } from '../../../../i18n'
import { useTheme } from '@material-ui/core'

import useDrawerStyles from './drawer.styles'

const GameInfoModal = props => {
  const { title, open, onClose, children, executedFrom } = props
  const formatMessage = useTranslations()
  const drawerClasses = useDrawerStyles()
  const theme = useTheme()

  return (
    <SwipeableDrawer
      open={open}
      className={drawerClasses.drawer}
      anchor="bottom"
      onClose={onClose}
      onOpen={() => null}
      onTouchMove={onClose}
      disableSwipeToOpen
    >
      <div>
        <div>
          {!theme.custom.universalPrelaunchModal || executedFrom !=='infoModal' ?
          <div className={drawerClasses.header}>
            <h1 className={drawerClasses.title}>{title}</h1>
            <button className={drawerClasses.closeButton} onClick={onClose}>
              {formatMessage('action:close')}
            </button>
          </div>: ''}

          {children}
        </div>

      </div>
    </SwipeableDrawer>
  )
}
GameInfoModal.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default GameInfoModal
