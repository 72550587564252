import React from 'react'
import { action } from '@storybook/addon-actions'
import MediaQuery from 'react-responsive'

import { useTranslations } from '../../../i18n'

import Header from '../../../ui/external-components/Header'
import Footer from '../../../ui/external-components/Footer'
import MobileActions from '../../../ui/components/GlobalHeader/MobileActions'
import Button from '../../../ui/components/Button'
import PropTypes from 'prop-types'
import BlackJack from '../../../ui/external-components/Header/BlackJack'
import Separator from '../../../ui/external-components/Header/separator'
import BalanceAccount from '../../../ui/external-components/Header/balanceAccount'
import CustomButtons from '../../../ui/external-components/Header/Buttons/CustomButtons'


const LayoutDecorator = props => {
  const { Story, params } = props
  const formatMessage = useTranslations()
  const logged = params.globals.logged

  return (
    <>
      <Header
        logo={`/logo.svg`}
        logoSlim={`/logo-slim.svg`}
        logged={logged}
      >
        {logged ? (
          <>
            <MediaQuery minWidth={600}>
              <BlackJack to='/wherever' />
              <Separator />
            </MediaQuery>
            <BalanceAccount />
            <MediaQuery minWidth={600}>
              <Separator />
              <CustomButtons
                icon="banking"
                variant="secondarySolid"
                height="smallest"
              >
                {formatMessage('action:deposit-now')}
              </CustomButtons>
            </MediaQuery>

          </>
        ) : (
          <>
            <MediaQuery minWidth={600}>
              <BlackJack to='/wherever' />
              <CustomButtons
                type="banking"
                variant="ghost"
                height="smaller"
              >
                {formatMessage('noun:banking')}
              </CustomButtons>
            </MediaQuery>
            <CustomButtons
              type="login"
              variant="primarySolid"
              height="smaller"
            >
              {formatMessage('action:login')}
            </CustomButtons>
            <CustomButtons
              type="join"
              variant="secondarySolid"
              height="smaller"
            >
              {formatMessage('action:join-now')}
            </CustomButtons>
          </>
        )}
      </Header>
      <Story logged={logged} />
      {logged ? null : (
        <MobileActions>
          <Button
            variant="secondary"
            height="small"
            onClick={action('onJoinClick')}
          >
            {formatMessage('action:join-now')}
          </Button>
          <Button height="small" onClick={action('onLoginClick')}>
            {formatMessage('action:login')}
          </Button>
        </MobileActions>
      )}
      <Footer />
    </>
  )
}
LayoutDecorator.propTypes = {
  Story: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
}

export default LayoutDecorator
