import { makeStyles } from '../../../ui/components/materialUiWrapper'
import { mq, hoverDevice } from '../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  slider: {},
  slides: {
    margin: 0,
  },
  button: {
    position: 'absolute',
    top: '50%',
    transform: 'translate3d(0, -50%, 0)',
    border: 'none',
    background: 'none',
    transition: 'background 200ms ease',
    cursor: 'pointer',
    outline: 'none',
    width: 28,
    height: 28,
    padding: 0,
    '& .icon': {
      width: '100%',
      height: '100%',
      color: theme.custom.palette.neutral1,
      fill: theme.custom.palette.neutral1,
      transition: 'opacity 200ms ease',
      [hoverDevice()]: {
        '&:hover': {
          opacity: 0.6,
        },
      },
    },
    [mq('lg')]: {
      width: 44,
      height: 44,
    },
    '&.hidden': {
      display: 'none',
    },
  },
  buttonLeft: {
    left: 12,
    [mq('sm')]: {
      left: 24,
    },
    [mq('lg')]: {
      left: 48,
    },
  },
  buttonRight: {
    right: 12,
    [mq('sm')]: {
      right: 24,
    },
    [mq('lg')]: {
      right: 48,
    },
  },
  clickableContainer: {
    cursor: 'pointer',
  },
}))

export default useStyles
