export const OPTIONS = {
  type: 'carousel',
  rewind: false,
  startAt: 0,
  autoplay: 5000,
  gap: 0,
  perView: 1,
  dragThreshold: true,
  animationDuration: 600,
  hoverpause: true,
}
