import { makeStyles } from '../materialUiWrapper'
import { hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',

    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    [hoverDevice()]: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  primary: {
    color: theme.custom.palette.tertiary500,
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.btnSecondary || theme.custom.palette.tertiary800,
      },
    },
    '&[disabled]': {
      color: theme.custom.palette.neutral1_38,
    },
  },
  secondary: {
    color: theme.custom.palette.neutral1,
    [hoverDevice()]: {
      '&:hover': {
        color:  theme.custom.palette.btnSecondary || theme.custom.palette.tertiary800,
      },
    },
    '&[disabled]': {
      color: theme.custom.palette.neutral1_38,
    },
  },
  light: {
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.neutral2_60,
      },
    },
    '&[disabled]': {
      color: theme.custom.palette.neutral2_38,
    },

    '&$primary': {
      color:   theme.custom.palette.btnPrimary || theme.custom.palette.tertiary800,
    },

    '&$secondary': {
      color: theme.custom.palette.neutral2,
    },
  },
}))

export default useStyles
