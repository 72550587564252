const palette = {
  primary: '#01153D',
  primary800: '#D0A03A',
  primary50: '#FFF6DA',
  primaryBlack: '#01153D',
  secondary900: '#FFAA00',
  secondary800: '#3A6AD0',
  secondary700: '#426FD1',
  secondary600: '#577FD6',
  secondary500: '#6F92DC',
  secondary100: '#D3DEF4',
  secondary150: '#01153D',
  secondary50: '#00E6F2',
  tertiary900: '#B69E3E',
  tertiary800: '#B9A43C',
  tertiary700: '#BFAE3B',
  tertiary600: '#C6BA39',
  tertiary500: '#D0C63A',
  tertiary100: '#EFEBBA',
  quaternary800: '#050913',
  quaternary700: '#080F21',
  placeholder: '#EEEEF0',
  placeholder20: '#2A4279',
  filter:
    ' brightness(0) saturate(100%) invert(97%) sepia(90%) saturate(4368%) hue-rotate(152deg) brightness(93%) contrast(100%);',
  // states
  breadCrumb: '#ffffff',
  // footer
  accent: '#FD9823',
  chevron: '#828282',
  footerBg: '#262c3c',
  footerGradientPrimary: 'linear-gradient(180deg, #D0C63A 0%, #D0A03A 100%)',
  footerGradientSecondary:
    'linear-gradient(to bottom, #D0A03A 0%, #B9A43C 100%)',
  footerSubTitles: '#E7E7E7',
  gray100: '#f3f3f3',
  gray300: '#C1C1C1',
  gray600: '#414141',
  gray700: '#333333',
  gray800: '#4b4b4b',
  grayLight: '#979797',
  grayDark: '#90918D',
  links: '#70BF45',
  backdrop: 'rgba(0, 0, 0, .4)',
  mainMenuBgColor: '#082454',
  lowDeepBlue700: '#102D60',
  white: '#fff',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(1,1,1,.7)',
    modal: {
      modalBackgroundColor: '#fff',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #00e6f2, #00e6f2)',
      backgroundColorHover: 'linear-gradient(to bottom, #00e6f2, #00e6f2)',
      backgroundColorActive: 'linear-gradient(to bottom, #00e6f2, #00e6f2)',
      textColorActive: '#01153D',
      textColorHover: '#01153D',
      textColor: '#01153D',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #ffaa00, #ffaa00)',
      backgroundColorHover: 'linear-gradient(to bottom, #D0A03A, #B9A43C)',
      backgroundColorActive: 'linear-gradient(to bottom, #ffaa00, #ffaa00)',
      textColorActive: '#01153D',
      textColorHover: '#01153D',
      textColor: '#01153D',
    },
    header: {
      textColor: '#fff',
      bgColor: '#01153D'
    },
    description: {
      gameTitle: '#01153D',
      gameDescription: '#01153D',
      gameVolatilityTitleColor: '#01153D',
      gameVolatility: '#01153D',
    },
    playableBalance: {
      container: {
        textColor: '#01153D',
        backgroundColor: '#D9D9DE',
      },
      balance: {
        textColor: '#007777',
      },
    },
  },
}

export default palette
