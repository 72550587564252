import React from 'react'
import '@websites.common/redirect-btn/dist/redirect-btn.es'


const BackToClassicBtn = ({ url, text }) => {
    return (
        <>
            <redirect-btn url={url} text={text}></redirect-btn>
        </>
    )
}

export default BackToClassicBtn
