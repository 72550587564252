import { makeStyles } from '../components/materialUiWrapper'
import { mq } from '../config/utils'

const useStyles = makeStyles(theme => ({
  popover: {
    '--borderColor': theme.custom.palette.neutral3_9,
    '--detailColor': theme.custom.palette.neutral2_60,
    color: theme.custom.palette.neutral2,

    '& $details': {
      background: theme.custom.summary?.backgroundColor || theme.custom.palette.neutral3_5,
      [mq('lg')]: {
        padding: '1rem',
      },
    },

    '& .MuiSkeleton-root': {
      backgroundColor: theme.custom.palette.primaryBlue || theme.custom.palette.placeholder80,
    },
    '& $regular .MuiSkeleton-root': {
      transform: 'scaleY(0.55)',
    },
    '& $detail .MuiSkeleton-root': {
      transform: 'scaleY(0.6375)',
    },
    '& $total .MuiSkeleton-root': {
      transform: 'scaleY(0.45)',
    },
    '& $itemLabel .MuiSkeleton-root': {
      transform: 'scaleY(0.675)',
    },
  },
  myaccount: {
    '--borderColor': theme.custom.palette.neutral1_9,
    '--detailColor': theme.custom.palette.neutral1_60,
    color: theme.custom.summary?.myaccountColor || theme.custom.palette.neutral1_87,
    '& $details': {
      boxShadow: theme.custom.shadows.output,
      backgroundColor: theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_5,
      [mq('lg')]: {
        padding: '0.5rem 1rem 1rem',
      },
    },

    '& $regular .MuiSkeleton-root': {
      transform: 'scaleY(0.55)',
    },
    '& $detail .MuiSkeleton-root': {
      transform: 'scaleY(0.6375)',

      [mq('lg')]: {
        transform: 'scaleY(0.55)',
      },
    },
    '& $total .MuiSkeleton-root': {
      transform: 'scaleY(0.825)',
    },

    '& $detail': {
      '& $itemLabel': {
        [mq('lg')]: {
          fontSize: theme.custom.fontSize.md,
        },
      },
      '& $itemAmount': {
        [mq('lg')]: {
          fontSize: theme.custom.fontSize.lg,
        },
      },
    },
    '& $regular': {
      '& $itemLabel': {
        '&$button': {
          '& > span': {
            [mq('lg')]: {
              fontSize: theme.custom.fontSize.xl,
            },
          },
        },

        [mq('lg')]: {
          fontSize: theme.custom.fontSize.md,
        },
      },
      '& $itemAmount': {
        [mq('lg')]: {
          fontSize: theme.custom.fontSize.lg,
        },
      },
    },
    '& $total': {
      '& $itemLabel': {
        [mq('lg')]: {
          fontSize: theme.custom.fontSize.xl,
          lineHeight: theme.custom.lineHeight.xs,
        },
      },
      '& $itemAmount': {
        [mq('lg')]: {
          fontSize: theme.custom.fontSize.xxl,
          lineHeight: theme.custom.lineHeight.xs,
        },
      },
    },
  },
  small: {
    '& $details': {
      padding: '0.75rem',
    },
    '& $detail': {
      padding: '0.375rem 0 0',
    },
  },
  large: {},
  details: {
    padding: '0.75rem 0.75rem 1rem',
    borderRadius: theme.shape.borderRadius,

    [mq('lg')]: {
      padding: '1rem',
    },
  },
  aside: {
    padding: '0.5625rem 0.75rem 0',

    [mq('lg')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  item: {
    '& + &:not($detail)': {
      marginTop: '0.6875rem',
      paddingTop: '0.5625rem',
      borderTop: '0.0625rem solid var(--borderColor)',
    },
  },
  detail: {
    padding: '0.25rem 0 0',
    color: theme.custom.summary?.detailColor || 'var(--detailColor, lime)',

    '& $itemLabel': {
      fontSize: theme.custom.fontSize.sm,
      fontWeight: theme.custom.fontWeight.regular,
    },
    '& $itemAmount': {
      fontSize: theme.custom.fontSize.sm,
      fontWeight: theme.custom.fontWeight.regular,
    },
  },
  regular: {
    '& $itemLabel': {
      fontSize: theme.custom.fontSize.sm,
      fontWeight: theme.custom.fontWeight.semibold,
    },
    '& $itemAmount': {
      fontSize: theme.custom.fontSize.md,
      fontWeight: theme.custom.fontWeight.bold,
    },
  },
  total: {
    '& $itemLabel': {
      fontSize: theme.custom.fontSize.md,
      fontWeight: theme.custom.fontWeight.bold,
      lineHeight: theme.custom.lineHeight.md,
    },
    '& $itemAmount': {
      fontSize: theme.custom.fontSize.xl,
      fontWeight: theme.custom.fontWeight.extrabold,
    },
  },
  itemLabel: {
    lineHeight: theme.custom.lineHeight.xs,
  },
  itemAmount: {
    lineHeight: theme.custom.lineHeight.md,
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    border: 0,
    padding: 0,
    color: 'inherit',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'relative',

    '&::before': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: '-0.25rem',
      bottom: '-0.25rem',
      left: '-0.25rem',
      right: '-0.25rem',
    },

    '& > span:not([class])': {
      display: 'flex',
      marginLeft: '0.5rem',
      fontSize: theme.custom.fontSize.md,
    },
  },
}))

export default useStyles
