import { buildCategories, buildCategoryLinks } from '../../../business'
import { formatMessage } from '../../../i18n'
import { isSoftswissIntegrationEnabled } from '../../../config'

export const buildLinks = (categories = [], logout, theme) => ({
  cashier: {
    name: formatMessage('noun:cashier'),
    to: '/cashier',
    icon: 'money',
  },
  myAccount: {
    name: formatMessage('my-account'),
    to: '/my-account',
    icon: 'login',
  },
  categories: isSoftswissIntegrationEnabled() ? buildCategories(categories) : buildCategoryLinks(categories),
  promotions: {
    name: formatMessage('noun:promotions'),
    to: '/promotions',
    icon: 'promotions',
  },
  banking: {
    name: formatMessage('noun:banking'),
    to: '/banking',
    icon: 'money',
  },
  help: {
    name: formatMessage('noun:help'),
    to: theme.custom.brand.help,
    icon: 'help',
  },
  logout: {
    name: formatMessage('action:logout'),
    onClick: logout,
    icon: 'logout',
    to: '/'
  },
})
