import React from 'react'
import PropTypes from 'prop-types'

//Utils
import Icon from '../Icon'
import { countChildren } from '../../../libs/utils'
import Glide from '@glidejs/glide'

//Hooks
import { useState, useEffect, useRef, useCallback } from 'react'
import useStyles from './generalSlider.styles'

const GeneralSlider = ({
  element = 'glide',
  options,
  children,
  styles,
  useControls,
}) => {
  const classes = useStyles()

  const [controls, setControls] = useState({ prev: false, next: false })

  const sliderNode = useRef(null)
  const glide = useRef(null)

  // NOTE this behivor of control prev and next page is added on glide v3.6v ()
  const childrenCount = countChildren(children)
  const index = glide.current?.index ?? 0
  const perView = glide?.current?.settings?.perView ?? 0
  const prevPage = Math.max(0, index - glide?.current?.settings?.perView)
  const nextPage = Math.min(
    Math.max(childrenCount - perView, 0),
    index + perView,
  )

  const lastPage = Math.max(0,childrenCount - perView)


  const updateControls = useCallback(() => {
    if (!glide.current) return
    setControls({
      prev: glide.current.index > 0,
      next:
        glide.current.index + glide.current?.settings?.perView < childrenCount,
    })
  }, [childrenCount])

  useEffect(() => {
    glide.current = new Glide(sliderNode.current, options)
    glide.current.on(['mount.after', 'move', 'resize'], updateControls)
    glide.current.mount()

    return () => glide.current.destroy()
  }, [childrenCount, options, updateControls])

  return (
    <>
    <div
      className={`${element} ${classes.container}`}
      style={styles?.container}
      ref={sliderNode}
    >
      <div
        className={`glide__track ${classes.glideTrack}`}
        style={styles?.glideTrack}
        data-glide-el="track"
      >
        <ul
          className={`glide__slides ${classes.glideSlides}`}
          style={styles?.glideSlides}
        >
          {children.map((slide, index) => {
            return React.cloneElement(slide, {
              key: index,
              className: `glide__slide ${classes.glideSingleSlide}`,
              style: styles?.glideSingleSlide,
            })
          })}
        </ul>
      </div>
      {useControls && (
        <div
          className={`glide__arrows ${classes.glideArrows}`}
          style={styles?.glideArrows}
          data-glide-el="controls"
        >
          <button
            data-glide-dir={`=${controls.prev? prevPage : lastPage}`}
            className={`${classes.glideArrowLeft} ${classes.arrow}`}
            style={{ ...styles?.glideArrowLeft, ...styles?.arrow }}
          >
             <Icon name="chevron-left" />
          </button>

          <button
            data-glide-dir={`=${controls.next? nextPage : 0 }`}
            className={`${classes.glideArrowRight} ${classes.arrow}`}
            style={{ ...styles?.glideArrowRight, ...styles?.arrow }}
          >
             <Icon name="chevron-right" />
          </button>
        </div>
      )}
    </div>
    </>
  )
}

GeneralSlider.propTypes = {
  element: PropTypes.string,
  useControls: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  options: PropTypes.object.isRequired,
  styles: PropTypes.object,
}

export default GeneralSlider
