import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { parse } from 'qs'

import { useAuth } from '../authentication'
import { getTopLevelDomain } from '../libs/utils'
import { REFERRAL_BONUS_TYPES } from '../business'

const COOKIE_NAMES = { RAF: 'RAF', AFFILIATE: 'IncomeAccess' }
const COOKIE_CONFIG = {
  expires: 90,
  domain: getTopLevelDomain(),
  sameSite: 'None',
  secure: true,
}

const useReferralLink = () => {
  const { initialized, logged, redirectToJoin } = useAuth()
  const location = useLocation()
  const query = parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (initialized && !logged && query.RAF) {
      const cookie = `${query.RAF}&product=${REFERRAL_BONUS_TYPES.CASINO}`
      Cookies.remove(COOKIE_NAMES.AFFILIATE, { domain: getTopLevelDomain() })
      Cookies.set(COOKIE_NAMES.RAF, cookie, COOKIE_CONFIG)
    }
  }, [initialized, logged, redirectToJoin, query.RAF])

  useEffect(() => {
    if (initialized && !logged && query.btag && query.affid) {
      const cookie = `btag=${query.btag}&affid=${query.affid}`
      Cookies.remove(COOKIE_NAMES.RAF, { domain: getTopLevelDomain() })
      Cookies.set(COOKIE_NAMES.AFFILIATE, cookie, COOKIE_CONFIG)
    }
  }, [initialized, logged, redirectToJoin, query.btag, query.affid])
}

export default useReferralLink
