import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import keycloak from './keycloak'
import useAuth from './useAuth'

import useReferralLink from '../hooks/useReferralLink'

const RedirectToLogin = ({ children }) => {
  const { initialized, logged, username } = useAuth()
  const history = useNavigate()
  useReferralLink()

  useEffect(() => {
    if (initialized) {
      if (logged) history('/')
      else {
        keycloak.login({ redirectUri: window.location.origin })
      }
    }
  }, [initialized, logged, history])

  return children
}

export default RedirectToLogin
