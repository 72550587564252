import React from 'react'

import Skeleton from '../Skeleton'
import useStyles from './heading.styles'

const HeadingSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.heading}>
      <Skeleton width={136} height={20} />
    </div>
  )
}

export default HeadingSkeleton
