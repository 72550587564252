import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Box as Spacing } from '@material-ui/core'
import ErrorNote from '../ErrorNote'
import useStyles from './form.styles'

const FormField = ({
  helperText,
  errorText,
  hasError,
  icon,
  children,
  hasSelect,
}) => {
  const classes = useStyles()

  const formFieldClasses = cx(
    classes.formField,
    hasError && classes.hasError,
    icon && classes.hasIcon,
    hasSelect && classes.formSelectWrapper,
  )

  return (
    <div className={formFieldClasses}>
      {children}
      {errorText && hasError && (
        <Spacing mt={0.5}>
          <ErrorNote>{errorText}</ErrorNote>
        </Spacing>
      )}
      {helperText && <span className={classes.helperText}>{helperText}</span>}
    </div>
  )
}

FormField.propTypes = {
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  hasError: PropTypes.bool,
  hasSelect: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
}

export default FormField
