import { makeStyles } from '../../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  
  enabled: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '0',
    opacity: 0,
    pointerEvents: 'none'
  },
  disabled: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1000',
    opacity: 1,
    pointerEvents: 'auto'
  },
  shadow: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '302',
    background: theme.custom.palette.neutral3,
    opacity: '0.9',
    borderRadius: '4px',
  },
  plug: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    zIndex: '304',
  },
}))
export default useStyles
