import { deviceBrandId } from '../../../ui/config/deviceUtils'
import { createRequester } from '../requester'
import { SortAndFilterOptionsProps } from './types'

const createSortAndFilterOptionsService = () => {
  const requester = createRequester('card-categories/sort-filter-options')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const getSortAndFilterOptionsService = createSortAndFilterOptionsService()

const getSortAndFilterOptions = async (
  parameters: SortAndFilterOptionsProps,
) => {
  const requester = getSortAndFilterOptionsService
  return await requester.request({ ...parameters })
}

export { getSortAndFilterOptions }
