const palette = {
  primary500: '#f83c9b',
  primary600: '#e81a78',
  primary800: '#a70d4d',
  primary50: '#fef1f8',
  secondary900: '#5b297a',
  secondary800: '#703198',
  secondary700: '#8536ba',
  secondary600: '#9b49d4',
  secondary500: '#b068e4',
  secondary100: '#f4ebfc',
  secondary50: '#fbf6fe',
  tertiary800: '#934f0d',
  tertiary700: '#b56708',
  tertiary750: '#b56708',
  tertiary600: '#db9004',
  tertiary500: '#b068e4',
  tertiary100: '#fef9c7',
  quaternary: '#050913',
  quaternary800: '#ffffff',
  quaternary700: '#ffffff',
  quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  quaternary700_0: 'rgba(18, 18, 18, 0)',
  neutral900: '#3a3a40',
  neutral300: '#b8b9c1',
  neutral200: '#d9d9d3',
  neutral50: '#f7f7f8',
  placeholder: '#EEEEF0',
  placeholder20: '#3d3d3d',
  placeholder80: '#d6d6d6',
  primaryBlack: '#050913',
  gray: '#363535',
  grayLight: '#979797',
  grayLightSecondary: '#90918D',
  sbDarkGraphite: '#fff',
  links: '#f83c9b',
  white: '#ffffff',
  black: '#000000',
  accent: '#f83c9b',
  chevron: '#828282',
  btnPrimary: '#f83c9b',
  footerBg: '#EEEEF0',
  footerGray: '#717171',
  footerSubTitles: '#E7E7E7',
  footerGradientPrimary: '#f83c9b',
  footerGradientSecondary: 'linear-gradient(to bottom, #a70d4d, #e81a78)',
  btnSecondary: '#8536ba',
  // state
  breadCrumb: '#050913',
  primaryBlue: '#ffffff',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(255,255,255,0.7)',
    modal: {
      modalBackgroundColor: '#fff',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #8536ba, #8536ba)',
      backgroundColorHover: 'linear-gradient(to bottom, #703198, #703198)',
      backgroundColorActive: 'linear-gradient(to bottom, #8536ba, #8536ba)',
      textColorActive: '#FFF',
      textColorHover: '#FFF',
      textColor: '#FFF',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #f83c9b, #f83c9b)',
      backgroundColorHover: 'linear-gradient(to bottom, #e81a78, #e81a78)',
      backgroundColorActive: 'linear-gradient(to bottom, #f83c9b, #f83c9b)',
      textColorActive: '#fff',
      textColorHover: '#fff',
      textColor: '#fff',
    },
    header: {
      textColor: '#fff',
      bgColor: '#f83c9b'
    },
    description: {
      gameTitle: '#050913',
      gameDescription: '#050913',
      gameVolatilityTitleColor: '#050913',
      gameVolatility: '#050913',
    },
    playableBalance: {
      container: {
        textColor: '#fff',
        backgroundColor: '#3a3a40',
      },
      balance: {
        textColor: '#fff',
      },
    },
  },
}

export default palette
