import { useEffect, useRef } from 'react'

const useUrlChange = (eventName: string = 'urlChange') => {
    const prevUrl = useRef<string>(window.location.pathname)

    const handlerUrlChange = (): void => {
        const currentUrl: string = window.location.pathname
        if (prevUrl.current !== currentUrl) {
            const event = new CustomEvent(eventName, {
                detail: currentUrl,
            })
            window.dispatchEvent(event)
            prevUrl.current = currentUrl
        }
    }

    useEffect(() => {
        const originalPushState = window.history.pushState
        const originalReplaceState = window.history.replaceState

        window.history.pushState = function (data: any, title: string, url?: string | URL | null): void {
            originalPushState.apply(this, arguments as any)
            handlerUrlChange()
        }

        window.history.replaceState = function (data: any, title: string, url?: string | URL | null): void {
            originalReplaceState.apply(this, arguments as any)
            handlerUrlChange()
        }

        window.addEventListener('popstate', handlerUrlChange)

        return () => {
            window.removeEventListener('popstate', handlerUrlChange)
            window.history.pushState = originalPushState
            window.history.replaceState = originalReplaceState
        }
    }, [eventName])

}

export { useUrlChange } 