import React from 'react'
import PropTypes from 'prop-types'
import { QueryClientProvider, QueryClient } from 'react-query'

import { logout, isLogged } from '../authentication'

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: error => {
          if (error === 401 && isLogged()) {
            logout(window.location.origin)
          }
        },
      },
    },
  })

const QueryProvider = ({ children }) => {
  const queryClient = createQueryClient()

  // NOTE do not retry CMS queries if endpoints are not enabled or empty
  queryClient.setQueryDefaults(['cms'], {
    retry: (failureCount, error) => {
      if (error === 404 || error === 403) return false
      return failureCount < 3 // NOTE default react-query retry limit
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

QueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default QueryProvider
