const palette = {
  //custom for bolsac
  sideMenu: '#2D2E2C',
  green300: '#add64c',
  green: '#8AC500',
  green700: '#6E9D00',
  btnSecondary: '#4B4B4B',
  tagBgNew: '#EE3536',
  primaryBlack: '#2D2E2C',
  primary: '#EE3536',
  primary700: '#BE2A2B',
  cardSubtitle: '#1C1D1C',
  hoverBtn: '#2D2E2C',
  disabledBtnText: '#90918D',
  cardHover: 'rgba(151, 151, 151, 0.5)',
  inputBg: '#dcdcdc',
  backdrop: 'rgba(0, 0, 0, .4)',
  gray: '#90918d',
  xpGreen: '#5B7916',
  xpLightGreen: '#8AC500',

  //
  tableBg: '#5F6D7D33',
  gray100: '#f3f3f3',
  gray700: '#333333',
  gray800: '#4b4b4b',
  gray900: '#2D2E2C',
  white: '#FFFFFF',
  primary800: '#FCC400',
  primary50: '#e6f6ec',
  secondary900: '#0087F6',
  secondary700: '#0087F6',
  secondary600: '#f88d20',
  secondary500: '#003B4D', //BETNAVY
  secondary800: '#0087F6',
  secondary100: '#fee0b5',
  secondary50: '#fff3e1',
  secondary150: '#2D2E2C',
  tertiary800: '#FCC400',
  tertiary700: '#4e9b33',
  tertiary600: '#61af3d',
  tertiary500: '#003b4d',
  tertiary400: '#005974',
  tertiary100: '#d4ecc7',
  tertiary900: '#239cff',
  quaternary800: '#EE3536',
  quaternary700: '#E7E7E7',
  quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  quaternary700_0: 'rgba(18, 18, 18, 0)',
  placeholder: '#2D2E2C',
  placeholder20: '#3d3d3d',
  placeholder80: '#d6d6d6',
  filter:
    'invert(40%) sepia(75%) saturate(1500%) hue-rotate(328deg) brightness(93%) contrast(109%)',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.5)',
    modal: {
      modalBackgroundColor: '#333333',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #8AC500, #8AC500)',
      backgroundColorHover: 'linear-gradient(to bottom, #ADD64C, #ADD64C)',
      backgroundColorActive: 'linear-gradient(to bottom, #6E9D00, #6E9D00)',
      textColorActive: '#FFF',
      textColorHover: '#FFF',
      textColor: '#FFF  ',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #ffdf00, #ff8800)',
      backgroundColorHover: 'linear-gradient(to bottom, #ff8800, #ff9500)',
      backgroundColorActive: 'linear-gradient(to bottom, #fff8e1, #fefbbf)',
      textColorActive: '#ff8800',
      textColorHover: '#3a0b4a',
      textColor: '#3a0b4a',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255,.87)',
      gameDescription: 'rgba(255,255,255, .6)',
      gameVolatilityTitleColor: 'rgba(255,255,255,.87)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#979797',
        backgroundColor: '#363535',
      },
      balance: {
        textColor: '#539cff',
      },
    },
  },
}

export default palette
