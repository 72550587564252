import React, { ReactNode } from 'react'
import Cookies from 'js-cookie'
import { RequestFilters } from '../asyncData/bff/sort-and-filter-options/types'

export const toKebabCase = str => str.toLowerCase().replace(/ /g, '-')

export const capitalize = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join('')

// NOTE for development purpose only, in order to wait before returning a mock
// for a request
export const timeout = (t = 2000) =>
  new Promise(resolve => setTimeout(resolve, t))

/**
 * getTopLevelDomain
 */
const COOKIE_NAME = 'getTopLevelDomain'
export const getTopLevelDomain = (hostname = window.location.hostname) => {
  // const parts = window.location.hostname.split('.')
  const parts = hostname.split('.')

  for (let i = parts.length - 1; i >= 0; i--) {
    const host = parts.slice(i).join('.')
    Cookies.set(COOKIE_NAME, COOKIE_NAME, { domain: host })
    if (Cookies.get(COOKIE_NAME)) {
      Cookies.remove(COOKIE_NAME, { domain: host })
      return host
    }
  }
}

export const isLiveCasinoMobile = (path, isMobile) => {
  return (
    (path.match(/live\-casino\/red/g) !== null ||
      path.match(/live\-casino\/black/g) !== null) &&
    isMobile
  )
}
/**
 * childrenToArray – Convert react children to an array. Works for a single
 * child. Omits children that will not be rendered (null, undefined, false).
 * Flattens children inside fragments
 * REVIEW we are calling Array.prototype.flat() once in every recursive call
 * @param {React.ReactNode} children - Children object as provided by react
 * @param {Boolean} flatten - Whether or not to flatten children inside
 * fragments. Defaults to true
 * @return {Array} An array of react elements
 */
const childrenToArray = (children: ReactNode | ReactNode[], flatten: boolean = true) => {
  const array = React.Children.toArray(children)
  if (flatten) {
    return array
      .map((child: React.ReactNode) =>
        React.isValidElement(child) && child.type === React.Fragment
          ? childrenToArray(child.props.children, flatten)
          : child,
      )
      .flat()
  }
  return array
}

/**
 * countChildren – Count the number of children. Omits children that will not be
 * rendered (null, undefined, false). Flattens children inside fragments
 * @param {React.ReactNode} children - Children object as provided by react
 * @param {Boolean} flatten - Whether or not to flatten children inside
 * fragments. Defaults to true
 * @return {Number} The actual number of children
 */
export const countChildren = (children, flatten = true) =>
  React.Children.count(childrenToArray(children, flatten))

/**
 * mapChildren – Map over all children, invoking a callback with any of them.
 * Omits children that will not be rendered (null, undefined, false). Flattens
 * children inside fragments
 * @param {React.ReactNode} children - Children object as provided by react
 * @param {Function} fn - Callback that will be called with every child
 * @param {Boolean} flatten - Whether or not to flatten children inside
 * fragments. Defaults to true
 * @return {Array} An array with the results of calling fn with every child in
 * children
 */
export const mapChildren = (children, fn, flatten = true) =>
  React.Children.map(childrenToArray(children, flatten), fn)
 
export const querryPathCapitalize = (text) => {
    return text.toLowerCase()
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
  }


export function getFilterValues(data: Array<RequestFilters>) {
  if(!data) return [];
  const result = data.reduce((acc, filter) => {
    const checkedItems = filter.listItems.filter((item) => item.checked);
    if (filter.checked || checkedItems.length > 0) {
      if(!filter.isExpandable){
        acc.push({
          id: filter.filterId,
          name: filter.name
        });
      }
      if(checkedItems.length > 0) {
        checkedItems.map((checkedItem) => {
          return acc.push({
            id: checkedItem.id,
            name: checkedItem.name
          });
        })
      }
      
    }
    return acc;
  }, []);

  return result;
}

export const dataLayerFilterValues = (filters: Array<RequestFilters>) => {
  if(!filters) return [];

  return filters.reduce((acc, filter) => {
    const checkedItems = filter.listItems.filter((item) => item.checked);
    if (filter.checked || checkedItems.length > 0) {
      let checkedFilters = []
      checkedItems.forEach((checkedItem) => checkedFilters.push(checkedItem.name))

      acc.push({
        name: filter.name,
        filterValues: checkedFilters
      });
    }
    return acc;
  }, []);
}

export const sendFilterValues = (filters: Array<RequestFilters>) => {
  if(!filters) return [];
  return filters.reduce((acc, filter) => {
    const checkedItems = filter.listItems.filter((item) => item.checked);
    if (filter.checked || checkedItems.length > 0) {
      let checkedFilters = []
      checkedItems.forEach((checkedItem) => checkedFilters.push(checkedItem.id))

      acc.push({
        filterId: filter.filterId,
        filterValues: checkedFilters
      });
    }

    return acc;
  }, []);
};