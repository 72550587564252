import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'

const useStyles = makeStyles(theme => ({
  emptyMessage: {
    color: theme.custom?.emptyPages?.textColor || theme.custom.palette.neutral1_60,
    fontFamily: theme.custom?.fontFamily?.primaryFont,
    textAlign: 'center',    
    '& svg': {
      '& g':{
        fill: theme.custom?.emptyPages?.iconColor,
      },
      display: 'block',
      margin: '0 auto 1rem',
    },
    '& img': {
      display: 'block',
      margin: '0 auto 1rem',
    },
  },
  filled: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.custom.palette.neutral1_5,
    boxShadow: theme.custom.shadows.output,
  },
  minimal: {
    backgroundColor: 'transparent',
    fontSize: theme.custom?.fontSize?.md,
  },
  small: {
    padding: '1.5rem 1.25rem',
  },
  medium: {
    padding: '3.5rem 0 4.5rem 0',
  },
  title: {
    ...texts.baseline,
    fontWeight: theme.custom.fontWeight.extrabold,
  },
  description: {
    ...texts.baseline,
  },
  action: {
    marginTop: '1.5rem',
  },
}))

export default useStyles
