import { useQuery, useMutation } from 'react-query'

import { useAuth } from '../../authentication'
import {
  fetchFavoriteGames,
  addFavoriteGame,
  deleteFavoriteGame,
} from './services'
import useGTMTracking from '../../hooks/useGTMTracking'
import { useIsBrand } from '../../hooks/useIsBrand'

export const useFavorites = options => {
  const { logged, username } = useAuth()
  const { isQBC, isLV } = useIsBrand()

  const {
    isLoading: isLoadingFavorites,
    isSuccess: isSuccessFavorites,
    error: errorFavorites,
    data: favorites,
    refetch: refetchFavorites,
    remove: removeFavoritesCache,
  } = useQuery(['favorites', username], fetchFavoriteGames, {
    enabled: isQBC() || isLV() ? false : logged, //TODO: remove isQBC and isLV condition when a global sac implementation have set
    ...options,
  })
  const { pushDataLayer} = useGTMTracking();
  const {
    mutate: add,
    isError: addFavoriteError,
    reset: resetAddFavorite,
  } = useMutation(data => addFavoriteGame(data.GameID, data.ExtGameID), {
    onSuccess: (data, variables) => {
      refetchFavorites();
      pushDataLayer({
        'event': 'game_added_to_favorite',
        'game_id': variables.GameID,
        'ext_game_id': variables.ExtGameID,
        'origin': variables.origin,
        'provider_id': variables.ProviderID
      });
    }, // NOTE update favorites after toggling
  })

  const addFavorite = (props) => {
    console.log('error', errorFavorites || addFavoriteError || deleteFavoriteError);
    add(props);
  }
  const {
    mutate: remove,
    isError: deleteFavoriteError,
    reset: resetDeleteFavorite,
  } = useMutation(data => deleteFavoriteGame(data.GameID, data.ExtGameID), {
    onSuccess: (data, variables) => {
      refetchFavorites();
      pushDataLayer({
        'event': 'game_removed_from_favorite',
        'game_id': variables.GameID,
        'ext_game_id': variables.ExtGameID,
        'origin': variables.origin,
        'provider_id': variables.ProviderID
      });
    }, // NOTE update favorites after toggling
  })

  const deleteFavorite = (props) => {
    remove(props);
  }

  return {
    favorites,
    isLoadingFavorites,
    isSuccessFavorites,
    errorFavorites,
    addFavorite,
    addFavoriteError,
    resetAddFavorite,
    deleteFavorite,
    deleteFavoriteError,
    resetDeleteFavorite,
    refetchFavorites,
    removeFavoritesCache,
  }
}
