import { useState, useEffect } from 'react'

const useGraphyte = () => {
  const graphyte = window.graphyte

  const getAnonymousId = async () => {
    if (graphyte && graphyte.user) {
      let user = await graphyte?.user()
      if(user){
        let id = user?.anonymousId()
        return id
      }
    }
  }

  /**
   * Sends event tracking of user clicking sub-categories to Clickstream API.
   * @param {object} props {category:string, name:string} Category (lower case of name) and name of the category.
   */
  const sendPageViewTrack = props => {
    const { category, name } = props
    if (typeof graphyte?.page === 'function') {
      graphyte.page(category, name, null)
    }
  }

  /**
   * Sends event tracking of user when clicking a game to Clickstream API.
   * @param {object} props
   */
  const sendGamePlayTrack = props => {
    if (typeof graphyte?.page === 'function') {
      graphyte.track('GAME_PLAY', { ...props }, null, () => {
        if (getLocalGraphyteValue) {
          localStorage.removeItem('graphyteLocalValue')
        }
      })
    }
  }

  const setLocalGraphyteValue = props => {
    localStorage.setItem('graphyteLocalValue', props)
  }

  const getLocalGraphyteValue = () => localStorage.getItem('graphyteLocalValue')

  return {
    sendPageViewTrack,
    sendGamePlayTrack,
    getLocalGraphyteValue,
    setLocalGraphyteValue,
    graphyte,
    getAnonymousId,
  }
}

export default useGraphyte
