import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '../../i18n'

import Skeleton from '../components/Skeleton'
import ErrorNote from '../components/ErrorNote'
import Summary from './Summary'
import SummaryItem from './SummaryItem'

const SummaryPopover = props => {
  const {
    isLoading,
    isLoadingBonus,
    hasError,
    totalBalance,
    cashBalance,
    bonusBalance,
    lockedCash,
    bonus,
    promotionalBonus,
    onErrorClick,
    onPromotionalClick,
    onBonusClick,
  } = props
  const formatMessage = useTranslations()
  const bonusRef = useRef()
  const promotionalRef = useRef()

  return (
    <Summary
      variant="popover"
      aside={
        isLoadingBonus || hasError || promotionalBonus > 0 ? (
          <SummaryItem
            hasError={
              hasError ? (
                <ErrorNote>
                  {formatMessage('bonus-wallet:bonus-error', {
                    cta: formatMessage => (
                      <button onClick={onErrorClick}>{formatMessage}</button>
                    ),
                  })}
                </ErrorNote>
              ) : null
            }
            onClick={onPromotionalClick}
            hideInfo={isLoadingBonus}
            ref={promotionalRef}
            label={
              isLoadingBonus ? (
                <Skeleton width={156} />
              ) : (
                formatMessage('noun:promotional-bonus')
              )
            }
            amount={isLoadingBonus ? <Skeleton width={72} /> : promotionalBonus}
          />
        ) : null
      }
    >
      <SummaryItem
        label={formatMessage('noun:cash-balance')}
        amount={isLoading ? <Skeleton width={96} /> : cashBalance}
      />
      <SummaryItem
        ref={bonusRef}
        onClick={onBonusClick}
        label={formatMessage('noun:bonus-balance')}
        amount={isLoading ? <Skeleton width={72} /> : bonusBalance}
      />
      <SummaryItem
        type="detail"
        label={formatMessage('noun:locked-cash-funds')}
        amount={isLoading ? <Skeleton width={72} /> : lockedCash}
      />
      <SummaryItem
        type="detail"
        label={formatMessage('noun:bonus-funds')}
        amount={isLoading ? <Skeleton width={72} /> : bonus}
      />
      <SummaryItem
        type="total"
        label={formatMessage('noun:total-balance')}
        amount={isLoading ? <Skeleton width={120} /> : totalBalance}
      />
    </Summary>
  )
}

SummaryPopover.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingBonus: PropTypes.bool,
  hasError: PropTypes.bool,
  totalBalance: PropTypes.number.isRequired,
  cashBalance: PropTypes.number.isRequired,
  bonusBalance: PropTypes.number.isRequired,
  lockedCash: PropTypes.number.isRequired,
  bonus: PropTypes.number.isRequired,
  promotionalBonus: PropTypes.number,
  onErrorClick: PropTypes.func,
  onBonusClick: PropTypes.func,
  onPromotionalClick: PropTypes.func,
}

export default SummaryPopover
