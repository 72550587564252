import useStyles from './gameCardOverlay.styles'
import playOverlaySvg from '../../../assets/icons/play-overlay.svg?url'

const PlayOverlay = ({className}) => {
  return <img src={playOverlaySvg} className={className} alt="no-results"></img>
}

interface CardOverlayTypes {
  gameTitle: string,
  size: 'lg' | 'xl',
}

const GameCardOverlay = ({ gameTitle, size }: CardOverlayTypes) => {
  const classes = useStyles({size})

  return (
    <div 
      className={classes.overlay}
    >
      <div className={classes.content}>
        <PlayOverlay className={size === 'lg' ? classes.iconlg : classes.iconxl } />
        <p>{ gameTitle }</p>
      </div>
    </div>
  )
}

export default GameCardOverlay