import React from 'react'
import MobileActions from '../../../ui/components/GlobalHeader/MobileActions'
import Button from '../../../ui/components/Button'
import { useAuth } from '../../../authentication'
import { useTranslations } from '../../../i18n'

const DefaultMobileActions = () => {
  const formatMessage = useTranslations()

    const {
      initialized,
      logged,
      redirectToJoin,
      redirectToLogin,
    } = useAuth()
    
  return (
        <MobileActions>
          <Button
            disabled={!initialized}
            variant="secondary"
            height="small"
            onClick={redirectToJoin}
          >
            {formatMessage('action:join-now')}
          </Button>
          <Button
            disabled={!initialized}
            height="small"
            onClick={redirectToLogin}
          >
            {formatMessage('action:login')}
          </Button>
        </MobileActions>
  )
}

export default DefaultMobileActions