import { BehaviorSubject } from 'rxjs'
import { formatDate } from './utils'
import { UserBalanceAction, UserBalanceActions } from './interfaces'

const handler = {
  [UserBalanceActions.INITIALIZE]: (state, action) => {
    return {
      ...state,
      userData: new BehaviorSubject({}),
      balances: new BehaviorSubject({}),
      totalBalance: 0
    }
  },
  [UserBalanceActions.UPDATE_USER_DATA]: (state, action) => {
    const { CustomerID, FirstName } = action.payload
    state.userData.next({
      name: FirstName,
      customerId: CustomerID,
    })
    return {
      ...state,
    }
  },
  [UserBalanceActions.UPDATE_BALANCE]: (state, action) => {
    const { payload } = action
    state.balances.next({
      totalBalanceAmount: payload?.TotalBalance,
      cashBalanceAmount: payload?.AvailableBalance,
      freeplayAmount: payload?.FreePlayBalance,
      hasBonusAllowed: payload?.IsBonusWalletAllowed,
      bonusAmount: payload?.BonusMoneyBalance,
      pendingBetsAmount: payload?.PendingBets,
      LockedCash: payload?.LockedCash,
      BonusFunds: payload?.BonusFunds,
      RealBalance: payload?.RealBalance
    })
    return {
      ...state,
      totalBalance: payload?.TotalBalance
    }
  },
  [UserBalanceActions.UPDATE_VIP_DATA]: (state, action) => {
    const { payload } = action
    const { vipData, isLoading, isError } = payload
    const UpdatedAt = vipData?.CurrentLevelName === null ? undefined : formatDate(vipData?.UpdatedAt);
    state.vipData.next({ level: vipData?.CurrentLevelName, ...vipData, isLoading, isError, UpdatedAt })

    return {
      ...state,
    }
  },
}

export const userBalanceReducer = (state, action: UserBalanceAction) =>
  handler[action?.type] ? handler[action.type](state, action) : state

