import { makeStyles } from '../../../../ui/components/materialUiWrapper'

const useModalStyles = makeStyles(
  theme => ({
    
    scrollContainer: {
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      transform: 'translate3d(0, -50%, 0)',
      transformOrigin: '50% 50%',
      maxWidth: 'min(95%, 680px)',
    },
  }),
  { name: 'MuiInfoModal' },
)

export default useModalStyles
