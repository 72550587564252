import React, { useState, useCallback, useEffect } from 'react'
import { Box as Spacing, useTheme } from '@material-ui/core'
import { useNavigate, useLocation } from 'react-router-dom'
import { parse } from 'qs'
import Skeleton from '../../../ui/components/Skeleton'
import Button from '../../../ui/components/Button'
import {
  useFavorites,
  useGameUrl,
  useGetGameDetail,
  usePlayableBalance,
} from '../../../asyncData'
import { getToken, isLogged, useAuth } from '../../../authentication'
import { useTranslations } from '../../../i18n'
import useGraphyte from '../../../hooks/useGraphyte/useGraphyte'
import EventBuilderGraphyte from '../../../hooks/useGraphyte/eventBuilder'
import useDevice from '../../../hooks/useDevice'
import { usePlayContext } from '../PlayContext'
import GameInfoModal from './View'
import GameInfo from './GameInfo'
import GameInfoSkeleton from './GameInfoSkeleton'
import { useEnvData } from '../../../hooks/useEnvData'
import { isBalanceAlertEnabled } from '../../../config'
import useGTMTracking from '../../../hooks/useGTMTracking'
import { isScrollEnabled } from './utils'

// TODO: The Component is not being used because VITE_USE_NEW_GAME_DETAIL env is set to true in all SACs.
const GameInfoModalContext = () => {
  const { gameData, setGameData, openGameInIframe } = usePlayContext()
  const formatMessage = useTranslations()
  const history = useNavigate()
  const theme = useTheme()
  const userToken = getToken()
  const {
    initialized,
    logged,
    redirectToLogin,
    username,
    customerSub,
  } = useAuth()
  const customerId = username || null
  const location = useLocation()
  const query = parse(location.search, { ignoreQueryPrefix: true })
  const [error, setError] = useState(false)
  const enableBalanceAlert = isBalanceAlertEnabled() && isLogged()
  const { pushDataLayer } = useGTMTracking()
  const {
    isLoadingFavorites,
    errorFavorites,
    favorites,
    addFavorite,
    addFavoriteError,
    resetAddFavorite,
    deleteFavorite,
    deleteFavoriteError,
    resetDeleteFavorite,
    removeFavoritesCache,
  } = useFavorites()

  const isModalOpen =
    Boolean(query.gameId) &&
    Boolean(query.extGameId) &&
    (gameData === null || !openGameInIframe)


  const {
    data,
    isError: gameError,
    isLoading: isLoadingGame,
    isFetching,
    isPreviousData,
    remove: removeGameDetailCache,
  } = useGetGameDetail(query.gameId, query.ProviderID, query.extGameId, {
    enabled: isModalOpen,
    // NOTE used to keep the information while closing the drawer on mobile
    keepPreviousData: true,
  })

  const params = {
    gameId: data?.GameID,
    extGameId: data?.ExtGameID,
    providerId: data?.ProviderID,
    returnUrl: window.location.origin + window.location.pathname,
    bonusAccountId: location.state?.bonus,
    parentCategoryName: data?.parentCategoryName,
    categoryName: data?.categoryName,
    customerId,
    customerSub,
    userToken,
  }
  const isGameUrlEnabled =
    !isPreviousData && !data?.casinoSuspended && logged && Boolean(data)

  const {
    isLoading: isLoadingUrl,
    error: urlError,
    data: urlData,
  } = useGameUrl(params, data, {
    enabled: isGameUrlEnabled,
  })

  const favorited =
    logged &&
    data &&
    favorites &&
    favorites?.FavoritesGames.some(game => game.GameID === data.GameID)

  const handleFavorite = useCallback(
    () =>
      favorited
        ? deleteFavorite({ ...query, ...data })
        : addFavorite({ ...query, ...data }),
    [favorited, deleteFavorite, query, data, addFavorite],
  )
  const { isGraphyteEnabled } = useEnvData()
  const { sendGamePlayTrack } = useGraphyte()
  const { getUserDevice } = useDevice()
  const gameClicked = () => {
    pushDataLayer({
      event: 'play_now_clicked',
      game_id: data.GameID,
      ext_game_id: data.ExtGameID,
      origin: query.origin,
      provider_id: data.ProviderID,
    })
    const eventGraphyte = EventBuilderGraphyte(
      data,
      customerId,
      query?.origin,
      getUserDevice(),
    )
    if (isGraphyteEnabled) {
      sendGamePlayTrack(eventGraphyte)
    }
  }

  const handlePlay = useCallback(() => {
    gameClicked()
    if (!urlData) return

    if (!openGameInIframe) {
      window.location.assign(urlData.Url || urlData.launchURL)
      return
    }

    setGameData({
      url: urlData.Url || urlData.launchURL,
      name: data.GameName,
      GameID: data.GameID,
      ExtGameID: data.ExtGameID,
      isScrollEnabled: isScrollEnabled(urlData),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlData, data, setGameData, openGameInIframe])

  const handleClose = () => {
    // NOTE clean up mutation errors, if any
    if (addFavoriteError) resetAddFavorite()
    if (deleteFavoriteError) resetDeleteFavorite()
    // NOTE keep game error, as it will be removed after updating current path
    if (gameError) setError(true)
    // NOTE clean up others errors only after the modal is closed
    setTimeout(() => {
      // WARNING error is still defined here because of clousure, be careful
      // if turning this into a callback hook
      if (gameError) {
        removeGameDetailCache()
        setError(false)
      }
      if (errorFavorites) removeFavoritesCache()
    }, theme.transitions.duration.leavingScreen)

    history(location.pathname, { replace: true })
  }

  function checkErrorBefore() {
    if (errorFavorites || addFavoriteError || deleteFavoriteError) {
      return formatMessage('game-info:favorite:error-message')
    } else {
      return undefined
    }
  }

  function checkErrorAfter() {
    if (data?.casinoSuspended) {
      return formatMessage('game-info:casino-suspended:error-message')
    }
    if (urlError) {
      return formatMessage('game-info:url:error-message')
    }
    return undefined
  }

  const hasData = data && !isPreviousData // current data is ready
  const isClosing = isPreviousData && !isFetching // drawer is being closed
  const isLoading = !initialized || isLoadingGame || isLoadingUrl
  const isError = gameError || error
  const errorBefore = checkErrorBefore()
  const errorAfter = checkErrorAfter()

  const {
    data: playableBalanceData,
    isLoading: isLoadingPlayableBalance,
    isError: playableBalanceError,
  } = usePlayableBalance(data?.CatalogGameID, data?.ProviderID, isModalOpen)

  const myBonusLocation =
    theme.custom.bonusTable?.routePath || '/my-account/my-bonus'

  function retrieveModalTitle() {
    if (hasData || isClosing) {
      return data?.GameName
    }
    if (isError) {
      return ''
    }
    return <Skeleton width={136} height={20} />
  }

  const modalTitle = retrieveModalTitle()

  function showGameInfo() {
    return !isError && (hasData || isClosing)
  }

  const handleClickAction = () => {
    if (logged) {
      return handlePlay()
    }
    gameClicked()
    redirectToLogin()
  }

  const userHasBalance = () => {
    return playableBalanceData?.playableBalance !== 0
  }

  const userHasFreePlay = () => {
    return playableBalanceData?.playForFree
  }

  const isComingFromBonus = () => {
    return myBonusLocation === location.pathname
  }

  const userAvailableAction = () => {
    if (userHasBalance() || userHasFreePlay() || isComingFromBonus()) {
      return (
        <Button
          disabled={isLoading || data?.CasinoSuspended || Boolean(urlError)}
          isLoading={isLoading}
          variant="secondary"
          widthBehaviour="full"
          height="medium"
          onClick={handleClickAction}
        >
          {formatMessage('action:play-now')}
        </Button>
      )
    } else {
      return (
        <Button
          variant="primary"
          widthBehaviour="full"
          height="medium"
          onClick={() => history('/cashier')}
        >
          {formatMessage('action:make-deposit')}
        </Button>
      )
    }
  }

  return (
    <GameInfoModal
      open={isModalOpen}
      title={modalTitle}
      onClose={handleClose}
      executedFrom="infoModal"
    >
      {isError && (
        <Spacing
          mt={{ xxs: 1, sm: 4.5, md: 4 }}
          mb={{ xxs: 1, sm: 5.9375, md: 5.375 }}
          px={1}
        >
          <></>
        </Spacing>
      )}
      {showGameInfo() ? (
        <GameInfo
          game={data}
          logged={logged}
          favorited={favorited}
          disabled={isLoadingFavorites || Boolean(errorFavorites)}
          onFavorite={handleFavorite}
          handleClose={handleClose}
          errorBefore={errorBefore}
          errorAfter={errorAfter}
          enableBalanceAlert={enableBalanceAlert}
          playableBalance={playableBalanceData?.playableBalance}
          activeBonusStatus={playableBalanceData?.activeBonusStatus}
          isLoadingPlayableBalance={isLoadingPlayableBalance}
          playableBalanceError={playableBalanceError}
          isModalOpen
          playForFree={userHasFreePlay()}
          isBtnDisabled={
            isLoading || data?.CasinoSuspended || Boolean(urlError)
          }
          executedFromBonusLocation={
            userHasBalance() ||
            userHasFreePlay() ||
            myBonusLocation === location.pathname
          }
          handlePlay={handleClickAction}
          actions={userAvailableAction()}
        />
      ) : (
        <GameInfoSkeleton />
      )}
    </GameInfoModal>
  )
}

export default GameInfoModalContext
