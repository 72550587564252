import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import NavLink from '../NavLink'
import useStyles from './link.styles'

const CustomLink = props => {
  const { variant, type, to, children } = props
  const classes = useStyles()

  const linkClasses = cx(classes.link, classes[variant], type && classes[type])

  return (
    <NavLink className={linkClasses} to={to}>
      {children}
    </NavLink>
  )
}

CustomLink.defaultProps = {
  variant: 'primary',
  type: 'dark',
}

CustomLink.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  type: PropTypes.oneOf(['dark', 'light']),
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default CustomLink
