const palette = {
  //custom for sbnasite
  sideMenu: '#000C4A',
  btnPrimary: '#FCC400',
  btnPrimaryHover: '#FFD400',
  btnSecondary: '#0087F6',
  tagBgNew: '#EE3536',
  primaryBlack: '#2D2E2C',
  primaryBlue: '#031679',
  primary: '#031679',
  cardSubtitle: '#1C1D1C',
  hoverBtn: '#043DA3',
  disabledBtnText: '#90918D',
  cardHover: 'rgba(151, 151, 151, 0.5)',
  darkBlueCustom: '#000b44',
  inputBg: '#dcdcdc',
  backdrop: 'rgba(0, 0, 0, .4)',

  //
  tableBg: '#5F6D7D33',
  gray700: '#ABB3BC',
  gray100: '#fdfbff',
  gray900: '#2D2E2C',
  sbDarkGraphite: '#16181A',
  white: '#FFFFFF',
  white_01: 'rgba(255,255,255,0.1)',
  primary800: '#FCC400',
  primary50: '#e6f6ec',
  secondary900: '#0087F6',
  secondary700: '#0087F6',
  secondary600: '#f88d20',
  secondary500: '#0087F6',
  secondary800: '#0087F6',
  secondary100: '#fee0b5',
  secondary50: '#fff3e1',
  secondary150: '#000B44',
  tertiary800: '#FCC400',
  tertiary700: '#4e9b33',
  tertiary600: '#61af3d',
  tertiary500: '#FCC400',
  tertiary100: '#d4ecc7',
  tertiary900: '#239cff',
  quaternary800: '#031679',
  quaternary700: '#E7E7E7',
  quaternary700_87: 'rgba(18, 18, 18, 0.87)',
  quaternary700_0: 'rgba(18, 18, 18, 0)',
  placeholder: '#031679',
  placeholder20: '#3d3d3d',
  placeholder80: '#d6d6d6',
  footerGray: '#717171',
  footerBlue: '#006CC6',
  footerCopyright: '#ACACAC',
  footerGrid: '#D2D2D2',
  // states
  breadCrumb: '#031679',
  chevron: '#828282',
  filter:
    'invert(40%) sepia(73%) saturate(1556%) hue-rotate(181deg) brightness(93%) contrast(109%)',
  universalPrelaunchModal: {
    modalBackdropColor: 'rgba(0,0,0,.7)',
    modal: {
      modalBackgroundColor: '#031679',
    },
    playBtn: {
      backgroundColor: 'linear-gradient(to bottom, #fcc400, #fcc400)',
      backgroundColorHover: 'linear-gradient(to bottom, #ffd400, #ffd400)',
      backgroundColorActive: 'linear-gradient(to bottom, #fff3e1, #fee0b5)',
      textColorActive: '#0087f6',
      textColorHover: '#FFF',
      textColor: '#16181a',
    },
    depositBtn: {
      backgroundColor: 'linear-gradient(to bottom, #fcc400, #fcc400)',
      backgroundColorHover: 'linear-gradient(to bottom, #ffd400, #ffd400)',
      backgroundColorActive: 'linear-gradient(to bottom, #fff3e1, #fee0b5)',
      textColorActive: '#0087f6',
      textColorHover: '#16181a',
      textColor: '#16181a',
    },
    header: {
      textColor: '#fff',
    },
    description: {
      gameTitle: 'rgba(255,255,255,.87)',
      gameDescription: 'rgba(255,255,255, 1)',
      gameVolatilityTitleColor: 'rgba(255,255,255,.87)',
      gameVolatility: '#fff',
    },
    playableBalance: {
      container: {
        textColor: '#fff',
        backgroundColor: 'rgba(255,255,255,0.1',
      },
      balance: {
        textColor: '#fff',
      },
    },
  },
}

export default palette
