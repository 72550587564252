import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { Collapse } from '@material-ui/core'

import useShadows from '../../hooks/useShadows'

import Icon from '../components/Icon'

import { bonusHeaders, buildBonusData, buildBonusExtraData } from './utils'
import useStyles from './bonusWalletDesktop.styles'

const ItemRow = props => {
  const { data } = props

  const [open, setOpen] = useState(false)

  const classes = useStyles()
  const collapsableRowClasses = cx(
    classes.collapsableTableRow,
    open && classes.isVisible,
  )

  return (
    <>
      <tr className={collapsableRowClasses}>
        {data.map((d, index) => (
          <td key={index} className={classes.collapsableTableData}>
            {index === 0 ? (
              <>
                <button
                  className={classes.collapsableButton}
                  onClick={() => setOpen(!open)}
                >
                  <Icon name="chevron-right" />
                </button>
                {d}
              </>
            ) : (
              <span className={classes.clamp}>{d}</span>
            )}
          </td>
        ))}
      </tr>
      <tr className={collapsableRowClasses}>
        <td colSpan={7}>
          <Collapse classes={{ wrapperInner: classes.features }} in={open}>
            {Object.entries(props.features).map(([key, value]) => (
              <div key={key} className={classes.feature}>
                <span className={classes.featureLabel}>{key}</span>
                {/* TODO: define correctly type for value and avoid casting */}
                {value as React.ReactNode}
              </div>
            ))}
          </Collapse>
        </td>
      </tr>
    </>
  )
}

ItemRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.node).isRequired,
  features: PropTypes.object.isRequired,
}

const BonusWalletDesktop = props => {
  const { ref, shadows, observe } = useShadows()

  const classes = useStyles()

  const wrapperClasses = cx(
    classes.wrapper,
    shadows.left && classes.shadowLeft,
    shadows.right && classes.shadowRight,
  )

  return (
    <div className={wrapperClasses}>
      <div className={classes.innerWrapper} ref={ref}>
        <table
          className={classes.collapsableTable}
          cellPadding={0}
          cellSpacing={0}
          ref={observe}
        >
          <thead>
            <tr>
              {bonusHeaders.map((heading, index) => (
                <th key={index} className={classes.collapsableTableHeading}>
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.bonuses.map((bonus, index) => (
              <ItemRow
                key={index}
                data={buildBonusData(bonus)}
                features={buildBonusExtraData(bonus)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

BonusWalletDesktop.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default BonusWalletDesktop
