import { useQuery } from 'react-query'

import { getGameUrl, getLiveGameUrl } from '../bff'
import { isUnifiedLobbyEnabled } from '../../config'

export const useGameUrl = (params, data, options) => {
  const isUnifiedLobby = isUnifiedLobbyEnabled()

  const query = useQuery(
    ['gameUrl', params?.gameId, params.bonusAccountId],
    () => {
      if (isUnifiedLobby || params?.bonusAccountId) {
        return getGameUrl({ ...params })
      }
      return getLiveGameUrl(
        {
          gameID: data?.GameID,
          extGameID: data?.ExtGameID?.toString(),
          providerID: data?.ProviderID,
          returnUrl: window.location.origin + window.location.pathname,
          parentCategoryName: data?.ParentCategoryName,
          categoryName: data?.CategoryName
        },
        params?.bonusAccountId,
        params?.username,
        params?.userToken,
        params?.customerSub)
    },
    {...options, cacheTime: 0, staleTime:0},
  )

  return query
}
