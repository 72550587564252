import baseTheme from './baseTheme'

// mq
// ----------------------------------------
// Use by default with min-width:
// [mq('sm')]: {
//  Your rules
// }

// Use with max-width:
// [mq('sm', 'max')]: {
//  Your rules
// }
const mq = (breakpoint, type?: string) => {
  const bpValue = baseTheme.breakpoints.values[breakpoint]
  return type === 'max'
    ? `@media (max-width: ${bpValue}px)`
    : `@media (min-width: ${bpValue}px)`
}

export default mq
