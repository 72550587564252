import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './summary.styles'

const Summary = props => {
  const classes = useStyles()
  const summaryClasses = cx(
    classes[props.variant],
    props.size && classes[props.size],
  )
  return (
    <div className={summaryClasses}>
      <div className={classes.details}>{props.children}</div>
      {props.aside ? <div className={classes.aside}>{props.aside}</div> : null}
    </div>
  )
}

Summary.defaultProps = {
  variant: 'popover',
}

Summary.propTypes = {
  variant: PropTypes.oneOf(['popover', 'myaccount']),
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node.isRequired,
  aside: PropTypes.node,
}

export default Summary
