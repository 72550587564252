import { useEffect } from 'react'
import { useAuth } from '../authentication'

const useGTMTracking = (isBOL = false) => {

    const { logged } = useAuth()
    
    useEffect(() => {
        if (!isBOL) return

        window.dataLayer = window.dataLayer || []

        const initiateEvent = window.dataLayer.find(e => e.event === 'initiate')

        if (!initiateEvent) {
            window.dataLayer.push({
                'event': 'initiate',
                'login_status': logged ? 'logged_in' : 'logged_out'
            })
        } else {
            initiateEvent['login_status'] = logged ? 'logged_in' : 'logged_out'
        }
        

    }, [logged])

    const normalizeToString = (data) => {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key] === null || data[key] === undefined) {
                    throw new Error(`DataLayer: ${key} is null or undefined`);
                } else if (typeof data[key] === 'object') {
                    normalizeToString(data[key]);
                } else {
                    data[key] = data[key].toString();
                }
            }
        }
    }

    const pushDataLayer = (data) => {
        window.dataLayer = window.dataLayer || []
        try {
            normalizeToString(data);
            window.dataLayer.push(data)
        } catch (err) {
            console.error(err)
        }
    }

    const registerUniversalBalanceGTMEvent = (e) => {
        const EVT = {
          event: e.event,
          interaction_type: e?.interaction_type,
          element_type: e?.element_type,
          element_subtype: e?.element_subtype,
          element_layout: e.element_layout,
          element_name: e.element_name,
          element_content: e.element_content,
          element_id: e.element_id,
          element_position: e.element_position,
          element_text: e.element_text,
          element_redirect_url: e.element_redirect_url,
        };
        window.dataLayer = window.dataLayer  || [];
        window.dataLayer.push(EVT);
    }
    
    const getUniversalBalanceGTMEvent = (open) => {
        return {
            'event': 'element_interaction',
            'interaction_type': 'click',
            'element_type': 'header', 
            'element_subtype':  'balance',        
            'element_layout': 'expandable',           
            'element_name': open ? 'open' : 'close', 
            'element_content': open ? 'balance' : 'close', 
            'element_id': null,
            'element_position':{
              'index': 'n/a',  
              'total': null
            },
            'element_text': null,
            'element_redirect_url': null,
        }
    }

    return { pushDataLayer, registerUniversalBalanceGTMEvent, getUniversalBalanceGTMEvent }
}

export default useGTMTracking