import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'

const CustomPopper = props => <Popper {...props} placement="bottom-end" />

const Autocomplete = props => {
  const { placeholder, onChange, ...rest } = props

  return (
    <MuiAutocomplete
      {...rest}
      blurOnSelect={true}
      PopperComponent={CustomPopper}
      onChange={onChange}
      renderInput={params => (
        <TextField {...params} placeholder={placeholder} />
      )}
    />
  )
}

Autocomplete.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any
}

export default Autocomplete
