import { makeStyles } from '../materialUiWrapper';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    top: 0,
    left: 0,
    background: theme.custom.palette.neutral2_87,
    cursor: 'pointer',
    zIndex: 99,
    opacity: 0,
    transition: 'opacity .25s ease',
    '&:hover': {
      opacity: 1,
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.16rem',
    color: theme.custom.palette.neutral1,
  },
  iconlg: {
    width: '2.8125rem',
    height: '2.8125rem',
    margin: '0 auto',
  },
  iconxl: {
    width: '5.9375rem',
    height: '5.9375rem',
    margin: '0 auto',
  }
}))

export default useStyles
