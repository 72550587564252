import React from 'react'
import { CarouselSkeleton } from '../../ui/Carousel/index'
import useHandlerSocketConnection from '../../hooks/useHandlerSocketConnection'

import { getToken, useAuth } from '../../authentication'
import { useCardCategories } from '../../asyncData/bff/useUnifiedLaunch'
import GameCarousel from './GameCarousel'
import { GameCategoryInterface } from '../../shared/interfaces/gameInterfaces'
import { MAX_GAMES_CAROUSEL } from '../../business'

const GamesGrid: React.FC = () => {
  useHandlerSocketConnection()
  const { username, logged } = useAuth()
  const customerId = logged && username ? username : null
  const token = getToken()

  const { isLoading, data } = useCardCategories(customerId, token)
  const categories: GameCategoryInterface[] = data?.categories

  return (
    isLoading ? <CarouselSkeleton singleSkelenton={false} /> :
    <>
      {categories.map(category => {
        const gamesLength = category.cards?.length
        return (
          <GameCarousel
            key={category.categoryName}
            name={category.categoryName}
            icon={category.categoryIconUrl}
            slug={category.categoryUrl}
            games={category.cards.slice(0, MAX_GAMES_CAROUSEL)}
            gamesLength={gamesLength}
          />
        )
      })}
    </>
  )
}

export default GamesGrid
