import { makeStyles } from '../components/materialUiWrapper'
import { mq, hoverDevice } from '../config/utils'

const useStyles = makeStyles(theme => ({
    root: {
      margin: '0 -0.75rem',
      [mq('sm')]: {
        margin: '0 -1.5rem',
      },
      [mq('md')]: {
        margin: '0 -2.25rem',
      },
      [mq('lg')]: {
        margin: '0 -3rem',
      },
      [mq('xl')]: {
        margin: '0 -3.75rem',
      },
    },
    slider: {
      [hoverDevice()]: {
        '&:hover': {
          '& button': {
            background: theme.custom.palette.neutral3_38,
          },
          '& .icon': {
            opacity: 1,
          },
        },
      },
      '& .glide__slide': {
        // NOTE it could be improved if we know how many slides per view the carousel
        // is configurated to show (props?.current?.settings?.perView)
        // so we could use set the width as 100 / number %
        // We use width instead of flex because the carousel uses this property
        // and finally when the carousel is fully loaded with override it
        // Margin right is added to visully make each slide separate from its sibling
        width: big => (big ? 'min(50%, 210px)' : 'min(33.3333%, 180px)'),
        marginRight: '0.25rem',

        [mq('sm', 'max')]: {
          paddingLeft: '0.125rem',
          paddingRight: '0.125rem',

          '&:first-of-type': {
            paddingLeft: 0,
          },
          '&:last-of-type': {
            paddingRight: 0,
          },
        },
      },
    },
    slides: {
      margin: 0,
    },
    slide: {
      height: 'auto',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      '& img': {
        maxWidth: '100%',
      },
      '&:focus': {
        outline: 'none',
      },
      [mq('sm')]: {
        borderRadius: '0.3125rem',
      },
      [mq('xl')]: {
        borderRadius: '0.375rem',
      },
    },
    button: {
      border: 'none',
      background: 'none',
      position: 'absolute',
      top: 0,
      bottom: 0,
      cursor: 'pointer',
      outline: 'none',
      transition: 'background 200ms ease',

      [mq('md')]: {
        display: 'block',
        width: '1.75rem',
        padding: 0,
      },
      [mq('lg')]: {
        width: '2.5rem',
      },
      [mq('xl')]: {
        width: '3.25rem',
      },

      '& .icon': {
        opacity: 0,
        width: '1.5rem',
        height: '1.5rem',
        fill: theme.custom.palette.neutral1,
        color: theme.custom.palette.neutral1,
        transition: 'opacity 200ms ease',

        [mq('lg')]: {
          width: '2.25rem',
          height: '2.25rem',
        },
      },
      '&.hidden': {
        display: 'none',
      },
    },
    buttonLeft: {
      left: '0.375rem',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,

      [mq('md')]: {
        left: 0,
      },
    },
    buttonRight: {
      right: '0.375rem',
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,

      [mq('md')]: {
        right: 0,
      },
    },
  }),
)

export default useStyles
