import '@platform/header-navigation'
import { getExternalSiteUrl } from '../../../../config'
import useBaseUrl from '../../../../hooks/useBaseUrl'
import { useEffect } from 'react'
import { replaceMirrorUrlHelper } from 'src/config/originSingleton'

export const HeaderNavigation = ({children, slotButtons}) => {
  const baseDomain = getExternalSiteUrl()
  let options = {
    // logoUri: 'https://cdn.gamingcity.com/img/casino/ppd/bol_logo_44b2666f03.svg?101184.29999998212',
    routes: [
      {
        name: 'Sports',
        path: `${baseDomain}/sportsbook`
      },
      {
        name: 'Live Betting',
        path: `${baseDomain}/sportsbook/live-betting`
      },
      {
        name: 'Casino',
        path: useBaseUrl(),
      },
      {
        name: 'Racebook',
        path: `${baseDomain}/horse-betting`
      },
      {
        name: 'Poker',
        path: `${baseDomain}/poker`
      },
      {
        name: 'Esports',
        path: `${baseDomain}/esports`
      },
      {
        name: 'Contests',
        path: `${baseDomain}/contests`
      },
      {
        name: 'VIP Rewards',
        path: `${baseDomain}/vip-rewards`
      },
      {
        name: 'Promos',
        path: `${baseDomain}/promotions`
      }
    ],
    logoRedirectUrl: replaceMirrorUrlHelper(baseDomain)
  }

  useEffect(() => {
      const headerNav = document.querySelector('header-navigation')
      if (headerNav) {
        headerNav.options = {...options, slotButtons}
      }
  }, [slotButtons])

  window.addEventListener('webcomponent/header-navigation/ready', () => {
    const header = document.querySelector('header-navigation')
    header.options = options
    header.hasSlot = true
    header.brand = 'bolnasite'
  }) 

    return (
        <header-navigation>
            {children}
        </header-navigation>
)}