import { hoverDevice } from '../../../config/utils';
import { makeStyles } from '../../../components/materialUiWrapper';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'block',
        marginRight: '10px',
        width: '34px',
        height: '34px',
        cursor: 'pointer',
        '& svg': {
            '& rect': {
                fill: theme.custom.blackjack?.bgColor ? theme.custom.blackjack?.bgColor : 'white',
            },
            '& g': {
                fill: theme.custom.blackjack?.iconColor,
            },
            '& .number': {
                fill: theme.custom.blackjack?.bgColor || 'white',
            },
        },
        [hoverDevice()]: {
            '&:hover': {
                '& svg': {
                    '& g': {
                        fill: theme.custom.blackjack?.iconColorHover,
                    }
                },
            }
        },
    },

}));

export default useStyles;