import { CssBaseline, ThemeProvider } from '@material-ui/core'
import PropTypes from 'prop-types'
const CustomThemePropTypes = {
    children: PropTypes.any,
    theme: PropTypes.any,
}
const CustomTheme = ({ children, theme }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
    </ThemeProvider>
)
CustomTheme.propTypes = CustomThemePropTypes
export default CustomTheme