import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useTranslations } from '../../../i18n'

import useStyles from './loader.styles'

const Loader = props => {
  const { className, hasWrapper, size, hasText, ...rest } = props
  const formatMessage = useTranslations()
  const classes = useStyles(props)

  const loaderClasses = cx(
    classes.loader,
    classes[size],
    hasWrapper && classes.hasWrapper,
    className,
  )

  return (
    <div className={loaderClasses} {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className={classes.loaderIcon}
      >
        <defs>
          <linearGradient id="linear">
            <stop
              className="gradient-stop"
              offset="0%"
              stopColor="currentColor"
              stopOpacity="1"
            />
            <stop
              className="gradient-stop"
              offset="100%"
              stopColor="currentColor"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="url(#linear)"
          strokeWidth="8"
          r="32"
          strokeDasharray="100%"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.2s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
      {hasText ? (
        <span className={classes.loaderText}>
          {formatMessage('action:loading')}...
        </span>
      ) : null}
    </div>
  )
}

Loader.defaultProps = {
  size: 'small',
}

Loader.propTypes = {
  className: PropTypes.string,
  hasWrapper: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  hasText: PropTypes.bool,
}

export default Loader
