import Env from '@env';
import React, { Suspense, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import LegalAgeModal from './ui/components/LegalAgeModal'
import LoginSkeletonContext from './context/Login'
import { RedirectToJoin, RedirectToLogin } from './authentication'
import useVerifiedAgeCookie from './hooks/useVerifiedAgeCookie'
import { shouldUseNewLoginJoinLayout } from './config'
import { useIsBrand } from './hooks/useIsBrand'
import GlobalHeader from './ui/components/GlobalHeader'
import Header from './ui/external-components/Header'
import { isAmberLayout } from './ui/config/utils'
import MainMenu from './ui/external-components/MainMenu'
import { buildLinks } from './context/Layout/SportBettingLayout/utils'
import AmberHeader from './context/Layout/SportBettingLayout/AmberHeader'
import AmberFooter from './context/Layout/SportBettingLayout/AmberFooter'
import DefaultFooter from './context/Layout/DefaultLayout/DefaultFooter'
import DefaultHeader from './context/Layout/DefaultLayout/DefaultHeader'
import SAC from './LayoutRoutes/AmberLayoutRoutes'
import { isNewNavigationEnabled } from './context/kameleeon/experiments/newNavigation'
import { isMobile, isDesktop, isTablet } from './libs/uaparser'
import NewHeader from './ui/components/NewNavigation/newHeader/NewHeader'
import { BottomNav, SideMenu } from './ui/components/NewNavigation/components'
import useStyles from './context/Layout/NewLayout/layout.styles'
import { useTheme } from '@material-ui/core'
import Universal101 from './ui/external-components/Universal101'
import useFeatureFlags from './hooks/useFeatureFlag'

const EXTERNAL_REDIRECT_URL = 'https://google.com'

const App = () => {
  const { isBOL, isSB, isQBC, isLV } = useIsBrand()
  const allowHealthPath = isBOL() || isQBC() || isLV()

  const FallbackHeader = isBOL() || isSB() ? Header : GlobalHeader

  const [isVerified, isRejected, accept, reject] = useVerifiedAgeCookie()
  const { isNewDesktopEnabled } = useFeatureFlags()

  const siteKey = Env.get('VITE_GSETTING')
  const isGamingCity = siteKey === 'gcsite'
  const isPrerender = navigator.userAgent.includes('Prerender')
  const needAgeVerification = isGamingCity && !isVerified() && !isPrerender

  const [navigationItems] = useState(buildLinks(siteKey))
  const [isNewNav, setIsNewNav] = useState(true)
  const [menuState, setMenuState] = useState(false)
  const classes = useStyles({ menuState })

  const theme: {
    custom: {
        hasExperimentsEnabled: boolean;
        hasNewNavigation: boolean;
    }
  } = useTheme()
  useEffect(() => {
    if (isRejected()) {
      window.location.href = EXTERNAL_REDIRECT_URL
    }
  }, [isRejected])

  const initKameleoon = async () => {
    try {
      const FFEnabled = await isNewNavigationEnabled()
      setIsNewNav(FFEnabled)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if(theme.custom?.hasExperimentsEnabled) {
      initKameleoon()
    }
  }, [])

  const showNewNavComponentsMobile = theme.custom?.hasNewNavigation && isMobile()
  const showNewNavDesktop = theme.custom?.hasNewNavigation && (isDesktop() || isTablet()) && isNewDesktopEnabled

  const useNewLoginJoinLayout = shouldUseNewLoginJoinLayout()

  const checkKeycloakStatusAfterChangingPassword = () => {
    const isProfilePath = window.location.pathname.includes(
      '/my-account/profile',
    )
    if (isProfilePath) {
      let params = new URLSearchParams(window.location.search)
      const kcActionStatus = params.get('kc_action_status')
      if (kcActionStatus) {
        sessionStorage.setItem('change_password', kcActionStatus)
      }
    }
  }

  checkKeycloakStatusAfterChangingPassword()

  // App Elements

  const AppHeader = () =>
    isAmberLayout() ? (
      <>
        <AmberHeader />
        <MainMenu navigationItems={navigationItems} />
      </>
    ) : (
      <DefaultHeader />
    )

  const AppFooter = () =>
    isAmberLayout() ? <AmberFooter /> : <DefaultFooter />

  return (
    <>
      <Routes>
        {useNewLoginJoinLayout && (
          <Route
            path="/login"
            element={
              !needAgeVerification ? (
                <RedirectToLogin>
                  <LoginSkeletonContext />
                </RedirectToLogin>
              ) : (
                <LoginSkeletonContext />
              )
            }
          ></Route>
        )}

        {useNewLoginJoinLayout && (
          <Route
            path="/join"
            element={
              !needAgeVerification ? (
                <RedirectToJoin>
                  <LoginSkeletonContext />
                </RedirectToJoin>
              ) : (
                <LoginSkeletonContext />
              )
            }
          ></Route>
        )}

        {allowHealthPath && (
          <Route path="/health" element={<p>Status: ok</p>}></Route>
        )}

        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <FallbackHeader
                  logo={`/logo.svg`}
                  logoSlim={`/logo-slim.svg`}
                ></FallbackHeader>
              }
            >
              {showNewNavComponentsMobile || showNewNavDesktop ? <NewHeader /> : <AppHeader />}
              {(showNewNavComponentsMobile || showNewNavDesktop) && (
                <>
                  {/* @ts-expect-error Optional property children */}
                  <SideMenu sideNavTrigger={setMenuState} />
                </>
              )}
              <div className={`${classes.appFluidContainer} ${showNewNavDesktop ? classes.contentShift : ''}`}>
                <SAC />
                <Universal101 />
                <AppFooter />
              </div>
              {showNewNavComponentsMobile && <BottomNav />}
            </Suspense>
          }
        ></Route>
      </Routes>
      {needAgeVerification && (
        <LegalAgeModal onSubmit={isAdult => (isAdult ? accept() : reject())} />
      )}
    </>
  )
}

export default App
