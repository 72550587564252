import { deviceBrandId } from '../../ui/config/deviceUtils'
import { createRequester } from './requester'
import { SortAndFilterProps } from './sort-and-filter-options/types'

const createSortAndFilterService = () => {
  const requester = createRequester('card-categories/cards-filter')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const getSortAndFilterService = createSortAndFilterService()

const getFilteredGames = async (params: SortAndFilterProps) => {
  const requester = getSortAndFilterService
  return await requester.request({ ...params })
}

export { getFilteredGames }
