
import TransportImpl from './transportImpl';
import { isLiveCasinoEnabled } from './config/envVars';

export const disconnectBffServer = () => {
  if (!isLiveCasinoEnabled()) {
    return;
  }
  if (TransportImpl.getInstance()._isConnected()) {
    TransportImpl.getInstance().disconnect();
  }
}
  
export const connectBffServer = () => {
  if (!isLiveCasinoEnabled()) {
      return;
  }

  if (!TransportImpl.getInstance()._isConnected()) {
      TransportImpl.getInstance().connect();
  }
}