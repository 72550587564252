import React, { useContext } from 'react'

import BetLimits from './BetLimitsTag'
import Titles from './TitlesTag'
import LastResults from './BaccaratTag'
import Seats from './BlackjackTag'
import useStyles from './tagTypes.styles'
import { GamesInterface } from '../../../../shared/interfaces/gameInterfaces'

const TagList: React.FC<{
  game: GamesInterface
  offsetwidth?: string
  size?: string
  hasTags?: boolean
}> = ({ game, offsetwidth, size, hasTags }) => {
  const classes = useStyles({ offsetwidth, isUnifiedLobby: true })
  return (
    <>
      {hasTags && (
        <div className={classes.tagList}>
          <Titles title={game?.title} subtitle={game?.subtitle} />
          <div className={classes.gameResults}>
            {game?.seats && (
              <Seats
                seats={game?.seats}
                cardId={game?.cardId}
                isLive={game.hasLiveData}
              />
            )}
            {game?.lastResults && (
              <LastResults
                results={game?.lastResults}
                cardId={game?.cardId}
                isLive={game.hasLiveData}
              />
            )}
          </div>
          {game?.limit && <BetLimits limit={game?.limit} />}
        </div>
      )}
    </>
  )
}

export default TagList
