import ItemLink from './item';
import useStyles from './navigation.styles';
import PropTypes from 'prop-types';
import CustomPropTypes from '../../../../customPropTypes';

const Navigation = ({title, routes}) => {
    const classes = useStyles();
    return <nav className={classes.nav}>
        <div className={classes.headerTitle}>
            <h2>
                {title}
            </h2>
        </div>
        <ol>
            {routes.map(route  => (
               <li key={route.route}>
                    <ItemLink route={route.route} label={route.label} active={route.active} />
               </li> 
            ))}
        </ol>
    </nav>
}

Navigation.propTypes = {
    title: PropTypes.string,
    routes: PropTypes.arrayOf(CustomPropTypes.navigation101)
}

export default Navigation;