import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import useStyles from './cardSlim.styles'

const CardSlim = props => {
  const classes = useStyles()

  return (
    <div className={classes.step}>
      <Icon name={props.icon} />
      <span className={classes.stepText}>{props.title}</span>
    </div>
  )
}

CardSlim.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default CardSlim
