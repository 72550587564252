import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'

import BonusWalletMobile from './BonusWalletMobile'
import BonusWalletDesktop from './BonusWalletDesktop'
import { CustomTheme } from '../CustomTheme/types'

// TODO:
// Get data
// Manage and filter
// Render component
// How to bind headers order with cells order ?

const BonusWallet = props => {
  const isMobile = useMediaQuery<CustomTheme>(theme => theme.breakpoints.down('xs'))

  return isMobile ? (
    <BonusWalletMobile bonuses={props.data} />
  ) : (
    <BonusWalletDesktop bonuses={props.data} />
  )
}

BonusWallet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default BonusWallet
