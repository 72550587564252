import { useQuery } from 'react-query'

import { useAuth } from '../../authentication'

import { fetchVipLevels } from './services'
import { isVipLevelsEnabled } from '../../business';

interface VipLevelsInterface {
    isLoading: boolean;
    data: {
        CurrentLevelName: string;
        NextLevelName: string;
        CurrentProgress: number;
        UpdatedAt: Date;
    },
    isError: boolean;
    refetch: () => {}
}
export const useVipLevels = (): VipLevelsInterface => {
    const { logged, username } = useAuth()

    return useQuery(['rewards', username], fetchVipLevels, {
        enabled: logged && isVipLevelsEnabled(),
    })
}