import { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import parseEnvBoolean from 'src/config/parseEnvBoolean'


const flagValueExtraction = (envFlag, cookieFlag = envFlag) => {
  return parseEnvBoolean(envFlag) 
        || Cookies.get(cookieFlag) === 'true'
}

const useFeatureFlags = () => {
  const [isNewDesktopEnabled, setNewDesktopEnabled] = useState(false)

  useEffect(() => {
    
    setNewDesktopEnabled(flagValueExtraction('VITE_WEBSITES_FF_USE_NEW_NAV_DESKTOP', 'websites-ff-bol-newNavigationDesktop')

    )
  }, [])

  return {
    isNewDesktopEnabled,
  }
}

export default useFeatureFlags
