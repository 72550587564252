// see https://formatjs.io/docs/react-intl/api/#createintl
import { createIntl, createIntlCache } from 'react-intl'
import setupI18n from './setupI18n'

const { locale, messages } = setupI18n()

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache()

const intl = createIntl({ locale, messages }, cache)

export const formatMessage = (key, values = {}) =>
  intl.formatMessage({ id: key, defaultMessage: key }, values)

// NOTE same options used as default in useCurrency hook
export const formatNumber = (number, opts) =>
  intl.formatNumber(number, { style: 'currency', currency: 'USD' })

export default intl
