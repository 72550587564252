import Env from '@env';
import baseTheme from '../../config/baseTheme'
import paletteGC from '../../config/gcsite/palette'
import paletteHR from '../../config/hrnasite/palette'
import paletteSB from '../../config/sbnasite/palette'
import paletteSS from '../../config/ssnasite/palette'
import paletteWC from '../../config/wcnasite/palette'
import paletteLV from '../../config/lvnasite/palette'
import { getBolOptions } from './bolnasite'
import { mapLinks } from './utils'
import { getExternalSiteUrl } from '../../../config'
import paletteQBC from '../../config/qbcnasite/palette'
import { getCasinoSubPath101 } from '../../../ui/config/utils'

const SITE_CLASSIC_URL = Env.get('VITE_SITE_CLASSIC_URL');
const EXTERNAL_BASE_URI = getExternalSiteUrl()

export const buildOptions = (data, siteKey) => {
  const bolOptions = getBolOptions()

  const getTypeFromUrl = (url) => (url?.startsWith('/') ? 'inner' : 'outer')

  const quickLinks = (data?.links || []).map((link) => ({
    text: link.label,
    type: getTypeFromUrl(link.url),
    url: link.url,
    windowElement: null,
  }))

  const siteMapLinks = (data?.site_map || []).map(category => {
    const { name, links } = category
    const adaptedLinks = links?.map(link => ({
      text: link.label,
      type: getTypeFromUrl(link.url),
      url: link.url,
    }))

    return {
      title: name,
      links: adaptedLinks,
    }
  })

  const year = data?.year || new Date().getFullYear()

  const bolSiteMapLinkGroup = (group, defaultTitle) => {
    const title = group?.title ?? defaultTitle.toLowerCase()
    const links = group ? mapLinks(group.links) : bolOptions?.siteMapLinks.find(link => link.title === title)?.links
    return { title, links }
  }

  const config = {
    bolnasite: {
      theme: {
        background: '#2D2E2C',
        primary: '#EE3536',
        accent: '#EE3536',
        text: '#90918D',
        copyright: '#90918D',
        titles: '#FFFFFF',
        subTitles: '#E7E7E7',
        link: '#90918D',
        activeLink: '#FFFFFF',
        mobileLink: '#90918D',
        grid: '#4b4b4b',
        textButton: '#FFFFFF',
        activeTextButton: '#EE3536',
        activeButton: '#FFFFFF',
        svg: '#FFFFFF',
        chevron: '#FFFFFF',
      },
      nhButton: {
        text: 'Need help?',
      },
      sbcButton: {
        visible: true,
        text: 'Switch to classic site',
        siteClassicUrl: SITE_CLASSIC_URL,
      },
      promotions: data? mapLinks(data?.singleLinks) : bolOptions?.promotions,
      quickLinksTitle: 'Quick links',
      quickLinks: data ? mapLinks(data?.quickLinks?.links) : bolOptions?.quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks: [
        bolSiteMapLinkGroup(data?.group1Links, 'Casino'),
        bolSiteMapLinkGroup(data?.group2Links, 'Sports'),
        bolSiteMapLinkGroup(data?.group3Links, 'Poker'),
        bolSiteMapLinkGroup(data?.group4Links, 'Racebook')
      ],
      trustedExp: {
        copyright: `Copyright @${year} BetOnline.ag. All rights reserved.`,
        branding: 'BetOnline.ag.',
        title: data?.safeAndSecureTitle ?? 'A TRUSTED & SAFE EXPERIENCE',
        message: data?.safeAndSecureContent ?? "At BetOnline, our company's guiding principle is to establish long-lasting, positive relationships with our customers and within the online gaming community for over 25 years.",
        bitcoin: true,
        litecoin: true,
        ethereum: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        dateTime: true,
        socialNetworks: {
          visible: true,
          icons: data ? data?.socialLinks?.map(socialLink => {
            return {
              icon: socialLink?.image?.data?.attributes?.url,
              url: socialLink?.url,
              altText: socialLink?.label
            }
          }) : bolOptions.trustedExp?.socialNetworks?.icons
        },
        officialPartners: {
          visible: true,
          title: 'Official Partners',
          partners: data ? data?.partnershipLogos?.map(partnerLink => {
            return {
              logoDesktop: partnerLink?.desktopImage?.data?.attributes.url,
              logoMobile: partnerLink?.mobileImage?.data?.attributes.url,
              altText: partnerLink?.Label
            }
          }) : bolOptions.trustedExp?.officialPartners?.partners
        },
      },
    },
    lvnasite: {
      theme: {
        background: paletteLV.primaryBlack,
        primary: paletteLV.secondary900,
        accent: paletteLV.secondary900,
        text: baseTheme.palette.neutral1_60,
        copyright: baseTheme.palette.neutral1_60,
        titles: baseTheme.palette.neutral1,
        subTitles: paletteLV.footerSubTitles,
        link: baseTheme.palette.neutral1_60,
        activeLink: baseTheme.palette.neutral1,
        mobileLink: baseTheme.palette.neutral1,
        grid: paletteLV.gray800,
        textButton: paletteLV.primaryBlack,
        activeTextButton: paletteLV.primaryBlack,
        activeButton: 'linear-gradient(to bottom, #c58200, #FFA800, #c58200)',
        svg: baseTheme.palette.neutral1,
        chevron: baseTheme.palette.neutral1,
      },
      nhButton: {
        text: 'Need help?',
      },
      sbcButton: {
        visible: false,
      },
      promotions: data? mapLinks(data?.singleLinks) : bolOptions?.promotions,
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${year} Lowvig.ag. All rights reserved.`,
        branding: 'Lowvig.ag.',
        title: data?.safeAndSecureTitle ?? 'A TRUSTED & SAFE EXPERIENCE',
        message: "",
        bitcoin: true,
        litecoin: true,
        ethereum: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        dateTime: true,
        socialNetworks: {
          visible: false,
        },
        officialPartners: {
          visible: false,
        },
      },
    },
    sbnasite: {
      theme: {
        background: paletteSB.white,
        primary: paletteSB.secondary900,
        accent: paletteSB.secondary900,
        text: paletteSB.footerGray,
        copyright: paletteSB.footerCopyright,
        titles: paletteSB.sbDarkGraphite,
        subTitles: paletteSB.sbDarkGraphite,
        link: paletteSB.footerGray,
        activeLink: paletteSB.secondary900,
        grid: paletteSB.footerGrid,
        textButton: paletteSB.white,
        activeTextButton: paletteSB.white,
        activeButton: paletteSB.footerBlue,
        svg: paletteSB.secondary900,
        chevron: paletteSB.chevron,
      },
      nhButton: {
        text: 'Need help?',
      },
      sbcButton: {
        visible: false,
        text: 'Switch to classic site',
      },
      promotions: [
        {
          text: 'Promos',
          url: `${EXTERNAL_BASE_URI}/promotions`,
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks: [
        {
          text: 'About Us',
          type: 'outer',
          url: 'https://help.Sportsbetting.ag/knowledge-base/about-us/',
          windowElement: null,
        },
        {
          text: 'Refer a Friend',
          type: 'inner',
          url: `${EXTERNAL_BASE_URI}/refer-a-friend`,
        },
        {
          text: 'Rules',
          type: 'outer',
          url: 'https://help.Sportsbetting.ag/articles/sportsbetting-rules',
          windowElement: null,
        },
        {
          text: 'Privacy Policy',
          type: 'outer',
          url: 'https://help.Sportsbetting.ag/knowledge-base/privacy-policy/',
          windowElement: null,
        },
        {
          text: 'Affiliates',
          type: 'inner',
          url: 'https://www.commissionkings.ag/',
          windowElement: null,
        },
        {
          text: 'Terms & Conditions',
          type: 'outer',
          url: 'https://help.Sportsbetting.ag/knowledge-base/terms-and-conditions/',
          windowElement: null,
        },
        {
          text: 'Responsible Gaming',
          type: 'outer',
          url: 'https://help.Sportsbetting.ag/knowledge-base/responsible-g/',
          windowElement: null,
        },
      ],
      siteMapTitle: 'Site Map',
      siteMapLinks: [
        {
          title: 'casino',
          links: [
            {
              text: 'Play Casino',
              type: 'inner',
              url: '/',
            },
            {
              text: 'Blackjack',
              type: 'inner',
              url: '/games/table-games-blackjack',
            },
            {
              text: 'Baccarat',
              type: 'inner',
              url: `${getCasinoSubPath101()}baccarat`,
            },
            {
              text: `Craps`,
              type: `inner`,
              url: `${getCasinoSubPath101()}craps`,
            },
            {
              text: `Roulette`,
              type: `inner`,
              url: `${getCasinoSubPath101()}roulette`,
            },
            {
              text: `Keno`,
              type: `inner`,
              url: `${getCasinoSubPath101()}keno`,
            },
            {
              text: 'Slots',
              type: 'inner',
              url: '/games/slots',
            },
            {
              text: 'Video Poker',
              type: 'inner',
              url: '/games/video-poker',
            },
          ],
        },
        {
          title: 'sports',
          links: [
            {
              text: 'Sportsbook',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook`,
            },
            {
              text: 'NFL Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/football/nfl`,
            },
            {
              text: 'NBA Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/basketball/nba`,
            },
            {
              text: 'MLB Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/baseball/mlb`,
            },
            {
              text: 'NHL Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/hockey/nhl`,
            },
            {
              text: 'NCAAF Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/football/ncaa`,
            },
            {
              text: 'NCAAB Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/basketball/ncaa`,
            },
            {
              text: 'Super Bowl Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/futures-and-props/super-bowl`,
            },
            {
              text: 'UFC / MMA Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/martial-arts/mma`,
            },
            {
              text: 'Boxing Betting Odds',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/sportsbook/boxing/bouts`,
            },
          ],
        },
        {
          title: 'poker',
          links: [
            {
              text: 'Play Poker',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/poker`,
            },
            {
              text: 'Download',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/poker/download`,
            },
            {
              text: 'Texas Holdem',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/poker/texas-holdem`,
            },
            {
              text: 'Omaha Poker',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/poker/omaha`,
            },
          ],
        },
        {
          title: 'racebook',
          links: [
            {
              text: 'Horse Betting',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/racebook`,
            },
            {
              text: 'Kentucky Derby',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/racebook/kentucky-derby`,
            },
            {
              text: 'Preakness Stakes',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/racebook/preakness-stakes`,
            },
            {
              text: 'Belmont Stakes',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/racebook/belmont-stakes`,
            },
            {
              text: 'Breeders Cup',
              type: 'inner',
              url: `${EXTERNAL_BASE_URI}/racebook/breeders-cup`,
            },
          ],
        },
      ],
      trustedExp: {
        copyright: `Copyright @${year} Sportsbetting.ag. All rights reserved.`,
        branding: 'Sportsbetting.ag.',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message:
          "At Sportsbetting.ag, our company's guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online gaming and poker community since 2011.",
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        socialNetworks: {
          visible: true,
          icons: [
            {
              url: 'https://twitter.com/SportsBettingAG',
              icon: 'https://cdn-cms.betonline.ag/img/Twitter_bd8dc0a929.svg',
              altText: 'Twitter'
            }
          ]
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      },
    },
    ssnasite: {
      theme: {
        accent: paletteSS.accent,
        activeButton: paletteSS.footerGradientSecondary,
        activeLink: baseTheme.palette.neutral1,
        activeTextButton: paletteSS.quaternary800,
        background: paletteSS.footerBg,
        chevron: baseTheme.palette.neutral1,
        copyright: baseTheme.palette.neutral1_38,
        grid: baseTheme.palette.neutral1_38,
        link: baseTheme.palette.neutral1_60,
        mobileLink: baseTheme.palette.neutral1_60,
        primary: paletteSS.footerGradientPrimary,
        subTitles: paletteSS.footerSubTitles,
        svg: baseTheme.palette.neutral1,
        text: baseTheme.palette.neutral1_38,
        textButton: paletteSS.quaternary800,
        titles: baseTheme.palette.neutral1
      },
      nhButton: {
        text: 'Need help?'
      },
      sbcButton: {
        visible: false,
        text: 'Switch to classic site'
      },
      promotions: [
        {
          text: 'Promos',
          url: '/promotions',
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${year} SuperSlots.ag. All rights reserved.`,
        branding: 'SuperSlots.ag.',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message: 'At Super Slots, our company\'s guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online casino gaming community.',
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        socialNetworks: {
          visible: false,
          icons: []
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      }
    },
    wcnasite: {
      theme: {
        accent: paletteWC.accent,
        activeButton: paletteWC.footerGradientSecondary,
        activeLink: paletteWC.white,
        activeTextButton: paletteWC.white,
        background: paletteWC.footerBg,
        chevron: paletteWC.chevron,
        copyright: paletteWC.grayLightSecondary,
        grid: paletteWC.grayLightSecondary,
        link: paletteWC.grayLightSecondary,
        mobileLink: paletteWC.grayLightSecondary,
        primary: paletteWC.footerGradientPrimary,
        subTitles: paletteWC.footerSubTitles,
        svg: paletteWC.white,
        text: paletteWC.grayLightSecondary,
        textButton: paletteWC.white,
        titles: paletteWC.white
      },
      nhButton: {
        text: 'Need help?'
      },
      sbcButton: {
        visible: false,
        text: 'Switch to classic site'
      },
      promotions: [
        {
          text: 'Promos',
          url: '/promotions',
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${year} WildCasino.ag. All rights reserved.`,
        branding: 'WildCasino.ag.',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message: 'At WildCasino.ag, our company\'s guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online casino gaming community.',
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        socialNetworks: {
          visible: true,
          icons: [
            {
              icon: 'https://cdn-cms.wildcasino.ag/img/casino/prod/instagram_white_0ae507b05b.svg',
              url: 'https://instagram.com/WildCasino_ag',
              altText: 'Instagram',
            },
            {
              icon: 'https://cdn-cms.wildcasino.ag/img/casino/prod/x_logo_white_5c05045b22.svg',
              url: 'https://x.com/WildCasino_ag',
              altText: 'X'
            },
          ]
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      }
    },
    gcsite: {
      theme: {
        background: paletteGC.footerBg,
        primary: paletteGC.footerPrimary,
        accent: paletteGC.footerAccent,
        text: baseTheme.palette.neutral1,
        copyright: baseTheme.palette.neutral1_38,
        titles: baseTheme.palette.neutral1,
        subTitles: paletteGC.footerSubTitles,
        link: baseTheme.palette.neutral1_60,
        mobileLink: baseTheme.palette.neutral1_60,
        activeLink: baseTheme.palette.neutral1,
        grid: baseTheme.palette.neutral1_38,
        textButton: paletteGC.quaternary800,
        activeTextButton: paletteGC.quaternary800,
        activeButton: paletteGC.activeFooterButton,
        svg: baseTheme.palette.neutral1,
        chevron: baseTheme.palette.neutral1,
      },
      nhButton: {
        text: 'Need help?'
      },
      sbcButton: {
        visible: false,
      },
      promotions: [
        {
          text: 'Promos',
          url: '/promotions',
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${year} Gaming City. All rights reserved.`,
        branding: 'Gaming City',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message: 'At Gaming City, our company\'s guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online casino gaming community.',
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        socialNetworks: {
          visible: false,
          icons: []
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      }
    },
    hrnasite: {
      theme: {
        accent: paletteHR.accent,
        activeButton: paletteHR.footerGradientSecondary,
        activeLink: baseTheme.palette.neutral1,
        activeTextButton: paletteHR.quaternary800,
        background: paletteHR.footerBg,
        chevron: baseTheme.palette.neutral1,
        copyright: baseTheme.palette.neutral1_38,
        grid: baseTheme.palette.neutral1_38,
        link: baseTheme.palette.neutral1_60,
        mobileLink: baseTheme.palette.neutral1_60,
        primary: paletteHR.footerGradientPrimary,
        subTitles: paletteHR.footerSubTitles,
        svg: baseTheme.palette.neutral1,
        text: baseTheme.palette.neutral1_38,
        textButton: paletteHR.quaternary800,
        titles: baseTheme.palette.neutral1
      },
      nhButton: {
        text: 'Need help?'
      },
      sbcButton: {
        visible: false,
        text: 'Switch to classic site'
      },
      promotions: [
        {
          text: 'Promos',
          url: '/promotions',
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${year} HighRollerCasino.ag. All rights reserved.`,
        branding: 'HighRollerCasino.ag.',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message: 'At High Roller, our company\'s guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online casino gaming community.',
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        dateTime: true,
        socialNetworks: {
          visible: false,
          icons: [],
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      }
    },
    qbcnasite: {
      theme: {
        accent: paletteQBC.accent,
        activeButton: paletteQBC.footerGradientSecondary,
        activeLink: paletteQBC.links,
        activeTextButton: paletteQBC.white,
        background: paletteQBC.footerBg,
        chevron: paletteQBC.chevron,
        copyright: paletteQBC.quaternary,
        grid: paletteQBC.grayLightSecondary,
        link: paletteQBC.neutral900,
        mobileLink: paletteQBC.grayLightSecondary,
        primary: paletteQBC.footerGradientPrimary,
        subTitles: paletteQBC.quaternary,
        svg: paletteQBC.accent,
        text: paletteQBC.footerGray,
        textButton: paletteQBC.white,
        titles: paletteQBC.quaternary
      },
      nhButton: {
        text: 'Need help?'
      },
      sbcButton: {
        visible: false,
        text: 'Switch to classic site'
      },
      promotions: [
        {
          text: 'Promos',
          url: '/promotions',
        },
      ],
      quickLinksTitle: 'Quick links',
      quickLinks,
      siteMapTitle: 'Site Map',
      siteMapLinks,
      trustedExp: {
        copyright: `Copyright @${data?.year || new Date().getFullYear()} QueenBeeCasino.ag. All rights reserved.`,
        branding: 'QueenBeeCasino.ag.',
        title: 'A TRUSTED & SAFE EXPERIENCE',
        message: 'At Queen Bee Casino, our company\'s guiding principle is to establish long-lasting, positive relationships with our customers and with a presence in the online casino gaming community.',
        bitcoin: true,
        ethereum: true,
        litecoin: true,
        visa: true,
        mastercard: true,
        americanExpress: true,
        discover: true,
        moneygram: true,
        responsible: true,
        secure: true,
        eighteen: true,
        dateTime: true,
        socialNetworks: {
          visible: false,
          icons: [],
        },
        officialPartners: {
          visible: false,
          title: '',
          partners: []
        },
      }
    },
  }

  return config[siteKey]
}
