import React from 'react'
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'

import useStyles from './snackbarProviderWrapper.styles'

const SnackbarProviderWrapper = props => {
  const classes = useStyles()

  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{
        containerRoot: classes.containerRoot,
        containerAnchorOriginTopRight: classes.containerRootTopRight,
      }}
    >
      {props.children}
    </SnackbarProvider>
  )
}

SnackbarProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SnackbarProviderWrapper
