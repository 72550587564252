import React, { useState, useEffect, memo, useRef } from 'react'
import useStyles from './img.styles'
import { STATUS } from './constants'

interface ImgProps {
  placeholder: React.ReactNode;
  fallback: React.ReactNode;
  src: string;
  alt?: string;
}

const Img: React.FC<ImgProps> = (props) => {
  const { placeholder, fallback, ...rest } = props
  const classes = useStyles()
  const imgRef = useRef(null)
  const [status, setStatus] = useState(STATUS.LOADING)
  const handleLoad = () => setStatus(STATUS.READY)
  const handleError = () => setStatus(STATUS.ERROR)

  useEffect(() => {
    const imgElement = imgRef.current

    if (imgElement) {
      imgElement.addEventListener('load', handleLoad)
      imgElement.addEventListener('error', handleError)
    }

    return () => {
      if (imgElement) {
        imgElement.removeEventListener('load', handleLoad)
        imgElement.removeEventListener('error', handleError)
      }
    }
  }, [rest.src])

  // NOTE empty alt to prevent linter warning, should be overwriten by props
  return (
    <div className={classes.imageWrapper}>
      {status === STATUS.LOADING ? placeholder : null}
      {status === STATUS.ERROR ? fallback : null}
      <img
        alt=""
        loading="lazy"
        {...rest}
        ref={imgRef}
        className={status === STATUS.READY ? undefined : classes.hidden}
        onLoad={() => setStatus(STATUS.READY)}
        onError={() => setStatus(STATUS.ERROR)}
      />
    </div>
  )
}

export default Img
