import '@platform/bottom-nav';
import { useAuth } from '../../../../authentication';
import { useEffect } from 'react';

export const BottomNav = () => {
  const {logged, initialized, redirectToJoin, redirectToLogin } = useAuth()
  window.addEventListener('webcomponent/bottom-nav/ready', () => {
    const bottomNav = document.querySelector('bottom-nav')
    bottomNav.navRoutes = {
      joinNow: redirectToJoin,
      login: redirectToLogin,
      betslip: '/'
    }
   }) 
 useEffect(() => {
  if (initialized) {
    const event = new CustomEvent('user-login', { detail: logged })
    window.dispatchEvent(event)
  }
   }, [logged, initialized])
  return (
    <bottom-nav></bottom-nav>
  )
}