/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react'
import Heading from '../../ui/components/Heading'
import Grid from '../../ui/components/Grid'
import GameCard from '../../ui/components/GameCard'
import { GamesInterface } from '../../shared/interfaces/gameInterfaces'
import useStyles from './grid.styles'
import { LinearProgress } from '@material-ui/core'

interface CategoryGridProps {
  name: string
  games: GamesInterface[]
}

const gamesPerRender = 20

const CategoryGrid: React.FC<CategoryGridProps> = ({ name, games }) => {
  const containerRef = useRef(null)
  const triggerRef = useRef(null)
  const [visibleItems, setVisibleItems] = useState([])
  const classes = useStyles()
  const totalItems = games?.length
  const hasPendingGames = visibleItems.length < totalItems

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if(entry.isIntersecting && hasPendingGames) {
          setVisibleItems((prevItems) => [
            ...prevItems,
            ...games.slice(prevItems.length, prevItems.length + gamesPerRender)]
            )
      }
    })
  }

  useEffect(() => {
    setVisibleItems(games.slice(0, gamesPerRender))
  }, [games])

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    if (triggerRef.current) {
      observer.observe(triggerRef.current)
    }
    return () => observer.disconnect()
  }, [games, triggerRef, containerRef])
  if (!games || games.length === 0) return null
  return (
    <>
      <Heading title={name} number={games.length} />
      <Grid gridRef={containerRef}>
        {visibleItems.map((game) => (
          <GameCard key={game.id} game={game} origin={name} size="regular" />))}
      </Grid>
        {hasPendingGames && <LinearProgress className={classes.linearProgress} />}
      <div ref={triggerRef} />     
    </>
  )
}

export default CategoryGrid
