import { isIntercomCookieEnabled } from '../business/index.js'
import { isIntercomFFEnabled } from '../config/index.js'

export const openLiveChat = () => {
  window.EdgeTierChat?.isAvailable((error, data) => {
    if (error) {
      console.error(error)
      return
    } else if (data.isAvailable) {
      return window.EdgeTierChat?.open()
    }
  })
}

export const overrideScopeLiveChat = () => {
  // TODO: This will be deleted after Intercom implementation so only the intercom file will be used.
  if ((isIntercomCookieEnabled() || isIntercomFFEnabled())) {
    return null
  } else {
    window.startLiveChat = openLiveChat
  }
}
