import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import CustomPropTypes from '../../../customPropTypes'
import useStyles from './pageHeading.styles'
import Container from '../../components/Container'

import Dropdown from '../Dropdown'
import Icon from '../Icon'

const PageHeading = props => {
  const classes = useStyles()
  return (
    <Container className={classes.pageHeadingWrapper}>
      <div className={classes.pageHeading}>
        {props.backTo ? (
          <NavLink to={props.backTo} className={classes.headingBackLink}>
            <Icon name="back" />
          </NavLink>
        ) : null}
        <h2 className={classes.headingTitle}>{props.title}</h2>
        {props.menuItems?.length > 0 ? (
          <div className={classes.dropdownWrapper}>
            <Dropdown items={props.menuItems} />
          </div>
        ) : null}
      </div>
    </Container>
  )
}

PageHeading.propTypes = {
  title: PropTypes.string.isRequired,
  backTo: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: CustomPropTypes.link,
      selected: PropTypes.bool,
    }),
  ),
}

export default PageHeading
