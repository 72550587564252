import Env from '@env';
import { getMirrorAllowedDomains, isMirrorFFEnabled } from "."
import parseEnvString from "./parseEnvString"
class OriginSingleton {
  private static instance: OriginSingleton
  private origin: string
  private isFFEnabled: boolean

  mirrorId: string

  private constructor() {
    this.origin = window.origin
    this.isFFEnabled = isMirrorFFEnabled()
    this.mirrorId = window.location.hostname.slice(window.location.hostname.indexOf('.') + 1)
  }

  public static getInstance(): OriginSingleton {
    if (!OriginSingleton.instance) {
      OriginSingleton.instance = new OriginSingleton()
    }
    return OriginSingleton.instance
  }

  public getOrigin(): string {
    return this.origin
  }

  public isMirror(): boolean {
    const originalSite = Env.get('VITE_SITE_URL')

    return !originalSite?.includes(this.mirrorId)
  }


  public extractDomain(url:string): string {
    const parts =  url.split('.')
    if(parts.length > 2) {
      return parts.slice(-2).join('.')
    }else{
      return this.origin
    }
  }

  public extractSubDomain(url:string): string {
    const parts = url.split('.')

    if(parts.length > 2) {
      return parts.slice(0,-2).join('.')
    }else{
      return ''
    }
  }

  private isNonProdOrigin(): boolean {
    return this.origin.includes('localhost') || parseEnvString('VITE_ENV') !== 'prod'
  }

  public replaceMirrorUrlOrDefault(url) {

    const allowedDomains = getMirrorAllowedDomains()

    if(!url || !this.isFFEnabled || !this.isMirror() || this.isNonProdOrigin()) {
      return url
    }

    const urlObj = new URL(url);
    const currentDomainObj = new URL(this.origin);

    const hostnameParts = urlObj.hostname.split('.');
    const currentDomainParts = currentDomainObj.hostname.split('.');

    // Extract the domain parts for checking against allowed domains
    const domain = hostnameParts.slice(-2).join('.');

    // Check if the domain is in the allowed domains list
    if (!allowedDomains.includes(domain)) {
        return url; // Return the original URL if the domain is not allowed
    }

    // Extract the new domain parts (e.g., 'bolblack.ag', 'bolblue.it', etc.)
    const newDomain = currentDomainParts.slice(-2).join('.');

    // Preserve the subdomains if they exist
    const subdomains = hostnameParts.length > 2 ? hostnameParts.slice(0, -2).join('.') + '.' : '';

    return url.replace(urlObj.hostname, `${subdomains}${newDomain}`);
  }

}

const replaceMirrorUrlHelper = (url: string) => {
  return OriginSingleton.getInstance().replaceMirrorUrlOrDefault(url);
}

export { OriginSingleton, replaceMirrorUrlHelper } ;
