import { makeStyles } from '../materialUiWrapper'
import { aspectRatio, hoverDevice } from '../../config/utils'


const useStyles = makeStyles(theme => ({
  linkCard: {
    ...aspectRatio(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '138%',
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.custom.fontSize.md,
    lineHeight: theme.custom.lineHeight.md,
    textAlign: 'center',
    color: theme.custom.linkCard?.color || theme.custom.palette.neutral1,
    backgroundColor: theme.custom.linkCard?.bgColor || theme.custom.palette.neutral1_9,
    position: 'relative',

    '&:visited': {
      backgroundColor: theme.custom.linkCard?.bgColorVisited || theme.custom.palette.neutral1_9,
    },
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.linkCard?.bgColorHover || theme.custom.palette.neutral1_24,
        color: theme.custom.linkCard?.hoverColor || theme.custom.palette.neutral1
      },
     '&:active': {
        backgroundColor: theme.custom.palette.neutral1,
        color: theme.custom.linkCard?.activeColor || theme.custom.palette.quaternary800,
      },  
    },
    
    '& span': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
}))

export default useStyles
