import React from 'react'
import { Link } from 'react-router-dom'

import Container from '../../ui/components/Container'
import Heading from '../../ui/components/Heading'
import Grid from '../../ui/components/Grid'
import GridSkeleton from '../../ui/components/GridSkeleton'
import GameCard from '../../ui/components/GameCard'
import EmptyMessage from '../../ui/components/EmptyMessage'
import Button from '../../ui/components/Button'
import { useTranslations } from '../../i18n'
import { useFavorites } from '../../asyncData'
import noFavoritesImageSvg from '../../assets/icons/crying-star.svg?url'

const NoFavoritesImage = ({className}) => {
  return <img src={noFavoritesImageSvg} className={className} alt="no-results"></img>
}

const Favorites = () => {
  const formatMessage = useTranslations()
  const { isSuccessFavorites, favorites } = useFavorites({
    useErrorBoundary: true,
  })

  if (favorites && !favorites.FavoritesGames.length) {
    return (
      <EmptyMessage
        image={<NoFavoritesImage />}
        title={formatMessage('favorites:empty-title')}
        description={formatMessage('favorites:empty-hint')}
      >
        <Button as={Link} to="/">
          {formatMessage('action:explore-favorites')}
        </Button>
      </EmptyMessage>
    )
  }

  return (
    <Container>
      {isSuccessFavorites ? (
        <>
          <Heading
            title={formatMessage('noun:favorites')}
            number={favorites?.FavoritesGames.length}
          />
          <Grid>
            {favorites?.FavoritesGames.map(game => (
              <GameCard key={`${game.GameID}.${game.ExtGameID}`} game={game} origin="Favorites" />
            ))}
          </Grid>
        </>
      ) : (
        <GridSkeleton />
      )}
    </Container>
  )
}

export default Favorites
