import  React,{ useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '../../../i18n'
import { useAuth } from '../../../authentication'

import Button from '../../../ui/components/Button'
import { useCashierInformation } from '../../../asyncData'
import ModalDialog from '../ModalDialog'

import useModalStyles from './modal.styles'
import ProvidersIds from '../gameProviderIds'


// TO DO, line 90, add enum for providers with scroll available.

const IFRAME_ID = 'quickDepositCashier'

const GamePlayModal = props => {
  const formatMessage = useTranslations()

  const {
    quickDepositEnabled,
    title,
    gameUrl,
    open,
    onClose,
    providerId,
    isScrollEnabled,
  } = props

  const ref = useRef()
  const [isCashierOpen, setIsCashierOpen] = useState(false)

  const modalClasses = useModalStyles()

  const { logged } = useAuth()
  const { data, isSuccess } = useCashierInformation({
    enabled: quickDepositEnabled && logged,
    useErrorBoundary: true,
  })

  useEffect(() => {
    if (!quickDepositEnabled) return
    if (!isSuccess) return

    if (isCashierOpen) {
      ref.current.submit()
    }
  }, [isSuccess, isCashierOpen, quickDepositEnabled])

  const allowAttribute = providerId && providerId === ProvidersIds.BetGamesTV ? {allow: 'autoplay; fullscreen; encrypted-media'} : {}

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      className={modalClasses.playContainer}
      title={title}
      executedFrom='gameplayModal'
      providerId={providerId}
      actionRight={
        quickDepositEnabled && isSuccess && data ? (
          <Button
            variant="primary"
            height="small"
            onClick={() => setIsCashierOpen(true)}
            className={modalClasses.quickDepositButton}
            icon="money"
          >
            {formatMessage('game-info:quick-deposit')}
          </Button>
        ) : null
      }
    >
      <div className={modalClasses.iframeContainer}>
        {/*TODO: scrolling and frameBorder are deprecated props in iframe html tag*/}
        <iframe
          src={gameUrl}
          scrolling={isScrollEnabled ? 'yes' : 'no'}
          {...allowAttribute}
          frameBorder="0"
          title={title}
          className={modalClasses.iframe}
          allow="fullscreen"
        />
        {isCashierOpen && (
          <div className={modalClasses.cashierContainer}>
            <iframe
              className={modalClasses.cashierIframe}
              name={IFRAME_ID}
              title={formatMessage('noun:cashier')}
            />
            <form
              ref={ref}
              action={data?.Url}
              method="POST"
              target={IFRAME_ID}
            >
              <input type="hidden" name="ID" value={data?.ID ?? ''} />
              <input type="hidden" name="bp" value={false} />
              <input type="hidden" name="tid" value={0} />
              <input
                type="hidden"
                name="rurl"
                value={window.location.origin}
              />
              <input
                type="hidden"
                name="PreviusUrl"
                value={window.location.origin}
              />
              <input
                type="hidden"
                name="RedirectURL"
                value="CashierMenu.asp"
              />
            </form>
          </div>
        )}
      </div>
    </ModalDialog>
  )
}


export default GamePlayModal
