import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'

import { useTranslations } from '../../i18n'

import Skeleton from '../components/Skeleton'
import ErrorNote from '../components/ErrorNote'

import Summary from './Summary'
import SummaryItem from './SummaryItem'
import BonusInfo from './BonusInfo'
import PromotionalBonusInfo from './PromotionalBonusInfo'
import useStyles from './myAccount.styles'

const SummaryMyAccount = props => {
  const {
    isLoading,
    isLoadingBonus,
    hasError,
    totalBalance,
    cashBalance,
    bonusBalance,
    lockedCash,
    bonus,
    promotionalBonus,
    onErrorClick,
  } = props
  const formatMessage = useTranslations()
  const bonusRef = useRef()
  const promotionalRef = useRef()
  const [bonusOpen, setBonusOpen] = useState(false)
  const [promotionalOpen, setPromotionalOpen] = useState(false)

  const classes = useStyles()

  return (
    <Summary
      variant="myaccount"
      aside={
        isLoadingBonus || hasError || promotionalBonus > 0 ? (
          <SummaryItem
            hasError={
              hasError ? (
                <ErrorNote>
                  {formatMessage('bonus-wallet:bonus-error', {
                    cta: formatMessage => (
                      <button onClick={onErrorClick}>{formatMessage}</button>
                    ),
                  })}
                </ErrorNote>
              ) : null
            }
            onClick={() => setPromotionalOpen(true)}
            hideInfo={isLoadingBonus}
            ref={promotionalRef}
            label={
              isLoadingBonus ? (
                <Skeleton width={156} />
              ) : (
                formatMessage('noun:promotional-bonus')
              )
            }
            amount={isLoadingBonus ? <Skeleton width={72} /> : promotionalBonus}
          />
        ) : null
      }
    >
      <SummaryItem
        type="total"
        label={formatMessage('noun:total-balance')}
        amount={isLoading ? <Skeleton width={120} /> : totalBalance}
      />
      <SummaryItem
        label={formatMessage('noun:cash-balance')}
        amount={isLoading ? <Skeleton width={96} /> : cashBalance}
      />
      <SummaryItem
        ref={bonusRef}
        onClick={() => setBonusOpen(true)}
        label={formatMessage('noun:bonus-balance')}
        amount={isLoading ? <Skeleton width={72} /> : bonusBalance}
      />
      <SummaryItem
        type="detail"
        label={formatMessage('noun:locked-cash-funds')}
        amount={isLoading ? <Skeleton width={72} /> : lockedCash}
      />
      <SummaryItem
        type="detail"
        label={formatMessage('noun:bonus-funds')}
        amount={isLoading ? <Skeleton width={72} /> : bonus}
      />
      <Popover
        classes={{ paper: classes.paper }}
        open={bonusOpen}
        anchorEl={bonusRef?.current}
        onClose={() => setBonusOpen(false)}
      >
        <BonusInfo />
      </Popover>
      <Popover
        classes={{ paper: classes.paper }}
        open={promotionalOpen}
        anchorEl={promotionalRef?.current}
        onClose={() => setPromotionalOpen(false)}
      >
        <PromotionalBonusInfo />
      </Popover>
    </Summary>
  )
}

SummaryMyAccount.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingBonus: PropTypes.bool,
  hasError: PropTypes.bool,
  totalBalance: PropTypes.number.isRequired,
  cashBalance: PropTypes.number.isRequired,
  bonusBalance: PropTypes.number.isRequired,
  lockedCash: PropTypes.number.isRequired,
  bonus: PropTypes.number.isRequired,
  promotionalBonus: PropTypes.number,
  onErrorClick: PropTypes.func,
}

export default SummaryMyAccount
