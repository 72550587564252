import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'
import texts from '../../config/texts'

const useStyles = makeStyles(
  theme => ({
    wrapPaymethodsList: {
      '& $paymethodsTitle': {
        // Increase specificity
        ...texts.stepUp2,
        fontWeight: theme.custom.fontWeight.bold,
        color: theme.custom.paymethod?.list?.color || theme.custom.palette.neutral1_87,
      },
    },
    small: {
      [mq('md')]: {
        marginBottom: '2rem',
      },
    },
    medium: {
      marginTop: '1rem',

      [mq('md')]: {
        marginTop: '2rem',
      },
    },
    paymethodsTitle: {},
    paymethodsList: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '1px 0',
      paddingLeft: 0,
      marginTop: '1rem',
      marginBottom: 0,

      [mq('sm')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1.5rem 0',
      },
      [mq('md')]: {
        marginTop: '1.5rem',
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      [mq('lg')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    paymethodListItem: {
      boxShadow: `0 -1px 0 ${theme.custom.palette.neutral1_16}, 0 1px 0 ${theme.custom.paymethod?.list?.borderColor || theme.custom.palette.neutral1_16}`,

      '&:nth-child(even)': {
        backgroundColor: theme.custom.paymethod?.list?.background || theme.custom.palette.neutral1_5,
      },
    },
    paymethodsListActions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '3.25rem',
    },
    paymethod: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.custom.paymethod?.background || 'transparent',
      [mq('sm')]: {
        display: 'block',
      },
    },
    paymethodImage: {
      flex: '0 0 7.5rem',
      padding: '0.75rem 1rem',
      [mq('xs')]: {
        flex: '0 0 9.25rem',
      },
      [mq('sm')]: {
        height: '4.25rem',
        borderBottom: `1px solid ${theme.custom.paymethod?.list?.borderColor || theme.custom.palette.neutral1_16}`,
      },

      '& > img': {
        margin: '0 auto',

        [mq('sm')]: {
          marginLeft: 0,
        },
      },
    },
    paymethodImageWrapper: {
      height: '100%',

      [mq('sm')]: {
        maxWidth: '7.1875rem',
      },
    },
    paymethodInfo: {
      flexGrow: 1,
      padding: '0.75rem 1rem',
      position: 'relative',

      [mq('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '4.25rem',
      },
    },
    paymethodInfoUds: {
      padding: '0.125rem 0',
      fontSize: theme.custom.fontSize.md,
      color: theme.custom.paymethod?.InfoUds?.color || theme.custom.palette.neutral1_87,

      '& > span': {
        display: 'inline-block',
        width: '2.5rem',
        color: theme.custom.paymethod?.InfoUds?.color || theme.custom.palette.neutral1_60,
      },
    },
    paymethodButton: {
      width: '2.75rem',
      height: '2.75rem',
      padding: 0,
      border: 0,
      borderRadius: theme.shape.borderRadius,
      fontSize: theme.custom.fontSize.xl,
      color: theme.custom.paymethod?.list?.btnColor || theme.custom.palette.neutral1_60,
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      outline: 'none',
      cursor: 'pointer',

      '&[disabled]': {
        cursor: 'default',
      },

      '& .icon': {
        display: 'block',
        margin: '0 auto',
        fill: 'currentColor ',
      },
    },
    paper: {
      maxWidth: 'min(calc(100% - 2rem), 22rem)',
      padding: '0.75rem 1rem 1rem',

      '& h1, & h2, & h3, & h4, & h5, & h6': {
        marginTop: 0,
        marginBottom: '0.5rem',
        fontSize: theme.custom.fontSize.md,
        fontWeight: theme.custom.fontWeight.extrabold,
        color: theme.custom.palette.neutral2,
      },
      '& p': {
        marginTop: 0,
        marginBottom: 0,
        ...texts.stepDown1,
        color: theme.custom.palette.neutral2,

        '& + p': {
          marginTop: '0.5rem',
        },
      },
    },
  }),
  { name: 'paymethods', index: 1 },
)

export default useStyles
