import React from 'react'

import Grid from '../Grid'
import GameCard from '../GameCard'
import HeadingSkeleton from '../Heading/Skeleton'

const GAME_CARD_SKELETON_COUNT = 8

const GridSkeleton = () => {
  const items = new Array(GAME_CARD_SKELETON_COUNT)
    .fill(null)
    .map((_, i) => <GameCard.Skeleton key={i} />)

  return (
    <>
      <HeadingSkeleton />
      <Grid>{items}</Grid>
    </>
  )
}

export default GridSkeleton
