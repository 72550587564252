import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '0.5rem',
    gridRowGap: '0.5rem',
    [mq('sm')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [mq('md')]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [mq('lg')]: {
      gridTemplateColumns: 'repeat(8, 1fr)',
    },
  },
  promotions: {
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    [mq('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [mq('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [mq('lg')]: {
      marginTop: '3rem',
    },
  },
  promotionDetail: {
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    [mq('sm')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [mq('lg')]: {
      marginTop: '3rem',
    },
  },
  mobileLiveCasino: {
    marginTop: '1rem',
    gridTemplateColumns: 'repeat(2, minmax(auto, 25.375rem))',
    gridColumnGap: '0.5rem',
    gridRowGap: '0.5rem',
  },
}))

export default useStyles
