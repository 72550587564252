export const promotionCarouselOption = {
    perView: 4,
    gap: 33,
    startAt: 0,
    peek: 0,
    perTouch:4,
    type: 'carousel',
    breakpoints: {
      780: {
        type: 'carousel',
        autoplay: 3000,
        hoverpause: true,
        startAt: 0,
        perView: 1,
        perTouch:1,
        gap:0,
      },
      1295: {
        type: 'carousel',
        autoplay: 3000,
        hoverpause: true,
        startAt: 0,
        perView: 2,
        perTouch:2,
        gap: 20,
      },
    },
  }
  
  export const customStyle = {
    container:{
      position:'static'
    }
  }