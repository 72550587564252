import { makeStyles } from '../materialUiWrapper';

const useStyles = makeStyles(theme => ({
  cardContent: {
    position: 'relative'
  },
  gameCard: { 
    position: 'relative',
    borderRadius: '4px',
    overflow: 'hidden',
    width: 'inherit',
    display: props => (props.size == 'size' ? 'inline-table' : 'flex'),
    '& img': {
      width: '100%',
      display: 'block',
      minHeight: 1,
    },
  },
}))

export default useStyles
