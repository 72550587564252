import { createRequester } from './requester';
import { deviceBrandId } from '../../ui/config/deviceUtils';

// TO-DO: remove old services when new lobby endpoint is enabled in all brands
const createCardCategoriesService = () => {
  const requester = createRequester('lobby/card-categories')
  requester?.setStaticParams(deviceBrandId())
  return requester
}
  
const createCardCategoriesByRouteService = () => {
  const requester = createRequester('lobby/categoriesByRoute')
  requester?.setStaticParams(deviceBrandId())
  return requester
}

const cardCategoriesService = createCardCategoriesService();
const cardCategoriesByRouteService = createCardCategoriesByRouteService();

// to-do: remove old services when new endpoint is enabled in all brands
const getCardCategories = async (parameters) => {
  const requester = cardCategoriesService
  return await requester.request({ ...parameters })
}

const getCardCategoriesByRoute = async (parameters) => {
  const requester = cardCategoriesByRouteService
  return await requester.request({ ...parameters })
}

export {
  getCardCategories,
  getCardCategoriesByRoute,
}