import PropTypes from 'prop-types'
import cx from 'classnames'

import { useTranslations } from '../../../i18n'
import CustomPropTypes from '../../../customPropTypes'
import useStyles from './button.styles'

import Loader from '../Loader'
import Icon from '../Icon'

const Button = props => {
  const {
    as,
    id,
    children,
    variant,
    widthBehaviour,
    textBehaviour,
    height,
    isLoading,
    icon,
    className,
    fontWeight,
    ...restProps
  } = props
  const formatMessage = useTranslations()
  const classes = useStyles({ variant })

  const Component = as

  const buttonClasses = cx(
    classes.button,
    classes[variant],
    classes[height],
    classes[widthBehaviour],
    classes[fontWeight],
    classes[textBehaviour],
    icon && classes.hasIcon,
    className,
  )

  // NOTE provide default type for buttons implemented with button tag
  return (
    <Component
      id={id}
      className={buttonClasses}
      type={as === 'button' ? 'button' : undefined}
      {...restProps}
    >
      <span className={classes.buttonText} >
        {isLoading ? formatMessage('action:loading') : children}
        {icon ? (
          isLoading ? null : (
            <Icon className={classes.buttonIcon} name={icon} />
          )
        ) : null}
      </span>
      {isLoading ? <Loader className={classes.buttonLoader} /> : null}
    </Component>
  )
}

Button.defaultProps = {
  as: 'button',
  variant: 'primary',
  widthBehaviour: 'adaptative',
  height: 'large',
  textBehaviour: 'uppercase',
  fontWeight: 'bold',
}

Button.propTypes = {
  as: CustomPropTypes.tagOrComponent(['button']).isRequired,
  id: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'flat', 'ghostSolid', 'primarySolid', 'secondarySolid', 'white', 'pill', 'ghostDark'])
    .isRequired,
  widthBehaviour: PropTypes.oneOf(['adaptative', 'full', 'responsive', 'fixed'])
    .isRequired,
  height: PropTypes.oneOf(['extralarge', 'large', 'medium', 'small', 'smaller', 'smallest'])
    .isRequired,
  textBehaviour: PropTypes.oneOf(['capitalize', 'uppercase', 'lowercase', 'none']),
  fontWeight: PropTypes.oneOf(['bold', 'normal']),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
}

export default Button
