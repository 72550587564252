import { makeStyles } from '../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_5,
    boxShadow: theme.custom.shadows.output,
  },
  boxHeader: {
    padding: '0.5rem 0.75rem',
    borderTopLeftRadius: '0.25rem',
    borderTopRightRadius: '0.25rem',
    fontSize: theme.custom.fontSize.sm,
    fontWeight: theme.custom.fontWeight.extrabold,
    lineHeight: theme.custom.lineHeight.xs,
    color: theme.custom.box?.headerColor || theme.custom.palette.neutral1_87,
    backgroundColor: theme.custom.palette.neutral1_5,
  },
  boxBody: {
    padding: '1rem 0.75rem',
  },
  nopadded: {
    '& $boxBody': {
      padding: 0,
    },
  },
}))

export default useStyles
