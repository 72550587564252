import { useCallback, useRef, useEffect, ReactNode } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

interface CustomNavLinkProps extends NavLinkProps {
  activeClassName?: string,
  children: ReactNode,
  props?: any
}

const SCROLL_INTO_VIEW_OPTIONS = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'center',
}

const CustomNavLink = ({ activeClassName, ...props }: CustomNavLinkProps) => {
  const ref = useRef(null)
  const isActiveRef = useRef(null)

  const cb = useCallback(
    mutations => {
      mutations.forEach(mutation => {
        const active = mutation.target?.classList?.contains(activeClassName)
        
        if (active && !isActiveRef.current) {
          mutation.target.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
        }

        isActiveRef.current = active
      })
    },
    [activeClassName],
  )

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const active = ref.current.classList.contains(activeClassName)

    if (active) {
      ref.current.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS)
    }

    const observer = new MutationObserver(cb)
    observer.observe(ref.current, { attributeFilter: ['class'] })
    return () => observer.disconnect()
  }, [cb, activeClassName])

  return <NavLink {...props} activeClassName={activeClassName} ref={ref} exact />
}

export default CustomNavLink