import { post, get } from '../api'
import { apiManagerFactory, API_MANAGER_TYPE } from '../apiManager'

const TARGET_API = apiManagerFactory
  .create(API_MANAGER_TYPE.BASE)
  .setTarget('casexternal')
const TARGET_WALLETS_API = apiManagerFactory
  .create(API_MANAGER_TYPE.WALLETS)
  .setTarget('wallet-balances')

export const fetchGameUrl = async parameters => {
  return post({
    api: TARGET_API,
    endpoint: '/get-game-url',
    payload: parameters,
  })
}

export const fetchGameBonusUrl = async parameters => {
  return post({
    api: TARGET_API,
    endpoint: '/get-game-bonus-url',
    payload: parameters,
  })
}

export const fetchLiveCasinoUrl = async operation => {
  return post({
    api: TARGET_API,
    endpoint: '/live-casino-url',
    payload: { operation },
  })
}

export const fetchBonusInfo = async () => {
  return post({ api: TARGET_API, endpoint: '/get-bonus-info' })
}

export const fetchBonusWalletInfo = async () => {
  return get({
    api: TARGET_WALLETS_API,
    endpoint: '/bonuses',
    validateLegacyModel: false,
  })
}

export const fetchPlayableBalance = async parameters => {
  return get({
    api: TARGET_WALLETS_API,
    endpoint: '/playablebalance',
    validateLegacyModel: false,
    payload: parameters,
  })
}
