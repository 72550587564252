import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Icon from '../Icon'

import useStyles from './avatar.styles'

const Avatar = props => {
  const classes = useStyles()

  return (
    <NavLink id={props.id} to={props.to} className={classes.account}>
      <Icon name="login" />
      <span>{props.text}</span>
    </NavLink>
  )
}

Avatar.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
}

export default Avatar
