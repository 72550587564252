import { makeStyles } from '../../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  bottomTicker: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
    fontWeight: theme.custom.fontWeight.bold,
    color: theme.custom.palette.neutral1,
    backgroundImage: `linear-gradient(
      to bottom,
      ${theme.custom.palette.neutral2_60},
      ${theme.custom.palette.neutral2}
    )`,
    '&.regular': {
      height: '2.75rem',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
    '&.small': {
      height: '2.25rem',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
  },
}))

export default useStyles
