import React from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router-dom'
import { useQueryErrorResetBoundary } from 'react-query'

const ErrorBoundary = ({ children, ...rest }) => {
  const location = useLocation()
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ReactErrorBoundary onReset={reset} resetKeys={[location.key]} {...rest}>
      {children}
    </ReactErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
