import React from 'react'
import { Box as Spacing } from '@material-ui/core'

import Skeleton from '../Skeleton'
import useStyles from './contentSkeleton.styles'

const PromotionDetailSkeleton = props => {
  const classes = useStyles()

  return (
    <Spacing className={classes.detailSkeleton} mt={{ xs: 1, sm: 1.5 }}>
      <div className={classes.heading1}>
        <Skeleton height={32} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
      <div className={classes.heading2}>
        <Skeleton height={20} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
      <div className={classes.paragraph}>
        <Skeleton height={12} />
      </div>
    </Spacing>
  )
}

export default PromotionDetailSkeleton
