import { makeStyles } from '../../../../ui/components/materialUiWrapper'
import { mq } from '../../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  gameInfo: {
    display: 'flex',
    padding: '0.75rem 0.75rem 0',
    color: theme.custom.infoModal?.color || theme.custom.palette.neutral1,
    overflow: 'hidden',
    backgroundColor: !theme.custom.universalPrelaunchModal ? theme.custom.infoModal?.background : 'transparent',
    [mq('md')]: {
      display: 'block',
    },
  },
  imageWrapper: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },
  gameInfoImage: {
    width: '99px',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    overflow: 'hidden',

    '& picture': {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },

    '& img': {
      display: 'block',
      maxWidth: '100%',
    },

    [mq('md')]: {
      maxWidth: '100%',
      width: '100%',
      paddingRight: 0,

      '& img': {
        width: '100%',
      },
    },
  },
  gameInfoData: {
    flex: 1,

    paddingLeft: '1rem',
    [mq('sm')]: {
      display: 'flex',
    },
    [mq('md')]: {
      flex: 'auto',
      paddingTop: '0.75rem',
      paddingBottom: '0.75rem',
    },
  },
  gameInfoTexts: {
    [mq('sm')]: {
      flex: 1,
    },
  },
  gameInfoTitle: {
    display: 'flex',
    justifyContent: 'space-between',

    '& h1': {
      display: 'inline-block',
      margin: 0,
      fontSize: theme.custom.fontSize.md,
      lineHeight: theme.custom.lineHeight.md,
      fontWeight: theme.custom.fontWeight.extrabold,
      opacity: 0.87,
    },

    [mq('sm')]: {
      justifyContent: 'flex-start',

      '& h1': {
        fontSize: theme.custom.fontSize.xl,
        lineHeight: theme.custom.lineHeight.xs,
      },
    },
  },
  gameInfoCategory: {
    paddingTop: '0.125rem',
    fontSize: theme.custom.fontSize.sm,
    lineHeight: theme.custom.lineHeight.xs,
    opacity: 0.87,

    [mq('md')]: {
      fontSize: theme.custom.fontSize.md,
      lineHeight: theme.custom.lineHeight.md,
    },
  },
  gameInfoDescription: {
    fontSize: theme.custom.fontSize.xs,
    lineHeight: theme.custom.lineHeight.sm,

    paddingTop: '0.5rem',
    opacity: 0.6,

    [mq('md')]: {
      fontSize: theme.custom.fontSize.sm,
      lineHeight: theme.custom.lineHeight.xs,
    },
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.25rem 0.375rem',
    backgroundColor: theme.custom.infoModal?.background || theme.custom.palette.quaternary700,
    position: 'sticky',
    bottom: 0,

    '& button': {
      backgroundImage: theme.custom?.gradients?.flatGradientYellow,
      color: theme.custom.palette?.sbDarkGraphite,
      '&:hover':{
        backgroundImage: theme.custom?.gradients?.flatGradientYellowHover
      },
      marginLeft: '0.375rem',
      marginRight: '0.375rem',
    },
  },
}))

export default useStyles
