import Env from '@env';
const parseEnvString = envKey => {
    const envValue = Env.get('')[envKey]
  
    if ([null, 'null', undefined, 'undefined'].includes(envValue) ) {
        console.warn(
          `No proper value "${envValue}" provided for ${envKey}. Provide string value for this ${envKey}`
        )
        return false;
    }
  
    return envValue
}
  
  export default parseEnvString
  