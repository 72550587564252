import Env from '@env';
import {
    Environment,
    KameleoonClient,
    SDKConfigurationType,
    UserAgent,
    DeviceType,
    Device,
    OperatingSystemType,
    OperatingSystem,
  } from '@kameleoon/javascript-sdk'
  import { checkKameleoonClientData, getDomainFromLocation } from './utils'
import { getUserDevice } from '../../ui/config/deviceUtils'
import { getOperatingSystem } from '../../hooks/useIsIOS'
  
  const environmentMap = {
    dev: Environment.Development,
    qa: Environment.Development,
    ppd: Environment.Staging,
    production: Environment.Production,
  }
  const ENV = Env.get('VITE_ENV')
  const DOMAIN = getDomainFromLocation()
  const SITE_CODE = Env.get('VITE_SITE_CODE')

  const configuration: Partial<SDKConfigurationType> = {
    updateInterval: 20, //refreshing time in minutes
    environment: environmentMap[ENV],
    domain: DOMAIN,
  }
  const deviceMap = {
    desktop: DeviceType.Desktop,
    mobile: DeviceType.Phone,
    tablet: DeviceType.Tablet
  }

  const operatingSystemMap = {
    ios: OperatingSystemType.IOS,
    android: OperatingSystemType.Android,
    windows: OperatingSystemType.Windows,
    mac: OperatingSystemType.Mac,
    linux: OperatingSystemType.Linux,
    windowsPhone: OperatingSystemType.WindowsPhone,
  }
  // singleton instance
  let kameleoonInstance: KameleoonClient | null
  
  export const getKameleoonInstance = async (): Promise<KameleoonClient> => {
    if (kameleoonInstance) {
      return kameleoonInstance
    }
  
    const client = new KameleoonClient({ siteCode: SITE_CODE, configuration })
    kameleoonInstance = client
    const userOperatingSystem = getOperatingSystem()
    const userAgent = new UserAgent(window.navigator.userAgent)
    const userDevice = getUserDevice()
    const device = new Device(deviceMap[userDevice])
    const operatingSystem = new OperatingSystem(
      operatingSystemMap[userOperatingSystem],
    )
    try {
      checkKameleoonClientData()
      await client.initialize()
      const visitorCode = client.getVisitorCode()
      client.getFeatureFlagVariationKey(visitorCode, 'websites-ff-universal_navigation')
      const engineCode = client.getEngineTrackingCode(visitorCode)
      client.addData(visitorCode, userAgent)
      client.addData(visitorCode, device)
      client.addData(visitorCode, operatingSystem)
      const kameleoonScript = document.getElementById('kameleoonScript')
      if(!kameleoonScript) {
        const script = document.createElement('script')
        script.id = 'kameleoonScript'
        script.textContent = engineCode
        document.body.appendChild(script)
      }
    } catch (error) {
      console.error('error kameleoon', error)
    }
    return kameleoonInstance
  }
  
  export const isFeatureFlagActive = async (key: string) => {
    const client = await getKameleoonInstance()
    const visitorCode = client.getVisitorCode()
    return client.getFeatureFlagVariationKey(visitorCode, key)
  }
