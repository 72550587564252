const getNames = () => {
  const icons = import.meta.glob('../../../assets/icons/*.svg')

  const fullNames = Object.keys(icons).map(key => String(key))
  const names = fullNames.map(el =>
    el
      .replace('../../../', '')
      .replace('assets/icons/', '')
      .replace('.svg', ''),
  )

  return names
}

const names = getNames()

const loadIcon = async name => {
  const module = await import(`../../../assets/icons/${name}.svg?raw`)
  return module.default
}

const getIcons = async () => {
  const iconPromises = names.map(async name => {
    const content = await loadIcon(name)
    return { name, content }
  })

  const iconObjects = await Promise.all(iconPromises)

  return iconObjects.reduce((acc, { name, content }) => {
    acc[name] = content
    return acc
  }, {})
}

export default getIcons
