import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Collapse } from '@material-ui/core'

import useStyles from './errorMessage.styles'
import Icon from '../../components/Icon'

const ErrorMessage = props => {
  const { className, type, title, description, ...rest } = props
  const classes = useStyles()
  const errorClasses = cx(classes.error, type && classes[type])
  return (
    <Collapse
      classes={{
        wrapperInner: classes[className],
      }}
      {...rest}
    >
      <div className={errorClasses}>
        <Icon name="info" />
        {title ? <p className={classes.errorTitle}>{title}</p> : null}
        <p>{description}</p>
      </div>
    </Collapse>
  )
}

ErrorMessage.defaultProps = {
  type: 'fixed',
}

ErrorMessage.propTypes = {
  className: PropTypes.oneOf(['errorFav', 'errorUrl']),
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['fixed', 'fluid']), // TODO: rethink approach
}

export default ErrorMessage
