import Env from '@env';
import { useQuery } from 'react-query'

import { fetchExternalBackToClassic } from './services' 


export const useBackToClassicBtn = () => {
  
  const isNSB = Env.get('VITE_GSETTING') === 'sbnasite'
  const query = useQuery(['cms', 'back to classic button'], fetchExternalBackToClassic,{enabled:isNSB})

  return query
}
