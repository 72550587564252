import { makeStyles } from '../materialUiWrapper'

const useStyles = makeStyles(theme => ({
  contact: {
    padding: '1.875rem 1.25rem 1.25rem 6.375rem',
    color: theme.custom?.contact?.cardColor || theme.custom.palette.neutral1_87,
    position: 'relative',
    backgroundColor: theme.custom?.contact?.bgCardColor || theme.custom.palette.btnSecondary,
  },
  contactImage: {
    position: 'absolute',
    top: '1rem',
    left: '1.25rem',
  },
  contactTitle: {
    marginTop: 0,
    marginBottom: '0.25rem',
    fontSize: theme.custom.fontSize.md,
    fontWeight: theme.custom.fontWeight.extrabold,
  },
  contactText: {
    marginTop: 0,
    marginBottom: '1rem',
    fontSize: theme.custom.fontSize.sm,
  },
}))

export default useStyles
