import React from 'react'
import PropTypes from 'prop-types'

import MobileOrDesktopImg from '../../Img/MobileOrDesktopImg'

import HeroPlaceholder from '../HeroPlaceholder'
import HeroSkeleton from '../HeroSkeleton'

const StaticHero = props => {
  return (
    <MobileOrDesktopImg
      placeholder={<HeroSkeleton />}
      fallback={<HeroPlaceholder />}
      {...props}
    />
  )
}

StaticHero.propTypes = {
  imageMobile: PropTypes.string.isRequired,
  imageDesktop: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default StaticHero
