import React from 'react'
import 'vip-rewards-progress-popup'
import { useContext, useEffect, useState } from 'react'
import { getToken, useAuth } from '../../../authentication'
import { getCookie, isVipSnackbarEnabled } from '../../../business'
import { useEnvData } from '../../../hooks/useEnvData'
import Cookies from 'js-cookie'
import { UserBalanceContext } from '../../../context/UserBalance'

const VipRewards = () => {
  const [snackbarOpened, setSnackbarOpened] = useState(false)
  const { brand } = useEnvData()
  const { logged } = useAuth()
  const { state: { vipData: { value} } } = useContext(UserBalanceContext)
  const showVIPSnackbar = () => {
    return getCookie('showvipsnackbar') !== 'false'
  }

  const shouldShowVIPSnackbar = () => {
    return isVipSnackbarEnabled() && logged && showVIPSnackbar()
  }

  const openVIPRewardsSnackbar = () => {
    if (shouldShowVIPSnackbar()) {
      setSnackbarOpened(true)
      window.dispatchEvent(
        new CustomEvent('showvipmodalsnackbar', {
          bubbles: true,
          detail: { token: getToken() }
        })
      )

      window.addEventListener('closeVipSnackbar', () => {
        Cookies.set('showvipsnackbar', false)
      })
    }
  }

  useEffect(() => {
    openVIPRewardsSnackbar()
  }, [snackbarOpened])

  useEffect(() => {
    const popup = document.querySelector('vip-rewards-progress-popup')
    popup.visible = false
    let nextLevelName = value?.NextLevelName
    let currentLevelName = value?.CurrentLevelName
    let isError = value?.isError
    let progress = value?.CurrentProgress || 0

    if (nextLevelName === null || currentLevelName === 'N/A') {
      nextLevelName = 'Silver'
      currentLevelName = 'Bronze'
    }

    popup.nextLevelName = nextLevelName
    popup.currentLevelName = currentLevelName
    popup.progress = progress + "%"
    popup.loading = value?.isLoading
    popup.serviceError = isError
    popup.updatedAt = value?.UpdatedAt
    // vip snackbar uses its own props for displaying data
    popup.nextLevelNameSnackbar = nextLevelName
    popup.currentLevelNameSnackbar = currentLevelName
    popup.progressSnackbar = progress + "%"
    popup.loadingFlagSnackbar = value?.isLoading
    popup.serviceErrorSnackbar = isError
    popup.updatedAtSnackbar = value?.UpdatedAt
}, [value])

  return <vip-rewards-progress-popup brand='nbol-sac' gsetting={brand}></vip-rewards-progress-popup>
}

export default VipRewards;