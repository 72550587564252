import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import PageContents from '../../ui/components/PageContents'
import Container from '../../ui/components/Container'
import { Box as Spacing } from '@material-ui/core'
import Error from '../../ui/components/Error'
import ButtonGroup from '../../ui/components/ButtonGroup'
import Button from '../../ui/components/Button'
import { useTranslations } from '../../i18n'
import Navigation from '../../context/Navigation'

const NotFound = props => {
  const { onGoToHome } = props
  const formatMessage = useTranslations()

  return (
    <PageContents>
      <Navigation />
      <Container maxWidth="sm">
        <Spacing my={{ xxs: 5.5, md: 8 }}>
          <Error
            icon="not-found"
            title={formatMessage('error:404-title')}
            description={formatMessage('error:404-description')}
          >
            <ButtonGroup>
              <Button as={Link} onClick={onGoToHome} to="/">
                {formatMessage('action:go-homepage')}
              </Button>
            </ButtonGroup>
          </Error>
        </Spacing>
      </Container>
    </PageContents>
  )
}

NotFound.propTypes = {
  onGoToHome: PropTypes.func,
}

export default NotFound
