import { makeStyles } from '../components/materialUiWrapper'
import { mq } from '../config/utils'

const gap = '0.75rem'

const useStyles = makeStyles(theme => ({
  panel: {
    maxWidth: '33.75rem',
    padding: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.custom.shadows.output,
    backgroundColor:  theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_5,
  },
  summary: {
    marginBottom: '1.5rem',

    [mq('sm')]: {
      marginBottom: '2rem',
    },
  },
  actions: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: gap,

    [mq('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',

      '& > :last-child': {
        gridRow: '1 / 2',
        gridColumn: '1 / 2',
      },
    },

    '& + &': {
      marginTop: gap,
    },
  },
}))

export default useStyles
