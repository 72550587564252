import { useEffect, useRef } from 'react'
import {
  createCashierFormWithDraw,
  getUniversalBalanceElement,
  isCashierFormWithDrawCreated,
  sendCashierForm,
} from './UserBalance.utils'
import { useAuth } from '../../../../../authentication'
import '@platform/universal-balance'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'universal-balance': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}

const UserBalance = ({ logout, logged, balances, vipData, userData, usextremepush }) => {
  const { redirectToLogin, redirectToJoin } = useAuth()
  const userBalanceWrapperRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    window.addEventListener('logout', logout)
    window.addEventListener('onclickwithdrawbutton', sendCashierForm)

    return () => {
      window.removeEventListener('logout', logout)
      window.removeEventListener('onclickwithdrawbutton', sendCashierForm)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('drawerpopupclosed', () => {
      document.body.removeAttribute('style')
    })

    return () => {
      window.removeEventListener('closedrawerpopup', () => {
        document.body.removeAttribute('style')
      })
    }
  }, [])

  useEffect(() => {
    const universalBalance = getUniversalBalanceElement()
    if (universalBalance) {
      universalBalance.authenticated = logged
    }
  }, [logged])

  useEffect(() => {
    const universalBalance = getUniversalBalanceElement()

    if (universalBalance) {
      universalBalance.options = JSON.stringify({
        customerId: userData?.customerId,
        name: userData?.name,
        profile: {
          bonusAmount: balances?.BonusMoneyBalance,
          cashBalanceAmount: balances?.AvailableBalance,
          freeplayAmount: balances?.FreePlayBalance,
          hasBonusAllowed: balances?.IsBonusWalletAllowed,
          pendingBetsAmount: balances?.PendingBets,
          totalBalanceAmount: balances?.TotalBalance,
        },
      })
    }
  }, [userData, balances])

  useEffect(() => {
    const universalBalance = getUniversalBalanceElement()

    if (universalBalance) {
      universalBalance.level = JSON.stringify({
        title: vipData?.CurrentLevelName,
        percent: vipData?.CurrentProgress,
        blockLevel: false,
        UpdatedAt: vipData?.UpdatedAt,
      })
      universalBalance.usextremepush = usextremepush
    }
  }, [vipData, usextremepush])

  useEffect(() => {
    if (!isCashierFormWithDrawCreated && userBalanceWrapperRef.current) {
      userBalanceWrapperRef.current.innerHTML = createCashierFormWithDraw()
    }
  }, [])

  useEffect(() => {
    const handleClickLogin = () => {
      redirectToLogin();
    }

    window.addEventListener('onclicktologin', handleClickLogin);

    return () => {
      window.removeEventListener('onclicktologin', handleClickLogin);
    };
  }, []);

  useEffect(() => {
    const handleClickJoin = () => {
      redirectToJoin();
    }

    window.addEventListener('onclicktojoin', handleClickJoin);

    return () => {
      window.removeEventListener('onclicktojoin', handleClickJoin);
    };
  }, []);
  return (
    <>
      <span ref={userBalanceWrapperRef} />
      <universal-balance></universal-balance>
    </>
  )
}

export default UserBalance
