import Env from '@env';
import { isRafEndpointFFEnabled } from '../../config'
import { post, get } from '../api'
import { apiManagerFactory, API_MANAGER_TYPE } from '../apiManager'
// TO-DO: clean this conditional  after new api iscompletely merged

enum ManagerTypes {
  Raf = 'RAF',
  Base = 'BASE'
}

const isNewRaFEndpoint = Env.get('VITE_API_RAF_ENDPOINT') && isRafEndpointFFEnabled();

const getTargetApi = (type?: 'raf') => {
  let managerType: ManagerTypes = ManagerTypes.Base
  if (type === 'raf' && isNewRaFEndpoint) {
    managerType = ManagerTypes.Raf
    return apiManagerFactory.create(API_MANAGER_TYPE[managerType]).setTarget('referrals')
  }
  return apiManagerFactory.create(API_MANAGER_TYPE[managerType]).setTarget('customer')
}

export const fetchProfile = async () => {
  return post({ api: getTargetApi(), endpoint: '/profile' })
}

export const updateBalance = async () => {
  return post({ api: getTargetApi(), endpoint: '/update-balance' })
}

// REVIEW add mobile and isTournament flags?
export const fetchCashierInformation = async (operation = 'cashier') => {
  const op = operation?.queryKey[0] //cashier
  return post({
    api: getTargetApi(),
    endpoint: '/get-cashier-information-to-login',
    payload: { operation: op || 'cashier' },
  })
}

export const referFriends = async () => {
  const api = getTargetApi('raf');
  const endpoint = '/refer-friends';

  if (isNewRaFEndpoint) {
    return get({ api, endpoint, payload: undefined, validateLegacyModel: false })
  } else {
    return post({ api, endpoint })
  }
}

export const referAFriend = async payload => {
  return post({ api: getTargetApi('raf'), endpoint: '/reference-sent', payload })
}

export const fetchMyReferrals = async payload => {
  return post({ api: getTargetApi('raf'), endpoint: '/my-referrals', payload })
}

export const fetchMyReferralsHistory = async (startPosition: number, totalPerPage: number) => {
  return post({
    api: getTargetApi('raf'), endpoint: '/my-referrals-history', payload: {
      startPosition,
      totalPerPage,
    }
  })
}

export const fetchVipLevels = async () => {
  return post({ api: getTargetApi(), endpoint: '/rewards' })
}
