import merge from 'deepmerge'
import baseTheme from '../baseTheme'
import brandPalette from './palette'
import UniversalFooter from '../../../ui/external-components/Footer'
import noResultsSvg from '../../../assets/icons/no-results.svg?url'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const highRollerPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette();
  const palette = merge(brandPalette, cssVariablesPalette);

  return {
    palette,
    brand: {
      namespace: 'hrnasite',
      name: 'High Roller Casino',
      website: 'HighRollerCasino.ag',
      email: 'help@highrollercasinocs.ag',
      help: 'https://help.highrollercasino.ag/',
      themeColor: palette.quaternary800,
      bonusInfoUrl:
        'https://help.highrollercasino.ag/articles/bonus-tutorial-and-faq',
    },
    sideMenu: {
      selectedImg:
        'brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(325%) hue-rotate(183deg) brightness(88%) contrast(95%)',
      activeImg:
        'brightness(0) saturate(100%) invert(3%) sepia(15%) saturate(991%) hue-rotate(53deg) brightness(98%) contrast(90%)',
    },
    buttons: {
      primaryFrontColor: baseTheme.palette.neutral1,
      primarySolidColor: baseTheme.palette.neutral1,
      secondarySolidColor: baseTheme.palette.neutral1,
      secondarySolidBg: palette.primary800,
      iconColorDisabled: baseTheme.palette.neutral1_38,
      ghostSolidBg: 'transparent',
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: baseTheme.palette.neutral1,
      hover: {
        ghostSolidBg: baseTheme.palette.neutral1,
        ghostSolidColor: baseTheme.palette.neutral1,
      },
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    pageHeading: {
      bgColor: palette.quaternary700,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: baseTheme.palette.neutral1_60,
      variant: 'filled',
    },
    bonusTable: {
      headerSize: null,
      headColor: baseTheme.palette.neutral1,
      headBgColor: null,
      tableVariant: 'dark',
    },
    selectInput: {
      border: `2px solid ${baseTheme.palette.neutral1_16}`,
      borderColorHover: baseTheme.palette.neutral1_60,
      iconColor: baseTheme.palette.neutral1,
      color: baseTheme.palette.neutral1_87,
    },
    explanatoryFooter: {
      wrapper: palette.placeholder,
      text: baseTheme.palette.neutral1,
    },
    header: {
      logoHeight: '100%',
    },
    infoModal: {
      background: palette.quaternary700,
    },
    rafSocialShareMessage: 'raf:hr:twitter-message',
    needHelp: {
      mainColor: palette.quaternary800,
    },
    footer: <UniversalFooter />,
    edgeTier: {
      breakpoint: 'xs',
      bottom: '4rem',
      bottomloggedOut: '5.3rem',
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'internal',
      url: `/cashier`,
    },
    intercom: {
      brand: 'highrollercasino'
    },
    input: {
      color: baseTheme.palette.neutral1,
      colorAlt: baseTheme.palette.neutral2,
      bgColor: 'transparent',
      bgAltColor: baseTheme.palette.neutral1,
      borderColor: baseTheme.palette.neutral1_16,
      borderColorAlt: 'transparent',
      borderColorHover: baseTheme.palette.neutral1_60,
      placeholder: baseTheme.palette.neutral1_38,
      placeholderAlt: baseTheme.palette.neutral2_38,
    },
    searchModal: {
      headerBg: palette.quaternary700,
      active: false,
      applyButton: {
        background: palette.secondary800,
      },
      checkBox: {
        checkedColor: palette.primary800,
      },
      allFiltersButton: {
        background: '#252B3B',
      },
    },
    switch: {
      thumbColor: baseTheme.palette.neutral1,
      thumbColorChecked: palette.secondary700,
      trackColor: baseTheme.palette.neutral3_60,
      trackColorChecked: palette.secondary700,
    },
  }
}
export default highRollerPartialTheme
