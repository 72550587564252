import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from '../../i18n'

import Summary from './Summary'
import SummaryItem from './SummaryItem'
import SummaryPopover from './Popover'
import BonusInfo from './BonusInfo'
import PromotionalBonusInfo from './PromotionalBonusInfo'

const STATES = {
  DEFAULT: 'default',
  BONUS: 'bonusBalance',
  PROMOTIONAL_BONUS: 'promotionalBonus',
}

const BonusWalletSummary = props => {
  const { bonusBalance, lockedCash, bonus } = props
  const formatMessage = useTranslations()
  const [state, setState] = useState(STATES.DEFAULT)

  return state === STATES.DEFAULT ? (
    <SummaryPopover
      {...props}
      onBonusClick={() => setState(STATES.BONUS)}
      onPromotionalClick={() => setState(STATES.PROMOTIONAL_BONUS)}
    />
  ) : state === STATES.BONUS ? (
    <BonusInfo onGoBack={() => setState(STATES.DEFAULT)}>
      <Summary size="small">
        <SummaryItem
          label={formatMessage('noun:bonus-balance')}
          amount={bonusBalance}
        />
        <SummaryItem
          type="detail"
          label={formatMessage('noun:locked-cash-funds')}
          amount={lockedCash}
        />
        <SummaryItem
          type="detail"
          label={formatMessage('noun:bonus-funds')}
          amount={bonus}
        />
      </Summary>
    </BonusInfo>
  ) : (
    // state === STATES.PROMOTIONAL_BONUS
    <PromotionalBonusInfo onGoBack={() => setState(STATES.DEFAULT)} />
  )
}

BonusWalletSummary.propTypes = {
  totalBalance: PropTypes.number.isRequired,
  cashBalance: PropTypes.number.isRequired,
  bonusBalance: PropTypes.number.isRequired,
  lockedCash: PropTypes.number.isRequired,
  bonus: PropTypes.number.isRequired,
  promotionalBonus: PropTypes.number,
  hasError: PropTypes.bool,
  isLoadingBonus: PropTypes.bool
}

export default BonusWalletSummary
