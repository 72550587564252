import { makeStyles } from '../../../../ui/components/materialUiWrapper'
import { hoverDevice } from '../../../../ui/config/utils'

const useDrawerStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: !theme.custom.universalPrelaunchModal ? theme.custom.palette.neutral3_70 : '',
    },
    paper: {
      backgroundColor: theme.custom.infoModal?.background || theme.custom.palette.quaternary700,
      color: theme.custom.palette.neutral1,
    },
    header: {
      alignItems: 'center',
      backgroundColor:  theme.custom.infoModal?.background || theme.custom.palette.neutral1_5,
      color: theme.custom.palette.neutral1,
      display: 'flex',
      fontSize: theme.custom.fontSize.xs,
      height: '2rem',
      justifyContent: 'space-between',
      lineHeight: theme.custom.lineHeight.sm,
      paddingLeft: '0.75rem',
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: theme.zIndex.modal,
    },

    title: {
      fontWeight: theme.custom.fontWeight.bold,
      fontSize: theme.custom.fontSize.xs,
      lineHeight: theme.custom.lineHeight.sm,
      margin: 0,
      padding: 0,
      position: 'relative',
      zIndex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      appearance: 'none',
      color: 'inherit',
      fontSize: theme.custom.fontSize.xs,
      lineHeight: theme.custom.lineHeight.sm,
      outline: 'none',
      cursor: 'pointer',
      height: '100%',
      padding: '0 0.75rem',
      position: 'relative',
      zIndex: 1,
      opacity: 0.6,
      [hoverDevice()]: {
        '&:hover': {
          opacity: 0.87,
        },
      },
    },
  }),
  { name: 'MuiDrawer' },
)

export default useDrawerStyles
