import { useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from './mobileMenu.styles'
import { MenuItem } from '../MenuItem'
import { useLockScroll } from '../../../../hooks/useLockScroll'
import { useTheme } from '@material-ui/core'

const MainMenu = ({navigationItems, navigationTopItems}) => {
  const [isOpen, setIsOpen] = useState(false)
  const firstMenuItems = navigationTopItems.slice(0, -2)
  const lastMenuItems = navigationTopItems.slice(2)
  const { lockScroll, unlockScroll } = useLockScroll()
  const theme = useTheme()

  function handleOpen() {
    if(isOpen === true){
      unlockScroll()
      setIsOpen(false)
    } else {
      lockScroll()
      setIsOpen(true)
    }
  }

  const classes = useStyles()
  return (
    <>
    {!theme.custom?.hasNewNavigation && (
      <nav className={classes.navContainer}>
      <div className={classes.mainNav}>
      {firstMenuItems.map(item => (
        <MenuItem item={item} key={item.name} onClick={e => setIsOpen(false)}/>
        ))}
        <button type='button' className={`${classes.toggleButton}` + (isOpen ? ' open' : '')} onClick={e => handleOpen()}>
        <span></span>
        <span></span>
        <span></span>
        </button>
        {lastMenuItems.map(item => (
          <MenuItem item={item} key={item.name} onClick={e => setIsOpen(false)}/>
          ))}
          </div>
          <div className={`${classes.menuItems}` + (isOpen ? ' open' : '')} onClick={e => handleOpen()}>
          {navigationItems.map(item => (
            <MenuItem item={item} key={item.name}/>
            ))}
            </div>
            </nav>)
          }
          </>
  )
}

MainMenu.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
  navigationTopItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ),
}

export default MainMenu;