//Utils
import { Link } from 'react-router-dom'
import { formatMessage } from '../../../../../i18n'

// Material
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'

//Hooks
import useStyles from './promotionCard.styles'
import { getExternalSiteUrl } from '../../../../../config'
import { ClassNameMap } from '@material-ui/styles'

interface Attributes {
  title?: string;
  image?: {
    data: {
      attributes: {
        url?: string
      }
    }
  };
  slug?: string;
}
interface DataTypes {
    title?: string;
    imgCard?: {
      url?: string
    };
    buttonLink?: string;
    buttonText?: string;
    path?: string;
    attributes?: Attributes;
}

type PromotionsProps = {
  data: DataTypes
}
const Promotion: React.FC<PromotionsProps> = props => {
  const title = props.data?.title || props.data?.attributes?.title
  const image = props.data?.imgCard?.url || props.data?.attributes?.image?.data?.attributes?.url
  const buttonLink = props.data?.path || props.data?.attributes?.slug

  const EXTERNAL_BASE_URI = getExternalSiteUrl();

  const classes: ClassNameMap<string> = useStyles()

  return (
    <Card classes={{ root: classes.cardContainer }}>
      <Link to={`${EXTERNAL_BASE_URI}/promotions/casino/${buttonLink}`} target='_blank'>
        <CardMedia
          component="img"
          className={classes.image}
          image={image}
          alt={title}
        />
        <CardContent className={classes.content}>
          <Typography variant="subtitle1" className={classes.contentSubtitle}>
            {title}
          </Typography>

          <Button
            classes={{ root: classes.button }}
            variant="contained"
            color="primary"
            href={`${EXTERNAL_BASE_URI}/promotions/casino/${buttonLink}`}
            target='_blank'
          >
            {formatMessage('action:more-info')}
          </Button>
        </CardContent>
      </Link>
    </Card>
  )
}

export default Promotion