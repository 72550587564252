import { makeStyles } from '../../../../ui/components/materialUiWrapper'
import { mq } from '../../../../ui/config/utils'

const useStyles = makeStyles(theme => ({
  gameInfoSkeleton: {
    display: 'flex',
    width: '100%',
    padding: '0.75rem 0.75rem 5rem',
    textDecoration: 'none',
    overflow: 'hidden',
    [mq('sm')]: {
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    width: '99px',

    [mq('sm')]: {
      width: '100%',
    },
  },
  contentWrapper: {
    flex: 1,
    paddingLeft: '1rem',
    paddingRight: '1rem',

    [mq('sm')]: {
      display: 'flex',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  textGroup: {
    [mq('sm')]: {
      flex: 1,
      paddingRight: '4.625rem',
      paddingTop: '0.75rem',
    },
  },
  heading: {
    marginBottom: '0.75rem',
    paddingRight: '4rem',

    [mq('sm')]: {
      paddingRight: '15.5rem',
    },
  },
  paragraph: {
    marginBottom: '0.75rem',
    '& + $paragraph': {
      [mq('sm')]: {
        paddingRight: '10.375rem',
      },
    },
    '&:last-child': {
      paddingRight: '4.125rem',

      [mq('sm')]: {
        paddingRight: '10.375rem',
      },
    },
  },
  ratingGroup: {
    paddingTop: '0.25rem',

    [mq('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: '0.75rem',
    },
  },
  ratingTitle: {
    width: 'min(80%, 5.625rem)',
    marginBottom: '0.5rem',
  },
  ratingIcons: {
    display: 'flex',

    '& > span': {
      width: '1.25rem',
      height: '1.25rem',

      [mq('sm')]: {
        width: '1.5rem',
        height: '1.5rem',
      },
    },
    '& span + span': {
      marginLeft: '4px',
    },
  },
}))

export default useStyles
