import { isLiveCasinoEnabled } from './config/envVars';
import TransportImpl from './transportImpl';
import { eventType } from './requesterFactory';
import RequesterFactory from './requesterFactory';

const onRequestServiceStatusChanged = e => {
    const { service, status } = e.detail
  }
  
  const onTransportStatusChanged = () => {
    return null
  }
  
  export const createRequester = (serviceName, parameters = {}) => {
    if (isLiveCasinoEnabled() === false) {
      return;
    }
    TransportImpl.subscribe(
      eventType.onTransportStatusChanged,
      onTransportStatusChanged,
    )
    RequesterFactory.subscribe(
      eventType.onRequestServiceStatusChanged,
      onRequestServiceStatusChanged,
    )
    return RequesterFactory.getInstance(serviceName, parameters)
  }