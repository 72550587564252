import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'

import Img from '../Img'
import TextPlaceholder from './TextPlaceholder'
import Skeleton from '../Skeleton'
import Markdown from '../Markdown'
import Icon from '../Icon'
import { ASPECT_RATIOS } from '../../constants'
import { useTranslations, useCurrency } from '../../../i18n'
import useStyles from './payMethods.styles'

const formatOpts = { maximumFractionDigits: 0 }

const PayMethodSkeleton = () => (
  <Skeleton variant="rect" aspectRatio={ASPECT_RATIOS.PAYMENT_METHOD} />
)

const PayMethod = props => {
  const { name, image, content, min, max } = props
  const formatMessage = useTranslations()
  const formatCurrency = useCurrency()
  const ref = useRef()
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <div className={classes.paymethod}>
      <div className={classes.paymethodImage}>
        <div className={classes.paymethodImageWrapper}>
          <Img
            src={image}
            alt={name}
            placeholder={<PayMethodSkeleton />}
            fallback={<TextPlaceholder text={name} />}
          />
        </div>
      </div>
      <div className={classes.paymethodInfo}>
        <p className={classes.paymethodInfoUds}>
          <span>{formatMessage('noun:min')}</span>{' '}
          {min ? formatCurrency(min, formatOpts) : formatMessage('noun:no-min')}
        </p>
        <p className={classes.paymethodInfoUds}>
          <span>{formatMessage('noun:max')}</span>{' '}
          {max ? formatCurrency(max, formatOpts) : formatMessage('noun:no-max')}
        </p>
        <button
          ref={ref}
          className={classes.paymethodButton}
          onClick={() => setOpen(true)}
        >
          <Icon name="info-outlined" />
        </button>
      </div>
      <Popover
        open={open}
        anchorEl={ref?.current}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Markdown content={content} type="light" />
      </Popover>
    </div>
  )
}

PayMethod.Skeleton = PayMethodSkeleton

PayMethod.propTypes = {
  name: PropTypes.string,
  image: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default PayMethod
