import { makeStyles } from '../materialUiWrapper'

const useStyles = makeStyles({
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
  },
})

export default useStyles
