import Env from '@env';
import {  replaceMirrorUrlHelper } from '../config/originSingleton'

/*
 *   ApiManager
 *   @class ApiManager
 *   @extends { ApiManager }
 */
export class ApiManager {
  #target: string
  constructor() {
    if (this.constructor === ApiManager) {
      throw new Error('Abtract class can not be instantiated.')
    }
  }

  setTarget(target: string) {
    this.#target = target
    return this
  }

  getBaseUri() {
    throw new Error('Method must be implemented')
  }

  getTarget() {
    return this.#target || ''
  }
}

class BaseApiManager extends ApiManager { 
  #target: string
  getBaseUri() {
    return replaceMirrorUrlHelper(Env.get('VITE_API_ENDPOINT'))
  }
}

class WalletsApiManager extends ApiManager {
  #target: string
  getBaseUri() {
    return replaceMirrorUrlHelper(Env.get('VITE_API_WALLETS_ENDPOINT'))
  }
}

class RafApiManager extends ApiManager {
  #target: string
  getBaseUri() {
    return Env.get('VITE_API_RAF_ENDPOINT')
  }
}

export const API_MANAGER_TYPE = {
  BASE: 'BASE',
  WALLETS: 'WALLETS',
  RAF: 'RAF',
}

class ApiManagerFactory {
  create(type: string) {
    switch (type) {
      case API_MANAGER_TYPE.BASE:
        return new BaseApiManager()
      case API_MANAGER_TYPE.WALLETS:
        return new WalletsApiManager()
      case API_MANAGER_TYPE.RAF:
        return new RafApiManager()
      default:
        return new BaseApiManager()
    }
  }
}

export const apiManagerFactory = new ApiManagerFactory()
