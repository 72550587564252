import { makeStyles } from '../components/materialUiWrapper'
import texts from '../config/texts'

const useStyles = makeStyles(theme => ({
  item: {
    padding: '0.75rem',
    color: theme.custom.palette.neutral1,

    '& + &': {
      borderTop: `1px solid ${theme.custom.palette.neutral1_16}`,
    },
  },
  isVisible: {
    '&$item': {
      '& $button': {
        transform: 'translateX(-0.25rem) rotate(90deg)',
      },
    },
  },
  info: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0.75rem 0.125rem',
  },
  button: {
    padding: 0,
    border: 0,
    color: 'currentColor',
    backgroundColor: 'transparent',
    position: 'relative',
    transform: 'translateX(-0.25rem)',
    transition: 'transform 300ms',
    cursor: 'pointer',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-0.3125rem',
      bottom: '-0.3125rem',
      left: '-0.3125rem',
      right: '-0.3125rem',
    },

    '& > span': {
      display: 'flex',
    },
  },
  details: {
    flex: '1 1 49%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1rem',
    ...texts.stepDown1,
  },
  title: {
    fontWeight: theme.custom.fontWeight.bold,
  },
  id: {
    marginTop: '0.125rem',
    marginBottom: '0.5rem',
  },
  action: {
    flex: '0 1 9.375rem',
  },
  features: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '0.75rem',
    padding: '1.25rem',
    marginTop: '0.375rem',
    borderRadius: theme.shape.borderRadius,
    backgroundColor:  theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_5,
    boxShadow: theme.custom.shadows.output,
  },
  feature: {
    fontSize: theme.custom.fontSize.sm,
    lineHeight: theme.custom.lineHeight.xs,
    color: theme.custom.palette.neutral1_87,
  },
  featureLabel: {
    display: 'block',
    marginBottom: '0.125rem',
    fontSize: theme.custom.fontSize.xxs,
    fontWeight: theme.custom.fontWeight.bold,
    textTransform: 'uppercase',
    color: theme.custom.palette.neutral1_38,
  },
}))

export default useStyles
