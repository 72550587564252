import { useQuery } from 'react-query'
import { isLogged } from '../../authentication'
import { isBalanceAlertEnabled } from '../../config'
import { fetchPlayableBalance } from './services'

export const usePlayableBalance = (GameId, ProviderId, optionEnabled) => {
  const enableEndpoint = Boolean(GameId) && Boolean(ProviderId) && optionEnabled && isBalanceAlertEnabled() && isLogged()
  const query = useQuery(
    ['playableBalance', GameId, ProviderId],
    () => {
      const parameters = {
        IntegrationProviderId: ProviderId,
        GameId,
      }
      return fetchPlayableBalance(parameters)
    },
    {
      enabled: enableEndpoint,
      onError: (error) => {
        console.error(error)
      },
    },
  )

  return query
}
