import React from 'react'
import Loader from '../../ui/components/Loader'
import { formatMessage } from '../../i18n'
import { isMobileOrTablet } from '../../libs/uaparser'
import { useGetTournamentUrl } from '../../asyncData/casexternal/useGetTournamentUrl'
import { useAuth } from '../../authentication'

const style = {
  border: 'none',
  width: '100%',
  height: isMobileOrTablet() ? '4500px' : '1500px',
  overflow: 'auto',
}

const CashRaces = () => {
  const { logged, username } = useAuth()
  const customerId = logged && username ? username : undefined

  const { isLoading, isError, data } = useGetTournamentUrl({
    customerId,
  })
  if (!data?.launchURL || isError || isLoading)
    return <Loader size="large" hasWrapper hasText />

  return (
    <div>
      <iframe
        title={`${formatMessage('category:cashraces')} ${formatMessage(
          'category:cashraces',
        )}`}
        style={style}
        src={data?.launchURL}
        scrolling={'yes'}
        seamless={'true'}
      />
    </div>
  )
}

export default CashRaces
