import { useMemo } from 'react';

const useBaseUrl = () => {
    const baseUrl = useMemo(() => {
        const { protocol, host } = window.location;
        return `${protocol}//${host}`;
    }, []);
    
    return baseUrl;
};

export default useBaseUrl;