import React from 'react'
import PropTypes from 'prop-types'

import ModalDialog from '../../ModalDialog'

import useModalStyles from './modal.styles'

const GameInfoModal = props => {
  const { title, open, onClose, children, executedFrom } = props

  const modalClasses = useModalStyles()
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={title}
      executedFrom={executedFrom}
      className={modalClasses.paper}
    >
      <div className={modalClasses.scrollContainer}>{children}</div>
    </ModalDialog>
  )
}

GameInfoModal.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default GameInfoModal
