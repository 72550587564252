import { makeStyles } from '../materialUiWrapper'
import { aspectRatio } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  root: {
    width: props => props.width,
    height: props => props.height,
    backgroundColor: theme.custom.palette.placeholder,
  },
  adaptive: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 'auto',
    height: 'auto',
  },
  neutral: {
    backgroundColor: theme.custom.palette.neutral2_16
  },
  rounded: {
    borderRadius: theme.shape.borderRadius,
  },
  aspectRatio: {
    ...aspectRatio(),
  },
}))

export default useStyles
