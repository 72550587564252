import { getCmsEndpointExternal, getCmsSacEndpointUrl, isFFCmsSacEnabled } from "../../config"
import { strapiVersion } from "../../ui/external-components/Promotions"

const API_ENDPOINT_EXTERNAL = getCmsEndpointExternal()

const FFCmsSacEnabled = isFFCmsSacEnabled()
// Getting CMS URL 
const API_ENDPOINT = getCmsSacEndpointUrl(FFCmsSacEnabled)


export const getCmsImageUrl = image => {
  if (image.provider === 'local') return `${API_ENDPOINT}${image.url}`

  // NOTE ftp-v2 provider
  return image.url
}

const request = async (endpoint, API) => {
  const response = await fetch(`${API}${endpoint}`)

  if (response.ok) {
    if(FFCmsSacEnabled) {
      const responseJson = await response.json()
      return responseJson.data
    } else {
      return response.json()
    }
    
  } else {
    throw response.status
  }
}


const getParams = param => FFCmsSacEnabled ? param : '';

const endpointQueryParams = {
  promotions: getParams('?populate[0]=banner&populate[1]=banner.desktop&populate[2]=banner.mobile&populate[3]=image&populate[4]=content&populate[5]=content.cta&populate[6]=content.tabs&populate[7]=content.games&populate[8]=content.items'),
  referAFriend: getParams('?populate[0]=banner&populate[1]=banner.desktop&populate[2]=banner.mobile&populate[3]=steps&populate[4]=steps.cards&populate[5]=image'),
  banking: getParams('?populate[0]=banner&populate[1]=banner.desktop&populate[2]=banner.mobile&populate[3]=payment&populate[4]=payment.logo&populate[5]=payout&populate[6]=payout.logo&populate[7]=safe_and_secure&populate[8]=safe_and_secure.cards'),
  homeSlider: getParams('?populate[slides][populate][0]=*&populate[slides][populate][1]=logged_in_banner.banner&populate[slides][populate][2]=logged_in_banner.banner.desktop&populate[slides][populate][3]=logged_in_banner.banner.mobile&populate[slides][populate][4]=logged_out_banner.banner&populate[slides][populate][5]=logged_out_banner.banner.desktop&populate[slides][populate][6]=logged_out_banner.banner.mobile'),
  categories: getParams('?populate[0]=banner&populate[1]=banner.desktop&populate[2]=banner.mobile'),
  footer: getParams('?populate[links][fields][0]=*&populate[site_map][populate][links][fields][0]=*')
}

export const fetchPromotions = async () => {
  return request(`/promotions${endpointQueryParams.promotions}`, API_ENDPOINT)
}

export const fetchReferAFriend = async () => {
  return request(`/refer-a-friend${endpointQueryParams.referAFriend}`, API_ENDPOINT)
}

export const fetchBonusContribution = async () => {
  return request('/bonus-contribution', API_ENDPOINT)
}

export const fetchBanking = async () => {
  return request(`/banking${endpointQueryParams.banking}`, API_ENDPOINT)
}

export const fetchHomeSlider = async () => {
  return request(`/home-slider${endpointQueryParams.homeSlider}`, API_ENDPOINT)
}

export const fetchCategories = async () => {
  return request(`/categories${endpointQueryParams.categories}`, API_ENDPOINT)
}

export const fetchFooter = async () => {
  return request(`/footer${endpointQueryParams.footer}`, API_ENDPOINT)
}

/**
 *
 * @returns Strapi contest banner component.
 */
export const fetchContestBanner = async () => {
  return request('/contests-banner', API_ENDPOINT)
}

export const fetchExternalCasinoPromotions = async (version) => {
  if(version === strapiVersion) {
    return request('/promotions?populate[image]=image&populate[banner]=banner&filters[section][$containsi]=casino', API_ENDPOINT_EXTERNAL)
  } else {
    return request('/promotions-casino', API_ENDPOINT_EXTERNAL)
  }
}

export const fetchExternalBackToClassic = async () => {
  return request('/back-to-classic-button', API_ENDPOINT_EXTERNAL)
}

export const fetchExternalFooterData = async () => {
  return request('/footer?populate=deep', API_ENDPOINT_EXTERNAL)
}
