import Navigation from './navigation';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types'
import Content from './content';
import useStyles from './casino101.styles';
import CustomPropTypes from '../../../customPropTypes';

const Casino101 = ({ routes, title }) => {
    const classes = useStyles();

    return <div className={classes.container}>
        <div className={classes.nav}>
            <Navigation title={title} routes={routes} />
        </div>
        <div className={classes.content}>
            <Routes>
                <Route path="/:slug" element={
                    <Content />
                }> 
                </Route>
            </Routes>
        </div>
    </div>
}

Casino101.propTypes = {
    title: PropTypes.string,
    routes: PropTypes.arrayOf(CustomPropTypes.navigation101)
}

export default Casino101;