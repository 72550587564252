import { makeStyles } from '../materialUiWrapper'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  promotionsDropdown: {
    boxShadow: theme.custom.shadows.overlay,
    boxSizing: 'border-box',
    '& $trigger': {
      width: '2.75rem',
      [mq('md')]: {
        width: 'auto',
      },
    },
    '&.isOpen': {
      '& $trigger': {
        '& .icon': {
          transform: 'rotate(180deg)',
        },
      },
    },
    [mq('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',

      position: 'relative',
      width: 'auto',
      right: '0',
    },
  },
  trigger: {
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    padding: '0.5rem',
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .icon': {
      width: '1.5rem',
      height: '1.5rem',
      marginLeft: 0,
      transition: 'transform 400ms ease-in-out',
    },
    [hoverDevice()]: {
      '&:hover': {
        '& .icon': {
          fill: theme.custom.palette.neutral2,
        },
      },
      '&:active': {
        '& .icon': {
          fill: theme.custom.palette.neutral1,
        },
        '&:hover': {
          '& .icon': {
            fill: theme.custom.palette.neutral1,
          },
        },
      },
    },
    [mq('md')]: {
      width: 'auto',
      padding: '0.6875rem 1.125rem 0.6875rem 1.25rem',
      '& .icon': {
        marginLeft: '0.75rem',
      },
    },
    [mq('xl')]: {
      fontSize: theme.custom.fontSize.md,
    },
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'absolute',
    top: '3.5rem',
    right: 0,
    zIndex: 1,
    width: 'calc(100vw - 24px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    [mq('sm')]: {
      width: '23.4375rem',
    },
  },
  navList: {
    margin: '0',
    padding: '0',
    color: theme.custom.palette.neutral2,
  },
  navListItem: {
    margin: '-1px 0 0',
    overflow: 'hidden',
    padding: 0,
    backgroundColor: theme.custom.palette.neutral1,

    '&:last-child $navListLinkSelected': {
      '&::before': {
        bottom: 0,
      },
    },
  },
  navListLink: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '0.8125rem 1rem',
    fontSize: theme.custom.fontSize.md,
    lineHeight: theme.custom.lineHeight.md,
    border: 0,
    borderTop: `1px solid ${theme.custom.palette.neutral3_12}`,
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'inherit',
    textAlign: 'left',
    cursor: 'pointer',
    '&:visited': {
      color: theme.custom.palette.neutral2,
    },
    [hoverDevice()]: {
      '&:hover': {
        backgroundColor: theme.custom.palette.neutral3_5,
      },
      '&:active': {
        backgroundColor: theme.custom.palette.neutral3_16,
      },
    },
  },
  navListLinkSelected: {
    color: theme.custom.palette.secondary500,
    fontWeight: theme.custom.fontWeight.bold,
    '&:visited': {
      color: theme.custom.palette.secondary500,
    },
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.secondary500,
      },
      '&:active': {
        color: theme.custom.palette.neutral2,
        backgroundColor: theme.custom.palette.neutral3_16,
      },
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 1,
      left: 0,
      width: 4,
      background: theme.custom.palette.secondary500,
      zIndex: 1,
    },
  },
  navListText: {
    opacity: 0.87,
    verticalAlign: 'middle',
  },
}))

export default useStyles
