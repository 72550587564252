import React, { useState, useEffect, useCallback, useRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Glide from '@glidejs/glide'

import { countChildren, mapChildren } from '../../../libs/utils'
import Icon from '../../../ui/components/Icon'

import { OPTIONS } from './constants'
import useStyles from './dynamicHero.styles'
import '@glidejs/glide/dist/css/glide.core.min.css'

const DynamicHero = props => {
  const { children, logged } = props
  const childrenCount = countChildren(children)
  const [controls, setControls] = useState(childrenCount > 1)
  const sliderNode = useRef(null)
  const glide = useRef(null)

  const updateControls = useCallback(() => {
    if (!glide.current) return

    if (childrenCount > 1) {
      glide.current.enable()
      glide.current.play()
    } else {
      glide.current.disable()
      glide.current.pause()
    }

    setControls(childrenCount > 1)
  }, [childrenCount])

  useEffect(() => {
    if (!sliderNode.current) return
    glide.current = new Glide(sliderNode.current, OPTIONS)
    glide.current.on('mount.after', updateControls)
    glide.current.mount()

    return () => glide.current.destroy()
  }, [childrenCount, updateControls])

  useEffect(() => {
    glide.current.update()
  }, [logged])

  const classes = useStyles()
  const sliderClasses = cx(classes.slider, 'glide')
  const slidesClasses = cx(classes.slides, 'glide__slides')
  const prevClasses = cx(
    classes.button,
    classes.buttonLeft,
    !controls && 'hidden',
  )
  const nextClasses = cx(
    classes.button,
    classes.buttonRight,
    !controls && 'hidden',
  )

  return (
    <div className={sliderClasses} ref={sliderNode}>
      <div className="glide__track" data-glide-el="track">
        <ul className={slidesClasses}>
          {mapChildren(children, (child, index) => (
            <li
              onLoad={() => {
                // NOTE update slider on next tick, after slide is updated
                setTimeout(() => glide.current.update(), 0)
              }}
              key={index}
              className="glide__slide"
              data-testid={`index-${index}`}
            >
              {child}
            </li>
          ))}
        </ul>
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        <button className={prevClasses} data-glide-dir="<" data-testid="chevron-left">
          <Icon name="chevron-left" />
        </button>
        <button className={nextClasses} data-glide-dir=">" data-testid="chevron-right">
          <Icon name="chevron-right" />
        </button>
      </div>
    </div>
  )
}

DynamicHero.propTypes = {
  children: PropTypes.node.isRequired,
  logged: PropTypes.bool,
}

export default DynamicHero
