import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './titlePlaceholder.styles'

const TitlePlaceholder = props => {
  const classes = useStyles(props)

  const titlePlaceholderClasses = cx(
    classes.titlePlaceholder,
    props.aspectRatio && classes.aspectRatio
  )

  return (
    <div className={`${titlePlaceholderClasses} ${props.size}`}>
      <div className={classes.titlePlaceholderTxt}>{props.title}</div>
    </div>
  )
}

TitlePlaceholder.propTypes = {
  aspectRatio: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default TitlePlaceholder
