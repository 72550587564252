import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import FormField from './FormField'
import FormFieldset from './FormFieldset'
import useStyles from './form.styles'

const Form = props => {
  const { className, children, ...rest } = props
  const classes = useStyles()
  const formClasses = cx(classes.root, className)

  return (
    <form className={formClasses} {...rest}>
      {children}
    </form>
  )
}

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Form.Field = FormField
Form.Fieldset = FormFieldset

export default Form
