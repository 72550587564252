import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  heading: {
    ...texts.baseline,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
    marginBottom: '0.5rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    [mq('sm')]: {
      marginTop: '2rem',
    },
  },
  headingLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.custom.palette.primaryBlack || theme.custom.palette.neutral1,
    lineHeight: theme.custom.lineHeight.md,
    fontWeight: theme.custom.fontWeight.extrabold,
    fontSize: theme.custom.fontSize.sm,
    fontFamily: theme.custom?.fontFamily?.primaryFont,
    [mq('md')]: {
      fontSize: theme.custom.fontSize.md,
    },
    '& span': {
      marginLeft: '0.125rem',
      fontWeight: theme.custom.fontWeight.regular,
    },
  },
  icon: {
    color: theme.custom.palette.neutral1,
    marginRight: '0.5rem',
    fontSize: theme.custom.fontSize.lg,
    display: 'flex',
    alignItems: 'center',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fill: theme.custom.carouselsTitle?.iconColor || theme.custom.palette.neutral1,
        maxHeight: '24px'
      },
      '& svg > path': {
        fill: theme.custom.carouselsTitle?.iconColor || theme.custom.palette.neutral1
      },
    },
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.custom.carouselsTitle?.linkColor || theme.custom.palette.tertiary500,
    fontFamily: theme.custom?.fontFamily?.secondaryFont,
    fontWeight: theme.custom?.fontWeight?.normal,
    '& .icon': {
      fill: 'currentColor',
    },
    '& span': {
      display: 'flex',
    },
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.btnSecondary || theme.custom.palette.tertiary800,
      },
    },
    '&[disabled]': {
      color: theme.custom.palette.neutral1_38,
    },
  },
}))

export default useStyles
