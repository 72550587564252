import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    paddingTop: '1.5rem',
    paddingBottom: '1.25rem',
    background: theme.custom.palette.primaryBlue || theme.custom.palette.neutral1_12,
    [mq('lg')]: {
      paddingTop: '1.75rem',
    },
  },
  notLogged: {
    [mq('sm', 'max')]: {
      paddingBottom: '5rem',
    },
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [mq('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  footerSection: {
    flex: '1',
    [mq('md')]: {
      flexBasis: '50%',
      maxWidth: '540px',
    },
  },
  footerNavTitle: {
    marginBottom: '0.5rem',
    ...texts.stepDown2,
    color: theme.custom.palette.neutral1_87,
  },
  footerNav: {
    margin: 0,
    padding: 0,
    [mq('md')]: {
      maxWidth: '352px',
    },
  },
  footerNavItem: {
    display: 'inline-block',
    width: '50%',
    [mq('sm')]: {
      width: '25%',
    },
    [mq('md')]: {
      width: '50%',
    },
  },
  footerNavLink: {
    display: 'inline-block',
    marginBottom: '0.375rem',
    ...texts.stepDown2,
    color: theme.custom.palette.neutral1_60,
    position: 'relative',
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.tertiary800,
      },
    },
  },
  footerNavLinkSelected: {
    color: theme.custom.palette.neutral1_87,
    cursor: 'default',
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.neutral1_87,
      },
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '1px',
      display: 'block',
      position: 'absolute',
      bottom: '-1px',
      backgroundColor: theme.custom.palette.neutral1,
    },
  },
  footerLogos: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: '0.375rem',
    borderBottom: `1px solid ${theme.custom.palette.neutral1_5}`,
    marginBottom: '1.5rem',
    [mq('sm')]: {
      justifyContent: 'flex-start',
      paddingBottom: '0.75rem',
    },
    [mq('md')]: {
      justifyContent: 'flex-end',
      paddingBottom: '0.75rem',
      borderBottom: 'none',
    },
    [mq('lg')]: {
      paddingBottom: '24px',
    },
  },
  footerLogosItem: {
    marginBottom: 8,
    width: 'calc(100% / 3)',
    '& svg': {
      width: '100%',
      height: 'auto',
      opacity: 0.24,
    },
    [mq('sm')]: {
      width: 'calc(100% / 4)',
    },
    [mq('md')]: {
      width: 'calc(100% / 2)',
    },
    [mq('lg')]: {
      width: 'calc(100% / 3)',
    },
    [hoverDevice()]: {
      '& a:hover': {
        '& svg': {
          opacity: 1,
        },
      },
    },
  },
  footerLogoWrapper: {
    textAlign: 'center',
    [mq('md')]: {
      textAlign: 'left',
    },
  },
  footerLogo: {
    display: 'inline-block',
    marginBottom: '1.5rem',
    opacity: 0.24,
    [mq('md')]: {
      marginBottom: 0,
    },
    [hoverDevice()]: {
      '&:hover': {
        opacity: 1,
      },
    },
  },
  footerCopyrightWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [mq('md')]: {
      justifyContent: 'flex-end',
    },
  },
  footerCopyright: {
    ...texts.stepDown2,
    color: theme.custom.palette.neutral1_24,
  },
}))

export default useStyles
