import React, {useEffect} from 'react'
import { createPortal } from 'react-dom'

const modalRoot = document.getElementById('modal-root');


const ModalPortals = ({children}) => {
  const el = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(el);
    document.body.style.overflowY = 'hidden';
    
    return () => {
      modalRoot.removeChild(el);
      document.body.style.overflowY = 'auto';
    }
  }, [el])

  return createPortal(children, el)
}

export default ModalPortals