import { replaceMirrorUrlHelper } from '../../../config/originSingleton';
import { parseEnvBoolean, parseEnvNumber, parseEnvString } from './envVars'

const socketSettings = {
    connectUrl: replaceMirrorUrlHelper(parseEnvString('VITE_SOCKET_CONNECT_URI') || 'ws://localhost:3080'),
    path: parseEnvString('VITE_PATH_SOCKET') || '/socket/',
    transportChangeTimeoutPeriodMillis: ((parseEnvNumber('VITE_TRANSPORT_CHANGE_TIMEOUT_PERIOD_SECONDS') || 10) * 1000)
}

const transportSettings = {
    /**
     * hint for maximum amount of time to allow local buffering of outgoing data before sending
     */
    maxSendBufferMillisHint: parseEnvNumber('VITE_MAX_SEND_BUFFER_MILLIS_HINT') || 200,
    /**
     * when connecting, amount of time before a connection attempt
     * is considered to have failed
     */
    connectionTimeoutPeriodMillis: ((parseEnvNumber('VITE_CONNECTION_TIMEOUT_PERIOD_SECONDS') || 20) * 1000),
    /**
     * Automatically reconnect ? (unless the server or client
     * explicitly disconnect)
     */
    autoReconnect: parseEnvBoolean('VITE_RECONNECT') || true,
    /**
     * on *manual* reconnection to the remote end, do we automatically
     * synchronise/restore session data.  Automatic reconnections always
     * restore session data.
     */
    restoreSessionDataOnReconnect: parseEnvBoolean('VITE_RESTORE_SESSION_DATA') || true,
    /**
     * when the transport is disconnected, how many times do we
     * attempt to reconnect
     */
    reconnectionAttempts: parseEnvNumber('VITE_RECONNECTION_ATTEMPTS') || 10,
    /**
     * initial delay when automatically reconnecting, the actual
     * delay used has random noise applied to it.
     */
    initialReconnectionDelayMillis: (parseEnvNumber('VITE_INITIAL_RECONNECTION_DELAY_SECONDS') || 1) * 1000,
    /**
     * At each automatic reconnection attempt, the delay increases
     * exponentially, this is the maximum delay.  The actual
     * delay used has random noise applied to it.
     */
    maximumReconnectionDelayMillis: (parseEnvNumber('VITE_MAXIMUM_RECONNECTION_DELAY_SECONDS') || 5) * 1000,
  };

  const liveUpdateSettings = {
    gameCardUpdateIdPropertyName: parseEnvString('VITE_CARD_ID') || 'cardId',
    // the maximum rate at which updates will be realised in the webapp
    minGameCardUpdatePeriodMillis: parseEnvNumber('VITE_MIN_GAME_CARD_UPDATE') || 2000,
  }
  export {
    socketSettings,
    transportSettings,
    liveUpdateSettings
  }