import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  loginSkeleton: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    padding: '1.5625rem 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    '& img': {
      display: 'block',
    },
  },
  main: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.3125rem',

    '@media (min-width: 360px)': {
      padding: '0.625rem',
    },
    '@media (min-width: 576px)': {
      padding: 0,
    },
  },
  loadingContent: {
    width: '100%',
    height: '26.9375rem',
    maxWidth: '25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    color: theme.custom.palette.neutral1,
    border: `0.0625rem solid ${theme.custom.palette.neutral2}`,
    borderRadius: '0.3125rem',
    marginBottom: '0.625rem',

    '& .loading': {
      color: theme.custom.palette.neutral2,

      '& span': {
        opacity: 0.38,
      },
    },
  },
  helpTxt: {
    color: theme.custom.palette.neutral1,
    alignItems: 'center',
    display: 'flex',
    gap: '.5rem',
    marginTop: '1.25rem',
    ...texts.stepDown2,
    lineHeight: '1.5',
    fontSmoothing: 'initial',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    background: theme.custom.palette.neutral1_5,
    marginTop: '1.25rem',
    color: theme.custom.palette.neutral1_87,
    ...texts.stepDown2,
    fontWeight: theme.custom.fontWeight.semibold,

    '& a': {
      color: theme.custom.palette.neutral1_87,

      '&:hover': {
        color: 'inherit',
      },
    },
  },
  footerContainer: {
    width: '100%',
    maxWidth: '25rem',
    margin: '1.5rem auto',
  },
  footerTop: {
    padding: '0 0 0.875rem',
    margin: 0,
    display: 'flex',
    gap: '1.875rem',
    justifyContent: 'center',
    borderBottom: `0.0625rem solid ${theme.custom.palette.neutral1_87}`,
  },
  footerItem: {
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: 1,
    gap: '.5rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  footerIcon: {
    '& path': {
      fill: theme.custom.palette.neutral1_87,
    },
  },
  footerBottom: {
    padding: '1rem 0 0',
    margin: '0 auto',
    maxWidth: '15.313rem',
    [mq('sm')]: {
      maxWidth: '100%',
    },
  },
}))

export default useStyles
