/**
 * live-updater.js
 * 
 * Provides subscription-based update services.
 */

// specific to SAC
const settings = {
  gameCardIdPropertyName : 'cardId',
  // this is how often the list of subscribed ids is updated to the
  // LiveUpdateSubscriptionService
  gameCardSubscriptionCoalescePeriodMillis : 500,
}

/**
 * generic defaults used by classes (this code may be migrated out of
 * SAC so these defaults would miugrate as well)
 */

const defaults = {
  idPropertyName : 'id',
  coalescePeriodMillis : 500,
}

const nullFunction = () => {
  return;
}

class LiveUpdaterFunction {
  #callback;
  #propertyName;
  #state;
  #transformHook;
  /**
   * @param {*} params {
   *  callback: function to call on updates
   *  propertyName: name of property from source update object to
   *  send to 'callback', may be nullish to send entire object to callback
   * }
   */
  constructor(callback, propertyName, transformHook = null) {
    this.#callback = callback ?? nullFunction;
    this.#propertyName = propertyName;
    this.#state = null;
    this.#transformHook = transformHook;
  }

  /**
   * Dispatches updates using a given object (which contains the updated properties).
   * 
   * @param {object} updateObject base object to dispatch updates with
   */
  updateWithObject(updateObject) {
    let update = (this.#propertyName == null) ? updateObject : updateObject[this.#propertyName];
    if (this.#transformHook != null) {
      update = this.#transformHook(update);
    }
    if (!this.#valuesAreEqual(update, this.#state)) {
      this.#callback(update);
      this.#state = update;
    }
  }

  #valuesAreEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}

class LiveUpdaterFunctionList {
  #functions = new Map();

  /**
   * Registers a callback function to receive updates
   * 
   * @param {function} callback function that will be called on object update
   * @param {string} propertyName when updateWithObject is called with an object,
   *  this is the name of the property of the object that will be passed to the callback
   * 
   * NOTEs:
   *  - callback, when called, will receive the 'propertyName' property of the updating object
   *  - it is *not* valid to to call subscribe more than once with the same 'callback'
   *    (without first calling unsubscribe(callback)).
   */
  subscribe(callback, propertyName, transformHook = null) {
    this.#functions.set(callback, new LiveUpdaterFunction(callback, propertyName, transformHook));
  }

  /**
   * Unregisters a callback function from receiving updates
   * 
   * @param {function} callback
   * 
   * Removes the callback function from the update mechanism.
   * It is not permitted to remove a callback function more than once.
   */
  unsubscribe(callback) {
    this.#functions.delete(callback);
  }

  /**
   * Dispatches updates using a given object (which contains the updated properties).
   * 
   * @param {object} updateObject base object to dispatch updates with
   */
  updateWithObject(updateObject) {
    this.#functions.forEach((liveUpdaterFunction) => {
      liveUpdaterFunction.updateWithObject(updateObject);
    });
  }

  /**
   * @return falsy if the list is not empty, truthy otherwise.
   */
  isEmpty() {
    return this.#functions.size === 0;
  }
}

/**
 * Tracks multiple objects 
 */
class LiveUpdater {
  #idPropertyName;
  #coalescePeriodMillis;
  #subscriptionCallback;
  #functionListsById = {};
  #subscriptionTimerId = null;

  /**
   * @param {object} params {
   *  idPropertyName: update objects are expected to each have a unqiue property of this name
   *  subscriptionCallback: function to call to send the most recent subscription, subscriptionCallback
   *    will receive the subscription as an array of id strings
   *  coalescePeriodMillis: period, in milliseconds, at which to send the subscriptionCallback the
   *    most recent subscription
   * }
   */
    constructor(params) {
      this.#idPropertyName = params.idPropertyName || defaults.idPropertyName;
      this.#coalescePeriodMillis = params.coalescePeriodMillis ?? defaults.coalescePeriodMillis;
      this.#subscriptionCallback = params.subscriptionCallback ?? nullFunction;
    }

  #assureFunctionList(id) {
    return this.#functionListsById[id] ?? (this.#functionListsById[id] = new LiveUpdaterFunctionList());
  }

  #updateSubscription() {
    this.#subscriptionTimerId = null;
    this.#subscriptionCallback(Object.keys(this.#functionListsById));
  }

  #updateSubscriptionTimer() {
    if (!this.#subscriptionTimerId) {
      this.#subscriptionTimerId = setTimeout(
        () => {
          this.#updateSubscription();
        },
        this.#coalescePeriodMillis,
      )
    }
  }

  /**
   * Sets the callback to be called to be notified of subsctipion changes.
   * 
   * @param {*} callback 
   */
  setSubscriptionCallback(callback) {
    this.#subscriptionCallback = callback ?? nullFunction;
  }

  /**
   * Registers a 'callback. id' tuple to receive updates
   * 
   * @param {function} callback function that will be called on objects updates with matching 'id'
   * @param {string} id id to associate with function, when updateWithObject is called with an object
   *  having the same id, callback will be called
   * @param {string} propertyName when updateWithObject is called with an object with a mathcing id,
   *  this is the name of the property of the object that will be passed to callback
   * 
   * NOTEs:
   *  - callback, when called, will receive the 'propertyName' property of the updating object
   *  - the tuple 'callback, id' is effectively a key that uniquely identfies an update subscriber,
   *  so conceivably, multiple functions can be added as long as each entry has a different id
   *  - it is *not* valid to to call subscribeToId more than once with the same 'callback, id'
   *  tuple (without first calling unsubscribeFromId).
   */
  subscribeToId(callback, id, propertyName, transformHook = null) {
    const functionList = this.#assureFunctionList(id);

    functionList.subscribe(callback, propertyName, transformHook);
    // we mutated the subscription, this will inform the appropriate callback
    this.#updateSubscriptionTimer();
  }


  /**
   * Unregisters a 'callback. id' tuple to from receiving updates
   * 
   * @param {function} callback
   * @param {string} id
   * 
   * Removes the tuple: 'callback, id' from the update mechanism.
   * It is not permitted to remove a tuple more than once.
   */
  unsubscribeFromId(callback, id) {
    const functionList = this.#functionListsById[id];

    if (functionList) {
      functionList.unsubscribe(callback);
      if (functionList.isEmpty()) {
        delete this.#functionListsById[id];
      }

      // we mutated the subscription, this will inform the appropriate callback
      this.#updateSubscriptionTimer();
    }
  }

  /**
   * Dispatches updates using a given object (which contains the updated properties).
   * 
   * @param {object} updateObject is expected to contain a property named: this.#idPropertyName which indicates which
   *  functions should be called to perform an update
   */
  updateWithObject(updateObject) {
    const id = updateObject?.[this.#idPropertyName];
    const functionList = this.#functionListsById[id];

    functionList?.updateWithObject(updateObject);
  }

  /**
   * @returns {array} the subscribed ids as array.
   */
  getSubcribedIds() {
    return Object.keys(this.#functionListsById);
  }
}

const createGameCardUpdater = () => {
  return new LiveUpdater({
    idPropertyName: settings.gameCardIdPropertyName,
    coalescePeriodMillis: settings.gameCardSubscriptionCoalescePeriodMillis,
  });
}

const gameCardUpdater = createGameCardUpdater();

export { gameCardUpdater };