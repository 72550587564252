/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import cx from 'classnames'
import { useClickOutside } from 'use-events'

import CustomPropTypes from '../../../customPropTypes'
import { useTranslations } from '../../../i18n'
import useStyles from './dropdown.styles'
import NavLink from '../NavLink'
import Icon from '../Icon'
import Button from '../Button'

const Dropdown = ({ items }) => {
  const formatMessage = useTranslations()

  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const toggleDropdown = useCallback(() => setOpen(open => !open), [])
  const closeDropdown = useCallback(() => setOpen(false), [])

  useClickOutside([ref], () => {
    if (open) {
      closeDropdown()
    }
  })

  const classes = useStyles()
  const dropdownClasses = cx(classes.promotionsDropdown, open && 'isOpen')

  return (
    <div className={dropdownClasses} ref={ref}>
      <Button
        variant="ghost"
        height="medium"
        className={classes.trigger}
        onClick={toggleDropdown}
      >
        <MediaQuery minWidth={900}>
          {formatMessage('action:please-select')}
        </MediaQuery>
        <Icon name="chevron-down" />
      </Button>
      {open ? (
        <div className={classes.paper}>
          <ul className={classes.navList}>
            {items.map(menuItem => (
              <li className={classes.navListItem} key={menuItem.name}>
                {menuItem.to ? (
                  <NavLink
                    onClick={closeDropdown}
                    to={menuItem.to}
                    className={cx(
                      classes.navListLink,
                      menuItem.selected && classes.navListLinkSelected,
                    )}
                    activeclassname={classes.navListLinkSelected}
                  >
                    {formatMessage(menuItem.name)}
                  </NavLink>
                ) : (
                  <button
                    onClick={() => {
                      menuItem.onClick()
                      closeDropdown()
                    }}
                    className={cx(
                      classes.navListLink,
                      menuItem.selected && classes.navListLinkSelected,
                    )}
                  >
                    <span className={classes.navListTopDivider}></span>
                    <Icon name={menuItem.icon} />
                    <span className={classes.navListText}>
                      {formatMessage(menuItem.name)}
                    </span>
                    <span className={classes.navListBottomDivider}></span>
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: CustomPropTypes.link,
      selected: PropTypes.bool,
    }),
  ).isRequired,
}

export default Dropdown
