import '@platform/main-navigation';
import { getExternalSiteUrl } from '../../../../config';
import useBaseUrl from '../../../../hooks/useBaseUrl';

export const MainNavigation = () => {
  const baseDomain = getExternalSiteUrl();

    const routes = [
        {
          name: 'Sports',
          path: `${baseDomain}/sportsbook`
        },
        {
          name: 'Live Betting',
          path: `${baseDomain}/sportsbook/live-betting`
        },
        {
          name: 'Casino',
          path: useBaseUrl(),
        },
        {
          name: 'Racebook',
          path: `${baseDomain}/horse-betting`
        },
        {
          name: 'Poker',
          path: `${baseDomain}/poker`
        },
        {
          name: 'Esports',
          path: `${baseDomain}/esports`
        },
        {
          name: 'Contests',
          path: `${baseDomain}/contests`
        },
        {
          name: 'VIP Rewards',
          path: `${baseDomain}/vip-rewards`
        },
        {
          name: 'Promos',
          path: `${baseDomain}/promotions`
        }
      ];
      
      window.addEventListener('webcomponent/main-navigation/ready', () => {
        const mainNav = document.querySelector('main-navigation')
        mainNav.routes = routes
        mainNav.mainDomain = `${baseDomain}`
      }) 
     
    return (
        <main-navigation></main-navigation>
    )
}
