import PropTypes from 'prop-types'

// business
const game = PropTypes.shape({
  GameID: PropTypes.number.isRequired,
  ExtGameID: PropTypes.oneOfType([PropTypes.string,PropTypes.number]).isRequired,
  GameName: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  ImageUrlGameFull: PropTypes.string.isRequired,
  ImageDescriptionURLFull: PropTypes.string.isRequired,
  Volatility: PropTypes.number,
  JackpotValue: PropTypes.number,
  IsPopular: PropTypes.bool,
  IsNew: PropTypes.bool,
  Reels: PropTypes.number,
  Lines: PropTypes.string,
  ProviderID: PropTypes.number.isRequired
})

const navigation101 = PropTypes.shape({
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
})

const liveCasinoGame = PropTypes.shape({
  categoryName: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,

})

const category = PropTypes.shape({
  CategoryName: PropTypes.string.isRequired,
  SubCategories: PropTypes.array,
  Games: PropTypes.arrayOf(game).isRequired,
})

const bonus = PropTypes.shape({
  BonusAccountId: PropTypes.number.isRequired,
  Description: PropTypes.string.isRequired,
  Amount: PropTypes.number.isRequired,
  RequiredRollover: PropTypes.number.isRequired,
  AchievedRollover: PropTypes.number.isRequired,
  AwardedDate: PropTypes.string.isRequired,
  ExpiryDate: PropTypes.string.isRequired,
  Games: PropTypes.arrayOf(
    PropTypes.shape({
      GameID: PropTypes.number.isRequired,
      GameName: PropTypes.string.isRequired,
    }),
  ).isRequired,
})

// polymorhpic components
const tagOrComponent = tags =>
  PropTypes.oneOfType([PropTypes.oneOf(tags), PropTypes.elementType])

// react-router (e.g. Link and Redirect components)
const link = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  PropTypes.func,
])

// Strapi stuff

const strapiBannerRedirection = PropTypes.shape({
  redirect: PropTypes.bool,
  redirect_type: PropTypes.oneOf(['LOGIN', 'JOIN']),
})

/**
 * loadingOr - Ensure certain props are received if a loading flag is not set
 * @example
 * MyComponent.propTypes = {
 *   isLoading: loadingOr({
 *     count: PropTypes.number,
 *   })
 * }
 */
const loadingOr = propTypes => {
  // copy provided prop types, making them required when possible
  const requiredPropTypes = Object.entries(propTypes).reduce(
    (acc, [key, propType]) => {
      return Object.assign(acc, {
        [key]: propType.isRequired ? propType.isRequired : propType,
      })
    },
    {},
  )

  return (props, propName, componentName) => {
    PropTypes.checkPropTypes(
      // check the original prop types or the required ones, depending on the
      // value (true/false) of this flag
      props[propName] ? propTypes : requiredPropTypes,
      props,
      'prop',
      componentName,
    )
  }
}

const requiredUnless = (dependentPropName, propType) => {
  return (props, propName, componentName) => {
    const isDependantPresent = props[dependentPropName]

    return PropTypes.checkPropTypes(
      isDependantPresent
        ? { [propName]: propType }
        : { [propName]: propType.isRequired },
      props,
      'prop',
      componentName,
    )
  }
}

const CustomPropTypes = {
  requiredUnless,
  game,
  liveCasinoGame,
  category,
  bonus,
  tagOrComponent,
  link,
  loadingOr,
  strapiBannerRedirection,
  navigation101,
}

export default CustomPropTypes
