import { makeStyles } from '@material-ui/core';
import mq from '../../config/mq';

const useStyles = makeStyles(theme => ({
    container: {

        display: 'flex',
        [mq('xxs')]: {
            flexDirection: 'column',
            paddingTop: '1rem',
        },
        [mq('md')]: {
            flexDirection: 'row',
            paddingTop: '3.5rem',
        }
    },
    nav: {
        [mq('xxs')]: {
            paddingTop: '0rem',
            width: '92%',
            marginBottom: '3.25rem'
        },
        [mq('md')]: {
            width: '30%',
        }
    },
    content: {

        [mq('xxs')]: {
            marginLeft: '0rem',
            paddingRight: '0rem',
            width: '100%',
        },
        [mq('md')]: {
            marginLeft: '4.813rem',
            paddingRight: '7.125rem',
            width: '70%',
        }

    }
}));

export default useStyles;