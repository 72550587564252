import { makeStyles } from '@material-ui/core';
import mq from '../../../config/mq';

const useStyles = makeStyles(theme => ({
    container: {
        '& h1': {
            fontFamily: theme.custom.fontFamily.primaryFont,
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '1.875rem',
            lineHeight: '2.125rem',
            marginBottom: '2.125rem',
        },
        '& h2': {
            fontFamily: theme.custom.fontFamily.primaryFont,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '1.438rem',
            lineHeight: '1.625rem',
            marginBottom: '1.188rem',
            textTransform: 'uppercase'
        },
        '& p': {
            fontFamily: 'Lato-Regular',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1.313rem',
            letterSpacing: '0px',
            textAlign: 'justify',
            margin: '0 0 1.1875rem'

        },
        [mq('xxs')]: {
            width: '92%',
            margin: '1.25rem 0 0 calc((100% - 92%)/2)',
            '& h1' : {
                lineHeight: '1.75rem'
            }
        },
        [mq('md')]: {
            maxWidth: '65.438rem',
            margin: '0rem'
        }
    }
}));

export default useStyles;