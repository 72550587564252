import merge from 'deepmerge'
import brandPalette from './palette'
import baseTheme from '../baseTheme'
import noResultsSvg from '../../../assets/icons/no-results.svg?url'
import UniversalFooter from '../../../ui/external-components/Footer'
import typography from '../bolnasite/typography'
import { getCssVariablesPalette } from '../CssVariablesPalette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const queenBeeCasinoPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette()
  const palette = merge(brandPalette, cssVariablesPalette)

  return {
    palette,
    brand: {
      namespace: 'qbcnasite',
      name: 'Queen Bee Casino',
      website: 'QueenBeeCasino.ag',
      email: 'help@queenbeecasinocs.ag',
      help: 'https://help.queenbeecasino.ag/',
      themeColor: palette.primary500,
      bonusInfoUrl:
        'https://help.queenbeecasino.ag/articles/bonus-tutorial-and-faq/',
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    buttons: {
      primaryFrontColor: palette.quaternary800,
      primarySolidColor: baseTheme.palette.neutral1,
      primarySolidBg: palette.tertiary800,
      primaryFrontColorActive: palette.white,
      secondarySolidColor: palette.white,
      secondarySolidBg: palette.primary800,
      secondaryFrontColorActive: palette.white,
      iconColorDisabled: baseTheme.palette.neutral3_16,
      ghostSolidBg: 'transparent',
      ghostColor: palette.quaternary,
      ghostSolidColor: palette.quaternary,
      ghostBorder: baseTheme.palette.neutral3,
      ghostSolidBorder: baseTheme.palette.neutral3,
      hover: {
        primarySolidBg: palette.tertiary800,
        ghostBg: palette.quaternary,
        ghostSolidBg: palette.quaternary,
        ghostSolidColor: palette.white,
        ghostColor: palette.white,
      },
      active: {
        ghostBg: palette.neutral200,
        ghostSolidBg: palette.neutral200,
        ghostColor: palette.quaternary,
        ghostSolidColor: palette.quaternary,
      },
    },
    pageHeading: {
      bgColor: palette.primary500,
      color: palette.white,
      fontWeight: typography.fontWeight.bold,
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: palette.quaternary,
      variant: 'filled',
      textColor: palette.quaternary,
    },
    bonusTable: {
      headerSize: null,
      headColor: palette.quaternary,
      headBgColor: null,
      tableVariant: 'light',
    },
    selectInput: {
      border: `2px solid ${palette.quaternary}`,
      borderColorHover: palette.quaternary700_87,
      iconColor: palette.quaternary,
      color: palette.quaternary,
    },
    appBar: {
      background: palette.white,
      logoHeight: '4rem',
      logoHeightMd: '3rem',
    },
    infoModal: {
      color: palette.quaternary,
      background: palette.white,
      bgHeader: palette.primary500,
      ratingColor: palette.quaternary,
    },
    carouselsTitle: {
      iconColor: palette.secondary600,
      linkColor: palette.links,
    },
    searchBar: {
      background: palette.tertiary500,
      closeIconColor: palette.secondary700,
      svgColor: palette.primary500,
    },
    input: {
      color: palette.quaternary,
      borderColor: palette.neutral300,
      borderColorHover: palette.black,
    },
    outputSlim: {
      valueColor: palette.quaternary,
    },
    toggle: {
      color: palette.quaternary,
    },
    box: {
      headerColor: palette.quaternary,
    },
    needHelp: {
      mainColor: palette.secondary800,
    },
    errorPage: {
      color: palette.black,
      weight: 700,
      goToHomeButtonVariant: 'ghostSolid',
    },
    footer: <UniversalFooter />,
    edgeTier: {
      breakpoint: 'xs',
      bottom: '4rem',
      bottomloggedOut: '5.3rem',
    },
    gradients: {
      primaryGradient: `linear-gradient(to bottom, ${palette.primary500}, ${palette.primary500})`,
      primaryGradientLight: `linear-gradient(to bottom, ${palette.primary500}, ${palette.primary500})`,
      primaryGradientDark: `linear-gradient(to bottom, ${palette.primary800}, ${palette.primary600})`,
      secondaryFrontColor: `linear-gradient(to bottom, ${palette.secondary700}, ${palette.secondary700})`,
      secondaryGradient: `linear-gradient(to bottom, ${palette.secondary700}, ${palette.secondary700})`,
      secondaryGradientLight: `linear-gradient(to bottom, ${palette.secondary700}, ${palette.secondary700})`,
    },
    navigation: {
      linkSelected: palette.secondary700,
      linkDefault: palette.primary500,
      linkHover: palette.primary600,
    },
    navigationTabs: {
      tabsButton: {
        color: palette.quaternary,
        selectedBtn: palette.secondary700,
        selectedBtnDuotone: palette.tertiary100,
      },
    },
    sideMenu: {
      textColor: palette.quaternary,
      hoverColor: palette.quaternary,
      hoverBg: palette.placeholder,
      selectedColor: palette.primary500,
      selectedIconColor: palette.primary500,
      beforeColor: palette.primary500,
      selectedHover: palette.primary500,
      activeSelectedIcon: palette.neutral50,
      activeBg: palette.primary500,
      activeAccordionBg: palette.quaternary,
      activeColor: palette.neutral50,
      activeIcon: palette.neutral50,
      selectedActive: palette.neutral50,
      imgFilter:
        'brightness(0) saturate(0%) invert(0%) sepia(0%) saturate(0%) hue-rotate(183deg) brightness(0%) contrast(100%)',
      selectedImg:
        'brightness(0) saturate(100%) invert(37%) sepia(20%) saturate(5328%) hue-rotate(301deg) brightness(98%) contrast(98%)',
      activeImg: 'saturate(0) brightness(3) sepia(0.1) hue-rotate(0deg)',
    },
    titlePlaceholder: {
      color: palette.quaternary,
    },
    globalHeader: {
      triggerButtonIconColor: palette.quaternary,
      iconColor: palette.quaternary,
      liveCasinoIconColor: palette.primary500,
      navigationLinkColor: palette.quaternary,
      navigationSelectedLinkColor: palette.quaternary,
      navigationSelectedLinkBorderColor: palette.quaternary,
      navigationSelectedHoverColor: palette.quaternary,
    },
    markdown: {
      contentColor: palette.black,
      background: 'transparent',
      evenTableBg: palette.placeholder,
    },
    textHeading: {
      color: palette.black,
    },
    paymethod: {
      background: 'transparent',
      list: {
        color: palette.black,
        btnColor: palette.quaternary,
        background: palette.placeholder,
        borderColor: palette.neutral300,
      },
      InfoUds: {
        color: palette.black,
      },
    },
    info: {
      link: palette.primary500,
    },
    placeholder: {
      color: palette.quaternary,
    },
    header: {
      logoHeight: '44px',
      logoAlignSelf: 'center',
    },
    divider: {
      color: palette.quaternary,
    },
    section: {
      color: palette.black,
    },
    card: {
      background: palette.neutral50,
      cardIcon: {
        color: palette.quaternary,
      },
      cardTitle: {
        color: palette.quaternary,
      },
      cardDescription: {
        color: palette.quaternary,
      },
      cardPromotion: {
        color: palette.quaternary,
      },
      cardSlim: {
        color: palette.quaternary,
      },
    },
    loader: {
      color: palette.black,
    },
    wrapper: {
      coloured: {
        bgColor: palette.neutral50,
      },
    },
    linkCard: {
      bgColor: palette.primary500,
      bgColorVisited: palette.primary500,
      bgColorHover: palette.neutral50,
      activeColor: palette.primary500,
      hoverColor: palette.primary500,
    },
    breadcrumb: {
      color: palette.quaternary,
      currentColor: palette.primary600,
    },
    textNote: {
      color: palette.quaternary,
      titleColor: palette.quaternary700_87,
    },
    form: {
      formLegendColor: palette.quaternary,
    },
    iconButton: {
      buttonColor: palette.black,
      buttonColorHover: palette.neutral900,
      buttonColorActive: palette.quaternary,
    },
    output: {
      label: {
        color: palette.quaternary,
      },
      value: {
        color: palette.quaternary,
      },
    },
    rowTable: {
      color: palette.quaternary,
      border: palette.neutral200,
    },
    account: {
      color: palette.quaternary,
    },
    summary: {
      myaccountColor: palette.quaternary,
      detailColor: palette.quaternary,
      backgroundColor: baseTheme.palette.neutral3_5,
    },
    contact: {
      cardColor: palette.quaternary,
      bgCardColor: palette.white,
    },
    indicatorBar: {
      titleColor: palette.quaternary,
      itemColor: palette.quaternary,
      amountColor: palette.quaternary,
    },
    playableBalance: {
      background: palette.white,
      numberColor: palette.primary600,
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'internal',
      url: `/cashier`,
    },
    intercom: {
      brand: 'queenbeecasino',
    },
    searchModal: {
      headerBg: '#123333',
      active: false,
    },
  }
}

export default queenBeeCasinoPartialTheme
