import { getCasinoSubPath101 } from '../../../../ui/config/utils';

export const mockCasino =  {
  title: 'CASINO 101',
  basePath: 'casino',
  routes: [
    {
      label: 'BACK TO CASINO',
      route: '/',
    },
    {
      label: 'Baccarat',
      route: `${getCasinoSubPath101()}baccarat`,
    },
    {
      label: 'Craps',
      route: `${getCasinoSubPath101()}craps`,
    },
    {
      label: 'Roulette',
      route: `${getCasinoSubPath101()}roulette`,
    },
    {
      label: 'Keno',
      route: `${getCasinoSubPath101()}keno`,
    }
  ]
}

export const mockGuide = {
  title: 'CASINO 101',
  basePath: 'guide',
  routes: [
    {
      label: 'BACK TO CASINO',
      route: '/',
    },
    {
      label: 'Baccarat',
      route: '/guide/baccarat',
    },
    {
      label: 'Craps',
      route: '/guide/craps',
    },
    {
      label: 'Roulette',
      route: '/guide/roulette',
    },
    {
      label: 'Keno',
      route: '/guide/keno',
    }
  ]
}
