import { makeStyles } from '@material-ui/core'
import { hoverDevice } from '../../../config/utils';

const useStyles = makeStyles(theme => ({
    anchorItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '.75rem',
        color: theme.custom?.navigation?.iconColor || theme.custom.palette.gray,
        flexDirection: 'column',
        fontFamily: theme.custom.fontFamily.primaryFont,
        fontWeight: theme.custom.fontWeight.bold,
        '& svg':{
            '& g > path':{
                fill: theme.custom?.navigation?.iconColor || theme.custom.palette.gray,
            },
            '& > path':{
                fill: theme.custom?.navigation?.iconColor || theme.custom.palette.gray,
            }
        },
        [hoverDevice()]: {
            '&:hover': {
                filter: theme.custom.palette.filter
            },
          },
        '&.active':{
            filter: theme.custom.palette.filter
        },
    },
}));

export default useStyles;
