import { useQuery } from 'react-query'

import { useAuth } from '../../authentication'
import { useEnvData } from '../../hooks/useEnvData'

import { fetchBonusInfo, fetchBonusWalletInfo } from './services'

export const useBonus = options => {
  const { logged, username } = useAuth()
  const { bonusTableTemplate } = useEnvData() 
  const queryOptions = (bonusTableTemplate === 'simple') && options?.useNewBonusWallet ? {
    function: fetchBonusWalletInfo,
    name: 'bonus-wallet'
  } : {
    function: fetchBonusInfo,
    name: 'bonus-info'
  }
  return useQuery([queryOptions.name, username], queryOptions.function, {
    enabled: logged,
    ...options,
  })
}