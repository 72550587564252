export const useIsIOS = () => {
    const browserInfo = window.navigator.userAgent
    //TO-DO: This "userAgent" approach should be changed if websites teams decides to use another cross app approach
    const isIOS = /iP\w*/gm.test(browserInfo)
    return { isIOS }
}

export const getOperatingSystem = () => {
    const userAgent  = window.navigator.userAgent
    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
        return 'mac'
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
        return 'ios'
    } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
        return 'windows'
    } else if (/Android/.test(userAgent)) {
        return 'android'
    } else if (/Linux/.test(userAgent)) {
        return 'linux'
    } else {
        return 'windowsPhone'
    } 
}