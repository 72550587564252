import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './summaryPanel.styles'

const SummaryPanel = props => {
  const classes = useStyles()

  return (
    <div className={classes.panel}>
      <div className={classes.summary}>{props.children}</div>
      {props.actions ? (
        <div className={classes.actions}>{props.actions}</div>
      ) : null}
    </div>
  )
}

SummaryPanel.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
}

export default SummaryPanel
