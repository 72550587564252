export function formatDate(inputDate: string | null | undefined): string | null {
    if (!inputDate) return null;

    const date = new Date(inputDate);

    const padLeft = (number: number, length = 2, char = '0'): string => {
        return number.toString().padStart(length, char);
    };

    const formatTime = (date: Date): string => {
        const hours12Format = (date.getHours() + 11) % 12 + 1; // Convert to 12-hour format
        const period = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${padLeft(hours12Format)}:${padLeft(date.getMinutes())}:${padLeft(date.getSeconds())}${period}`;
    };

    const formatDate = (date: Date): string => {
        return `${padLeft(date.getMonth() + 1)}/${padLeft(date.getDate())}/${date.getFullYear()}`;
    };

    return `${formatTime(date)}, ${formatDate(date)}`;
}