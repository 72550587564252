import React from 'react'
import PropTypes from 'prop-types'
import { buildGameImageUrl } from '../../../../business'
import ErrorMessage from '../../../../ui/components/ErrorMessage'
import BonusMessage from './BonusMessage'
import MobileOrDesktopImg from '../../../../ui/components/Img/MobileOrDesktopImg'
import useStyles from './gameInfo.styles'
import Skeleton from '../../../../ui/components/Skeleton'
import { ASPECT_RATIOS } from '../../../../ui/constants'
import Placeholder from '../../../../ui/components/Placeholder'
import BottomTicker from './BottomTicker'
import { useTranslations, useCurrency } from '../../../../i18n'
import FavButton from '../../FavButton'
import Rating from './Rating'
import { useLocation } from 'react-router'
import CustomPropTypes from '../../../../customPropTypes'

const GameInfoSkeleton = props => (
  <Skeleton
    variant="rect"
    isRounded={props.isRounded}
    aspectRatio={ASPECT_RATIOS.GAME_INFO}
  />
)

const GameInfoPlaceholder = () => (
  <Placeholder aspectRatio={ASPECT_RATIOS.GAME_INFO} type="default" />
)

GameInfoSkeleton.propTypes = {
  isRounded: PropTypes.bool,
}

function OldInfoModal({
  errorBefore,
  game,
  logged,
  favorited,
  disabled,
  onFavorite,
  category,
  errorAfter,
  enableBalanceAlert,
  myBonusLocation,
  isLoadingPlayableBalance,
  activeBonusStatus,
  playableBalanceError,
  playableBalanceStatus,
  playableBalance,
  actions,
}) {
  const formatMessage = useTranslations()
  const formatCurrency = useCurrency()
  const location = useLocation()
  const classes = useStyles()
  return (
    <div>
      <ErrorMessage
        className="errorFav"
        in={Boolean(errorBefore)}
        type="fluid"
        description={errorBefore}
      />
      <div className={classes.gameInfo}>
        <div className={classes.gameInfoImage}>
          <MobileOrDesktopImg
            breakpoint="md"
            imageMobile={buildGameImageUrl(game?.ImageUrlGame)}
            imageDesktop={buildGameImageUrl(game?.ImageDescriptionURL)}
            alt={formatMessage('noun:game-cover')}
            placeholder={<GameInfoSkeleton />}
            fallback={<GameInfoPlaceholder />}
          />
          {game?.JackpotValue > 0 && (
            <BottomTicker jackpot={formatCurrency(game?.JackpotValue)} />
          )}
        </div>
        <div className={classes.gameInfoData}>
          <div className={classes.gameInfoTexts}>
            <div className={classes.gameInfoTitle}>
              <h1 data-testid="game-title">{game?.GameName}</h1>
              {logged && (
                <FavButton
                  label={formatMessage('action:add-favorite')}
                  favorited={favorited}
                  disabled={disabled}
                  onClick={onFavorite}
                />
              )}
            </div>
            {category && <p className={classes.gameInfoCategory}>{category}</p>}
            {game?.Description && (
              <p className={classes.gameInfoDescription}>{game?.Description}</p>
            )}
          </div>
          <Rating
            label={formatMessage('noun:volatility')}
            volatility={game?.Volatility}
          />
        </div>
      </div>
      <ErrorMessage
        className="errorUrl"
        in={Boolean(errorAfter)}
        type="fluid"
        description={errorAfter}
      />
      {enableBalanceAlert && location.pathname !== myBonusLocation && (
        <BonusMessage
          isLoadingPlayableBalance={isLoadingPlayableBalance}
          isError={playableBalanceError}
          message={activeBonusStatus}
          status={playableBalanceStatus}
          bonus={playableBalance}
        />
      )}
      <div className={classes.actionBar}>{actions}</div>
    </div>
  )
}

OldInfoModal.defaultProps = {
  errorBefore: '',
  errorAfter: '',
}

OldInfoModal.Placeholder = GameInfoPlaceholder

OldInfoModal.propTypes = {
  game: CustomPropTypes.game.isRequired,
  logged: PropTypes.bool,
  favorited: PropTypes.bool,
  disabled: PropTypes.bool,
  onFavorite: PropTypes.func,
  actions: PropTypes.node,
  errorBefore: PropTypes.string,
  errorAfter: PropTypes.string,
  isLoadingPlayableBalance: PropTypes.bool,
  activeBonusStatus: PropTypes.string,
  playableBalanceStatus: PropTypes.string,
  playableBalance: PropTypes.number,
  playForFree: PropTypes.bool,
  enableBalanceAlert: PropTypes.bool,
  playableBalanceError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default OldInfoModal
