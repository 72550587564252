import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper:{
    background: theme.custom.playableBalance?.background,
    padding: '.5rem',
    borderRadius: theme.custom.borders.rounded,
    margin: '.75rem .9rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '.2rem',
    alignItems: 'center',
    color: theme.custom.palette.grayLight,
  },
  title:{
    fontSize: theme.custom.fontSize.xs,
    textAlign: 'center',
  },
  bonus:{
    fontSize: theme.custom.fontSize.xs,
  },
  number: {
    color: theme.custom.playableBalance?.numberColor,
    fontWeight: theme.custom.fontWeight.bold,
    marginLeft: '0.25rem',
  },
  link:{
    textDecoration: 'underline',
    fontSize: theme.custom.fontSize.xxs,
  },
  decorator:{
    display: 'flex',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

export default useStyles
