import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  page: {
    '& $errorTitle': {
      ...texts.stepUp3,
    },
    '& $errorIcon': {
      color: theme.custom.palette.neutral1_87,
    },
  },
  content: {
    '&$errorSection': {
      marginLeft: 'auto',
      marginRight: 'auto',

      [mq('sm')]: {
        maxWidth: '35rem',
      },
    },
    '& $errorTitle': {
      ...texts.stepUp2,
    },
    '& $errorIcon': {
      fontSize: '4rem',
      color: theme.custom.palette.errorLight,

      [mq('md')]: {
        marginBottom: theme.spacing(1.25),
      },
    },
  },
  errorSection: {
    textAlign: 'center',
  },
  errorIcon: {
    display: 'inline-block',
    fontSize: '5rem',
    marginBottom: theme.spacing(0.75),

    '& svg': {
      display: 'block',
      width: '1em',
      height: '1em',
      color: theme.custom.errorPage?.color || theme.custom.palette.neutral1_60,
    },
  },
  errorIconWrapper: {
    display: 'flex',
  },
  errorTitle: {
    marginTop: 0,
    marginBottom: theme.spacing(0.5),
    fontWeight: theme.custom.errorPage?.weight || theme.custom.fontWeight.extrabold,
    lineHeight: theme.custom.lineHeight.xs,
    color: theme.custom.errorPage?.color || theme.custom.palette.neutral1_87,

    [mq('lg')]: {
      marginBottom: theme.spacing(0.75),
    },
  },
  errorDescription: {
    marginBottom: theme.spacing(1.5),
    ...texts.baseline,
    lineHeight: theme.custom.lineHeight.md,
    color: theme.custom.errorPage?.color || theme.custom.palette.neutral1_60,

    [mq('lg')]: {
      marginBottom: theme.spacing(2.5),
    },
  },
}))

export default useStyles
