import { lazy, Suspense } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import GlobalErrorBoundary from '../../context/Errors/GlobalBoundary'
import PageContents from '../../ui/components/PageContents'
import Head from '../../context/Head'
import HomeSlider from '../../context/Home/Slider'
import Home from '../../context/Home'
import Navigation from '../../context/Navigation'
import { isAmberLayout, getCasinoSubPath101 } from '../../ui/config/utils'
import Promotions from '../../ui/external-components/Promotions'
import CategoryHero from '../../context/Category/Hero'
import ProtectedRoute from '../ProtectedRoute'
import { useTranslations } from '../../i18n'
import { useIsBrand } from '../../hooks/useIsBrand'
import Favorites from '../../context/Favorites'
import { cashRacesUrlEnabled, cashierOauth } from '../../config'
import Casino101 from '../../ui/external-components/Casino101'
import UnifiedCategory from '../../context/Category'
import CashRaces from '../../context/CashRaces'
import {
  mockCasino,
  mockGuide,
} from '../../ui/external-components/Casino101/mock'
import { useAuth } from '../../authentication'
import BonusContribution from '../../context/BonusContribution'
import Loading from '../../context/Loading'
import Cashier from '../../context/Cashier'
import CashierOAuth from '../../context/CashierOAuth'
import Banking from '../../context/Banking'
import ContactUs from '../../context/ContactUs'
import NotFound from '../../context/Errors/NotFound'
import {
  GamePlayModal,
  GameInfoModalBFF,
  GamePlayContextProvider,
} from '../../context/Game'
import SharedStyles from '../../ui/components/NewNavigation/components/SharedStyles'
import ScrollToTop from '../../context/ScrollToTop'
import ContentLayout from '../../context/Layout/ContentLayout'
import { isDesktop, isTablet } from 'src/libs/uaparser'
import useFeatureFlags from 'src/hooks/useFeatureFlag'

const Promotion = lazy(() => import('../../context/Promotion'))
const CasinoBonus = lazy(() => import('../../context/CasinoBonus'))
const MyAccount = lazy(() => import('../../context/MyAccount'))
const AmberRoutes = () => {
  const pageContentType = isAmberLayout() ? 'light' : 'dark'
  const formatMessage = useTranslations()
  const { isBOL, isSB, isHR } = useIsBrand()
  const useCashRacesUrl = cashRacesUrlEnabled()
  const { isNewDesktopEnabled } = useFeatureFlags()
  const showNewNavDesktop = (isDesktop() || isTablet()) && isNewDesktopEnabled

  return (<ContentLayout>
    <ScrollToTop />
    <Head
        title={formatMessage('meta:title')}
        description={formatMessage('meta:description')}
      />
    <GlobalErrorBoundary>
      <Routes>
        {/* NOTE used after the user exits cashier */}
        <Route path="/ssl-login" element={<Navigate to="/" />} />
        <Route
          path="/"
          element={
            <PageContents type={pageContentType} isNewNavigationActive={showNewNavDesktop}>
              <Head
                title={formatMessage('meta:title')}
                description={formatMessage('meta:description')}
              />
              <HomeSlider />
              <Navigation />
              <Home />
              {isAmberLayout() && <Promotions />}
            </PageContents>
          }
        ></Route>
        <Route
          path="/games/favorites"
          element={
            <ProtectedRoute notLoggedRedirect="/login">
              <PageContents type={pageContentType} isNewNavigationActive={showNewNavDesktop}>
                <Head
                  title={formatMessage('meta:title:category:favorites')}
                  description={formatMessage(
                    'meta:description:category:favorites',
                  )}
                />
                <CategoryHero slug="favorites" />
                <Navigation />
                <Favorites />
              </PageContents>
            </ProtectedRoute>
          }
        ></Route>
        {(isBOL() || isSB()) && (
          <Route
            path={`${getCasinoSubPath101()}*`}
            element={
              <PageContents type={pageContentType}>
                <Casino101 {...mockCasino} />
              </PageContents>
            }
          ></Route>
        )}
        {isSB() && (
          <Route
            path="/guide/*"
            element={
              <PageContents type={pageContentType}>
                <Casino101 {...mockGuide} />
              </PageContents>
            }
          ></Route>
        )}
        {useCashRacesUrl && (
          <Route
            path="/tournaments"
            element={
              <>
                <Head
                  title={formatMessage('meta:title:category:tournaments')}
                  description={formatMessage(
                    'meta:description:category:tournaments',
                  )}
                />
                <PageContents type={pageContentType} isNewNavigationActive={showNewNavDesktop}>
                  <CategoryHero slug="tournaments" />
                  <Navigation />
                  <CashRaces />
                </PageContents>
              </>
            }
          ></Route>
        )}
        <Route
          path="/games/:slug"
          element={
            <PageContents type={pageContentType} isNewNavigationActive={showNewNavDesktop}>
              <CategoryHero />
              <Navigation />
              <UnifiedCategory />
            </PageContents>
          }
        ></Route>
        <Route
          path="/promotions/bonus-contribution"
          element={
            <>
              <Head
                title={formatMessage('meta:title:promotions')}
                description={formatMessage('meta:description:promotions')}
              />
              <BonusContribution />
            </>
          }
        ></Route>
        <Route
          path="/promotions/*"
          element={
            <>
              <Head
                title={formatMessage('meta:title:promotions')}
                description={formatMessage('meta:description:promotions')}
              />
              <Suspense fallback={<Loading />}>
                <Promotion />
              </Suspense>
            </>
          }
        ></Route>
        {(isBOL() || isSB()) && (
          <Route
            path="/casino-bonus"
            element={
              <ProtectedRoute notLoggedRedirect="/login">
                <Head title={formatMessage('meta:title:my-account')} />
                <CategoryHero slug="casino-bonus" />
                <Navigation />
                <Suspense fallback={<Loading />}>
                  <CasinoBonus />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        )}
        {!isAmberLayout() && (
          <Route
            path="/my-account/*"
            element={
              <ProtectedRoute notLoggedRedirect="/login">
                <Head title={formatMessage('meta:title:my-account')} />
                <Suspense fallback={<Loading />}>
                  <MyAccount />
                </Suspense>
              </ProtectedRoute>
            }
          ></Route>
        )}
        <Route
          path="/cashier"
          element={
            <ProtectedRoute notLoggedRedirect="/login">
              <Head title={formatMessage('meta:title:cashier')} />
              {cashierOauth() ? <CashierOAuth /> : <Cashier />}
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/banking"
          element={
            <>
              <Head
                title={formatMessage('meta:title:banking')}
                description={
                  isHR() && formatMessage('meta:description:banking')
                }
              />
              <Banking />
            </>
          }
        ></Route>
        <Route
          path="/contact-us"
          element={
            <>
              <Head title={formatMessage('meta:title:contact-us')} />
              <ContactUs />
            </>
          }
        ></Route>

        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <GamePlayContextProvider>
        <GameInfoModalBFF />
        <GamePlayModal />
      </GamePlayContextProvider>
    </GlobalErrorBoundary>
    <SharedStyles />
    </ContentLayout>
  )
}

export default AmberRoutes
