import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import Modal from './Modal'
import Drawer from './Drawer'

const GameInfoModal = props => {
  const { title, open, onClose, children, executedFrom } = props

  return (
    <MediaQuery minWidth={600}>
      {matches =>
        matches ? (
          <Modal open={open} title={title} onClose={onClose} executedFrom={executedFrom}>
            {children}
          </Modal>
        ) : (
          <Drawer open={open} title={title} onClose={onClose} executedFrom={executedFrom}>
            {children}
          </Drawer>
        )
      }
    </MediaQuery>
  )
}

GameInfoModal.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  executedFrom: PropTypes.string,
}

export default GameInfoModal
