import useStyles from './navigation.styles'
import Skeleton from '../Skeleton'
const NavigationSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.navigationItem}>
      <Skeleton variant="rect" isAdaptive />
    </div>
  )
}

export default NavigationSkeleton