import React from 'react'
import Cookies from 'js-cookie';
import { useEffect } from 'react';

interface CashierOauthRedirectHook {
  lastUrlForCashier: string;
};

const useCashierOauthRedirect = (): CashierOauthRedirectHook => {
  const { pathname } = window.location;
  const lastUrlForCashier:string = Cookies.get('lastUrlforCashier') || pathname;

  useEffect(() => {
    const isCashierDeposit:boolean = pathname === '/cashier';

    if (!isCashierDeposit) {
      Cookies.set('lastUrlforCashier', pathname);
    }

    return () => {
      Cookies.get('lastUrlforCashier');
    };
  }, [pathname]);

  return { lastUrlForCashier };
};

export default useCashierOauthRedirect;