import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../../components/Icon'
import useStyles from './contact.styles'

const Contact = props => {
  const classes = useStyles()

  return (
    <div className={classes.contact}>
      <Icon className={classes.contactImage} name={props.icon} />
      <h4 className={classes.contactTitle}>{props.heading}</h4>
      <p className={classes.contactText} id="contact-email">{props.text}</p>
      {props.children}
    </div>
  )
}

Contact.propTypes = {
  icon: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Contact
