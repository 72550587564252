const kameleoonNewVersionFlagName = 'KameleoonNewVersion'
const kameleoonClientDataKey = 'kameleoonClientData'
const kameleeonPropertyDataName = 'kameleoonTargetingData'

const isKameleoonNewVersionFlag = !!localStorage.getItem(
  kameleoonNewVersionFlagName,
)

const setKameleoonNewVersionFlag = () =>
  localStorage.setItem(kameleoonNewVersionFlagName, String(true))

export const checkKameleoonClientData = () => {
  if (!isKameleoonNewVersionFlag) {
    const kameleeonClientData = localStorage.getItem(kameleoonClientDataKey)
    if (kameleeonClientData) {
      const kameleeonClientDataParsed = JSON.parse(kameleeonClientData)
      if (kameleeonPropertyDataName in kameleeonClientDataParsed) {
        localStorage.removeItem(kameleoonClientDataKey)
      }
    }
    setKameleoonNewVersionFlag()
  }
}

export const getDomainFromLocation = () => {
  const hostname = window.location.hostname
  const domainParts = hostname.split('.')
  if (domainParts.length >= 2 ) {
    return `.${domainParts.slice(-2).join('.')}` 
  }
  return `.${hostname}`
}