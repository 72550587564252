import React, { useRef, useState, useEffect } from 'react'

import PageContents from '../../ui/components/PageContents'
import { useTranslations } from '../../i18n'
import { useAuth } from '../../authentication'
import { useCashierInformation } from '../../asyncData'
import { openLiveChat } from '../../liveChat'

const IFRAME_ID = 'cashier'

const Cashier = () => {
  const formatMessage = useTranslations()
  const ref = useRef()
  const [height, setHeight] = useState(0)
  const { logged } = useAuth()
  const { data, isSuccess } = useCashierInformation({
    enabled: logged,
    useErrorBoundary: true,
  })

  useEffect(() => {
    if (!isSuccess) return
    ref.current.submit()
  }, [isSuccess])

  useEffect(() => {
    if (!data) return

    const handleMessage = event => {
      if (!data.Url.startsWith(event.origin)) return
      if (typeof event.data === 'number' || typeof event.data === 'string') {
        setHeight(event.data)
      } else if (event.data.SetTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else if (typeof event.data.G2C === 'boolean') {
        if (event.data.G2C) openLiveChat()
      } else {
        console.warn('Unhandled cashier event', event.data)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [data])

  const style = { border: 'none', width: '100%', height }

  return (
    <PageContents>
      <iframe
        name={IFRAME_ID}
        title={formatMessage('noun:cashier')}
        style={style}
      />
      <form ref={ref} action={data?.Url} method="POST" target={IFRAME_ID}>
        <input type="hidden" name="ID" value={data?.ID ?? ''} />
        <input type="hidden" name="bp" value={false} />
        <input type="hidden" name="tid" value={0} />
        <input type="hidden" name="rurl" value={window.location.origin} />
        <input type="hidden" name="PreviusUrl" value={window.location.origin} />
        <input type="hidden" name="RedirectURL" value="CashierMenu.asp" />
      </form>
    </PageContents>
  )
}

export default Cashier
