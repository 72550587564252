import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './indicatorBar.styles'
import { mapChildren } from '../../../libs/utils'

const IndicatorBar = props => {
  const classes = useStyles()

  return (
    <ul className={classes.indicatorBar}>
      {mapChildren(props.children, (child, idx) => (
        <li key={idx} className={classes.indicatorBarItem}>
          {child}
        </li>
      ))}
    </ul>
  )
}

IndicatorBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default IndicatorBar
