import { makeStyles } from '../../components/materialUiWrapper';
import { mq } from '../../config/utils';

const useStyles = makeStyles(theme => ({
  header: {
    transform: 'translateZ(0)',
  },
  globalHeader: {
    height: '3.75rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 0.75rem',
    top: 0,
    zIndex: theme.custom.zIndex?.appBar,
    backgroundColor: theme.custom.appBar?.background,
    position: 'sticky',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: '100rem',
    [mq('xxs')]: {
      padding: '0rem 0.5rem 0rem 0.625rem',
    },
    [mq('md')]: {
      padding: '0rem 1.25 0rem 1.875rem',
    },
    [mq('xl')]: {
      padding: '0rem 0rem 0rem 0.625rem',
    }
  },
  shadow: {
    boxShadow: theme.custom.shadows.header,
  },
  logoCtn: {
    alignSelf: 'center',
    display: 'flex',
    height: '100%',
  },
  logo: {
    height: '100%',
    display: 'block',
    width: theme.custom.appBar?.logoWidth,
    alignSelf: 'flex-end',
    [mq('md')]: {
      width: theme.custom.appBar?.logoWidthMd,
    },
  },
  logoctn: {
    height: '100%',
    width: theme.custom.appBar?.logoWidth,
    alignItems: 'center'
  },
  logoImg: {
    height: '100%',
    objectFit: 'contain',
    width: theme.custom.appBar?.logoWidth,
    [mq('md')]: {
      width: theme.custom.appBar?.logoWidthMd,
    },
  },
  wrapActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '1',
    marginRight: '0.375rem',

    [mq('sm')]: {
      marginRight: '.85rem',
    },

    '& button:not(:last-child)': {
      marginRight: '0.375rem',
      [mq('sm')]: {
        marginRight: '.75rem',
      },
    },
  },
  backToClassicBtn: {
    alignSelf: 'center',
    marginInline: '0.5rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    [mq('sbMobile')]: {
      justifyContent: 'flex-start',
    },
  }
}))

export default useStyles