import { UAParser } from 'ua-parser-js'

const UAPARSER_PORTABLE_DEVICES_TYPES: string[] = [
  UAParser.DEVICE.MOBILE,
  UAParser.DEVICE.TABLET,
]

const uaParser = new UAParser()

const isMobileOrTablet = () =>
  UAPARSER_PORTABLE_DEVICES_TYPES.includes(uaParser.getDevice().type)


/**
 * Check if the user's device is mobile.
 * @returns boolean
 */
const isMobile = (): boolean => uaParser.getDevice().type === UAParser.DEVICE.MOBILE;

/**
 * Check if the user's device is tablet
 * @returns boolean
 */
const isTablet = (): boolean => uaParser.getDevice().type === UAParser.DEVICE.TABLET;

/**
 * Check if the user's device is desktop
 * @returns boolean
 */
const isDesktop = (): boolean => !isTablet() && !isMobile();

export {isMobileOrTablet, isMobile, isTablet, isDesktop}