import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    createContext,
    useContext,
  } from 'react'
  import cx from 'classnames'
  import PropTypes from 'prop-types'
  import { useTheme } from '@material-ui/core'
  import BackToClassicBtn from './BackToClassicBtn'
  import useIsEnabledBackToClassic from '../../../hooks/useIsEnabledBackToClassic'

  import { useTranslations } from '../../../i18n'

  import useStyles from './header.styles'
  import { useIsBrand } from '../../../hooks/useIsBrand'


  const HeaderContext = createContext()

  const Header = props => {
    const { children, logo, logoSlim, logoLink, backToClassicBtn = {} } = props
    // XXX themification (i18n)
    const theme = useTheme()
    const [shadow, setShadow] = useState(false)
    const headerRef = useRef()
    const {isSB} = useIsBrand()
    const getScrollPosition = useCallback(
      () => (window.scrollY <= 0 ? setShadow(false) : setShadow(true)),
      [],
    )
    const showBackToClassic = isSB()
    const { enableInMobilePlatform = true, enableInDesktopPlatform = true, Url = 'https://www.sportsbetting.ag/', Text } = backToClassicBtn
    const { isEnabled } = useIsEnabledBackToClassic(enableInMobilePlatform, enableInDesktopPlatform)
    const enabledBtn = isEnabled()

    useEffect(() => {
      getScrollPosition()
      const options = { passive: true }
      window.addEventListener('scroll', getScrollPosition, options)
      return () =>
        window.removeEventListener('scroll', getScrollPosition, options)
    }, [getScrollPosition])

    const formatMessage = useTranslations()
    const classes = useStyles()
    const headerClasses = cx(classes.globalHeader, shadow && classes.shadow)

    return (
      <header className={headerClasses} ref={headerRef}>
        <HeaderContext.Provider
          value={{ height: headerRef?.current?.offsetHeight }}
        >
          <div className={classes.container}>
            <div className={classes.logoCtn}>
              <a href={logoLink} className={classes.logo} id='header-logo-brand'>
                <picture>
                  <source media="(max-width: 480px)" srcSet={logoSlim} />
                  <source media="(min-width: 481px)" srcSet={logo} />
                  <img
                    className={classes.logoImg}
                    src={logo}
                    alt={formatMessage('noun:logo-brand', {
                      brand: theme.custom.brand.name,
                    })}
                  />
                </picture>
              </a>
            </div>
            {enabledBtn && (<div className={classes.backToClassicBtn}>
            {showBackToClassic && <BackToClassicBtn url={Url} text={Text} />}
          </div>)}
            <div className={classes.wrapActions}>{children}</div>
          </div>
        </HeaderContext.Provider>
      </header>
    )
  }

  Header.propTypes = {
    logo: PropTypes.string.isRequired,
    logoSlim: PropTypes.string.isRequired,
    children: PropTypes.node,
    logged: PropTypes.bool,
    logoLink: PropTypes.string,
    backToClassicBtn: PropTypes.shape({
      enableInMobilePlatform: PropTypes.bool,
      enableInDesktopPlatform: PropTypes.bool,
      Url: PropTypes.string,
      Text: PropTypes.string,
    })
  }

  export const useHeaderContext = () => {
    const context = useContext(HeaderContext)
     if (context === undefined) {
      throw new Error(
        'useHeaderContext must be used within HeaderContextProvider',
      )
    }
    return context
  }

  export default Header
