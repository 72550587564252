import Env from '@env';
import { stringify } from 'qs'
import { isLogged, getToken, updateToken } from '../authentication'

export const getApiEndpoint = apiManager =>
  `${apiManager
    .getBaseUri()
    .replace('%currentApi%', apiManager.getTarget())}/${apiManager.getTarget()}`

export const buildPath = (api, endpoint) => `${getApiEndpoint(api)}${endpoint}`

export const buildHeaders = () => {
  const headers = {
    gsetting: Env.get('VITE_GSETTING'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  if (isLogged()) {
    headers['Authorization'] = `Bearer ${getToken()}`
  }

  if (Env.get('VITE_API_WALLETS_ENDPOINT_XVERSION')) {
    headers['X-version'] = Env.get('VITE_API_WALLETS_ENDPOINT_XVERSION')
  }

  return headers
}

const isGetOrHead = method => method === 'GET' || method === 'HEAD'

export const buildOptions = (payload, method, path) => ({
  method,
  headers: buildHeaders(),
  body: isGetOrHead(method) ? undefined : JSON.stringify(payload),
})

export const updateTokenIfExpiresInSeconds = async seconds => {
  if (!isLogged()) {
    return
  }

  await updateToken(seconds)
}

export class ModelError extends Error {
  constructor(message) {
    super(message)
    this.name = 'ModelError'
  }
}

const request = async (api, endpoint, payload, method, validateLegacyModel) => {
  let path = buildPath(api, endpoint)

  if (isGetOrHead(method) && payload) path = `${path}?${stringify(payload)}`

  const options = buildOptions(payload, method, path)

  await updateTokenIfExpiresInSeconds(5)

  const response = await fetch(path, options)

  if (response.ok) {
    const data = await response.json()

    if (validateLegacyModel && (data.IsError || !data.IsValidModel)) {
      const error = data.ModelErrorMessage || 'Invalid data in response'
      throw new ModelError(error)
    }

    return data
  } else {
    if (response.status === 401 && isLogged()) {
      await updateToken(Number.MAX_SAFE_INTEGER)
    }

    throw response.status
  }
}

export const get = async ({
  api,
  endpoint,
  payload,
  validateLegacyModel = true,
}) => request(api, endpoint, payload, 'GET', validateLegacyModel)

export const post = async ({
  api,
  endpoint,
  payload = undefined,
  validateLegacyModel = true,
}) => request(api, endpoint, payload, 'POST', validateLegacyModel)
