import { v4 as uuidv4 } from 'uuid'
const EventBuilderGraphyte = (data, userid, event_location, event_channel) => ({
  event_type: 'gaming',
  event_uuid: uuidv4(),
  event_datetime: new Date().toISOString(),
  event_value: '',
  event_info_1: data?.GameID,
  event_info_2: data?.GameName,
  event_info_3: data?.CategoryName,
  userid,
  event_ref_id: '',
  event_ccy: 'USD',
  event_language: 'EN',
  event_value_3: '',
  event_country: '',
  event_platform: '',
  event_location,
  event_location_index: data?.DisplayOrder,
  event_channel,
})

export default EventBuilderGraphyte
