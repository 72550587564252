import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  cardContainer: {
    minWidth: '300px',
    maxWidth:'300px',
    minHeight: '342px',
    maxHeight: '342px',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    marginBottom: '2rem',
    '@media (min-width:780px)': {
      '&:hover': {
        backgroundColor: theme.custom.palette.cardHover, 
        boxShadow:'0px 3px 8px 0px #00000026',
        transition: 'none', 
        '& $button': {
          backgroundColor: theme.custom.promotions?.button.hover,
          '&.Mui-disabled': {
            backgroundColor: theme.custom.palette.quaternary700,
            color: theme.custom.palette.disabledBtnText,
            transition: 'none',
          },
        },
      },
    },
    
  },
  image: {
    width: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentSubtitle: {
    width:'205px',
    minHeight: '60px',
    maxHeight: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpaces: 'no-wrap',
    color: theme.custom.palette.cardSubtitle,
    fontFamily: theme.custom.fontFamily.primaryFont,
    fontWeight: '700',
    fontSize: '18px',
    marginBottom:'10px',
    textAlign:'center',
  },
  button: {
    backgroundColor: theme.custom.promotions?.button.bgColor,
    borderRadius: '3px',
    height: '52px',
    minWidth: '80%',
    maxWidth: '80%',
    textAlign:'center',
    overflow:'hidden',
    padding: '16px 24px 16px 24px',
    '&:hover': {
      backgroundColor: theme.custom.promotions?.button.hover,
    },
    '&:disabled': {
      backgroundColor: theme.custom.palette.quaternary700,
      color: theme.custom.palette.disabledBtnText,
    },
  },
}))

export default useStyles