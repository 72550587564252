import React from 'react'
import Button from '../../../components/Button'
import PropTypes from 'prop-types'
import Icon from '../../../components/Icon';
import { useStyles } from './customButtons.styles';

const CustomButtons = props => {
    const { children, type } = props;
    const { icon, ...rest } = props;
    const classes = useStyles();
    const styles = {
        login: classes.customFixedButton,
        join: classes.customFixedButton,
        banking: classes.customBankingButton,
        newNav: classes.customNewNavButton,
        newNavDeposit: `${classes.customNewNavButton} ${classes.customNewNavDepositButton} `,
    }
    return <Button className={styles[type] || classes.customFixedButton} {...rest}>
            {icon && (<Icon className={classes.buttonIcon} name={icon} />)}
            {children}
            
    </Button>
}
CustomButtons.propTypes = {
    type: PropTypes.string,
    icon: PropTypes.string || PropTypes.node,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string
}
export default CustomButtons;