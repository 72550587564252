import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  searchBar: {
    marginBottom: '2rem',
    [mq('md')]: {
      maxWidth: '22rem',
    },
  },
  searchInput: {
    fontFamily: ({brand}) => brand ? theme?.custom?.fontFamily?.secondaryFont :'inherit',
    fontWeight: ({brand}) => brand ? theme?.custom?.fontWeight?.bold : theme?.custom?.fontWeight?.normal,
    backgroundColor: ({brand}) => brand ? `${theme?.custom?.searchBar?.background} !important` : 'transparent',
  },
  
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    padding: '0 1rem',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.custom?.searchBar?.closeIconColor || theme.custom.palette.neutral1,
    opacity: theme?.custom?.opacity?.opacity90 || '1',
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      fill: theme.custom?.searchBar?.svgColor || theme.custom.palette.neutral1,
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    padding: '0 1rem',
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.custom.palette.neutral1,
    opacity: theme?.custom?.opacity?.opacity40 || '1',
    '& svg': {
      fill: theme.custom?.searchBar?.svgColor || theme.custom.palette.neutral1,
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}))

export default useStyles
