import { makeStyles } from '../../materialUiWrapper'
import { slideTheme } from '../../../config/slideTheme'
import { getBreakpoint } from '../../../config/utils'

const useStyles = makeStyles(theme => ({
  tagList: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: '0.5rem 0.5rem',
    paddingBottom: props =>
      slideTheme.tagList.paddingBottom[getBreakpoint(props.offsetWidth)],
    backgroundColor: props => props.isUnifiedLobby ? 'rgba(0,0,0,0.7)' : 'transparent',
  },
  gameResults: {
    height: 'auto',
    minHeight: (props) => slideTheme.gameResults.minHeight[getBreakpoint(props.offsetWidth)],
    paddingBottom: props =>
      slideTheme.tagList.paddingBottom[getBreakpoint(props.offsetWidth)],
  },
  imgCtn: {
    width: '5.055rem',
    margin: 'auto',
    paddingBottom: '50%',
  },
}))

export default useStyles
