import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import CustomPropTypes from '../../../customPropTypes'
import useStyles from './linkCard.styles'
import { ASPECT_RATIOS } from '../../constants'

const LinkCard = props => {
  const { text, to } = props
  // NOTE pass aspect ratio without including it as a prop
  const classes = useStyles({ ...props, aspectRatio: ASPECT_RATIOS.GAME_CARD })

  const linkCardClasses = cx(classes.linkCard, classes.aspectRatio)

  return (
    <Link to={to} className={linkCardClasses}>
      <span>{text}</span>
    </Link>
  )
}

LinkCard.propTypes = {
  text: PropTypes.string,
  to: CustomPropTypes.link.isRequired,
}

export default LinkCard
