import React from 'react';
import { Link } from 'react-router-dom';

import { formatMessage } from '../../../i18n';
import PromotionCarousel from './PromotionCarousel';
import useStyles from './promotions.styles';
import { useExternalCasinoPromotions } from '../../../asyncData/cms/useExternalCasinoPromotions';
import { useIsBrand } from '../../../hooks/useIsBrand';
import { useEnvData } from '../../../hooks/useEnvData';

export const strapiVersion = 4

const Promotions = () => {
  const { isBOL } = useIsBrand()
  const classes = useStyles()
  const { baseDomain } = useEnvData()
  const { isLoading, error, data } = useExternalCasinoPromotions(isBOL() ? strapiVersion : null)
  const promos = isBOL() ? data?.data : data
  
  return (
    <>
    {data && (
      <div className={classes.container}>
      <h2 className={classes.h2}>{formatMessage('meta:sbPromotions:title')}</h2>
      <Link to={`${baseDomain}/promotions/casino`} target='_blank'>
      <h3 className={classes.h3}>
      {formatMessage('meta:sbPromotions:viewAll')}
      </h3>
      </Link>
      {!isLoading && !error && data && <PromotionCarousel data={promos} /> }
      </div>
      )
    }
    </>
  )
}
export default Promotions
