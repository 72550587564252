import React from 'react'
import { Link } from 'react-router-dom'

import PageContents from '../../ui/components/PageContents'
import Container from '../../ui/components/Container'
import PageHeading from '../../ui/components/PageHeading'
import ContentSkeleton from '../../ui/components/ContentSkeleton'
import Markdown from '../../ui/components/Markdown'
import EmptyMessage from '../../ui/components/EmptyMessage'
import Button from '../../ui/components/Button'
import NoResultsImage from '../../assets/icons/no-results.svg'

import { useBonusContribution } from '../../asyncData'

import { useTranslations } from '../../i18n'

const BonusContribution = () => {
  const formatMessage = useTranslations()
  const { isLoading, error, data } = useBonusContribution()

  if (error && error !== 404 && error !== 403) throw error

  return (
    <PageContents>
      <PageHeading title={formatMessage('noun:bonus-contribution')} />
      {error ? (
        <EmptyMessage
          image={<NoResultsImage />}
          title={formatMessage('generic:empty-message:title')}
          description={formatMessage('bonus-contribution:empty-message')}
        >
          <Button as={Link} to="/promotions">
            {formatMessage('action:go-promotions')}
          </Button>
        </EmptyMessage>
      ) : (
        <Container maxWidth="sm">
          {isLoading ? (
            <ContentSkeleton />
          ) : (
            <Markdown content={data?.content} allowDangerousHtml />
          )}
        </Container>
      )}
    </PageContents>
  )
}

export default BonusContribution
