import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './error.styles'
import Icon from '../Icon'
import DynamicSvgIcon from '../Icon'

const Error = props => {
  const classes = useStyles()
  const errorClasses = cx(classes.errorSection, classes[props.type])
  return (
    <section className={errorClasses}>
      <div className={classes.errorIcon}>
        <Icon className={classes.errorIconWrapper} name={props.icon} />
      </div>
      <h1 className={classes.errorTitle}>{props.title}</h1>
      <p className={classes.errorDescription}>{props.description}</p>
      {props.children}
    </section>
  )
}

Error.defaultProps = {
  type: 'page',
}

Error.propTypes = {
  type: PropTypes.oneOf(['content', 'page']),
  icon: PropTypes.oneOf(['internal-error', 'not-found']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Error
