import React from 'react'

import { HeroPlaceholder, HeroSkeleton } from '../../ui/components/Hero'
import { useHomeSlider, getCmsImageUrl } from '../../asyncData'
import { useAuth } from '../../authentication'
import DynamicHero, { Slide } from './DynamicHero'
import { useRedirectToExternal } from '../../hooks/useRedirectToExternal'

const byLoginStatus = logged => slide =>
  logged ? slide.logged_in_banner !== null : slide.logged_out_banner !== null

const getBannerData = logged => slide => {
  const { logged_in_banner, logged_out_banner, ...rest } = slide
  const { banner, URL } = logged ? logged_in_banner : logged_out_banner
  const { desktop, mobile } = banner

  return {
    ...rest,
    mobile: getCmsImageUrl(mobile),
    desktop: getCmsImageUrl(desktop),
    URL,
  }
}

const shouldRedirect = logged => slide => {
  const { redirection, ...rest } = slide
  const lowerCaseURL = slide?.URL?.toLowerCase();
  // TO-DO: Review this patch. It was made only to fix PPD UI becuase URL is missing in strapi.
  if (!lowerCaseURL) return { ...rest, shouldRedirect: false }

  if (lowerCaseURL === '/login') {
    return { ...rest, shouldRedirect: true, redirectType: 'LOGIN', }
  } else if (lowerCaseURL === '/join') {
    return { ...rest, shouldRedirect: true, redirectType: 'JOIN', }
  } else if (lowerCaseURL.startsWith('/')) {
    return { ...rest, shouldRedirect: false }
  }
  return { ...rest, shouldRedirect: true, redirectType: 'EXTERNAL', }
}

const HomeSlider = () => {
  const { initialized, logged, redirectToJoin, redirectToLogin } = useAuth()
  const { isLoading, error, data } = useHomeSlider()
  const { redirectToExternalUrl, getLocationOrigin } = useRedirectToExternal()

  if (isLoading) return <HeroSkeleton />

  if (error || data?.slides.length === 0) return <HeroPlaceholder />

  const slides = data.slides
    .filter(byLoginStatus(logged))
    .map(getBannerData(logged))
    .map(shouldRedirect(logged))
  if (!slides.length) return <HeroPlaceholder />

  return (
    <DynamicHero logged={logged}>
      {slides.map(slide => {
        if (!initialized) {
          return <HeroSkeleton key={slide.id} />
        }

        const redirectToUrl = () => redirectToExternalUrl(slide.URL)
        const redirect = () => {
          const options = {
            redirectUri: getLocationOrigin() + slide.URL,
          }
          if (slide.redirectType === 'EXTERNAL') {
            return redirectToUrl()
          }
          return slide.redirectType === 'LOGIN'
            ? redirectToLogin(options)
            : redirectToJoin(options)
        }

        const handleClick = slide.shouldRedirect ? redirect : slide.URL

        return (
          <Slide
            key={slide.id}
            alt={slide.title}
            imageMobile={slide.mobile}
            imageDesktop={slide.desktop}
            onClick={handleClick}
          />
        )
      })}
    </DynamicHero>
  )
}

export default HomeSlider
