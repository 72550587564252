import {
  RequestService,
  RequestServiceMultiServiceTransportClientImpl,
} from '@websites.common/request-service'
import TransportImpl from './transportImpl'
export const eventType = {
  onRequestServiceStatusChanged: 'request-service-status-change',
  onTransportStatusChanged: 'transport-status-changed',
}
export default class RequesterFactory {
  static requesterServices = {}

  static subscribe(eventName, listener) {
    window.addEventListener(eventName, listener)
  }

  static unsubscribe(eventName, listener) {
    window.removeEventListener(eventName, listener)
  }

  static onRequestServiceStatusChanged(service, status) {
    const event = new CustomEvent(eventType.onRequestServiceStatusChanged, {
      detail: { service, status },
    })
    window.dispatchEvent(event)
  }
  static getInstance(serviceName, parameters = {}) {
    // this grants us only one connection by service
    if (RequesterFactory.requesterServices[serviceName] == null) {
      const transport = TransportImpl.getInstance()

      const requesterImpl = new RequestServiceMultiServiceTransportClientImpl(
        serviceName,
        transport,
      )
      const requester = new RequestService(
        requesterImpl,
        parameters,
        RequesterFactory.onRequestServiceStatusChanged
      )
      
      requester.connect()
      
      RequesterFactory.requesterServices[serviceName] = requester
    }
    return this.requesterServices[serviceName]
  }
}
