import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'

import { useTranslations } from '../../i18n'

import BackButton from '../components/BackButton'
import TextHeading from '../components/TextHeading'
import NavLink from '../components/NavLink'
import Icon from '../components/Icon'

import Info from './Info'
import { CustomTheme } from '../CustomTheme/types'

const BonusInfo = props => {
  const { onGoBack, children } = props
  const formatMessage = useTranslations()
  // XXX themification
  const theme = useTheme<CustomTheme>()
  return (
    <>
      {onGoBack ? (
        <BackButton onClick={onGoBack}>
          {formatMessage('action:back')}
        </BackButton>
      ) : null}
      <Info>
        <TextHeading level={1}>{formatMessage('noun:bonus-info')}</TextHeading>
        <TextHeading level={2}>
          {formatMessage('noun:locked-cash-funds')}
        </TextHeading>
        <p>{formatMessage('bonus-wallet:bonus:locked-cash-funds-info')}</p>
        <TextHeading level={2}>{formatMessage('noun:bonus-funds')}</TextHeading>
        <p>{formatMessage('bonus-wallet:bonus:bonus-funds-info')}</p>
        {children}
        {/* REVIEW add link or remove? */}
        <NavLink to={theme?.custom?.brand.bonusInfoUrl}>
          {formatMessage('bonus-wallet:bonus:more-info')}
          <Icon name="external-link" />
        </NavLink>
      </Info>
    </>
  )
}

BonusInfo.propTypes = {
  onGoBack: PropTypes.func,
  children: PropTypes.node,
}

export default BonusInfo
