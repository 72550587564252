import React from 'react'
import { Grid as Columns, Box as Spacing, useTheme } from '@material-ui/core'

import PageContents from '../../ui/components/PageContents'
import PageHeading from '../../ui/components/PageHeading'
import Container from '../../ui/components/Container'
import TextNote from '../../ui/components/TextNote'
import Contact from '../../ui/components/Contact'
import Button from '../../ui/components/Button'
import NavLink from '../../ui/components/NavLink'

import { useTranslations } from '../../i18n'
import { openLiveChat } from '../../liveChat'
import { isIntercomCookieEnabled } from '../../business/index.js'
import { isIntercomFFEnabled } from '../../config/index.js'

const ContactUs = () => {
  const formatMessage = useTranslations()
  const theme = useTheme()

  const handleLiveChat = () => {
    if ((isIntercomCookieEnabled() || isIntercomFFEnabled())) {
      window.Intercom('show')
    } else {
      openLiveChat()
    }
  }

  return (
    <PageContents type="light">
      <PageHeading title={formatMessage('action:contact-us')} />
      <Container maxWidth="sm">
        <Columns container justify="center" spacing={1}>
          <Columns item xs={12}>
            <Container maxWidth="sm">
              <Spacing mt={2} mb={0.5}>
                <TextNote variant="intro">
                  {formatMessage('contact:intro')}
                </TextNote>
              </Spacing>
            </Container>
          </Columns>
          <Columns item xs={12} md={6}>
            <Contact
              icon="send"
              heading={formatMessage('noun:customer-support')}
              text={theme.custom.brand.email}
            >
              <Button
                as={NavLink}
                to={`mailto:${theme.custom.brand.email}`}
                target="_self"
                widthBehaviour="full"
                variant="ghost"
              >
                {formatMessage('action:send-email')}
              </Button>
            </Contact>
          </Columns>
          <Columns item xs={12} md={6}>
            <Contact
              icon="chat"
              heading={formatMessage('noun:live-help')}
              text={formatMessage('contact:live-chat')}
            >
              <Button
                widthBehaviour="full"
                onClick={handleLiveChat}
              >
                {formatMessage('action:start-live-chat')}
              </Button>
            </Contact>
          </Columns>
        </Columns>
      </Container>
    </PageContents>
  )
}

export default ContactUs
