import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import PageContents from '../../ui/components/PageContents'
import Container from '../../ui/components/Container'
import { Box as Spacing, useTheme } from '@material-ui/core'
import Error from '../../ui/components/Error'
import ButtonGroup from '../../ui/components/ButtonGroup'
import Button from '../../ui/components/Button'
import { useTranslations } from '../../i18n'

import Navigation from '../../context/Navigation'

const InternalError = props => {
  const { onGoToHome, onTryAgain } = props
  const formatMessage = useTranslations()
  const theme = useTheme()

  return (
    <PageContents>
      <Navigation />
      <Container maxWidth="sm">
        <Spacing my={{ xxs: 5.5, md: 8 }}>
          <Error
            icon="internal-error"
            title={formatMessage('error:500-title')}
            description={formatMessage('error:500-description')}
          >
            <ButtonGroup isReversible>
              <Button onClick={onTryAgain} widthBehaviour="fixed">
                {formatMessage('action:try-again')}
              </Button>
              <Button
                as={Link}
                onClick={onGoToHome}
                to="/"
                widthBehaviour="fixed"
                variant={
                  theme.custom.errorPage?.goToHomeButtonVariant || 'ghost'
                }
              >
                {formatMessage('action:go-homepage')}
              </Button>
            </ButtonGroup>
          </Error>
        </Spacing>
      </Container>
    </PageContents>
  )
}

InternalError.defaultProps = {
  onTryAgain: () => window.location.reload(),
}

InternalError.propTypes = {
  onGoToHome: PropTypes.func,
  onTryAgain: PropTypes.func,
}

export default InternalError
