import { isAmberLayout } from '../ui/config/utils'
import { replaceMirrorUrlHelper } from './originSingleton'
import parseEnvBoolean from './parseEnvBoolean'
import parseEnvString from './parseEnvString'

export const isQuickDepositEnabled = () =>
  parseEnvBoolean('VITE_QUICK_DEPOSIT_ENABLED')

export const isFavoriteFromOpenGameEnabled = () =>
  parseEnvBoolean('VITE_FAVORITE_FROM_OPEN_GAME_ENABLED')

export const shouldOpenMobileGameInIframe = () =>
  parseEnvBoolean('VITE_MOBILE_GAME_IFRAME')

export const shouldUseNewLoginJoinLayout = () =>
  parseEnvBoolean('VITE_USE_NEW_LOGIN_JOIN_LAYOUT')

export const cashRacesUrlEnabled = () =>
  parseEnvBoolean('VITE_USE_CASHRACES')

export const getCashRacesUrl = () => parseEnvString('VITE_CASHRACES_URL')

export const isBalanceAlertEnabled = () =>
  parseEnvBoolean('VITE_USE_PLAYABLE_BALANCE_ALERT')

export const getExternalSiteUrl = () => {
  return isAmberLayout() ? parseEnvString('VITE_EXTERNAL_BASE_URI') : ''
}

export const isNewLobbyEnabled = () =>
  parseEnvBoolean('VITE_USE_BFF_LOBBY')

export const isSoftswissIntegrationEnabled = () =>
  parseEnvBoolean('VITE_SOFTSWISS_INTEGRATION_ENABLED')

export const isNewGameDetailEnabled = () =>
  parseEnvBoolean('VITE_USE_NEW_GAME_DETAIL')

export const isVipEnabled = () => parseEnvBoolean('VITE_USE_VIP_LEVELS')

export const isUnifiedLobbyEnabled = () =>
  parseEnvBoolean('VITE_UNIFIED_LOBBY_ENABLED')

export const cashierOauth = () =>
  parseEnvBoolean('VITE_CASHIER_OAUTH')

export const getCmsSacEndpointUrl = (cmsSacEnabled) => replaceMirrorUrlHelper(parseEnvString(cmsSacEnabled ? 'VITE_CMS_SAC_ENDPOINT': 'VITE_CMS_ENDPOINT'))

export const isFFCmsSacEnabled = () => parseEnvBoolean('VITE_CMS_SAC_FF_ENABLED')

export const isRafEndpointFFEnabled = () =>
  parseEnvBoolean('VITE_RAF_ENDPOINT_FF_ENABLED')

export const isMirrorFFEnabled = () =>
  parseEnvBoolean('VITE_MIRROR_FF_ENABLED')

export const getCmsEndpointExternal = () => replaceMirrorUrlHelper(parseEnvString('VITE_CMS_ENDPOINT_EXTERNAL'))

export const getMirrorAllowedDomains = () => {
  const allowedDomains = parseEnvString('VITE_MIRROR_ALLOWED_DOMAINS')
  return allowedDomains ? allowedDomains.split(',') : []

}

export const isIntercomFFEnabled = () =>
  parseEnvBoolean('VITE_WEBSITES_INTERCOM_SWITCH')

export const isLibraryMode = () => parseEnvBoolean('VITE_LIBRARY_MODE')

export const getExternalSportsbookUrl = () => isAmberLayout() ? parseEnvString('VITE_EXTERNAL_SPORTSBOOK_URI') : ''
