import Env from '@env';
export const openNeedHelpPopUp = () => {
    window.dispatchEvent(
        new CustomEvent('showhelpmodal', {
            bubbles: true,
        })
    );
}

export const getBrandFromGsetting = () => {
    const brand  = Env.get('VITE_GSETTING')
    switch(brand) {
        case 'hrnasite':
            return 'hr'
        case 'sbnasite':
            return 'nsb'
        case 'ssnasite':
            return 'ss'
        case 'wcnasite':
            return 'wc'
        case 'gcsite':
            return 'gc'
        case 'qbcnasite':
            return 'qbc'
        case 'lvnasite':
            return 'lvg'
        default:
            return 'bol'
    }
}
