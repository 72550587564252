import baseTheme from '../baseTheme'
import UniversalFooter from '../../external-components/Footer'
import { getExternalSiteUrl } from '../../../config'
import typography from '../bolnasite/typography'
import noResultsSvg from '../../../assets/icons/no-results.svg'
import { getCssVariablesPalette } from '../CssVariablesPalette'
import merge from 'deepmerge'
import brandPalette from '../lvnasite/palette'

const NoResultsImageAlt = () => {
  return <img src={noResultsSvg} alt="no-results"></img>
}

const lowvigCasinoPartialTheme = () => {
  const cssVariablesPalette = getCssVariablesPalette()
  const palette = merge(brandPalette, cssVariablesPalette)

  return {
    palette,
    brand: {
      namespace: 'lvnasite',
      name: 'Lowvig Casino',
      website: 'Lowvig.ag',
      email: 'help@lowvigcs.ag',
      help: 'https://help.lowvig.ag/',
      themeColor: palette.quaternary800,
      bonusInfoUrl: 'https://help.lowvig.ag/articles/bonus-tutorial-and-faq',
      balanceWidget: {
        name: 'lowvig',
        colors: {
          balanceState: palette.primary,
          balanceCloseState: palette.white,
        },
        customSettings: {
          depositUrl: `${getExternalSiteUrl()}/cashier`,
          pendingBetsUrl: `${getExternalSiteUrl()}/my-account?section=pendingwagers`,
          betHistoryUrl: `${getExternalSiteUrl()}/my-account?section=gradedwagers`,
          transactionsUrl: `${getExternalSiteUrl()}/my-account?section=transactions`,
        },
      },
    },
    sideMenu: {
      selectedImg:
        'brightness(0) saturate(100%) invert(56%) sepia(69%) saturate(325%) hue-rotate(183deg) brightness(88%) contrast(95%)',
      activeImg:
        'brightness(0) saturate(100%) invert(3%) sepia(15%) saturate(991%) hue-rotate(53deg) brightness(98%) contrast(90%)',
    },
    buttons: {
      primaryFrontColor: palette.quaternary800,
      primarySolidColor: palette.primary,
      primarySolidBg: palette.secondary900,
      secondarySolidColor: palette.primary,
      secondarySolidBg: palette.secondary50,
      iconColorDisabled: baseTheme.palette.neutral1_38,
      ghostSolidBg: 'transparent',
      ghostSolidColor: baseTheme.palette.neutral1,
      ghostSolidBorder: baseTheme.palette.neutral1,
      hover: {
        ghostSolidBg: baseTheme.palette.neutral1,
        ghostSolidColor: palette.primaryBlack,
        primaryFrontColor: palette.secondary900,
        primarySolidColor: palette.primary,
        primarySolidBg: palette.secondary900,
        secondarySolidColor: palette.primary,
        secondarySolidBg: palette.secondary50,
      },
    },
    titlePlaceholder: {
      bgColor: palette.gray600,
    },
    opacity: {
      opacity40: 0.4,
      opacity90: 0.9,
    },
    fontFamily: {
      primaryFont: 'Open Sans',
      secondaryFont: 'Lato',
    },
    pageHeading: {
      textTransform: 'title',
      color: palette.primaryBlack,
      fontWeight: typography.fontWeight.bold,
      bgColor: 'transparent',
    },
    emptyPages: {
      noResults: <NoResultsImageAlt />,
      iconColor: palette.primary,
      variant: 'filled',
      textColor: palette.primaryBlack,
    },
    loader: {
      color: palette.primaryBlack,
      fontWeightSemiBold: typography.fontWeight.bold,
    },
    bonusTable: {
      headerSize: 'xl',
      headColor: baseTheme.palette.neutral1_87,
      headRadius: '.25rem .25rem 0 0',
      headBgColor: palette.grayDark,
      tableVariant: 'light',
      rowBg: palette.gray100,
      altRowBg: palette.footerSubTitles,
      action: 'secondary',
      fontWeight: typography.fontWeight.regular,
      detailsWrapperBg: palette.footerSubTitles,
      routePath: '/casino-bonus',
    },
    selectInput: {
      border: `2px solid ${palette.gray300}`,
      borderColorHover: palette.gray300,
      iconColor: baseTheme.palette.neutral3,
      color: baseTheme.palette.neutral3,
      bgColor: baseTheme.palette.neutral1,
    },
    explanatoryFooter: {
      wrapper: palette.placeholder,
      text: baseTheme.palette.neutral1,
    },
    header: {
      logoHeight: '100%',
    },
    needHelp: {
      mainColor: '#0075ff',
    },
    footer: <UniversalFooter />,
    universal101: true,
    edgeTier: {
      breakpoint: 'xs',
      bottom: '4rem',
      bottomloggedOut: '5.3rem',
    },
    gradients: {
      primaryGradient: `linear-gradient(to bottom, ${palette.secondary900}, ${palette.secondary900})`,
      primaryGradientDark: `linear-gradient(to bottom, ${palette.secondary900}, ${palette.secondary900})`,
    },
    universalPrelaunchModal: true,
    cashierUrl: {
      type: 'external',
      url: `${getExternalSiteUrl()}/cashier`,
    },
    appBar: {
      background: palette.primary,
      logoWidth: '120px',
      logoWidthMd: '140px',
    },
    blackjack: {
      iconColor: palette.mainMenuBgColor,
      iconColorHover: palette.mainMenuBgColor,
      bgColor: baseTheme.palette.neutral1,
    },
    infoModal: {
      background: palette.secondary700,
    },
    mobileMenuToggle: {
      bgColor: palette.mainMenuBgColor,
    },
    carouselsTitle: {
      iconColor: palette.secondary500,
      linkColor: palette.secondary900,
    },
    promotions: {
      bgColor: 'transparent',
      fontColor: baseTheme.palette.neutral2,
      titleColor: baseTheme.palette.neutral2,
      subtitleColor: palette.primary50,
      arrowsColor: palette.primary50,
      button: {
        bgColor: palette.primary50,
        hover: palette.primary800,
      },
    },
    searchBar: {
      background: palette.primary,
      closeIconColor: palette.primary,
      svgColor: palette.primary,
    },
    input: {
      color: palette.primary,
      borderColor: palette.primary,
      borderColorHover: palette.primary,
    },
    linkCard: {
      bgColor: palette.gray600,
      bgColorVisited: palette.gray600,
      bgColorHover: palette.gray300,
      activeColor: baseTheme.palette.neutral1,
      hoverColor: baseTheme.palette.neutral1,
      color: baseTheme.palette.neutral1,
    },
    breadcrumb: {
      color: palette.primary50,
      currentColor: palette.primary50,
    },
    navigation: {
      linkSelected: palette.primary,
      linkDefault: palette.lowDeepBlue700,
      linkHover: palette.primary,
      bgColor: palette.mainMenuBgColor,
      iconColor: baseTheme.palette.neutral1,
      afterBg: palette.secondary900,
    },
    pageContentBg: {
      light: baseTheme.palette.neutral1,
    },
    zIndex: {
      appBar: 1301,
      needHelpBackdrop: 1302,
    },
    mainPageContentSpec: {
      paddingTop: '0rem',
      paddingBottom: '4.5rem',
    },
    elementColors: {
      appBackground: baseTheme.palette.neutral1,
    },
    errorPage: {
      color: palette.primaryBlack,
      weight: 700,
      goToHomeButtonVariant: 'primary',
    },
    intercom: {
      brand: 'lowvig',
    },
    hasCasinoBonusSection: false,
    searchModal: {
      headerBg: '#123333',
      active: false,
    },
  }
}

export default lowvigCasinoPartialTheme
