import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import useStyles from './textPlaceholder.styles'

const TextPlaceholder = props => {
  const classes = useStyles(props)

  return <div className={cx(classes.textPlaceholder)}>{props.text}</div>
}

TextPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
}

export default TextPlaceholder
