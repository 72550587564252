import 'need-help-popup'
import { useTheme } from '@material-ui/core'
import { overrideScopeLiveChat } from '../../../liveChat'
import { getBrandFromGsetting } from './needHelp'
import { CustomTheme } from '../../CustomTheme/types'
import { useStyles } from './needHelp.styles'
import { useEffect, useRef } from 'react'
import { isIntercomFFEnabled } from '../../../config'


const NeedHelpPopUp = () => {
    const theme  = useTheme<CustomTheme>()
    const brand = getBrandFromGsetting()
    const styles = useStyles()
    const needHelpRef = useRef(null)

    overrideScopeLiveChat()

    useEffect(() => {
        if (needHelpRef) {
            const { current } = needHelpRef
            // need help reads the intercom cookie
            current.intercomOnly = isIntercomFFEnabled()
        }

    }, [needHelpRef])

    return (
    <div className={styles.backdrop}>
        {/* @ts-expect-error need-help-popup is an external web component without typescript definition */}
        <need-help-popup ref={needHelpRef} color={theme.custom.needHelp?.mainColor || theme.custom.palette.quaternary800} brand={brand} title='Help Center'></need-help-popup>
    </div>
    )
}
export default NeedHelpPopUp
