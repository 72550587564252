import { makeStyles } from '../materialUiWrapper'
import texts from '../../config/texts'
import { mq, hoverDevice } from '../../config/utils'

const useStyles = makeStyles(theme => ({
  header: {
    transform: 'translateZ(0)',
  },
  globalHeader: {
    height: '3.5rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0.75rem',
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar,
    backgroundColor: theme.custom.palette.quaternary800,

    [mq('sm')]: {
      height: '4.5rem',
      padding: '0 1.5rem',
    },
    [mq('md')]: {
      padding: '0 2.25rem',
    },
    [mq('lg')]: {
      padding: '0 3rem',
    },
    [mq('xl')]: {
      padding: '0 3.75rem',
    },
  },
  shadow: {
    boxShadow: theme.custom.shadows.header,
  },
  logo: {
    display: 'block',
    height: theme.custom.header?.logoHeight || '48px',
    marginLeft: theme.custom.header?.logoMarginLeft || '0px',
    alignSelf: theme.custom.header?.logoAlignSelf || 'flex-end',

    [mq('sm')]: {
      height: theme.custom.header?.logoHeight || '64px',
    },
    [mq('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
    '& img': {
      height: '100%',
    },
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3.5rem',
  },
  navigationLink: {
    padding: '0.75rem 0',
    borderBottom: `2px solid transparent`,
    ...texts.stepDown1,
    color: theme.custom.globalHeader?.navigationLinkColor || theme.custom.palette.neutral1,
    '&:not(:last-child)': {
      marginRight: '2rem',
    },
    [hoverDevice()]: {
      '&:hover': {
        color: theme.custom.palette.btnPrimary || theme.custom.palette.tertiary800,
      },
    },
    '&.selected': {
      borderColor: theme.custom.globalHeader?.navigationSelectedLinkBorderColor || theme.custom.palette.neutral1,
      color: theme.custom.globalHeader?.navigationSelectedLinkColor || theme.custom.palette.neutral1,
      cursor: 'default',
      [hoverDevice()]: {
        '&:hover': {
          color: theme.custom.globalHeader?.navigationSelectedHoverColor || theme.custom.palette.neutral1,
        },
      },
    },
  },
  wrapActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '1',
    marginRight: '0.375rem',

    [mq('sm')]: {
      marginRight: '1.125rem',
    },

    '& button:not(:last-child)': {
      marginRight: '1.25rem',
    },
  },
  wrapTriggers: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  triggerButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px hidden transparent',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: 'transparent',

    '& + &': {
      marginLeft: '0.5rem',
      [mq('xs')]: {
        marginLeft: '0.75rem',
      },
    },

    '&[disabled]': {
      cursor: 'default',
    },

    '& .icon': {
      width: '1.5rem',
      height: '1.5rem',
      fill: theme.custom.globalHeader?.triggerButtonIconColor || theme.custom.palette.neutral1
    },
    [hoverDevice()]: {
      '&:hover': {
        '& .icon': {
          fill:  theme.custom.palette.btnPrimary || theme.custom.palette.tertiary800,
        },
      },
    }
  },
}))

export default useStyles
