import { makeStyles } from '../../../../materialUiWrapper'
import { slideTheme } from '../../../../../config/slideTheme'
import { mq } from '../../../../../config/utils'

const useStyles = makeStyles({
  result: {
    backgroundColor: ({backgroundColor}) => backgroundColor,
    height: slideTheme.result.height.xs,
    width: '1.052rem',
    color: 'white',
    marginTop: '0.428rem',
		fontSize: slideTheme.result.fontSize.xs,
    fontWeight: 700,
    flex: '0 0 15%',
    '& label': {
      display: 'flex',
      alignItems: 'center',
      height: '95%',
      justifyContent: 'center'
    },
    '&:last-child': {
      marginRight: 0
    },
    '&:nth-child(5)': {
      marginRight: 0
    },
    [mq('sm')]: {
      height: slideTheme.result.height.sm,
		  fontSize: slideTheme.result.fontSize.sm,
    },
    [mq('md')]: {
      height: slideTheme.result.height.md,
		  fontSize: slideTheme.result.fontSize.md,
    }
  }
})

export default useStyles
