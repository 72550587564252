import React, { useState } from 'react'
import GlobalHeader from '../../../ui/components/GlobalHeader'
import IndicatorBar from '../../../ui/components/IndicatorBar'
import IndicatorDropdown from '../../../ui/components/IndicatorBar/IndicatorDropdown'
import { useTranslations } from '../../../i18n'
import { BonusWalletSummary } from '../../../ui/BonusWallet'
import Indicator from '../../../ui/components/IndicatorBar/Indicator'
import MediaQuery from 'react-responsive'
import Avatar from '../../../ui/components/Avatar'
import Button from '../../../ui/components/Button'
import { useAuth } from '../../../authentication'
import { useBalance, useBonus, useGetCategories } from '../../../asyncData'
import { buildLinks } from './utils'
import { isSoftswissIntegrationEnabled } from '../../../config'
import { useTheme } from '@material-ui/core'
import SideMenu from '../../../ui/components/SideMenu'
import DefaultMobileActions from './DefaultMobileActions'

const DefaultHeader = (props) => {
  const formatMessage = useTranslations()

  const {
    initialized,
    logged,
    redirectToJoin,
    redirectToLogin,
    logout,
  } = useAuth()

  const {
    isLoading: isLoadingBalance,
    error: balanceError,
    data: balanceData,
  } = useBalance()

  const {
    isLoading: isLoadingBonus,
    isError: isErrorBonus,
    data: bonusData,
  } = useBonus()

  const { data: categoryData } = useGetCategories()

  const theme = useTheme()

  const categories = categoryData
    ? categoryData[
        isSoftswissIntegrationEnabled() ? 'Categories' : 'CategoryGames'
      ]
    : []

  const [open, setOpen] = useState(false)

  const links = buildLinks(categories, logout, theme)

  const headerLinks = [logged ? links.cashier : links.banking, links.help]

  const menuItems = logged
    ? [
        links.myAccount,
        links.cashier,
        ...links.categories,
        links.promotions,
        links.help,
        links.logout,
      ]
    : [...links.categories, links.promotions, links.banking, links.help]

  return (
    <>
      <GlobalHeader
        logo={`/logo.svg`}
        logoSlim={`/logo-slim.svg`}
        links={headerLinks}
        onMenuClick={() => setOpen(true)}
        logged={logged}
      >
        {logged ? (
          <IndicatorBar>
            {balanceData?.Profile?.IsBonusWalletAllowed ? (
              <IndicatorDropdown
                name={formatMessage('noun:total-balance')}
                amount={balanceData?.Profile?.TotalBalance}
                isLoading={isLoadingBalance}
                error={balanceError}
              >
                <BonusWalletSummary
                  totalBalance={balanceData?.Profile?.TotalBalance}
                  cashBalance={balanceData?.Profile?.RealBalance}
                  bonusBalance={balanceData?.Profile?.BonusMoneyBalance}
                  lockedCash={balanceData?.Profile?.LockedCash}
                  bonus={balanceData?.Profile?.BonusFunds}
                  isLoadingBonus={isLoadingBonus}
                  promotionalBonus={bonusData?.BonusBalance}
                  hasError={isErrorBonus}
                />
              </IndicatorDropdown>
            ) : (
              <>
                <Indicator
                  id={'totalBalanceHeader'}
                  isLoading={isLoadingBalance}
                  name={formatMessage('noun:balance')}
                  to="/my-account"
                  amount={balanceData?.Profile?.RealBalance}
                />
                <Indicator
                  isLoading={isLoadingBonus}
                  name={formatMessage('noun:bonus')}
                  to="/my-account/my-bonus"
                  id="bonus-number"
                  amount={bonusData?.BonusBalance}
                />
              </>
            )}
            <MediaQuery minWidth={600}>
              <Avatar
                id="my-account-button"
                to="/my-account"
                text={formatMessage('my-account')}
              />
            </MediaQuery>
          </IndicatorBar>
        ) : (
          <>
            <Button
              disabled={!initialized}
              variant="secondary"
              height="small"
              id="join-now-button"
              onClick={redirectToJoin}
            >
              {formatMessage('action:join-now')}
            </Button>
            <Button
              disabled={!initialized}
              height="small"
              id="login-button"
              onClick={redirectToLogin}
            >
              {formatMessage('action:login')}
            </Button>
          </>
        )}
      </GlobalHeader>
      {props?.children}
      {logged ? null : 
        <DefaultMobileActions/>
      }
      <SideMenu
        isOpen={open}
        items={menuItems}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default DefaultHeader
