import getIconAssetsMap from './getIconAssetsMap'
import { useEffect, useState } from 'react'

interface IconProps{
  name?: string;
  className?: string;
  customStyles?: React.CSSProperties;
  url?: string;
}

const Icon = ({name, className, url, customStyles} : IconProps) => {
  const [icons, setIcons] = useState({})
  useEffect(()=>{
    getIconAssetsMap().then((data)=>{
      setIcons(data)
    })
  }, [])

  if(url) return <img src={`${url}`} className={className} alt={'custom-icon-' + name} style={customStyles} />

  return (
    <span 
      className={className}
      dangerouslySetInnerHTML={{__html: icons[name]}}
      style={customStyles}
    />
  )
}

Icon.iconNames = Object.keys(getIconAssetsMap())

export default Icon