import React from 'react'
import Footer from '../../../ui/components/Footer'
import { useTheme } from '@material-ui/core'

const DefaultFooter = () => {
  const theme = useTheme()
  return theme.custom?.footer ?? <Footer />
}

export default DefaultFooter
