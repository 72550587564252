const typography = {
  fontFamily: 'Arimo',
  fontFamilySecondary: 'Lato',
  fontWeight: {
    regular: 400,
    bold: 700,
  },
  fontSize: {
    xxxs: '0.5rem',
    xxs: '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.875rem',
    xxl: '1.875rem',
    xxxl: '2.25rem',
    xxxxl: '3rem',
    xxxxxl: '4rem',
  }
}

export default typography