import { makeStyles } from '../../../materialUiWrapper'
import { slideTheme } from '../../../../config/slideTheme'
import { mq } from '../../../../config/utils'

const useStyles = makeStyles(theme => ({
  betLimits: {
    height: slideTheme.betLimits.height.xs,
    display: 'flex',
    alignItems: 'center', 
    justifyContent: 'center',
    color: 'white',
    borderRadius: '3px',
    backgroundColor: '#009761',
    fontSize: slideTheme.betLimits.fontSize.xs,
    fontWeight: 700,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    textRendering: 'optimizeLegibility',
    width: '100%',
    textAlign: 'center',
    '& label': {
      verticalAlign: 'sub'
    },

    [mq('sm')]: {
      height: slideTheme.betLimits.height.sm,
      fontSize: slideTheme.betLimits.fontSize.sm,
    },
    [mq('md')]: {
      height: slideTheme.betLimits.height.md,
      fontSize: slideTheme.betLimits.fontSize.md,
    }
  }
}))

export default useStyles
