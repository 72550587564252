import { useCallback } from "react"
import { replaceMirrorUrlHelper } from "../config/originSingleton"

export const useRedirectToExternal = () => {
    const redirectToExternalUrl = useCallback((url) => {
        const replacedUrl = replaceMirrorUrlHelper(url)
        if (replacedUrl && typeof replacedUrl === 'string') window.location.assign(replacedUrl)
    }, [])

    const getLocationOrigin = useCallback(() => {
        return window.location.origin
    }, [])

    return { redirectToExternalUrl, getLocationOrigin }
}