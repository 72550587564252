import { makeStyles } from '../materialUiWrapper'
import { mq } from '../../config/utils'
import { isMobileOrTablet } from '../../../libs/uaparser'
import { isLiveCasinoMobile } from '../../../libs/utils'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    margin: '0 auto',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',

    [mq('sm')]: {
      paddingLeft: isLiveCasinoMobile(window.location.pathname, isMobileOrTablet())? '0rem' : '1.5rem',
      paddingRight: isLiveCasinoMobile(window.location.pathname, isMobileOrTablet())? '0rem' : '1.5rem',
    },
    [mq('md')]: {
      paddingLeft: isLiveCasinoMobile(window.location.pathname, isMobileOrTablet())? '0rem' : '2.25rem',
      paddingRight: isLiveCasinoMobile(window.location.pathname, isMobileOrTablet())? '0rem' : '2.25rem',
    },
    [mq('lg')]: {
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    [mq('xl')]: {
      paddingLeft: '3.75rem',
      paddingRight: '3.75rem',
    },
    '& $container': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  sm: {
    [mq('sm')]: {
      maxWidth: '34.5rem',
    },
    [mq('md')]: {
      maxWidth: '42.5rem',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  xl: {
    [mq('lg')]: {
      maxWidth: '69rem',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  left: {
    marginLeft: 0,
    marginRight: 0,
  },
}))

export default useStyles
