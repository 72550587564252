import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useStyles from './itemlink.styles';
import PropTypes from 'prop-types';

const ItemLink = props => {
    const { label, route } = props;
    const [active, setActive] = useState(false);
    const location = useLocation();
    useEffect(() => {
        setActive(route !== '/' && location.pathname.match(new RegExp(route), 'g'));

        return setActive(route !== '/' && location.pathname.match(new RegExp(route), 'g'));
        
    }, [location]);

    const classes = useStyles();
    const activeLink = active && classes.active;
    return <Link className={`${activeLink} ${classes.link}`} to={`${route}`}> {label} </Link>
}

ItemLink.propTypes = {
    label: PropTypes.string,
    route: PropTypes.string,
    active: PropTypes.bool
}

export default ItemLink;