import { useQuery } from 'react-query'

import { useAuth } from '../../authentication'
import { getTournament } from '../bff'

export const useGetTournamentUrl = ({ customerId }) => {
  const { initialized } = useAuth()

  const query = useQuery(
    ['get-tournament-url'],
    () => getTournament(customerId),
    { enabled: initialized, useErrorBoundary: true },
  )

  return query
}
