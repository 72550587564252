import { makeStyles } from '@material-ui/core'
import mq from '../../../config/mq'

export const useStyles = makeStyles(
   theme => ({

    customFixedButton: {
      minWidth: '5.5625rem',
      height: '10px',
      fontFamily: theme.custom.fontFamily.primaryFont,
      padding: '0px ',
      [mq('xxs')]: {
        minWidth: '4.75rem',
      },
      [mq('md')]: {
        minWidth: '5.5625rem',
      },
    },
    customBankingButton: {
      minWidth: '6.375rem',
        '&:hover': {
          boxShadow: '0px 8px 6px rgba(0, 0, 0, 0.15)'
        }
    },
    customNewNavButton: {
      height: '1.5rem',
      marginRight: '1rem',
      minWidth: '2rem',
      padding: '0.375rem 1rem',
    },
    customNewNavDepositButton: {
      marginLeft: '1rem',
    },
    buttonIcon: {
      marginTop: '5px',
      marginRight: '6px',
    },
   })
)