import { useQuery } from 'react-query'

import { useAuth } from '../../authentication'

import { updateBalance } from './services'

export const useBalance = options => {
  const { logged, username } = useAuth()

  return useQuery(['balance', username], updateBalance, {
    enabled: logged,
    ...options,
  })
}
