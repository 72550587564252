import { makeStyles } from '../components/materialUiWrapper'
import texts from '../config/texts'
import { shadows } from '../config/utils'

const useStyles = makeStyles(theme => ({
  wrapper: {
    ...shadows(theme),
  },
  shadowLeft: {},
  shadowRight: {},
  collapsableTable: {
    tableLayout: 'fixed',
    width: '100%',
    borderCollapse: 'collapse',
    color: theme.custom.palette.neutral1,
    backgroundColor: theme.custom.palette.primaryBlue || 'transparent',
  },
  collapsableTableHeading: {
    width: 130,
    padding: '0.75rem 1rem',
    borderBottom: `2px solid ${theme.custom.palette.neutral1_87}`,
    ...texts.stepDown1,
    fontWeight: theme.custom.fontWeight.bold,
    textAlign: 'left',
    lineHeight: theme.custom.lineHeight.xs,
    verticalAlign: 'top',

    '&:nth-child(1)': {
      width: 148,
    },
    '&:nth-child(2)': {
      width: 130,
    },
    '&:nth-child(3)': {
      width: 130,
      textAlign: 'right',
    },
    '&:nth-child(4)': {
      width: 140,
      textAlign: 'right',
    },
    '&:nth-child(5)': {
      width: 130,
      textAlign: 'right',
    },
    '&:nth-child(6)': {
      width: 110,
      textAlign: 'right',
    },
    '&:nth-child(7)': {
      width: 156,
    },
  },
  collapsableTableRow: {
    '&:nth-child(4n), &:nth-child(4n-1)': {
      backgroundColor:  theme.custom.palette.neutral1_5,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.custom.palette.neutral1_16}`,
    },

    '&$isVisible': {
      '& $collapsableButton': {
        transform: 'translateY(0.125rem) rotate(90deg)',
      },
    },
  },
  isVisible: {},
  collapsableTableData: {
    padding: '0.875rem 1rem',
    ...texts.stepDown1,
    lineHeight: theme.custom.lineHeight.xs,
    color: theme.custom.palette.neutral1_87,

    '&:nth-child(n + 3)': {
      textAlign: 'right',
    },

    'td&': {
      borderTop: `1px solid ${theme.custom.palette.neutral1_16}`,
    },
  },
  collapsableInnerTable: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  collapsableButton: {
    padding: 0,
    marginRight: '0.375rem',
    border: 0,
    color: 'currentColor',
    backgroundColor: 'transparent',
    transform: 'translateY(0.125rem)',
    transition: 'transform 300ms',
    cursor: 'pointer',

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-0.3125rem',
      bottom: '-0.3125rem',
      left: '-0.3125rem',
      right: '-0.3125rem',
    },

    '& > span': {
      display: 'flex',
    },
  },
  clamp: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  features: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridColumnGap: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  feature: {
    padding: '0.875rem 0 2rem',
    ...texts.stepDown1,
  },
  featureLabel: {
    display: 'block',
    marginBottom: '0.25rem',
    fontWeight: theme.custom.fontWeight.bold,
  },
}))

export default useStyles
